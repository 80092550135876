import { useService, useQuery } from '@hooks';
import { makeTipMatchMock, makeTipMock } from '@mocks';
import { dayjs } from '@utils';
import type { TTipMatch } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TPatchTipForm } from '@schemas';

export const useTipMatchesQuery = (newTipForm: TPatchTipForm, options?: UseQueryOptions<TTipMatch[], Error>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TTipMatch[], Error>(
		['tipMatches', newTipForm],
		async () => {
			// TODO: Implement API call to get tip matches
			await new Promise((resolve) => setTimeout(resolve, 1000));
			if (newTipForm.categories?.length === 1 && newTipForm.categories[0] === 'Other') return [];

			const mocks = Array.from({ length: 5 }).map(() => makeTipMatchMock());
			mocks.forEach((item) =>
				reactQuery.queryClient.setQueryData(reactQuery.queryKeys.tip(item.id), () => {
					makeTipMock({ ...item, link: item.link, linkLabel: 'Amazon' });
				}),
			);
			return mocks;
		},
		{
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			...options,
		},
	);
};
