import OktaAuth, { IdxStatus } from '@okta/okta-auth-js';
import { ROUTES } from '@constants';
import { AuthenticatorKey } from '@okta/okta-auth-js';
import { OktaAccountLockedError } from './errors/OktaAccountLockedError';
import { OktaUnknownError } from './errors/OktaUnknownError';
import { OktaInvalidCredentialsError } from './errors/OktaInvalidCredentialsError';
import { OktaMfaRequiredError } from './errors/OktaMfaRequiredError';
import { OktaInvalidPassCodeError } from './errors/OktaInvalidPasscodeError';
import { OktaAuthenticatorEnrollRequiredError } from './errors/OktaAuthenticatorEnrollRequiredError';
import { OktaSetNewPasswordRequiredError } from './errors/OktaSetNewPasswordRequiredError';
import { OktaWrongPasswordError } from './errors/OktaWrongPasswordError';
import { OktaVerificationChallengeRequiredError } from './errors/OktaVerificationChallengeRequiredError';
import type { TAuthCredentials, TAuthData } from '@typings';
import type { IOktaConfig } from '../../interfaces/AppConfig.interface';
import type { IDevLogger } from '../../interfaces/DevLogger.interface';
import type { IOktaService, TOktaVerificationFlow, TVerificationResult } from '../../interfaces/OktaService.interface';
import type { IRouterService } from '../../interfaces/RouterService.interface';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { OktaApiService } from '@tiger21-llc/connect-shared/src/services/OktaApiService';
import type { ReduxService } from '../ReduxService';

/**	Client to communicate with Okta auth service.
 * 	The proxy is used (via Vite locally and Netlify remotely) to make requests to Okta.
 */
export class OktaService implements IOktaService {
	static inject = ['AppConfigService', 'OktaApiService', 'logger', 'RouterService', 'ReduxService'] as const;
	constructor(
		private readonly oktaConfig: IOktaConfig,
		private readonly oktaApi: OktaApiService,
		logger: IDevLogger,
		private readonly router: IRouterService,
		private readonly redux: ReduxService,
	) {
		this.logger = logger.child('OktaService');

		const issuerUrl = this.getOktaIssuerUrl();
		const postLogoutRedirectUri = this.router.origin + ROUTES.signIn();
		const redirectUri = ROUTES.signInCallback();
		this.DEVICE_ID = this.redux.store.getState().me.deviceId;

		this.logger.debug('Initialize OktaAuth');
		this.oktaAuth = new OktaAuth({
			idx: {
				exchangeCodeForTokens: true,
			},
			issuer: issuerUrl,
			clientId: oktaConfig.OKTA_CLIENT_ID,
			redirectUri,
			scopes: ['openid', 'profile', 'email'],
			pkce: true,
			postLogoutRedirectUri,
			logoutUrl: this.router.origin + ROUTES.signOut(),
			headers: {
				'X-Device-Fingerprint': this.DEVICE_ID,
			},
			services: {
				autoRenew: false,
				autoRemove: false,
				syncStorage: false,
			},
			tokenManager: {
				autoRemove: false,
				autoRenew: false,
				syncStorage: false,
			},
			httpRequestInterceptors: [
				(request) => {
					/** When OKTA send 'identify' request (https://tiger21.oktapreview.com/idp/idx/identify)
					 * 	it ignores issuerUrl. So we need to replace it manually. [@dmitriy.nikolenko]
					 */
					const oktaDomainUrl = `https://${oktaConfig.OKTA_DOMAIN}`;
					if (request.url?.startsWith(oktaDomainUrl)) {
						request.url = request.url.replace(oktaDomainUrl, issuerUrl);
					}
				},
			],
			authorizeUrl: window.location.origin + ROUTES.signIn(), // in order to open internal page, but not a remote Okta one.
		});
	}

	private logger: IDevLogger;
	private currentIdxTransaction: IdxTransaction | undefined; // cache current MFA transaction to reuse.
	private DEVICE_ID: string;
	readonly oktaAuth: OktaAuth;

	get accessToken() {
		return this.oktaAuth.getAccessToken();
	}

	get revokeAccessToken() {
		return this.oktaAuth.revokeAccessToken();
	}

	getOktaIssuerUrl() {
		if (this.router.origin.includes('localhost')) {
			// it must be local DEV environment so we need to use proxy provided by vite.
			return `${this.router.origin}/okta/${this.oktaConfig.OKTA_DOMAIN}`;
		}
		return `https://${this.oktaConfig.OKTA_DOMAIN}`;
	}

	async signIn({ login, password, rememberMe }: TAuthCredentials) {
		this.logger.debug(`sign in for ${login}.`);

		const authenticateIdxTransaction = await this.oktaAuth.idx
			.authenticate({
				username: login,
				password,
				// The custom field which should be sent but absent in typings.
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				rememberMe,
			})
			.catch((error) => {
				this.signOut();
				throw error;
			});

		if (OktaAccountLockedError.shouldThrowError(authenticateIdxTransaction)) {
			throw new OktaAccountLockedError();
		} else if (OktaInvalidCredentialsError.shouldThrowError(authenticateIdxTransaction)) {
			throw new OktaInvalidCredentialsError();
		} else if (
			authenticateIdxTransaction.status === IdxStatus.TERMINAL ||
			authenticateIdxTransaction.status === IdxStatus.FAILURE
		) {
			this.cancelTransaction();
			throw new OktaUnknownError(authenticateIdxTransaction);
		} else if (authenticateIdxTransaction.status === IdxStatus.CANCELED) {
			// TODO have no idea what to do in this case
		} else if (OktaAuthenticatorEnrollRequiredError.shouldThrowError(authenticateIdxTransaction)) {
			this.cancelTransaction();
			throw new OktaAuthenticatorEnrollRequiredError();
		} else if (authenticateIdxTransaction.status === IdxStatus.SUCCESS) {
			await this.completeSigningIn(authenticateIdxTransaction);
		} else if (OktaMfaRequiredError.shouldThrow(authenticateIdxTransaction)) {
			this.currentIdxTransaction = authenticateIdxTransaction; // save to reuse in Verify Sign In step.
		}

		return authenticateIdxTransaction;
	}

	async completeSigningIn(idxTransaction: IdxTransaction) {
		const oktaAccessToken = String(idxTransaction.tokens?.accessToken?.accessToken);
		const tokens = await this.oktaApi.createSession({ oktaAccessToken }, this.DEVICE_ID);
		this.saveTokensResponse(tokens);
	}

	async refreshAccessToken() {
		try {
			const refreshToken = this.authStore.refreshToken;
			this.logger.debug('try to refresh access token', refreshToken);
			if (!refreshToken) {
				throw new Error('No refresh token');
			}

			const tokenResponse = await this.oktaApi.refreshSession({ refreshToken }, this.DEVICE_ID);
			this.saveTokensResponse(tokenResponse);
			return tokenResponse;
		} catch (error) {
			this.resetTokensResponse();
			this.logger.error(error);
			throw error;
		}
	}

	async signOut() {
		this.logger.debug(`sign out.`);
		await Promise.allSettled([
			this.resetTokensResponse(),
			this.cancelTransaction(),
			this.oktaAuth.tokenManager.clear(),
			this.oktaAuth.idx.clearTransactionMeta(),
		]);
		await this.oktaAuth.signOut();
	}

	async getCurrentTransaction(): Promise<IdxTransaction | undefined> {
		return this.currentIdxTransaction;
	}

	async startVerification(authenticatorKey: AuthenticatorKey) {
		this.logger.debug('startVerification');
		const idxTransaction = await this.getCurrentTransaction();
		if (!idxTransaction) throw new Error('No transaction found');

		const authenticatorKeys = this.getAvailableFactorTypes(idxTransaction);

		const targetAuthenticatorKey = String(
			authenticatorKeys.find((option) => option === authenticatorKey),
		) as AuthenticatorKey;
		if (!targetAuthenticatorKey) throw new Error('The target AuthenticatorKey type not found');
		await this.oktaAuth.idx.proceed({
			step: 'select-authenticator-authenticate',
			authenticator: targetAuthenticatorKey,
		});

		const methodType = this.getMethodTypeForFactorType(targetAuthenticatorKey);
		const mfaChallengeTransaction = await this.oktaAuth.idx.proceed({
			step: 'authenticator-verification-data',
			methodType,
		});

		if (OktaInvalidCredentialsError.shouldThrowError(mfaChallengeTransaction)) {
			throw new OktaInvalidCredentialsError(); // need to return to sign in here
		}

		this.currentIdxTransaction = mfaChallengeTransaction;
		return mfaChallengeTransaction;
	}

	async finishVerification(passCode: string, flow: TOktaVerificationFlow | undefined): Promise<TVerificationResult> {
		this.logger.debug('finishVerification');

		const authenticateTransaction = await this.oktaAuth.idx.proceed({ verificationCode: passCode });

		if (authenticateTransaction.status === 'SUCCESS') {
			await this.completeSigningIn(authenticateTransaction);
			return 'success';
		} else if (OktaInvalidPassCodeError.shouldThrow(authenticateTransaction)) {
			throw new OktaInvalidPassCodeError(flow);
		} else if (OktaSetNewPasswordRequiredError.shouldThrow(authenticateTransaction)) {
			return 'new-password-required';
		} else if (OktaVerificationChallengeRequiredError.shouldThrow(authenticateTransaction)) {
			this.currentIdxTransaction = authenticateTransaction;
			return 'next-verification-required';
		} else if (OktaAuthenticatorEnrollRequiredError.shouldThrowError(authenticateTransaction)) {
			this.cancelTransaction();
			return 'authenticator-enroll-required';
		} else {
			throw new OktaUnknownError(authenticateTransaction);
		}
	}

	async cancelTransaction() {
		this.logger.debug('cancelVerification');
		this.currentIdxTransaction = undefined;
		await this.oktaAuth.idx.cancel().catch();
	}

	async recoverPassword(username: string): Promise<TVerificationResult> {
		if (username.endsWith('@tiger21.com') || username.endsWith('@tiger21chair.com')) {
			window.open('https://apps.tiger21.com/signin/forgot-password');
			return 'success';
		}
		await this.oktaAuth.idx.startTransaction({ flow: 'recoverPassword' });
		const recoverPasswordTransaction = await this.oktaAuth.idx.proceed({ username });

		this.currentIdxTransaction = recoverPasswordTransaction;
		return 'next-verification-required';
	}

	async verifyRecoverPassword(verificationCode: string) {
		await this.oktaAuth.idx.proceed({ verificationCode });
	}

	async setNewPassword(newPassword: string) {
		const setNewPasswordTransaction = await this.oktaAuth.idx.proceed({ password: newPassword });

		if (OktaWrongPasswordError.shouldThrowError(setNewPasswordTransaction))
			throw new OktaWrongPasswordError(setNewPasswordTransaction);
	}

	// Redux state.

	protected get authStore() {
		return this.redux.store.getState().auth;
	}

	protected saveTokensResponse(tokenResponse: TAuthData) {
		this.redux.store.dispatch(this.redux.me.removeImpersonateToken()); // to make sure that impersonation is not active in case of incorrect sign out when a user was impersonated. (@see T21C-7935)
		this.redux.store.dispatch(this.redux.auth.saveTokensResponse(tokenResponse));
	}

	async resetTokensResponse() {
		this.logger.debug('reset token response');
		this.redux.store.dispatch(this.redux.auth.resetTokensResponse());
	}

	// Helpers

	protected getAvailableFactorTypes(idxTransaction: IdxTransaction): AuthenticatorKey[] {
		const selectAuthenticatorAuthenticateStep = idxTransaction.availableSteps?.find(
			(step) => step.name === 'select-authenticator-authenticate',
		);
		const authenticatorKeys =
			(selectAuthenticatorAuthenticateStep?.inputs?.[0]?.options?.map(
				(option) => option.value,
			) as AuthenticatorKey[]) ?? [];

		return authenticatorKeys;
	}

	protected getMethodTypeForFactorType(authenticatorKey: AuthenticatorKey): 'sms' | 'email' | undefined {
		const methodType =
			authenticatorKey === AuthenticatorKey.PHONE_NUMBER
				? 'sms'
				: authenticatorKey === AuthenticatorKey.OKTA_EMAIL
					? 'email'
					: undefined;

		return methodType;
	}
}
