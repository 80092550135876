import { useRef, useTranslation } from '@hooks';
import { makeHtmlContent, noop } from '@utils';
import styles from './TipCollapsibleTextSection.module.css';
import React, { useState } from 'react';
import CollapsibleText, { type ICollapsibleTextProps } from '@ui-kit/components/CollapsibleText/CollapsibleText';
import TextAreaEditTip from '@ui-modules/tipJar/components/TextAreaEditTip';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';

const TipCollapsibleTextSection = ({
	text,
	title,
	collapsedTitle,
	expandedTitle,
	textHighlighted,
	isTextButtonLight,
	isTipEditable,
	tipId,
	onTipPatched,
}: ICollapsibleTextSectionProps) => {
	const { t } = useTranslation();
	const collapseRef = useRef<HTMLSpanElement>(null);
	const [isEditing, setIsEditing] = useState(false);

	const toggleIsEditing = () => {
		setIsEditing((prev) => !prev);
	};

	return (
		<div className={styles.tipCollapsibleTextSection}>
			<span className={styles.tipCollapsibleTextSection__title} ref={collapseRef}>
				{title}
			</span>
			{isTipEditable && !isEditing ? (
				<span className={styles.tipCollapsibleTextSection__edit} onClick={toggleIsEditing} onKeyDown={noop}>
					{`(${t('Edit')})`}
				</span>
			) : null}
			{!isEditing ? (
				<CollapsibleText
					collapsedNumberOfLines={4}
					collapsedTitle={collapsedTitle}
					expandedTitle={expandedTitle}
					isTextButtonLight={isTextButtonLight}
					textHighlighted={textHighlighted}
					onCollapse={() => collapseRef?.current?.scrollIntoView({ behavior: 'smooth' })}
				>
					<div
						dangerouslySetInnerHTML={{
							__html: makeHtmlContent(text),
						}}
					/>
				</CollapsibleText>
			) : (
				<TextAreaEditTip<'description'>
					initialValue={text}
					propertyName="description"
					tipId={tipId}
					onCancel={toggleIsEditing}
					onTipPatched={(formValues) => {
						toggleIsEditing();
						onTipPatched?.(formValues);
					}}
				/>
			)}
		</div>
	);
};

export interface ICollapsibleTextSectionProps
	extends IPatchTipFormMainProps,
		Pick<ICollapsibleTextProps, 'collapsedTitle' | 'expandedTitle' | 'textHighlighted'> {
	text: string;
	title: string;
	isTextButtonLight?: boolean;
	isTipEditable?: boolean;
}

export default TipCollapsibleTextSection;
