import React from 'react';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useTipContributorsQuery } from '@ui-modules/tipJar/hooks/useTipContributorsQuery';
import { ActivityIndicator, Avatar } from '@ui-kit';
import styles from './TipContributors.module.css';

const TipContributors = ({ tipId, maxVisible = 4 }: ITipContributors) => {
	const { data: tip } = useTipQuery(tipId);
	const { data: tipContributors = [], isSuccess: isContributorsSuccess } = useTipContributorsQuery(tipId);

	const visibleContributors = tipContributors.slice(0, maxVisible);
	const remainingCount = tipContributors.length - visibleContributors.length;

	if (!tip || !isContributorsSuccess) return <ActivityIndicator size="small" />;

	return (
		<div className={styles.tipContributors}>
			{visibleContributors.map((contributor, index) => (
				<div
					className={styles.tipContributors__avatar}
					key={`${index}-${contributor.id}`}
					style={{ marginLeft: index === 0 ? 0 : -15 }}
				>
					<Avatar
						className={styles.tipContributors__avatarInner}
						imageUri={contributor.avatarUrl}
						outline={tip.owner.roles.includes('ROLE_CHAIR')}
						size={24}
						title={contributor.fullName}
					/>
				</div>
			))}
			{remainingCount > 0 && <div className={styles.tipContributors__lastImage}>+{remainingCount}</div>}
		</div>
	);
};

interface ITipContributors {
	tipId: string;
	maxVisible?: number;
}

export default TipContributors;
