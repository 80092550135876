import React from 'react';
import { useTranslation } from '@hooks';
import styles from './FiltersPageBody.module.css';
import FiltersSlide from '../../assets/filters-slide.png';

const FiltersPageBody = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Find your next discovery')}</p>
			<img alt="FiltersSlide" loading="lazy" src={FiltersSlide} />
		</div>
	);
};

export default FiltersPageBody;
