import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TTipsFilter, TTipWithCommunity } from '@typings';
import { GROUP_MOCK } from '@mocks';

export const useGroupTipsQuery = (filters?: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TTipWithCommunity>(
		reactQuery.queryKeys.groupsTips(filters),
		async ({ pageParam = 1 }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1500));
			// TODO it is to debug and demo the empty state
			if (filters?.categories?.length === 1 && filters?.categories[0] === 'Other')
				return {
					data: [],
					meta: { hasNext: false, hasPrevious: false },
					page: 1,
				};
			const filteredTips = GROUP_MOCK.filter((tip) => {
				// Filter by categories
				if (filters?.categories && filters.categories.length > 0) {
					return filters.categories.some((category) => tip.categories.includes(category));
				}
				return true;
			});
			return {
				data: filteredTips,
				meta: { hasNext: pageParam <= 5, hasPrevious: false },
				page: Number(pageParam),
			};
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
