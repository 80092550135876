import { clsx } from '@utils';
import { CrossIcon, PlusIcon } from '../../icons';
import When from '../When';
import styles from './TagRows.module.css';

const TagRows = ({ title, tags, mode = 'column', selectedTags = [], onSelect }: ITagRowsProps) => {
	const selectable = !!onSelect;

	return (
		<>
			{title ? <span className={styles.tags__title}>{title}</span> : null}
			<div className={clsx(styles.tags__container, mode === 'row' && styles.tags__container_row)}>
				{tags.map((tag, index) => {
					const selected = selectedTags?.includes(tag);
					const select = () => onSelect?.(tag);
					return (
						<div
							aria-disabled={!selectable}
							aria-selected={selected}
							className={styles.tags__item}
							key={index + tag}
							role="row"
							tabIndex={selectable ? 0 : undefined}
							onClick={select}
							onKeyDown={(event) => {
								if (event.key === 'Enter' || event.key === ' ') {
									select();
								}
							}}
						>
							<When condition={selectable}>
								<div className={styles.tags__itemIconWrapper}>
									{selected ? (
										<CrossIcon className={styles.tags__itemIcon} />
									) : (
										<PlusIcon className={styles.tags__itemIcon} />
									)}
								</div>
							</When>
							<span>{tag}</span>
						</div>
					);
				})}
			</div>
		</>
	);
};

export interface ITagRowsProps {
	title?: string;
	tags: string[];
	selectedTags?: string[];
	mode?: 'column' | 'row';
	onSelect?: (tag: string) => void;
}

export default TagRows;
