import { useMe, useMemo, useTranslation } from '@hooks';
import { ActivityIndicator, DropdownIcon, MeatballMenu, TickIcon } from '@ui-kit';
import { useDealMemberRelationsMutation } from '@ui-modules/deals/hooks/useDealMemberRelationsMutation';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import styles from './DealTabsPage.module.css';
import type { TCustomMenuContainerProps } from '@ui-kit/components/MeatballMenu/MeatballMenu';
import type { TDeal, TDealMemberRelationsStatus } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { compact } from 'lodash';

const DealTabsPageMeatballMenu = ({
	dealId,
	dealOwnerName,
	dealContactName,
	dealCreatorId,
}: IDealTabsPageMeatballMenuProps) => {
	const { t } = useTranslation();
	const { user } = useMe();

	const { data: deal, isRefetching } = useGetDealQuery(dealId);
	const { dealMemberRelations, dealMemberRelationsIsLoading } = useDealMemberRelationsMutation(dealId);

	const statusIsLoading = dealMemberRelationsIsLoading || isRefetching;
	const dealStatus = deal?.currentDealMemberRelation ? deal?.currentDealMemberRelation?.status : 'not following';
	const userIsDealCreator = dealCreatorId === user.id;

	const setNewMemberStatusOfRelationsWithMember = (
		status: TDealMemberRelationsStatus | 'not following',
		relationsId?: string,
	) => {
		const slicedId = relationsId?.slice(relationsId?.lastIndexOf('/') + 1);
		if (slicedId && status !== 'not following') {
			dealMemberRelations({ id: slicedId, data: { deal: deal?.['@id'] as string, member: user['@id'], status } });
		} else if (!slicedId && status !== 'not following') {
			dealMemberRelations({ id: undefined, data: { deal: deal?.['@id'] as string, member: user['@id'], status } });
		} else if (slicedId && status === 'not following') {
			dealMemberRelations({ id: slicedId });
		}
	};

	const setActiveStatusColor = (
		currentStatus: TDealMemberRelationsStatus | 'not following',
		dealStatus: TDealMemberRelationsStatus | 'not following',
	) => {
		return currentStatus === dealStatus ? 'black' : 'white';
	};

	const menuOptions: TMeatballMenuOption[] = compact([
		!userIsDealCreator && {
			text: <div className={styles.dealTab__dropdownTitle}>{t('Not Following')}</div>,
			onClick: () => setNewMemberStatusOfRelationsWithMember('not following', deal?.currentDealMemberRelation?.['@id']),
			icon: <TickIcon fill={setActiveStatusColor(dealStatus, 'not following')} height={16} width={16} />,
		},
		{
			text: (
				<div className={styles.dealTab__dropdownTitle}>
					{dealStatus === 'following' ? t('Following') : t('Follow')}
					<span className={styles.dealTab__dropdownStatus}>{` (${t('Private')})`}</span>
				</div>
			),
			onClick: () => setNewMemberStatusOfRelationsWithMember('following', deal?.currentDealMemberRelation?.['@id']),
			icon: <TickIcon fill={setActiveStatusColor(dealStatus, 'following')} height={16} width={16} />,
			description: <span className={styles.dealTab__dropdownDescription}>{t('Saves this Deal in My Deals.')}</span>,
		},
		{
			text: (
				<div className={styles.dealTab__dropdownTitle}>
					{t('Interested')}
					<span className={styles.dealTab__dropdownStatus}>{` (${t('Public')})`}</span>
				</div>
			),
			onClick: () => setNewMemberStatusOfRelationsWithMember('interested', deal?.currentDealMemberRelation?.['@id']),
			icon: <TickIcon fill={setActiveStatusColor(dealStatus, 'interested')} height={16} width={16} />,
			description: (
				<span className={styles.dealTab__dropdownDescription}>
					{t('Requests info from {{whom1}} and {{whom2}}.', { whom1: dealOwnerName, whom2: dealContactName })}
				</span>
			),
		},
		{
			text: (
				<div className={styles.dealTab__dropdownTitle}>
					{t('Invested')}
					<span className={styles.dealTab__dropdownStatus}>{` (${t('Public')})`}</span>
				</div>
			),
			onClick: () => setNewMemberStatusOfRelationsWithMember('invested', deal?.currentDealMemberRelation?.['@id']),
			icon: <TickIcon fill={setActiveStatusColor(dealStatus, 'invested')} height={16} width={16} />,
			description: (
				<span className={styles.dealTab__dropdownDescription}>
					{t('Indicates that you’ve invested, access Chat and Files.')}
				</span>
			),
		},
		!userIsDealCreator && {
			text: (
				<div className={styles.dealTab__dropdownTitle_destructive}>
					{t('Not Interested')}
					<span className={styles.dealTab__dropdownStatus}>{` (${t('Public')})`}</span>
				</div>
			),
			onClick: () =>
				setNewMemberStatusOfRelationsWithMember('not interested', deal?.currentDealMemberRelation?.['@id']),
			type: 'destructive',
			icon: <TickIcon fill={dealStatus === 'not interested' ? '#fa2f53' : 'none'} height={16} width={16} />,
			description: (
				<span className={styles.dealTab__dropdownDescription}>{t('Hides this Deal unless you search for it.')}</span>
			),
		},
	]);

	const MenuContainer = useMemo(() => {
		const Container = ({ menuOptions, menuRef }: TCustomMenuContainerProps) => {
			return (
				<div className={styles.dealTab__customMenu} ref={menuRef}>
					{menuOptions}
				</div>
			);
		};
		return Container;
	}, []);

	return (
		<MeatballMenu
			customIconStyle={styles.dealTab__dropdownIcon}
			CustomMenuContainer={MenuContainer}
			options={menuOptions}
		>
			<div className={styles.dealTab__notFollowingButton}>
				{statusIsLoading ? (
					<ActivityIndicator />
				) : (
					<span className={styles.dealTab__notFollowingTitle}>{dealStatus}</span>
				)}
				<div>
					<DropdownIcon fill="#000000" height={8} width={8} />
				</div>
			</div>
		</MeatballMenu>
	);
};

interface IDealTabsPageMeatballMenuProps {
	dealId: TDeal['id'];
	dealOwnerName: string;
	dealContactName: string;
	dealCreatorId: TDeal['creator']['id'];
}

export default DealTabsPageMeatballMenu;
