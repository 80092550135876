import { useQuery } from '@tanstack/react-query';
import { useService } from '@services';
import { makeTipMock } from '@mocks';
import type { TTip, TCommunity } from '@typings';

export const useMeetingTipsQuery = (calendarItemId: TCommunity['id'] | undefined) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return useQuery<TTip[]>(reactQuery.queryKeys.meetingTips(calendarItemId), async () => {
		await new Promise((resolve) => setTimeout(resolve, 1500));
		return Array.from({ length: 10 }, () => makeTipMock());
	});
};
