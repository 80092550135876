import { Fragment, memo } from 'react';
import { ActivityIndicator, Button, EmptyStateMessage, TopArrowIcon } from '@ui-kit';
import FeedNotifier from '@ui-modules/feed/components/FeedNotifier';
import FeedActivity from '@ui-modules/feed/components/FeedActivity';
import { FlatFeed } from 'react-activity-feed';
import { InfiniteScroll } from 'react-activity-feed/src/components/InfiniteScroll';
import {
	useCallback,
	useRestoreScroll,
	useNavigate,
	useTranslation,
	useService,
	useEffect,
	useBlockedAndBlockingUsers,
	useMe,
} from '@hooks';
import { useDoFeedRequest } from '@ui-modules/feed/hooks/useDoFeedRequest';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { useCrossPromotionsAnalytics } from '@ui-modules/feed/hooks/useCrossPromotionsAnalytics';
import { useFloatingScrollToTop } from '../../../../app/pages/ReferAnAssociatePage/useFloatingScrollToTop';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import { ROUTES } from '@constants';
import { ActivityEntity, clsx, noop, downloadFile, extractFileExtensionFromUrl } from '@utils';
import styles from './Feed.module.css';
import type {
	ActivityProps,
	FlatFeedProps,
	InfiniteScrollPaginatorProps,
	NewActivitiesNotificationProps,
} from 'react-activity-feed';
import type { TActivity, TActivityInteractionSource, TEvent } from '@typings';
import type { GetFeedOptions } from 'react-activity-feed/node_modules/getstream/lib/feed';

/** Wrapper around react-activity-feed FlatFeed which provides integration with our app. */
const Feed = ({ userId, isActivitySourceHidden, hidden, containerClassName, onScroll }: IFeedProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const pdfViewer = usePdfViewer();

	const { user } = useMe();
	const canDeletePosts = user?.roles?.includes('ROLE_ADMIN');
	// Behavior handlers.
	const { scrollerRef, restoreScroll } = useRestoreScroll(`feed-${userId}`);
	const { onScroll: onFeedScroll, scrollToTop, shouldShowScrollToTop } = useFloatingScrollToTop(scrollerRef);
	const doFeedRequest = useDoFeedRequest({ onLoadedFromCache: restoreScroll });

	// User action handlers.
	const handleActivityLink = useHandleActivityLink();
	const { trackActivityClick } = useCrossPromotionsAnalytics();
	const openPost = (activityId: TActivity['id']) => navigate(ROUTES.post(activityId, userId));
	const openEvent = (event: TEvent, activity: TActivity) => {
		trackActivityClick(`/api/events/${event.id}`, activity);
		navigate(event['@type'] === 'Event' ? ROUTES.viewEvent(event.id) : ROUTES.viewMeeting(event.id));
	};
	const openActivityLink = (link: string, activity: TActivity) => {
		trackActivityClick(link, activity);
		handleActivityLink(link);
	};
	const openFileUrl = (fileUrl: string) => {
		const fileExtension = extractFileExtensionFromUrl(fileUrl);
		if (fileExtension === 'pdf') pdfViewer.open(fileUrl);
		else downloadFile(fileUrl);
	};
	const trackActivityInteraction = (activity: TActivity, interactionSource: TActivityInteractionSource) => {
		analytics.trackEvent('PostInteractions', {
			post_owner: activity.actor.data.roles.includes('ROLE_STAFF') ? 'staff' : 'member',
			post_source: userId,
			interaction_source: interactionSource,
		});
	};

	// Render callbacks.
	const renderActivity = useCallback(
		({ activity }: ActivityProps) => {
			const activityItem = activity as unknown as TActivity; // can't reuse stream typescript definitions ;-( .
			const activityEntity = new ActivityEntity(activityItem);
			const isLearnActivity = activityEntity.isFromLearn() || userId.startsWith('L--');

			if (activityEntity.isReported() || activityEntity.isBlocked(blockedAndBlockingUsers))
				return <div className={styles.feed__activity_hidden} />;
			return (
				<FeedActivity
					activity={activityItem}
					canDeletePost={canDeletePosts}
					feedUserId={userId}
					isCard
					isSourceHidden={isActivitySourceHidden}
					key={activity.id}
					showBookmark={userId.startsWith('L--')}
					withSeparator={true}
					onClickComment={openPost}
					onClickEvent={openEvent}
					onClickReply={openPost}
					onFileClick={openFileUrl}
					onImageOpened={() => {
						if (isLearnActivity)
							analytics.trackEvent('LearnInteractions', {
								interaction_type: 'image opened',
							});
					}}
					onInteracted={(interactionSource) => trackActivityInteraction(activityItem, interactionSource)}
					onLinkClicked={() => {
						if (isLearnActivity)
							analytics.trackEvent('LearnInteractions', {
								interaction_type: 'link clicked',
							});
					}}
					onPressActivityLink={openActivityLink}
					onVideoPlayed={() => {
						if (isLearnActivity)
							analytics.trackEvent('LearnInteractions', {
								interaction_type: 'video played',
							});
					}}
				/>
			);
		},
		[blockedAndBlockingUsers, userId],
	);
	const renderLoadingIndicator = useCallback(() => <ActivityIndicator size="medium" type="fit" />, []);
	const renderPlaceholder = useCallback(() => <EmptyStateMessage text={t('There is no any posts yet.')} />, []);
	const renderPaginator = useCallback(
		({ children, hasNextPage, loadNextPage, refreshing, reverse, threshold }: InfiniteScrollPaginatorProps) => (
			<InfiniteScroll
				data-feed={userId} // it is used to be capable of find it DOM.
				hasMore={hasNextPage}
				isLoading={refreshing}
				isReverse={reverse}
				loader={
					<Fragment key="loading-indicator">
						<ActivityIndicator size="small" type="fit" />
					</Fragment>
				}
				loadMore={loadNextPage}
				ref={scrollerRef}
				threshold={threshold}
				useWindow={false}
			>
				{children}
			</InfiniteScroll>
		),
		[],
	);
	const renderNotifier = useCallback(
		(props: NewActivitiesNotificationProps) => (
			<FeedNotifier
				{...props}
				userId={userId}
				onClick={() => {
					queryClient.removeQueries({ queryKey: queryKeys.getUserFeed(userId) });
					scrollerRef.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
					(props.onClick as () => void)?.();
				}}
			/>
		),
		[],
	);

	useEffect(function subscribeToScroll() {
		if (!onScroll) return;
		scrollerRef.current?.addEventListener('scroll', onScroll as any as TAddEventListenerScrollEvent, { passive: true });
		return () => scrollerRef.current?.removeEventListener('scroll', onScroll as any as TAddEventListenerScrollEvent);
	}, []);

	return (
		<>
			<div
				aria-hidden={hidden}
				className={clsx(styles.feed__wrapper, containerClassName)}
				hidden={hidden}
				ref={scrollerRef}
				style={{ display: hidden ? 'none' : 'block' }} // to hide Feed without unmount and avoid triggering mount & refetch.
				onScroll={onFeedScroll}
			>
				<FlatFeed
					Activity={renderActivity}
					doActivityDeleteRequest={noop as FlatFeedProps['doActivityDeleteRequest']} // to prevent Stream doing it by itself but call update of the feed.
					doFeedRequest={doFeedRequest}
					feedGroup="user"
					LoadingIndicator={renderLoadingIndicator}
					Notifier={renderNotifier}
					notify={true}
					options={FEED_OPTIONS}
					Paginator={renderPaginator}
					Placeholder={renderPlaceholder}
					userId={userId}
				/>
			</div>
			{shouldShowScrollToTop ? (
				<div className={styles.feed__floatingButtonContainer} hidden={hidden}>
					<Button
						icon={<TopArrowIcon />}
						iconPosition="right"
						title={t('Back to Top')}
						type="blurred"
						variant="small"
						onClick={scrollToTop}
					/>
				</div>
			) : undefined}
		</>
	);
};

/** Settings of the react-activity-feed FlatFeed. */
const FEED_OPTIONS: GetFeedOptions = {
	limit: 25,
	withOwnReactions: true,
	ranking: 'feed_ranking',
};

type TScrollEvent = { target: HTMLDivElement };
type TAddEventListenerScrollEvent = (this: HTMLDivElement, ev: Event) => void;

export interface IFeedProps {
	/** ID of the feed to render. */
	userId: string;
	/** If 'true' the name of source (community, learn) is hidden. Default 'false'. Make sense if you render a community feed on the community page.  */
	isActivitySourceHidden: boolean;
	/** If 'true' the feed is hidden. Default 'false'. */
	hidden?: boolean;
	/** CSS class passes to div wrapping the FlatFeed. */
	containerClassName?: string;
	/** Scroll event listener fired on the scroller feed div. */
	onScroll?: (event: TScrollEvent) => void;
}

export default memo(Feed);
