import { Page } from '../../components';
import { ActionListItem, EmptyStateMessage, Gap, PlusIcon, When } from '@ui-kit';
import { SimplePageLayout } from '../../components/SimplePageLayout/SimplePageLayout';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import { useTranslation, useNavigate } from '@hooks';
import { useTipMatchesQuery } from '@ui-modules/tipJar/hooks/useTipMatchesQuery';
import { useAiTipsQuery } from '@ui-modules/tipJar/hooks/useAiTipsQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import { compact } from '@utils';
import { ROUTES } from '@constants';
import styles from './NewTipMatchesPage.module.css';
import type { TFunction } from 'i18next';
import type { UseQueryResult } from '@tanstack/react-query';

const NewTipMatchesPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues] = useTipFormSearchParams();
	const tipMatchesQuery = useTipMatchesQuery(tipFormValues);
	const aiTipsQuery = useAiTipsQuery(tipFormValues);

	const title = getTipMatchesScreenTitle(t)(tipMatchesQuery);
	const loadingFinished = !tipMatchesQuery.isLoading && !aiTipsQuery.isLoading;
	const somethingLoaded = tipMatchesQuery.data?.length || aiTipsQuery.data?.length;
	const matchesFound = loadingFinished && somethingLoaded;
	const noMatchesFound = !somethingLoaded && loadingFinished;

	if (tipFormParamsValidationError)
		return (
			<div className={styles.newTipMatchesPage__emptyMessageBox}>
				<EmptyStateMessage text={t('Something went wrong')} />
			</div>
		);
	return (
		<Page title={t('Select Match')}>
			<SimplePageLayout headerTitle={t('Select Match')} scrollEnabled>
				<div className={styles.newTipMatchesPage__pageContent}>
					<h2>{title}</h2>
					<Gap gap={16} />
					<p className={styles.newTipMatchesPage__description}>
						{t('Select the one that most closely matches your Tip or create your own.')}
					</p>
					<Gap gap={16} />
					{matchesFound ? null : (
						<>
							<ActionListItem
								Icon={<PlusIcon fill={'black'} height={24} width={24} />}
								note={t('Don’t see your match?')}
								title={t('Manually Create a Tip')}
								onClick={() => navigate(ROUTES.createTip(tipFormValues))}
							/>
							<Gap gap={16} />
						</>
					)}

					{noMatchesFound ? (
						<div className={styles.newTipMatchesPage__emptyMessageBox}>
							<EmptyStateMessage text={t('No matches found')} />
						</div>
					) : (
						<div className={styles.newTipMatchesPage__tipMatchesList}>
							{tipMatchesQuery.data?.length ? (
								<>
									{tipMatchesQuery.data?.map?.((tipMatch, index) => {
										return (
											<TipCard
												key={tipMatch.id + index}
												tip={tipMatch}
												onClick={() => {
													navigate(ROUTES.createTipReview({ tipId: tipMatch.id }));
												}}
											/>
										);
									})}
									{loadingFinished ? (
										<ActionListItem
											Icon={<PlusIcon fill={'black'} height={24} width={24} />}
											note={t('Don’t see your match?')}
											title={t('Manually Create a Tip')}
											onClick={() => navigate(ROUTES.createTip(tipFormValues))}
										/>
									) : null}
								</>
							) : null}

							{aiTipsQuery.isLoading ? (
								<>
									<div className={styles.newTipMatchesPage__loadingCard}>
										<span className={styles.newTipMatchesPage__loadingCardLabel}>{t('AI finding matches...')}</span>
									</div>
									{Array.from({ length: 5 }).map((_, index) => (
										<TipCard.Skeleton key={`TipCard.Skeleton-${index}`} />
									))}
								</>
							) : aiTipsQuery.isSuccess ? (
								<>
									{aiTipsQuery.data.map((aiTip, index) => (
										<TipCard
											key={index}
											tip={aiTip}
											onClick={() => {
												navigate(
													ROUTES.createTip({
														...tipFormValues,
														...aiTip,
														images: compact([
															aiTip.images?.find((image) => image.source === 'ai'),
															tipFormValues.images?.find((image) => image.source === 'user'),
														]),
													}),
												);
											}}
										/>
									))}
									<ActionListItem
										Icon={<PlusIcon fill={'black'} height={24} width={24} />}
										note={t('Don’t see your match?')}
										title={t('Manually Create a Tip')}
										onClick={() => navigate(ROUTES.createTip(tipFormValues))}
									/>
								</>
							) : null}
							<When condition={loadingFinished}>
								<p className={styles.newTipMatchesPage__description}>
									{t('All results have loaded. Got no match? Feel free to go back and alter your input.')}
								</p>
							</When>
						</div>
					)}
					<Gap gap={24} />
				</div>
			</SimplePageLayout>
		</Page>
	);
};

function getTipMatchesScreenTitle(t: TFunction) {
	return (query: UseQueryResult<unknown[]>): string =>
		query.isLoading
			? t('Looking for matches...')
			: query.data?.length
				? t('We found these matches..')
				: t('No matches found');
}

export interface INewTipMatchesPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NewTipMatchesPage;
