import { ROUTES } from '@constants';
import { useNavigate, useRef, useTranslation } from '@hooks';
import { BlockLink, Post, HighlightedText, When } from '@ui-kit';
import DealAlertsButton from '../DealAlertsButton';
import { useMutateDealMeatballMenuOptions } from '@ui-modules/deals/hooks/useMutateDealMeatballMenuOptions';
import { clsx, dayjs, formatAmount, formatCurrencySymbol, noop, sanitizeProfileId, sortBy } from '@utils';
import styles from './DealPost.module.css';
import { DealPostBodyRow } from './DealPostBodyRow';
import type { TCommunity, TDealTemplateField, TDealWithReactions } from '@typings';
import type { RefObject } from 'react';
import { getDynamicFieldSubtitle } from '@ui-modules/deals/utils/getDynamicFieldSubtitle';
import DealRibbon from '../DealRibbon';

const DealPost = ({
	deal,
	canSeePreview,
	currentNetworkId,
	highlightedText,
	restoreScrollRoute,
	likingDisabled = false,
	previewMode = false,
	onLike,
	onToggleAlerts,
	onClick,
	onTextCollapse,
}: IDealPostProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const dealPostRef = useRef<HTMLElement>() as RefObject<HTMLElement>;

	const profileLink = ROUTES.memberProfile(sanitizeProfileId(deal?.creator.profileIri));
	const [meatballMenuOptions, ConfirmationModals] = useMutateDealMeatballMenuOptions(
		deal,
		deal?.community?.id,
		noop,
		currentNetworkId,
		restoreScrollRoute,
		onClick,
	);

	const handleClickDiscussions = () => {
		onClick?.();
		navigate(ROUTES.dealTabs(deal.id, 'discussions'), { state: { restoreScrollRoute } });
	};

	return (
		<Post
			bodyClassName={styles.dealPost__body}
			footerElement={
				<When condition={!previewMode}>
					<Post.PostActionBar
						comments={deal.commentsCount}
						CustomButtonElement={
							<DealAlertsButton
								color={'#8E723B'}
								dealMemberRelation={deal.currentDealMemberRelation}
								iconSize={14}
								textButtonStyle={styles.textButtonStyle}
								onToggle={onToggleAlerts}
							/>
						}
						hasMyLike={!!deal.ownLikes?.length}
						likes={deal.likesCount}
						likeTitle={t('Like')}
						likingDisabled={likingDisabled}
						replyTitle={t('Reply')}
						onClickComment={handleClickDiscussions}
						onClickReply={handleClickDiscussions}
						onLike={onLike}
					/>
				</When>
			}
			headerElement={
				<Post.Header
					avatarProps={{
						imageUri: deal?.creator.avatarUrl,
						outline: false,
						size: 40,
					}}
					createdAt={deal?.createdAt}
					profileLink={profileLink}
					subtitle={deal.community.name}
					subtitleLink={ROUTES.networkTab(deal?.community.id, 'deals')}
					userId={deal?.creator.id}
					userName={deal.creator.fullName}
					userType={`(${deal?.creatorType.charAt(0).toUpperCase() + deal?.creatorType.slice(1)})`}
					onClick={onClick}
				/>
			}
			isCard
			meatballMenuOptions={previewMode ? undefined : meatballMenuOptions}
			navigationState={{ state: { restoreScrollRoute } }}
			postLink={ROUTES.dealTabs(deal.id, 'details')}
			ref={dealPostRef}
			withFooterSeparator={!previewMode}
			wrapperClassName={styles.dealPost}
			onClick={onClick}
		>
			<BlockLink
				navigationState={{ state: { restoreScrollRoute } }}
				to={ROUTES.dealTabs(deal.id, 'details')}
				onClick={onClick}
			>
				<div className={styles.dealPost__header}>
					<span className={styles.dealPost__title}>
						{deal?.status === 'closed' ? (
							<span className={styles.dealPost__title_status}>{`${t('Closed')}:`}&nbsp;</span>
						) : null}
						{deal?.status === 'cancelled' ? (
							<span className={styles.dealPost__title_status}>{`${t('Cancelled')}:`}&nbsp;</span>
						) : null}
						<HighlightedText className={styles.dealPost__dealName} highlightedText={highlightedText}>
							{deal?.name}
						</HighlightedText>
					</span>
					<div className={styles.dealPost__headerAttachments}>
						<DealRibbon
							canSeePreview={canSeePreview}
							dealStatus={deal?.currentDealMemberRelation?.status}
							hasPreview={deal.hasPreview}
							isDraftDeal={deal.status === 'draft' || deal.status === 'review'}
							status={deal.status}
						/>
					</div>
				</div>

				<div className={styles.dealPost__rowContainer}>
					{/* Static fields */}
					<DealPostBodyRow
						highlightedText={highlightedText}
						subtitle={deal.dealSubClass?.name}
						title={t('Sub-class')}
					/>
					<DealPostBodyRow highlightedText={highlightedText} subtitle={deal.dealType?.name} title={t('Type')} />
					<DealPostBodyRow
						highlightedText={highlightedText}
						subtitle={`${formatCurrencySymbol(deal?.currency)} ${deal.currency}`}
						title={t('Currency')}
					/>
					<DealPostBodyRow
						hidden={deal?.minInvestment === 0}
						required
						showPlug={!deal?.minInvestment}
						subtitle={`${formatCurrencySymbol(deal?.currency)}${formatAmount(Number(deal?.minInvestment))}`}
						title={t('Minimum')}
					/>
					{deal?.closingDate ? (
						<DealPostBodyRow subtitle={dayjs.tz(deal?.closingDate, 'UTC').format('DD MMM YYYY')} title={t('Closing')} />
					) : null}

					{/* Dynamic fields */}
					{deal?.data?.body?.sections?.map((section) =>
						sortBy(section.fields, 'display_order').map((field: unknown) => {
							const dynamicField = field as TDealTemplateField;
							const subtitle = getDynamicFieldSubtitle(
								dynamicField?.value,
								section.section_slug,
								deal?.currency,
								dynamicField?.type,
							);
							return dynamicField?.value ? (
								<DealPostBodyRow
									highlightedText={highlightedText}
									key={`${dynamicField.slug}_${dynamicField.type}`}
									subtitle={subtitle}
									title={dynamicField.label}
								/>
							) : null;
						}),
					)}
				</div>
				{deal?.description ? (
					<div>
						<span className={clsx(styles.dealPost__textTitle, styles.dealPost__space)}>{t('Description')}</span>
						<Post.Content
							className={styles.dealPost__content}
							collapsedNumberOfLines={3}
							collapsedTitle={t('Read more')}
							expandedTitle={t('Show less')}
							highlightingText={highlightedText}
							toggleHidden={previewMode}
							onCollapse={onTextCollapse}
						>
							{deal?.description}
						</Post.Content>
					</div>
				) : null}
			</BlockLink>
			{ConfirmationModals}
		</Post>
	);
};

export interface IDealPostProps {
	deal: TDealWithReactions;
	/** The community ID from the current viewed network. Pass it only from `/network/invest/*` paths.  */
	currentNetworkId?: TCommunity['id'];
	highlightedText?: string;
	likingDisabled?: boolean;
	canSeePreview: boolean;
	onLike: (newHasMyLike: boolean) => void;
	onToggleAlerts: (data: { relationId: string; newPostAlerts: boolean }) => void;
	onClick?: () => void;
	onTextCollapse?: () => void;
	restoreScrollRoute?: string;
	previewMode?: boolean;
}

export default DealPost;
