import type { PropsWithChildren } from 'react';
import { useService, useQuery, useNavigate, useMe, useLogger, useCallback } from '@hooks';
import { ROUTES, DEFAULT_CALENDAR_END_DATE } from '@constants';
import { convertProfileTypeToRole, dayjs, formatUserSubTitle } from '@utils';
import FeedStreamApp from '@ui-modules/feed/components/FeedStreamApp';
import { PlacesServiceContextProvider } from '../../../common/contexts/PlacesServiceContext';
import type { TUserProfileRecord } from '@typings';

/** Get currently authenticated user.
 * 	Disabled by default to run it only during authorization process.
 */
const InitializeSessionWrapper = ({ children }: IInitializeSessionWrapperProps) => {
	const logger = useLogger('InitializeSessionWrapper');
	const api = useService('ApiService');
	const navigate = useNavigate();
	const chat = useService('ChatService');
	const language = useService('I18nService');
	const sentry = useService('SentryService');
	const analytics = useService('AnalyticsService');
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { useAppDispatch, calendar, usersGroups } = useService('ReduxService');
	const { user } = useMe();
	const dispatch = useAppDispatch();
	const algoliaSearch = useService('AlgoliaSearchService');
	const algoliaAnalyticsService = useService('AlgoliaAnalyticsService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const feedService = useService('FeedService');

	const initialLoadCalendarItems = useCallback(async () => {
		const startOfMonthDate = dayjs().subtract(3, 'months').toISOString(); // upload in advance to prevent multiple requests.
		const uploadedCalendarItems = await queryClient.fetchQuery(
			['calendarItem.getInitialCalendarItems', startOfMonthDate, DEFAULT_CALENDAR_END_DATE],
			async () => await api.calendarItem.getCalendarItemsInRange(startOfMonthDate),
			{
				staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
				cacheTime: dayjs.duration(1, 'hour').asMilliseconds(),
			},
		);

		dispatch(
			calendar.addCalendarItems({
				calendarItems: uploadedCalendarItems,
				calendarItemsRange: [startOfMonthDate, DEFAULT_CALENDAR_END_DATE],
			}),
		);
	}, [api, queryClient, calendar, dispatch]);

	const initialLoadAddressBook = useCallback(async () => {
		const users = await queryClient.fetchQuery(
			queryKeys.getAddressBook(),
			async () => await api.profile.getAddressBook(),
			{
				staleTime: Infinity,
			},
		);
		const data = {} as { [key: string]: string };
		users.forEach((user) => {
			data[user.user_id] = formatUserSubTitle(language.i18n.t)(
				{
					roles: [convertProfileTypeToRole(user.type)],
					communitiesForBadge: { names: user.communities_for_badge?.shortNames || [] },
					profile: { '@type': user.type, title: user?.title },
				} as TUserProfileRecord,
				' • ',
			);
		});
		dispatch(usersGroups.setUsersGroups(data));
	}, [api, queryClient]);

	const initLifestyleNetworkTabs = useCallback(async () => {
		await queryClient.fetchQuery(queryKeys.getNetworkTabs(), async () => await api.community.getNetworkTabs(), {
			staleTime: Infinity,
		});
	}, []);

	const initMemberRelation = useCallback(async () => {
		await queryClient.fetchQuery(
			queryKeys.getMemberRelation(),
			async () => {
				const meMemberships = await api.user.getUser(user?.id as string);
				return meMemberships.memberships || [];
			},
			{
				staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			},
		);
	}, []);

	const connectFeedService = useCallback(
		async (userId: string) => {
			const userStreamFeedsToken = await queryClient.fetchQuery(
				['streamFeeds.getUserToken'],
				async () => await api.streamFeeds.getUserToken(userId),
				{
					staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
					cacheTime: dayjs.duration(1, 'hour').asMilliseconds(),
				},
			);
			feedService.connect(userStreamFeedsToken);
		},
		[queryClient, api, feedService],
	);

	// Initialize application session. here.
	const initAlgoliaSearch = async () => {
		const algoliaApiKey = await queryClient.fetchQuery(
			['algolia.getSecuredSearchKey'],
			async () => await api.algolia.getSecuredSearchKey(),
			{
				staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
				cacheTime: dayjs.duration(1, 'hour').asMilliseconds(),
			},
		);
		await algoliaSearch.init(algoliaApiKey);
		await algoliaAnalyticsService.init(algoliaApiKey);
	};

	// Initialize application session. here.
	useQuery(
		['setupSession'],
		async () => {
			const userName = `${user.firstName} ${user.lastName}`;

			// Important to be run before app enter.
			await Promise.all([
				sentry.setUser({ id: user.id, email: user.email, username: userName }),
				initAlgoliaSearch(),
				chat.connect(user.slug, userName),
				connectFeedService(user.id),
				analytics.setUser(user),
				algoliaAnalytics.setUser(user),
				queryClient.fetchQuery(['userBlocking.getBlockedUsers'], async () => await api.userBlocking.getBlockedUsers(), {
					staleTime: Infinity,
					cacheTime: Infinity,
				}),
				queryClient.fetchQuery(
					['userBlocking.getUsersBlockingMe'],
					async () => await api.userBlocking.getUsersBlockingMe(),
					{ staleTime: Infinity, cacheTime: Infinity },
				),
			]);

			return true;
		},
		{
			suspense: true,
			retry: 2,
			async onSuccess() {
				logger.log('session initialized');
				analytics.trackEvent('LoginCompleted', {
					Email: user.email,
					'Enabled Permissions': [], // always empty for the Web app
					FirstName: user.firstName,
					LastName: user.lastName,
					FullName: `${user.firstName} ${user.lastName}`,
					'TIGER21 Group': user.communitiesForBadge?.shortNames ?? [],
				});
				// Prefetch and post app enter calls.
				await Promise.all([
					initialLoadCalendarItems(),
					initialLoadAddressBook(),
					initLifestyleNetworkTabs(),
					initMemberRelation(),
				]);
				logger.log('prefetch requests completed');
			},
			onError() {
				navigate(ROUTES.signOut());
			},
		},
	);

	return (
		<FeedStreamApp>
			<PlacesServiceContextProvider>{children}</PlacesServiceContextProvider>
		</FeedStreamApp>
	);
};

export interface IInitializeSessionWrapperProps extends PropsWithChildren {}

export default InitializeSessionWrapper;
