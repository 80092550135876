import { Gap } from '@ui-kit';
import AllDealsZeroState from '@ui-modules/deals/components/AllDealsZeroState';

import { Virtuoso } from 'react-virtuoso';
import NetworkDealFooter from '../NetworkDealFooter';
import styles from './NetworkDeals.module.css';

import type { VirtuosoHandle } from 'react-virtuoso';
import type { TDeal, TDealWithReactions } from '@typings';
import type { RefObject } from 'react';

const Content = ({
	isDealsLoading,
	isNetworkDealsRefetching,
	networkDeals,
	isFetching,
	isSearchFetched,
	isSearchFetching,
	searchResult,
	networkDealsWithPlaceholders,
	showSearchResults,
	searchMore,
	fetchMore,
	initialTopMostItemIndex,
	renderDealItem,
	onRangeChanged,
	listRef,
	showSearchBar,
	onScroll,
	itemsRendered,
}: IContent) => {
	return (
		<>
			<Virtuoso<TDealWithReactions>
				className={styles.networkDeals__list}
				components={{
					Footer: () => (
						<NetworkDealFooter
							isFetching={isFetching}
							isLoading={isDealsLoading}
							isRefetching={isNetworkDealsRefetching}
							isSearchFetched={isSearchFetched}
							isSearchFetching={isSearchFetching}
							searchResult={searchResult}
						/>
					),
				}}
				computeItemKey={(index, item) => `${index}_${item?.id}`}
				data={networkDealsWithPlaceholders}
				defaultItemHeight={480}
				endReached={showSearchResults ? searchMore : fetchMore}
				initialTopMostItemIndex={initialTopMostItemIndex}
				itemContent={renderDealItem}
				itemsRendered={itemsRendered}
				rangeChanged={onRangeChanged}
				ref={listRef}
				topItemCount={1}
				totalCount={networkDealsWithPlaceholders?.length}
				onScroll={onScroll}
			/>
			{!networkDeals.length && !isDealsLoading && !showSearchBar ? (
				<>
					<Gap gap={64} />
					<AllDealsZeroState />
				</>
			) : null}
		</>
	);
};
export interface IContent {
	isDealsLoading: boolean;
	isNetworkDealsRefetching: boolean;
	networkDeals: TDealWithReactions[];
	isFetching: boolean;
	isSearchFetched: boolean;
	isSearchFetching: boolean;
	searchResult: TDeal[];
	networkDealsWithPlaceholders: TDealWithReactions[];
	showSearchResults: boolean;
	searchMore: AnyFunction;
	fetchMore: AnyFunction;
	initialTopMostItemIndex: number;
	renderDealItem: AnyFunction;
	onRangeChanged: AnyFunction;
	listRef: RefObject<VirtuosoHandle>;
	onScroll: AnyFunction;
	itemsRendered: AnyFunction;
	showSearchBar: boolean;
}

type AnyFunction = (...args: any[]) => any;

export default Content;
