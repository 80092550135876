import { useService, useInfiniteQuery, useDebounce, useMemo } from '@hooks';
import { dayjs, noop } from '@utils';
import { adaptSearchTipToTip } from '@ui-modules/tipJar/utils/adaptSearchTipToTip';
import { makeTipMock } from '@mocks';
import type { TAlgoliaSearchResponse, TCommunity, TTip, TTipsFilter } from '@typings';

const EMPTY_RESPONSE: TTip[] = [];

export const useSearchTipsQuery = (
	searchQuery = '',
	tipFilters?: Partial<TTipsFilter & { communityId?: TCommunity['id'] }>,
) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	const [preparedSearchQuery] = useDebounce(searchQuery.trim(), 300);
	const isSearchEnabled = preparedSearchQuery.length >= 3;

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse<TTip>, Error>(
		reactQuery.queryKeys.searchTips(preparedSearchQuery, tipFilters),
		async ({ pageParam = 0 }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1300));
			const tips =
				searchQuery === 'nothing'
					? []
					: Array.from({ length: 10 }, () => {
							const tip = makeTipMock(
								tipFilters?.categories?.length
									? {
											categories: [tipFilters.categories[Math.floor(Math.random() * tipFilters.categories.length)]],
										}
									: {},
							);
							return { ...tip, title: `${tip.title} ${preparedSearchQuery}`, objectID: tip.id };
						});

			return {
				nbHits: searchQuery === 'nothing' ? 0 : 20,
				page: pageParam,
				nbPages: searchQuery === 'nothing' ? 0 : 2,
				hitsPerPage: 10,
				hits: tips,
				processingTimeMS: 1000,
				exhaustiveNbHits: true,
				query: preparedSearchQuery,
				params: '',
			};
		},
		{
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			enabled: isSearchEnabled,
			keepPreviousData: true,
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) return maybeNextPage;
				return undefined;
			},
		},
	);

	const data = useMemo<TTip[]>(
		function normalizeData() {
			return (infiniteQuery.data?.pages.flatMap((page) => page.hits.map((searchNc) => adaptSearchTipToTip(searchNc))) ??
				EMPTY_RESPONSE) as TTip[];
		},
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		preparedSearchQuery,
		isSearchEnabled,
		...infiniteQuery,
		data,
		fetchMore,
	};
};
