import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TAttachments, TEvent } from '@tiger21-llc/connect-shared/src/typings';
import type { TRootState } from '../ReduxService';
import type { MessageResponse } from 'stream-chat';

export type IChatMessageDraft = {
	text?: string;
	attachments?: TAttachments[];
	selectedEvent?: TEvent | null;
	editingMessage?: MessageResponse;
};

type IChatMessageDraftsState = {
	[channelId in string]: IChatMessageDraft;
};

const initialState: IChatMessageDraftsState = {};

export const chatMessageDraftsSlice = createSlice({
	name: 'chatMessageDrafts',
	initialState,
	reducers: {
		updateMessageDraft: (
			state,
			{ payload: { channelId, draft } }: PayloadAction<{ channelId: string; draft: IChatMessageDraft }>,
		) => {
			state[channelId] = {
				...state[channelId],
				...draft,
			};
		},
		removeMessageDraft: (state, { payload: channelId }: PayloadAction<string>) => {
			delete state[channelId];
		},
		removeAllMessageDrafts: () => {
			return initialState;
		},
	},
});

export const chatMessageDraftsSelectors = {
	selectMessageDraft: (channelId: string) => (state: TRootState) => state.chatMessageDrafts[channelId],
};
