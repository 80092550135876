import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTipReviewReport } from '@typings';

export const useReportTipReviewMutation = (options?: MutateOptions<unknown, Error, TTipReviewReport>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<unknown, Error, TTipReviewReport>(
		['reportTipReview'],
		async (report) => await api.tips.postReportTipReview(report),
		{
			...options,
			onSuccess: (response, variables, context) => {
				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
