import { useService, useDebounce, useEffect, useMemo, useInfiniteQuery } from '@hooks';
import { dayjs, noop } from '@utils';
import type { TCommunity, TSearchDiscussion, TAlgoliaSearchResponse } from '@typings';
import type { InfiniteQueryObserverOptions } from '@tanstack/react-query';

export const useFeedSearchQuery = (
	{ searchQuery, communityName, isLearning, searchFromAll, streamFeedId }: IUseFeedSearchQuery,
	options?: InfiniteQueryObserverOptions<TAlgoliaSearchResponse, Error> & { onSearch?: (searchQuery: string) => void },
) => {
	const algoliaSearch = useService('AlgoliaSearchService');

	const [preparedSearchQuery] = useDebounce(searchQuery.trim(), 300);
	const isSearchEnabled = preparedSearchQuery.length >= 3;

	useEffect(() => {
		options?.onSearch?.(isSearchEnabled ? preparedSearchQuery : '');
	}, [preparedSearchQuery, isSearchEnabled]);

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse, Error>(
		[
			'algoliaSearch.searchByFeedActivities',
			communityName || streamFeedId || (isLearning ? 'learn' : 'global'),
			preparedSearchQuery,
		],
		async ({ pageParam = 0 }) =>
			await algoliaSearch.searchByFeedActivities({
				searchQuery: preparedSearchQuery,
				page: pageParam,
				communityName,
				isLearning,
				streamFeedId,
				searchFromAll,
			}),
		{
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			keepPreviousData: true,
			...options,
			enabled: isSearchEnabled,
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) return maybeNextPage;
				return undefined;
			},
		},
	);
	const data = useMemo<TSearchDiscussion[]>(
		function normalizeData() {
			return (infiniteQuery.data?.pages.flatMap((page) => page.hits) as TSearchDiscussion[]) ?? EMPTY_RESPONSE;
		},
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		preparedSearchQuery,
		isSearchEnabled,
		...infiniteQuery,
		data,
		fetchMore,
	};
};

interface IUseFeedSearchQuery {
	searchQuery: string;
	streamFeedId?: string;
	communityName?: TCommunity['name'];
	isLearning?: boolean;
	searchFromAll?: boolean; // When we want to search from all the activities we have in algolia
}

const EMPTY_RESPONSE: TSearchDiscussion[] = [];
