import React, { useCallback, useMemo } from 'react';
import styles from './PercentComparisonChart.module.css';
import { clsx } from '@utils';
import { mainComparativeChartTheme, secondaryComparativeChartTheme } from './PercentComparisonChart.themes';

const MIN_GRAPH_WIDTH = 2; // WHen value in chart is 0 the ine should be displayed and have min width
const VALUE_TO_ADDITIONAL_SPACE = 6;
const INITIAL_LINE_HEIGHT = 12;

const PercentComparisonChart = ({
	title,
	chartData,
	chartLineHeight = INITIAL_LINE_HEIGHT,
	mainTheme = mainComparativeChartTheme,
	comparativeTheme = secondaryComparativeChartTheme,
}: IPercentComparisonChartProps) => {
	const { sortedData, shouldHaveAdditionalSpace } = useMemo(() => {
		let isChartShouldHaveAdditionalSpace = false;
		const sortedChartData = chartData.sort((a, b) => b.percentValue - a.percentValue);

		const updatedData: Array<TChartElement & { space: number }> = sortedChartData.map((chartLine, index) => {
			const nextValue = sortedChartData[index + 1]?.percentValue || 0;
			const prevValue = sortedChartData[index - 1]?.percentValue || 0;
			const valueDifferenceWithNext = chartLine.percentValue - nextValue;
			const valueDifferenceWithPrev = prevValue - chartLine.percentValue;
			const isDifferenceTooSmall: boolean =
				!!chartLine.isMainComparative &&
				((!!sortedChartData[index + 1] && valueDifferenceWithNext < VALUE_TO_ADDITIONAL_SPACE) ||
					(!!sortedChartData[index - 1] && valueDifferenceWithPrev < VALUE_TO_ADDITIONAL_SPACE));

			if (isDifferenceTooSmall) {
				isChartShouldHaveAdditionalSpace = true;
			}

			// if difference beetwen 2 values is too small (less then VALUE_TO_ADDITIONAL_SPACE) need to add additional bottom space for main Comparative element
			return {
				theme: chartLine?.isMainComparative ? mainTheme : comparativeTheme,
				...chartLine,
				space: isDifferenceTooSmall ? 26 : 6,
			} as TChartElement & { space: number };
		});
		return { sortedData: updatedData, shouldHaveAdditionalSpace: isChartShouldHaveAdditionalSpace };
	}, [chartData]);

	const renderGraphBars = useCallback(() => {
		return sortedData.map((chartItem, index) => {
			const { percentValue, theme, space, isMainComparative } = chartItem;

			return (
				<React.Fragment key={`${percentValue}_${index}`}>
					<div
						className={styles.graphBar}
						style={
							{
								width: `${Math.max(percentValue, MIN_GRAPH_WIDTH)}%`,
								zIndex: index,
								background: theme?.graphBackgroundColor,
								'--line-color': theme?.lineColor,
								'--additional-space': `${space}px`,
							} as React.CSSProperties
						}
					>
						{/* Comparison value of chart element */}
						<div
							className={clsx(styles.circle, !isMainComparative && styles.circlePlacement)}
							style={{
								backgroundColor: theme?.labelBackgroundColor,
							}}
						>
							<span className={clsx(styles.circleText)} style={{ color: theme?.labelColor }}>{`${percentValue}%`}</span>
						</div>
					</div>
				</React.Fragment>
			);
		});
	}, [sortedData]);

	return (
		<div style={{ '--chart-lineHeight': `${chartLineHeight}px` } as React.CSSProperties}>
			<div className={clsx(styles.greyLine, shouldHaveAdditionalSpace && styles.greyLineAdditionalSpaced)} />
			<div className={styles.graphBarsContainer}>{renderGraphBars()}</div>
			<span className={styles.title}>{title}</span>
		</div>
	);
};

type TChartElement = {
	percentValue: number;
	isMainComparative?: boolean;
	theme?: TChartTheme;
};
interface IPercentComparisonChartProps {
	title: string;
	chartData: TChartElement[];
	chartLineHeight?: number;
	mainTheme?: TChartTheme;
	comparativeTheme?: TChartTheme;
}

type TChartTheme = {
	labelColor: string;
	labelBackgroundColor: string;
	lineColor: string;
	graphBackgroundColor: string;
};

export default PercentComparisonChart;
