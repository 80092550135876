import { NetworkIcon, RightArrowIcon } from '@ui-kit';
import styles from './LearnListItem.module.css';
import { useTranslation } from 'react-i18next';

const LearnListItem = ({ title, iconUrl, description, isActive }: ILearnListItem) => {
	const { t } = useTranslation();
	return (
		<div className={styles.learnListItem__container}>
			<div className={styles.learnListItem__content}>
				<div className={styles.learnListItem__imgContainer}>
					{iconUrl ? (
						<img alt={'learnIcon'} className={styles.learnListItem__img} src={iconUrl} />
					) : (
						<NetworkIcon fill={isActive ? '#fff' : '#000'} height={24} width={30} />
					)}
				</div>
				<div className={styles.learnListItem__text}>
					<h4 className={styles.learnListItem__title}>{title}</h4>
					<span>{description || t('All Learn Articles')}</span>
				</div>
			</div>
			<div>
				<RightArrowIcon fill={isActive ? '#fff' : '#000'} height={16} width={14} />
			</div>
		</div>
	);
};

interface ILearnListItem {
	title: string;
	iconUrl: string;
	description: string;
	isActive: boolean;
}

export default LearnListItem;
