import { toString } from '@utils';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';

const HighlightedText = ({ highlightedText, children, ...props }: IHighlightedTextProps) => {
	return (
		<span
			{...props}
			dangerouslySetInnerHTML={{
				__html: convertHighlightingTextToHtml(highlightedText, children),
			}}
		/>
	);
};

const convertHighlightingTextToHtml = (highlightedText: string | undefined, text: string, tag = 'b') => {
	if (!highlightedText) return text;

	const regularExpression = new RegExp(highlightedText, 'gi');
	const html = toString(text).replaceAll(regularExpression, (entry) => `<${tag}>${entry}</${tag}>`);

	return html;
};

export interface IHighlightedTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
	highlightedText?: string;
	children: string;
}

export default HighlightedText;
