import { clsx } from '@utils';
import { CheckMarkIcon, HorizontalRule } from '../../icons';
import styles from './Checkbox.module.css';
import type { ReactNode } from 'react';

const Checkbox = ({
	value = false,
	size = 'medium',
	disabled = false,
	children,
	errored = false,
	onChange,
	showHorizontalRule = false,
	filterCheckbox,
	className,
}: ICheckboxProps) => {
	const handleChange = () => {
		onChange(!value);
	};

	const isExtraSmall = size === 'extra-small';

	return (
		<label className={clsx(styles.checkbox, disabled && styles.checkbox_disabled)}>
			<input
				checked={value}
				className={styles.checkbox__input}
				disabled={disabled}
				type="checkbox"
				onChange={handleChange}
			/>
			<span
				className={clsx(
					disabled && styles.checkbox__icon_disabled,
					filterCheckbox && styles.checkbox__icon_filter,
					styles.checkbox__icon,
					errored && styles.checkbox__icon_error,
					isExtraSmall && styles.checkbox__icon_extraSmall && className,
				)}
				data-size={size}
				// FIXME Mark this as role="checked" and input as aria-hidden cause failing of 1 test.
				// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
				tabIndex={0}
				onKeyPress={handleChange}
			>
				{value && !showHorizontalRule ? (
					isExtraSmall ? (
						<CheckMarkIcon color="#666666" height={10} width={10} />
					) : (
						<CheckMarkIcon color="#000000" height={12} width={12} />
					)
				) : null}
				{showHorizontalRule && <HorizontalRule height={12} width={12} />}
			</span>
			{children}
		</label>
	);
};

export interface ICheckboxProps {
	/** Does it checked or not.  */
	value: boolean;
	/** medium or small size of the check box. Default medium. */
	size?: 'medium' | 'small' | 'extra-small';
	/* If true forbids change value.*/
	disabled?: boolean;
	/** Title of the checkbox displayed on the right side. */
	children?: ReactNode;
	/** Highlights input with red color. */
	errored?: boolean;
	/** If true shows horizontal line. */
	showHorizontalRule?: boolean;
	/** Returns a new checked value */
	onChange: (newValue: boolean) => void;
	/** Optional flag for checkboxes on Deal Filter page */
	filterCheckbox?: boolean;
	/** Additional custom style for check box */
	className?: string;
}

export default Checkbox;
