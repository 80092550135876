import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import { makeTipMock } from '@mocks';
import type { TTip, TTipsFilter } from '@typings';

export const useMyTipsQuery = ({ categories }: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');

	return usePaginatedInfiniteQuery<TTip>(
		reactQuery.queryKeys.myTips(categories),
		async ({ pageParam = 1 }) => {
			await new Promise((resolve) => setTimeout(resolve, 1500));
			if (categories?.length === 1 && categories[0] === 'Other') {
				return {
					data: [],
					meta: { hasNext: false, hasPrevious: false },
					page: 1,
				};
			}
			const tips = Array.from({ length: 10 }, () =>
				makeTipMock(
					categories?.length
						? {
								categories: [categories[Math.floor(Math.random() * categories.length)]],
							}
						: {},
				),
			);

			return {
				data: tips,
				meta: { hasNext: pageParam <= 2, hasPrevious: false },
				page: Number(pageParam),
			};
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};
