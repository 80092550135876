import { useService, useMutation, useMe, useCallback, useMemo, useNotification, useTranslation, useRef } from '@hooks';
import { pick, resizeFile } from '@utils';

import type { TFullUser, TMediaObject, TUser } from '@typings';
import type { TEditProfileInfo } from '@schemas';
import type { MutateOptions } from '@tanstack/react-query';

export const useUpdateUserMutation = (
	showNotification = true,
	options?: MutateOptions<TFullUser, Error, Partial<TUser>, unknown>,
) => {
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { t } = useTranslation();
	const { showUnknownError, showSuccess } = useNotification();
	const { user } = useMe();
	const userSnapshot = useRef(user).current;

	const updateUser = useMutation<TFullUser, Error, Partial<TUser>, unknown>(
		['user.updateUser'],
		async (updatingUser) => api.user.updateUser(user.id, updatingUser),
		{
			...options,
			onSuccess: (newUser, updatingUser, context) => {
				if (showNotification) {
					showSuccess({
						title: t('Success'),
						subtitle: t('Your profile has been updated.'),
					});
				}
				queryClient.setQueryData<TFullUser | undefined>(queryKeys.getMe(), (user) => {
					const response = user
						? {
								...user,
								...pick(newUser, ['firstName', 'lastName', 'location', 'avatar', 'features']),
							}
						: user;
					return response;
				});
				queryClient.refetchQueries(queryKeys.getMe());
				queryClient.removeQueries(queryKeys.getUserFeed());
				queryClient.removeQueries(queryKeys.getPostActivityReactions());

				Object.entries(updatingUser).forEach(([key, value]) => {
					const userFieldKey = key as keyof TUser;
					if (updatingUser[userFieldKey] !== userSnapshot[userFieldKey]) {
						analytics.trackEvent('AccountInteractions', {
							interaction_type: 'Profile',
							action: `value ${key} changed to ${JSON.stringify(value)}`,
						});
					}
				});

				options?.onSuccess?.(newUser, updatingUser, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);

	const uploadAvatar = useMutation<TMediaObject, Error, File>(
		async (imageFile) => {
			const imageResult = await resizeFile(imageFile);
			return await api.mediaObject.uploadImage(imageResult);
		},
		{
			onError: (error) => showUnknownError(error),
		},
	);

	// Form props.
	const initialValues: TEditProfileInfo = useMemo(
		() => ({
			firstName: user.firstName,
			lastName: user.lastName,
			avatarMediaObject: user.avatar ?? null,
			location: user.location,
		}),
		[user],
	);
	const submit = useCallback(
		async ({ firstName, lastName, location, avatarMediaObject }: Partial<TEditProfileInfo>) => {
			const avatarId =
				avatarMediaObject?.id && user.avatar?.id !== avatarMediaObject?.id ? avatarMediaObject.id : user.avatar?.id;

			const preparedUpdatingUser = {
				firstName: firstName,
				lastName: lastName,
				location: location,
				...(avatarId ? { avatar: `/api/media_objects/${avatarId}` } : {}),
			};
			await updateUser.mutateAsync(preparedUpdatingUser as Partial<TUser>);
		},
		[user],
	);

	return {
		...updateUser,
		user,
		initialValues,
		uploadAvatar: uploadAvatar.mutateAsync,
		isUploadingAvatar: uploadAvatar.isLoading,
		submit,
	};
};
