import { useTranslation } from 'react-i18next';
import { getAlpha2Code, registerLocale, getName } from 'i18n-iso-countries';
import englishLocale from 'i18n-iso-countries/langs/en.json';
import { getAllCountriesName, getListOfRegionsByCountry, getRegionNameByCountryCode } from '@utils';

registerLocale(englishLocale);

export const useCountriesRegions = () => {
	const { i18n } = useTranslation();

	const getListOfRegions = (country: string) => {
		return getListOfRegionsByCountry(country);
	};

	const countryList = getAllCountriesName();

	const transformCountryNameToISO = (countryName?: string | null) => {
		return getAlpha2Code(countryName || '', String(i18n.resolvedLanguage)) || countryName;
	};

	const formatCountryName = (countryName?: string | null) => {
		// Format ISO country code to name. If provided countryName is not ISO format returned countryName.
		return getName(countryName || '', String(i18n.resolvedLanguage)) || countryName;
	};

	const formatRegionNameByCountry = (countryCode?: string | null, regionCode?: string | null) => {
		return getRegionNameByCountryCode(countryCode || '', regionCode || '') || regionCode;
	};

	return {
		getListOfRegions,
		countryList,
		transformCountryNameToISO,
		formatCountryName,
		formatRegionNameByCountry,
	};
};
