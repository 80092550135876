import { useMemo, useService } from '@hooks';
import type { TCalendarDaysStatuses, TCalendarDayStatus } from '@typings';
import type { TCalendarCellMode } from '@ui-kit/components/Calendar';

const getMarkedDates = (
	calendarDaysStatuses: TCalendarDaysStatuses,
	calendarDayStatusModes: Record<TCalendarDayStatus, TCalendarCellMode>,
): Record<string, TCalendarCellMode> => {
	const markedDate: Record<string, TCalendarCellMode> = {};

	for (const day in calendarDaysStatuses) {
		markedDate[day] = calendarDayStatusModes[calendarDaysStatuses[day]];
	}

	return markedDate;
};

export const useCalendarMarkedDates = () => {
	const { useAppSelector } = useService('ReduxService');
	const calendarDaysStatuses = useAppSelector((store) => store.calendar.dayStatuses);
	return useMemo(() => getMarkedDates(calendarDaysStatuses, calendarDayStatusModes), [calendarDaysStatuses]);
};

const calendarDayStatusModes: Record<TCalendarDayStatus, TCalendarCellMode> = {
	'with-attending': 'outlined',
	'with-available-events': 'alert',
	today: 'highlighted',
};
