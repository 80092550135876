import { useSearchParams } from '@hooks';
import { createTipFormSchema, type TCreateTipForm } from '@schemas';
import { catchError, qs } from '@utils';

/** It parses the search params to use as initial values for Tip new/create forms
 *  and returns either the validated form values or a validation error in Node-style format.
 */
export const useTipCreateDataSearchParams = () => {
	const [searchParams] = useSearchParams();
	const [tipFormParamsValidationError, tipFormValues = {} as TCreateTipForm] = catchError(
		() =>
			createTipFormSchema.cast(qs.parse(searchParams.toString()), {
				stripUnknown: true,
				assert: false,
			}) as TCreateTipForm,
	);

	return [tipFormParamsValidationError, tipFormValues] as const;
};
