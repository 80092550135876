import { AppConfigService } from '../implementations/AppConfigService';
import { AxiosService } from '../implementations/AxiosService';
import { I18nService } from '../implementations/I18nService';
import { LocalStorageService } from '../implementations/LocalStorageService';
import { LoggerService } from '../implementations/LoggerService';
import { OktaApiService } from '@tiger21-llc/connect-shared/src/services/OktaApiService';
import { OktaService } from '../implementations/OktaService/OktaService';
import { StreamChatService } from '../implementations/ChatService';
import { ReactQueryService } from '../implementations/ReactQueryService';
import { ReduxService } from '../implementations/ReduxService';
import { RouterService } from '../implementations/RouterService';
import { SentryService } from '../implementations/SentryService';
import { ApiService } from '../implementations/ApiService';
import { MicrositeApiService } from '../implementations/MicrositeApiService';
import { WebRiskApiService } from '../implementations/WebRiskApiService';
import { ToastService } from '../implementations/ToastService';
import { AlgoliaSearchService } from '@tiger21-llc/connect-shared/src/services/AlgoliaSearchService';
import { StreamFeedService } from '../implementations/StreamFeedService';
import { MixPanelService } from '../implementations/MixPanelService';
import { AlgoliaAnalyticsService } from '../implementations/AlgoliaInsightsService';
import { CryptoService } from '../implementations/CryptoService';
import { ApiVideoService } from '../implementations/ApiVideoService';
import { ServicesContainer } from '../ServicesContainer';

// Describe all DI-container services here.
const services = {
	AppConfigService, // must be first.
	logger: LoggerService, // must be second.
	SentryService, // must be third.
	AnalyticsService: MixPanelService,
	AlgoliaAnalyticsService,
	LocalStorageService,
	CryptoService,
	I18nService,
	RouterService,
	OktaApiService,
	OktaService,
	ReactQueryService,
	ReduxService,
	ToastService,
	AxiosService,
	ApiService,
	MicrositeApiService,
	WebRiskApiService,
	AlgoliaSearchService,
	FeedService: StreamFeedService,
	ChatService: StreamChatService,
	ApiVideoService,
};

export type TServices = typeof services;
export type TServiceInstances = TInstances<TServices>;

type TInstances<C extends Record<string, abstract new (...args: any) => any>> = {
	[K in keyof C]: InstanceType<C[K]>;
};

export class AppServicesContainer extends ServicesContainer<TServices> {
	constructor() {
		super(services);
	}
}
