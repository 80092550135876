import type { TFunction } from 'i18next';
import { dayjs } from '@utils';

export const formatLastMessagePreviewDate = (t: TFunction) => (created_at: string) => {
	if (created_at === '') return '';
	const createdAt = new Date(created_at);
	const weekDayName = dayjs(createdAt).format('dddd');
	const msDiff = new Date().getTime() - new Date(`${createdAt}`).getTime(); //Current Date - Future date
	const diffInDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
	const formatedDate = dayjs(createdAt).format('MMM D YYYY'); // Aug 12 2020
	const formatedTime = dayjs(createdAt).format('hh:mm A');
	const date = createdAt
		? diffInDays === 1
			? t('Yesterday')
			: diffInDays > 6
				? formatedDate
				: diffInDays === 0
					? formatedTime
					: weekDayName
		: '';
	return date;
};
