import type { TEditContactCard } from '@schemas';
import type { TResultsAddresses } from '@typings';
import { getRegionFullName } from '@utils';

export const transformAddressDetails = ({
	details,
	values,
	index,
}: {
	details: TResultsAddresses;
	values: TEditContactCard;
	index: number;
}) => {
	const updatedValues = { ...values };

	if (updatedValues.address?.[index]) {
		const isRegionPresent = getRegionFullName(details.countryShort, details.stateShort);
		const addressLine1 =
			details.streetName || details.streetNumber ? `${details.streetName} ${details.streetNumber}` : null;
		updatedValues.address[index].address.streetAddress1 = details.address || addressLine1 || details.city;
		updatedValues.address[index].address.country = details.countryShort || '';
		updatedValues.address[index].address.city = details.city || '';
		updatedValues.address[index].address.state = isRegionPresent ? details.stateShort || '' : '';
		updatedValues.address[index].address.postalCode = details.zipCode || '';
	}
	return updatedValues;
};

export const getUpdatedRegionsList = (
	country: string,
	getListOfRegions: (country: string) => { value: string; title: string }[],
) => {
	if (!country) return [];
	return country === 'United States' ? getListOfRegions('US') : getListOfRegions(country);
};
