import { FormPageLayout, Page } from '../../components';
import NewTipCategoriesForm from '@ui-modules/tipJar/components/NewTipCategoriesForm';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import { useTranslation, useNavigate } from '@hooks';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { useGuessTipInputQuery } from '@ui-modules/tipJar/hooks/useGuessTipInputQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import styles from './NewTipCategoriesPage.module.css';
import { ROUTES } from '@constants';

const NewTipCategoriesPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues, tipFormWasEdited] = useTipFormSearchParams();
	const tipCategoriesQuery = useTipCategoriesQuery();
	const guessTipQuery = useGuessTipInputQuery(tipFormValues, {
		enabled: !tipFormParamsValidationError && !tipFormWasEdited,
	});

	return (
		<Page title={t('New Tip categories')}>
			{tipCategoriesQuery.isLoading || guessTipQuery.isInitialLoading ? (
				<ActivityIndicator type="fit" />
			) : tipCategoriesQuery.isError || guessTipQuery.isError || tipFormParamsValidationError ? (
				<EmptyStateMessage
					text={
						tipCategoriesQuery.error?.message ||
						guessTipQuery.error?.message ||
						tipFormParamsValidationError?.message ||
						t('Something went wrong')
					}
				/>
			) : (
				<NewTipCategoriesForm
					categories={tipCategoriesQuery.data}
					initialValues={
						tipFormWasEdited
							? tipFormValues
							: {
									title: guessTipQuery.data?.title ?? undefined,
									description: guessTipQuery.data?.description ?? tipFormValues?.description,
									images: tipFormValues?.images ?? [],
									categories: guessTipQuery.data?.categories ?? tipFormValues?.categories ?? [],
									link: tipFormValues?.link ?? undefined,
								}
					}
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.newTipCategories()]}
							formProps={formProps}
							headerTitle={t('Select Type(s)')}
							isSaveButtonHidden
						>
							<div className={styles.newTipCategoriesPage__pageContent}>
								<h2>{t('What type of tip is this?')}</h2>

								<p className={styles.newTipCategoriesPage__description}>
									{t(
										'We selected a type for you, but you can change it if necessary below. At least one type required.',
									)}
								</p>

								{children}
							</div>
						</FormPageLayout>
					)}
					onSubmit={async (values) => {
						navigate(ROUTES.newTipCategories({ ...values, edited: true }), { replace: true }); // to keep initialValues in the URL if a user go back
						navigate(ROUTES.newTipMatches(values));
					}}
				/>
			)}
		</Page>
	);
};

export interface INewTipCategoriesPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NewTipCategoriesPage;
