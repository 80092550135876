import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { useTranslation } from '@hooks';
import { useTipsSortSearchParams } from '@ui-modules/tipJar/hooks/useTipsSortSearchParams';

export const useTipCategoriesSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	const [selectedSortOption, setSelectedSortOption] = useTipsSortSearchParams(TipEntity.getDefaultSortValue(t));
	const sortOptions = useRef(TipEntity.getTipSortOptions(t)()).current;
	const { data: tipCategories = [] } = useTipCategoriesQuery();

	const selectedTipCategories = useMemo(() => {
		const categoriesFromQuery = searchParams.get('categories');
		return categoriesFromQuery ? categoriesFromQuery.split(',') : [];
	}, [searchParams]);

	const handleApplyCategories = (categories: string[]) => {
		if (categories.length > 0) {
			searchParams.set('categories', categories.join(','));
		} else {
			searchParams.delete('categories');
		}
		setSearchParams(searchParams);
	};

	return {
		selectedSortOption,
		setSelectedSortOption,
		sortOptions,
		tipCategories,
		selectedTipCategories,
		handleApplyCategories,
	};
};
