const mentionRegex = /@(\w+)/g;
export const extractMentions = (text: string): string | null => {
	let match;

	let lastMention = null;

	while ((match = mentionRegex.exec(text)) !== null) {
		lastMention = match[1];
	}

	const isAtEnd = text?.endsWith('@') || text?.trim().endsWith('@ ');

	return isAtEnd || !lastMention ? null : lastMention;
};
