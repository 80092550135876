import axios from 'axios';
import { httpCodes } from './httpCodes';
import type { EventType } from '../EventEmitter';
import { EventEmitter } from '../EventEmitter';
import { AxiosError } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { IApiConfig } from './AppConfig.interface';

export abstract class AbstractAxiosService<TEvents extends Record<EventType, unknown>> extends EventEmitter<TEvents> {
	private axios: AxiosInstance;

	constructor(
		private readonly appConfig: IApiConfig,
		axiosConfig?: AxiosRequestConfig<any>,
	) {
		super();

		const axiosInstance = axios.create({
			baseURL: this.appConfig.API_BASE_URL,
			responseType: 'json',
			responseEncoding: 'utf8',
			...axiosConfig,
		});

		axiosInstance.interceptors.request.use((preRequestConfig: AxiosRequestConfig) => {
			if (typeof preRequestConfig.headers !== 'object') preRequestConfig.headers = {};

			const authToken = this.getBearerToken(preRequestConfig);
			if (preRequestConfig.headers['Stream-Auth-Type'] === 'jwt') {
				preRequestConfig.headers.Authorization = `${preRequestConfig.headers.Authorization}`;
			} else if (authToken) {
				preRequestConfig.headers.Authorization = `Bearer ${authToken}`;
			}

			if (preRequestConfig.method === 'patch') {
				preRequestConfig.headers['Content-Type'] = 'application/merge-patch+json';
			}

			this.onRequestStarted(String(preRequestConfig.url), preRequestConfig.data);

			return preRequestConfig;
		});

		axiosInstance.interceptors.response.use(
			(response) => {
				this.onRequestFinished(response.config, response);
				return this.transformResponse(response);
			},
			(error) => {
				// If we doesn't wrap error handling in setTimeout, react-query's hooks does not obtain AxiosError but CancelledError.
				setTimeout(() => {
					const responseStatus = error?.response?.status ?? 0; // 0 = 'Network Error'

					// Handle backend auth errors.
					if (responseStatus === httpCodes.UNAUTHORIZED) {
						this.onUnauthorized();
					}
					// Handle API errors (except 'Network Error')
					else if (responseStatus) {
						this.onError(error);
					}
					// Handle 'Network Error'
					else {
						const url = error instanceof AxiosError ? error.config.url : 'unknown url';
						this.onNetworkError(String(url));
					}

					if (error.response) {
						// The request was made and the server responded with a status code
						this.onRequestFinished(error.response.config, error.response);
					} else if (error.request) {
						// The request was made but no response was received
					} else {
						// Something happened in setting up the request that triggered an Error
					}
				}, 0);

				return Promise.reject(error);
			},
		);

		this.axios = axiosInstance;
	}

	public get request(): AxiosInstance['request'] {
		return this.axios.request;
	}

	public get get(): AxiosInstance['get'] {
		return this.axios.get;
	}
	public get post(): AxiosInstance['post'] {
		return this.axios.post;
	}
	public get put(): AxiosInstance['put'] {
		return this.axios.put;
	}
	public get patch(): AxiosInstance['patch'] {
		return this.axios.patch;
	}
	public get delete(): AxiosInstance['delete'] {
		return this.axios.delete;
	}

	public async setAuthorizationToken(authToken: string) {
		await this.resetBearerToken();
		this.axios.interceptors.request.use((preRequestConfig: AxiosRequestConfig) => {
			if (typeof preRequestConfig.headers !== 'object') preRequestConfig.headers = {};
			if (authToken) {
				preRequestConfig.headers.Authorization = `${authToken}`;
				preRequestConfig.headers['X-Client'] = 'integration';
			}

			this.onRequestStarted(String(preRequestConfig.url), preRequestConfig.data);

			return preRequestConfig;
		});
	}

	public get baseUrl(): string | undefined {
		return this.axios.defaults.baseURL;
	}
	public setBaseUrl(baseUrl: string) {
		this.axios.defaults.baseURL = baseUrl;
	}

	protected transformResponse(response: AxiosResponse<unknown, unknown>) {
		return response.data;
	}

	/** Obtain authorization bearer token to put into headers. */
	protected abstract resetBearerToken(): void;

	/** Obtain authorization bearer token to put into headers. */
	protected abstract getBearerToken(requestConfig: AxiosRequestConfig): string | null | undefined;

	/** Handle unauthorized error. */
	protected abstract onUnauthorized(): void;

	/** Handle request error received from the server. */
	protected abstract onError(error: AxiosError): void;

	/** Handle Network Error */
	protected abstract onNetworkError(url: string): void;

	protected abstract onRequestStarted(uri: string, data?: unknown): void;

	/** Track http-responses */
	protected abstract onRequestFinished(request: AxiosRequestConfig, response?: AxiosResponse): void;
}
