import { clsx } from '@utils';
import { ActivityIndicator, Portal } from '@ui-kit';
import styles from './AttachmentTextArea.module.css';

import type { ReactNode } from 'react';
import type { TAttachment } from '@typings';

const SuggestionsWrapper = ({ children, isLoading, attachments, title, containerHeight }: ISuggestionsWrapperProps) => {
	return (
		<Portal>
			<div
				className={clsx(styles.suggestionsContainer, attachments.length > 0 && styles.suggestionsContainer_attachments)}
				style={{ bottom: `${containerHeight + 10}px` }}
			>
				<div className={styles.suggestionsContainer__titleWrapper}>
					<p>{title}</p>
				</div>
				<div>
					{isLoading ? (
						<div className={styles.suggestionsContainer__activityIndicator}>
							<ActivityIndicator />
						</div>
					) : (
						children
					)}
				</div>
			</div>
		</Portal>
	);
};

export interface ISuggestionsWrapperProps {
	children: ReactNode;
	isLoading: boolean;
	attachments: TAttachment[];
	title: string;
	containerHeight: number;
}

export default SuggestionsWrapper;
