import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Avatar, HighlightedText, RelativeTime } from '@ui-kit';
import styles from './SuggestionListItem.module.css';

import type { SuggestionDataItem } from 'react-mentions';
import type { TFullUser } from '@typings';
import { useState } from 'reinspect';
import { toString } from '@utils';

const SuggestionListItem = ({
	suggestion,
	userSubtitle,
	textToHighlight,
	focused,
	onClickHandler,
	onSelectHandler,
}: ISuggestionListItemProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [isMouseDown, setIsMouseDown] = useState(false);

	useEffect(() => {
		if (focused && ref && 'current' in ref && ref.current) {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [focused]);
	return (
		<div
			className={clsx(focused ? styles.mention_focused : '', styles.mention)}
			key={suggestion.id}
			ref={ref}
			role="button"
			tabIndex={0}
			onMouseEnter={() => onSelectHandler?.(suggestion)}
			onKeyPress={(event) => onClickHandler?.(event, suggestion)}
			/** "onMouseDown" instead of "onClick" to fix automatic scroll issue on chat's autocomplete suggestion list [https://tiger21.atlassian.net/browse/T21C-7959] @author Hrant */
			onMouseDown={(event) => {
				event.preventDefault();
				event.stopPropagation();
				setIsMouseDown(true);
			}}
			onMouseLeave={() => setIsMouseDown(false)}
			/** "onMouseUp" instead of "onClick" to fix automatic scroll issue on chat's autocomplete suggestion list [https://tiger21.atlassian.net/browse/T21C-7959] @author Hrant */
			onMouseUp={(event) => {
				event.preventDefault();
				event.stopPropagation();
				if (isMouseDown) {
					event.persist();
					onClickHandler?.(event, suggestion);
				}
				setIsMouseDown(false);
			}}
		>
			<div className={styles.mentionContent}>
				<Avatar
					outline={Boolean(suggestion?.roles?.includes('ROLE_CHAIR'))}
					size={40}
					source={suggestion.avatar?.contentUrl}
					title={suggestion.display}
				/>
				<div className={styles.mentionContent__description}>
					<HighlightedText
						className={clsx(
							styles.mentionContent__highlightedDisplayName,
							focused && styles.mentionContent__highlightedDisplayName_focused,
						)}
						highlightedText={textToHighlight}
					>
						{toString(suggestion.display)}
					</HighlightedText>
					{userSubtitle && <p className={styles.mentionContent__communities}>{userSubtitle}</p>}
					{!!suggestion?.activity && (
						<RelativeTime className={styles.mentionContent__communities} dateString={suggestion?.activity} />
					)}
				</div>
			</div>
		</div>
	);
};

type TSuggestionWithDate = {
	activity?: string;
};

export type TSuggestion = Partial<TFullUser> & SuggestionDataItem & TSuggestionWithDate;

export interface ISuggestionListItemProps {
	suggestion: TSuggestion;
	userSubtitle?: string;
	textToHighlight: string;
	focused: boolean;
	onClickHandler?: (event: React.BaseSyntheticEvent, suggestion: TSuggestion) => void;
	onSelectHandler?: (item: TSuggestion) => void;
}

export default SuggestionListItem;
