import { InputLabel, TagRows, InputErrorMessage } from '@ui-kit';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { toString } from '@utils';

const TipCategoriesInput = ({ label, value, tipCategories, errorMessage, onChange }: ITipCategoriesInputProps) => {
	const toggleCategory = (togglingCategory: string) => {
		const newValue = value.includes(togglingCategory)
			? value.filter((category) => category !== togglingCategory)
			: [togglingCategory, ...value].slice(0, TipEntity.categoriesLimit);
		onChange(newValue);
	};

	return (
		<div>
			{label ? <InputLabel text={label} /> : null}
			<TagRows mode="row" selectedTags={value} tags={tipCategories} onSelect={toggleCategory} />
			<InputErrorMessage text={toString(errorMessage)} />
		</div>
	);
};

export interface ITipCategoriesInputProps {
	label?: string;
	value: string[];
	tipCategories: string[];
	errorMessage?: string | string[];
	onChange: (value: string[]) => void;
}

export default TipCategoriesInput;
