import { clsx } from '@utils';
import type { MutableRefObject, ReactNode, SyntheticEvent } from 'react';
import Sidebar from '../Sidebar';
import styles from './PageLayout.module.css';

const PageLayout = ({
	sidebarContent,
	children,
	actionElement,
	sideBarClassName,
	paddingSize = 'small',
	containerMode = true,
	className,
	onScroll,
	scrollRef,
}: IPageLayoutProps) => {
	return (
		<>
			<div className={styles.pageLayout}>
				{sidebarContent ? <Sidebar className={sideBarClassName}>{sidebarContent}</Sidebar> : null}
				<div className={clsx(styles.pageLayout__body, className)} ref={scrollRef} onScroll={onScroll}>
					<div
						className={clsx(
							containerMode ? styles.pageLayout__staticContentContainer : styles.pageLayout__contentContainer,
						)}
						data-padding={paddingSize}
					>
						{children}
					</div>
				</div>
			</div>
			{actionElement}
		</>
	);
};

export default PageLayout;

export interface IPageLayoutProps {
	children: ReactNode;
	sidebarContent: ReactNode;
	actionElement?: ReactNode;
	sideBarClassName?: string;
	paddingSize?: 'small' | 'medium' | 'large';
	containerMode?: boolean;
	className?: string;
	onScroll?: (event: SyntheticEvent<HTMLDivElement>) => void;
	scrollRef?: MutableRefObject<HTMLDivElement | null>;
}
