import { LockIcon } from '@ui-kit/icons';
import { pickUpFileAvatarIcon } from './FileTypeAvatar.utils';
import styles from './FileTypeAvatar.module.css';

/** Avatar displays icon with file extension related to the mime type of a file. */
const FileTypeAvatar = ({ mimeType, secure = false, width = 20, height = 24 }: IFileTypeAvatarProps) => {
	const AvatarIcon = pickUpFileAvatarIcon(mimeType);
	return (
		<div className={styles.fileTypeAvatar}>
			<AvatarIcon height={height} width={width} />
			{secure ? (
				<div className={styles.fileTypeAvatar__secureIcon}>
					<LockIcon />
				</div>
			) : null}
		</div>
	);
};

export interface IFileTypeAvatarProps {
	/** The mime type of a file. */
	mimeType: string;
	/** Can a file be downloaded on;y with password. Default false. */
	secure?: boolean;
	width?: number;
	height?: number;
}

export default FileTypeAvatar;
