import { useCallback, useService } from '@hooks';
import { dayjs, noop, last } from '@utils';
import type { QueryKey } from '@tanstack/react-query';
import type { FeedAPIResponse } from 'getstream';
import type { GetFeedOptions } from 'react-activity-feed/node_modules/getstream/lib/feed';
import type { FlatFeedProps } from 'react-activity-feed';

/** Custom FlatFeed doFeedRequest implementation made in order to:
 *   1. cache chat response within React Query cache.
 *   2. load feed data from cache if it is exists.
 * 	 3. loading from cache provides ability to restore scroll position within a Feed.
 *  Pay attention that if you made any changes within Feed (edit/deleted post, etc.) which is already in the cache you should invalidate it or update manually.
 */
export const useDoFeedRequest = (callbacks?: TUseDoFeedRequestCallbacks) => {
	// Dependencies.

	const { queryClient, queryKeys } = useService('ReactQueryService');

	// Helpers.

	const calculateFeedPage = (options: GetFeedOptions) => (options?.offset ?? 0) / (options?.limit ?? 0);

	const fetchFeed = async (
		client: TFeedClient,
		feedGroup: string,
		userId: string,
		options: GetFeedOptions,
		page: number,
	) =>
		await queryClient.fetchQuery(
			queryKeys.getUserFeed(userId, page),
			async () => await client.feed(feedGroup, userId).get(options),
			{
				cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
				staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			},
		);

	const getFeedCache = (userId: string, page: number): TFeedQueryCache =>
		queryClient.getQueriesData<FeedAPIResponse>(queryKeys.getUserFeed(userId, page)) as TFeedQueryCache;

	const extractFeedFromQueryCache = (feedCache: TFeedQueryCache) => {
		if (!feedCache || !feedCache.length) return {} as FeedAPIResponse;

		const allFeedResponseResults = feedCache.flatMap(
			([queryKey, cacheRecord]) => cacheRecord.results as any,
		) as FeedAPIResponse['results'];
		const lastFeedResponse = last(feedCache)?.[1];
		const composedFeedResponse: FeedAPIResponse = {
			...lastFeedResponse,
			results: allFeedResponseResults,
		} as FeedAPIResponse;
		return composedFeedResponse;
	};

	//  Main function.

	return useCallback<TDoFeedRequest>(async (client, feedGroup, userId, options = {}) => {
		const page = calculateFeedPage(options);
		const feedCache = getFeedCache(String(userId), page);

		try {
			if (/* page === 0 &&  */ feedCache.length) {
				const feedApiResponse = extractFeedFromQueryCache(feedCache);
				setTimeout(callbacks?.onLoadedFromCache ?? noop, 0); // postpone task to the next event loop iteration to allow the list to be rendered on time.
				return feedApiResponse as unknown as ReturnType<TDoFeedRequest>;
			} else {
				throw new Error('There is no cache hit');
			}
			// Wrapped to catch to handle also exceptions on extractFeedFromQueryCache due to corrupted cache (fixes T21C-3255) [@dmitriy.nikolenko].
		} catch (error) {
			const feedApiResponse = await fetchFeed(client, feedGroup, String(userId), options, page);
			return feedApiResponse as unknown as ReturnType<TDoFeedRequest>;
		}
	}, []);
};

type TFeedQueryCache = [QueryKey, FeedAPIResponse][];
type TDoFeedRequest = Required<FlatFeedProps>['doFeedRequest'];
type TFeedClient = Parameters<TDoFeedRequest>[0];

export type TUseDoFeedRequestCallbacks = {
	onLoadedFromCache: () => void;
};
