import styles from '../CameraPhotoInput.module.css';
import { ChatPhotoIcon } from '../../../icons';

const TakeImageButton = ({ onClick }: ITakeImageButtonProps) => {
	return (
		<button
			aria-label="Take an image"
			className={styles.cameraPhotoInput__takeImageButton}
			type="button"
			onClick={onClick}
		>
			<ChatPhotoIcon className={styles.cameraPhotoInput__takeImageButtonIcon} height={24} width={24} />
		</button>
	);
};

export interface ITakeImageButtonProps
	extends Pick<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'> {}

export default TakeImageButton;
