import type { QueryKey } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import {
	compact,
	dayjs,
	updateDealReactionsInInfiniteQueryCache,
	produce,
	updateNetworkConnectionsInfiniteQueryCache,
	extractReactionsFormActivity,
} from '@utils';
import type {
	IInfinitePaginatedListData,
	TActivity,
	TCommunity,
	TDeal,
	TDealMemberRelationsStatusForRequestQuery,
	TDealQuerySort,
	TDealWithReactions,
	TEvent,
	TFolder,
	TNetworkCreationRequest,
	TPaginatedList,
	TProfile,
	TWaiverSignedInfo,
	TUserProfileRecord,
	TNetworkConnection,
	TNetworkConnectionQuerySort,
	TDealReactions,
	TNetworkConnectionReactions,
	TNetworkConnectionWithReactions,
	TAlgoliaSearchResponse,
	TUser,
	TAssetAllocationPeriodQueryParams,
	TAssetAllocationPeriod,
	TUid,
	TTipsFilter,
} from '@typings';
import { httpCodes } from '../abstracts/AbstractAxiosService/httpCodes';
import { AxiosError } from 'axios';
import type { Draft } from '@reduxjs/toolkit';
import type { TPostNetworkConnectionFilterDTO } from '@tiger21-llc/connect-shared/src/typings/api/PostNetworkConnectionUserFilterDto.type';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IRouterService } from '../interfaces/RouterService.interface';

/** Initializes query client for React-Query environment. Allows to call queryClient instance methods. */
export class ReactQueryService {
	static inject = ['logger', 'RouterService'] as const;
	constructor(logger: IDevLogger, router: IRouterService) {
		this.logger = logger.child('ReactQueryService');

		let isTokenRefetching = false; // deduplication of token refetching requests to avoid multiple requests at the same time causes signing out.
		const queryClient = new QueryClient({
			defaultOptions: {
				queries: {
					refetchOnWindowFocus: false,
					refetchIntervalInBackground: false,
					retry: (failureCount, error) => {
						const isUnauthorizedError =
							error instanceof AxiosError && error?.response?.status === httpCodes.UNAUTHORIZED;
						if (isUnauthorizedError) {
							const isFirstFailure = failureCount === 0;
							if (isFirstFailure) {
								this.logger.debug("refetch ['auth.refreshToken'] & retry query because of Unauthorized error");
								if (!isTokenRefetching) {
									isTokenRefetching = true;
									queryClient.refetchQueries(['auth.refreshToken']).finally(() => {
										isTokenRefetching = false;
									});
								}
								return true;
							} else {
								router.handleUserUnauthorized();
							}
						}

						return false;
					},
					retryDelay: dayjs.duration(3, 'seconds').asMilliseconds(), // give a time for refresh tokens (retry fn can't be async to wait for a request result).
					staleTime: dayjs.duration(3, 'seconds').asMilliseconds(),
				},
			},
		});

		this.queryClient = queryClient;
	}

	readonly queryClient: QueryClient;
	readonly logger: IDevLogger;

	/** Config of query keys to keep connection between useQuery and invalidateQueries/refetchQueries/prefetchQuery etc  */
	readonly queryKeys = Object.freeze({
		getMe: () => ['user.getMe'],
		getMemberRelation: () => ['user.getMemberRelation'],
		getMyLifestyleNetworks: (networkTab: string | undefined) =>
			compact(['community.getMyLifestyleNetworks', networkTab]),
		getMyInvestNetworks: () => ['community.getMyInvestNetworks'],
		getForeignLifestyleNetworksList: (networkTab: string) => ['community.getForeignLifestyleNetworksList', networkTab],
		getForeignInvestNetworksList: () => ['community.getForeignInvestNetworksList'],
		getCommunity: (communityId: TCommunity['id']) => ['community.getCommunity', communityId],
		getProfileCommunities: (profileId: TProfile['id']) => ['communities.getProfileCommunities', profileId],
		getProfileLeadsCommunities: (profileId: TProfile['id']) => ['communities.getProfileLeadsCommunities', profileId],
		getProfileByUserId: (userId: TUser['id']) => ['profile.getProfileByUserId', userId],
		getChannel: (channelId: TCommunity['id']) => ['chat.getChannel', channelId],
		isChatUserPaused: (userSlug: TUser['slug']) => ['chat.isUserPaused', userSlug],
		getChatMemberAvatar: (channelId: string) => ['chat.getMemberAvatar', channelId],
		getCommunityRegularMemberList: (communityId: TCommunity['id']) => [
			'membership.getCommunityRegularMemberList',
			communityId,
		],
		createMembership: () => [`membership.createMembership`],
		createMembershipRequest: () => ['membership.createMembershipRequest'],
		removeMembership: () => ['membership.removeMembership'],
		getAddressBook: () => ['profile.getAddressBook'],
		getUserFeed: (userId?: string, page?: number) => compact(['client.feed', userId, page]),
		getPostActivityReactions: (activityId?: string, page?: string) =>
			compact(['client.activityReactions', activityId, page]),
		getPinnedBanners: () => ['streamFeeds.getPinnedBanner'],
		getNetworkCreationRequest: (networkRequestId: TNetworkCreationRequest['id']) => [
			'networkCreationRequest.getNetworkCreationRequest',
			networkRequestId,
		],
		getCommunityCalendarItems: (communityId: TCommunity['id']) => [
			'calendarItem.getCommunityCalendarItems',
			communityId,
		],
		getMeeting: (eventId: TEvent['id']) => ['meeting.getMeeting', eventId],
		getMeetingAttendances: (meetingId: TEvent['id'], filters?: { guests: boolean; going: boolean }) =>
			compact([
				'attendance.getMeetingAttendances',
				meetingId,
				filters ? { going: filters.going, guests: filters.guests } : null,
			]),
		getCommunityFiles: (communityId: TCommunity['id'], folderId: TFolder['id'] | null) => [
			'communityFile.getCommunityFiles',
			communityId,
			folderId,
		],
		getCommunityFolder: (communityId: TCommunity['id'], folderId: TFolder['id'] | null) => [
			'communityFolder.getCommunityFolder',
			communityId,
			folderId,
		],
		getCommunityFolders: (communityId: TCommunity['id'], folderId?: TFolder['id'] | null) =>
			compact(['communityFolder.getCommunityFolders', communityId, folderId]),
		getDealFiles: (dealId: TDeal['id'], folderId: TFolder['id'] | null) => [
			'communityFile.getCommunityFiles',
			dealId,
			folderId,
		],
		getDealPreviewFiles: (dealId: TDeal['id'], folderId: TFolder['id'] | null) => [
			'communityFile.getCommunityPreviewFiles',
			dealId,
			folderId,
		],
		getNetworkTabs: () => ['community.getNetworkTabs'],
		getDealDocuments: (dealId: TDeal['id']) => ['dealFile.getDealDocuments', dealId],
		getDealFolder: (dealId: TDeal['id'], folderId: TFolder['id'] | null) => [
			'communityFolder.getCommunityFolder',
			dealId,
			folderId,
		],
		getDealFolders: (dealId: TDeal['id'], folderId?: TFolder['id'] | null) =>
			compact(['communityFolder.getCommunityFolders', dealId, folderId]),
		getSingleActivity: (activityId?: TActivity['id']) => compact(['activity', activityId]),
		getDeal: (dealId?: TDeal['id']) => compact(['deals.getDeal', dealId]),
		getDealPreview: (dealId?: TDeal['id']) => compact(['deals.getDealPreview', dealId]),
		getDealPreviewStatus: (dealId?: TDeal['id']) => compact(['deals.getDealPreviewStatus', dealId]),
		getNetworkDeals: (communityId?: TCommunity['id'], filterIdWithUpdatedDate?: string, sort?: TDealQuerySort) =>
			compact(['network.getDeals', communityId, filterIdWithUpdatedDate, sort]),
		getAllDeals: (filterIdWithUpdatedDate?: string, sort?: TDealQuerySort) =>
			compact(['getAllDeals', filterIdWithUpdatedDate, sort]),
		getDealTerms: () => ['getDealTerms'],
		getMyDeals: (sort?: TDealQuerySort) => compact(['deals.getMyDeals', sort]),
		getNotInterestedDeals: () => ['deals.getNotInterestedDeals'],
		getDealMemberRelations: (dealId: TDeal['id'], status?: TDealMemberRelationsStatusForRequestQuery) =>
			compact(['deals.useGetDealMemberRelationsQuery', dealId, status]),
		getLatestMembersForDeal: (dealId: TDeal['id']) => ['deals.useGetLatestMembersForDealQuery', dealId],
		getDealSettings: () => ['deals.useGetDealSettings'],
		getAllDealFilterConfig: () => ['invest.getAllDealFilterConfig'],
		getAllDealFilters: () => ['invest.getAllDealFilters'],
		getDealFilterViaCommunityId: (communityId: TCommunity['id']) => ['dealFilter.viaCommunityId', communityId],
		getWaiversSignedInfo: () => ['waivers.getSignedInfo'],
		getCalendarItem: (calendarItemId: TEvent['id']) => ['getCalendarItem', calendarItemId],
		getProfileDeal: (profileId?: TUserProfileRecord['id']) => compact(['deals.getUserDeals', profileId]),
		getCommunitiesNotificationSettings: () => ['communities.notificationSettings'],
		getDefaultNotificationSettings: () => ['notifications.defaultSettings'],
		getNotificationSettings: () => ['notifications.getSettings'],
		chaptersNotificationSettings: () => ['notifications.chaptersNotificationSettings'],
		/**
		 *  Network connections
		 * */
		createNetworkConnection: () => ['networkConnection.create'],
		editNetworkConnection: () => ['networkConnection.edit'],
		createNetworkConnectionDonationAction: () => ['networkConnectionAction.create'],
		editNetworkConnectionDonationAction: () => ['networkConnectionAction.edit'],
		getNetworkConnection: (id: TNetworkConnection['id']) => ['networkConnection', id],
		getNetworkConnectionCategories: (connectionId: TNetworkConnection['id']) => [
			'networkConnection.categories',
			connectionId,
		],
		getNetworkConnectionTemplate: (connectionId: TNetworkConnection['id']) => [
			'networkConnection.getTemplate',
			connectionId,
		],
		getNetworkConnectionMembers: (connectionId: TNetworkConnection['id']) => [
			'networkConnection.getNetworkConnectionMembers',
			connectionId,
		],
		getNetworkConnectionFiles: (connectionId: TNetworkConnection['id'], folderId: TFolder['id'] | null) => [
			'networkConnection.getFiles',
			connectionId,
			folderId,
		],
		getNetworkConnectionFolder: (connectionId: TNetworkConnection['id'], folderId: TFolder['id'] | null) => [
			'networkConnectionFolder.getNetworkConnectionFolder',
			connectionId,
			folderId,
		],
		getNetworkConnectionFolders: (connectionId: TNetworkConnection['id'], folderId?: TFolder['id'] | null) =>
			compact(['networkConnection.getNetworkConnectionFolders', connectionId, folderId]),
		getNetworkConnectionBookmarks: (connectionId: TNetworkConnection['id']) => [
			'networkConnection.bookmarks',
			connectionId,
		],
		patchNetworkConnectionBookmarks: () => ['networkConnection.patchBookmarks'],
		getNetworkConnections: (
			connectionId?: TNetworkConnection['id'],
			userFilters?: TPostNetworkConnectionFilterDTO['filters'],
			sort?: TNetworkConnectionQuerySort,
		) => compact(['networkConnections', connectionId, JSON.stringify(userFilters), sort]),
		getNetworkConnectionMemberRelation: (connectionId: TNetworkConnection['id']) => [
			'networkConnection.getNetworkConnectionUserRelation',
			connectionId,
		],
		deleteNetworkConnection: () => ['networkConnection.delete'],
		reportNetworkConnection: () => ['networkConnection.report'],
		getNetworkConnectionUserFilters: (communityId: TCommunity['id']) => ['networkConnectionUserFilters', communityId],
		deleteNetworkConnectionUserFilters: () => ['networkConnectionUserFilters.delete'],
		searchByFeedNCActivities: (communityId?: TCommunity['id'] | null, preparedSearchQuery?: string): QueryKey => [
			compact(['algoliaSearch.searchByFeedNCActivities', communityId, preparedSearchQuery]),
		],

		getMyAssetAllocation: () => ['getMyAssetAllocation'],
		getAssetAllocationCommunities: (params?: TAssetAllocationPeriod) =>
			compact(['getAssetAllocationCommunities', params]),
		postMyAssetAllocation: () => ['postMyAssetAllocation'],
		getCommunityAssetAllocation: (communityId?: string) => compact(['assetAllocation', communityId]),
		getAssetAllocationsRecommendations: (communityId?: string) =>
			compact(['assetAllocation.recommendations', communityId]),

		getAssetAllocationComparePeriods: (
			type: TAssetAllocationPeriodQueryParams['type'],
			communityId?: TAssetAllocationPeriodQueryParams['communityId'],
		) => compact(['assetAllocationComparePeriods', type, communityId]),

		// Flyout
		setFlyoutMessageAsRead: () => compact(['flyout.setFlyoutMessageAsRead']),
		getFlyoutMessageAsRead: (page?: number) => compact(['flyout.getFlyoutMessageAsRead', page]),
		getAssetAllocationsRecommendationCollections: (searchQueries?: string[]) =>
			compact(['assetAllocation.recommendationsCollection', searchQueries]),
		searchByIndex: (definition: string, searchQuery: string) => compact(['searchByIndex', definition, searchQuery]),

		// Tip Jar
		myTips: (filters?: string[], sort?: string[]) => compact(['tips', 'list', 'my', filters, sort]),
		allTips: (filters?: string[], sort?: TTipsFilter | undefined) => compact(['tips', 'list', 'all', filters, sort]),
		groupsTips: (filter?: TTipsFilter) => compact(['tips', 'list', 'groups', filter]),
		tip: (tipId: TUid) => ['tips', 'tip', tipId],
		tipReviews: (tipId: TUid) => ['tips', 'tip', tipId, 'reviews'],
		tipCommunityReviews: (tipId: TUid, communityId?: TCommunity['id']) =>
			compact(['tips', 'tip', tipId, 'communityReviews', communityId]),
		tipCalendarItemReviews: (tipId: TUid, calendarItemId?: TEvent['id']) =>
			compact(['tips', 'tip', tipId, 'calendarItemReviews', calendarItemId]),
		tipReview: (tipId: TUid, tipReviewId: TUid) => ['tips', 'tip', tipId, 'reviews', tipReviewId],
		tipContributors: (tipId: TUid) => ['tips', 'tip', tipId, 'contributors'],
		tipsGroup: () => ['tips', 'list', 'group'],
		tipShares: (tipId: TUid) => ['tips', 'tip', tipId, 'shares'],
		communityTips: (communityId?: TCommunity['id'], filters?: TTipsFilter) =>
			compact(['tips', 'list', 'community', communityId, filters]),
		searchTips: (searchQuery: string, tipFilter?: Partial<TTipsFilter & { communityId?: TCommunity['id'] }>) => [
			'tips',
			'list',
			'search',
			searchQuery,
			tipFilter,
		],
		meetingTips: (calendarItemId?: TEvent['id']) => compact(['tips', 'list', 'meeting', calendarItemId]),
	});

	/* Invalidate cache helpers */

	mutateEntityListInfiniteQueryCache<T extends { id: string }>(
		entityId: string,
		updater: (previous: T, index: number, self: Draft<T>[]) => void,
	) {
		return (cache: IInfinitePaginatedListData<T> | undefined) => {
			if (!cache) return cache;
			return produce(cache, (draft) => {
				draft.pages.forEach((page) => {
					page.data.forEach((entity, index, self) => {
						if (entity.id === entityId) {
							updater(page.data[index] as T, index, self);
						}
					});
				});
			});
		};
	}

	mutateEntityListQueryCache<T extends { id: string }>(entityId: string, updater: (previous: T) => void) {
		return (cache: TPaginatedList<T> | undefined) => {
			if (!cache || !cache.data.length) return cache;
			return produce(cache, (draft) => {
				draft.data.forEach((entity, index) => {
					if (entity.id === entityId) {
						updater(draft.data[index] as T);
					}
				});
			});
		};
	}
	mutateProfileDealEntityQueryCache<T extends { id: string }>(entityId: string, updater: (previous: T) => void) {
		return (cache: Array<T> | undefined) => {
			if (!cache) return cache;
			return produce(cache, (draft) => {
				cache.forEach((entity, index) => {
					if (entity.id === entityId) {
						updater(draft[index] as T);
					}
				});
			});
		};
	}

	removeEntityListInfiniteQueryCache<T extends { id: string }>(entityId: string) {
		return (cache: IInfinitePaginatedListData<T> | undefined) => {
			if (!cache) return cache;
			return produce(cache, (draft) => {
				draft.pages.forEach((page) => {
					page.data = page.data.filter((entity) => entity.id !== entityId);
				});
			});
		};
	}

	removeEntityListQueryCache<T extends { id: string }>(entityId: string) {
		return (cache: TPaginatedList<T> | undefined) => {
			if (!cache || !cache.data.length) return cache;
			return produce(cache, (draft) => {
				draft.data = draft.data.filter((entity) => entity.id !== entityId);
			});
		};
	}

	/* Entity cache helpers */

	invalidateCalendarItemsCache(eventId: TEvent['id']) {
		this.queryClient.invalidateQueries(this.queryKeys.getMeeting(eventId));
	}

	refetchAffectedCalendarDateStatuses(eventStartDate: TEvent['startDate']) {
		const year = dayjs(eventStartDate).year();
		const month = dayjs(eventStartDate).month() + 1; // correct 0..11 to 1.12
		this.queryClient.invalidateQueries(['calendarItem.getCalendarItemsDateStatuses', year, month]);
	}

	updateEventInGetMeetingCache(eventId: TEvent['id'], callback: (event: TEvent) => TEvent) {
		this.queryClient.setQueryData<TEvent | undefined>(this.queryKeys.getMeeting(eventId), (event) => {
			if (!event) return event;
			return callback(event);
		});
	}

	updateGroupMeetingsCache(communityId: TCommunity['id'], eventId: TEvent['id'], callback: (event: TEvent) => TEvent) {
		this.queryClient.setQueryData<TEvent[] | undefined>(
			this.queryKeys.getCommunityCalendarItems(communityId),
			(events) => {
				return events?.length
					? events.map((cachedEvent) => {
							if (cachedEvent.id !== eventId) return cachedEvent;
							return callback(cachedEvent);
						})
					: events;
			},
		);
	}

	async refetchDealsAfterReactionsMutation(updatedActivity: TActivity) {
		await this.queryClient.setQueriesData<IInfinitePaginatedListData<TDealWithReactions>>(
			this.queryKeys.getAllDeals(),
			(cache) => (cache ? updateDealReactionsInInfiniteQueryCache(cache, updatedActivity) : cache),
		);
		await this.queryClient.setQueriesData<IInfinitePaginatedListData<TDealWithReactions>>(
			this.queryKeys.getNetworkDeals(),
			(cache) => (cache ? updateDealReactionsInInfiniteQueryCache(cache, updatedActivity) : cache),
		);
	}

	updateNetworkConnectionCache(
		networkConnectionId: TNetworkConnection['id'],
		updater: (previous: TNetworkConnection) => void,
	) {
		this.queryClient.setQueryData<TNetworkConnection>(
			this.queryKeys.getNetworkConnection(networkConnectionId),
			(networkConnectionId) => (networkConnectionId ? produce(networkConnectionId, updater) : networkConnectionId),
		);
	}

	updateDealsCache(dealId: TDeal['id'], updater: (previous: TDeal, index?: number, self?: Draft<TDeal>[]) => void) {
		this.queryClient.setQueryData<TDeal>(this.queryKeys.getDeal(dealId), (deal) =>
			deal ? produce(deal, updater) : deal,
		);

		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getMyDeals(),
			this.mutateEntityListInfiniteQueryCache<TDeal>(dealId, updater),
		);
		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getAllDeals(),
			this.mutateEntityListInfiniteQueryCache<TDeal>(dealId, updater),
		);
		this.queryClient.setQueryData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getNotInterestedDeals(),
			this.mutateEntityListInfiniteQueryCache<TDeal>(dealId, updater),
		);

		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getNetworkDeals(), // loop through all caches because we don't know a target communityId
			this.mutateEntityListInfiniteQueryCache<TDeal>(dealId, updater),
		);
	}

	removeDealFromCache(dealId: TDeal['id']) {
		this.queryClient.removeQueries(this.queryKeys.getDeal(dealId));

		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getMyDeals(),
			this.removeEntityListInfiniteQueryCache<TDeal>(dealId),
		);
		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getAllDeals(),
			this.removeEntityListInfiniteQueryCache<TDeal>(dealId),
		);
		this.queryClient.setQueryData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getNotInterestedDeals(),
			this.removeEntityListInfiniteQueryCache<TDeal>(dealId),
		);

		this.queryClient.setQueriesData<IInfinitePaginatedListData<TDeal>>(
			this.queryKeys.getNetworkDeals(), // loop through all caches because we don't know a target communityId
			this.removeEntityListInfiniteQueryCache<TDeal>(dealId),
		);
	}

	refetchDealsQueries(communityId: TCommunity['id']) {
		this.queryClient.invalidateQueries(this.queryKeys.getAllDeals());
		this.queryClient.invalidateQueries(this.queryKeys.getMyDeals());
		this.queryClient.invalidateQueries(this.queryKeys.getNotInterestedDeals());
		this.queryClient.invalidateQueries(this.queryKeys.getNetworkDeals(communityId));
	}

	refreshWaiverIfDealsForbiddenError(error: AxiosError) {
		if (error.response?.status === httpCodes.FORBIDDEN) {
			const waiverSignedInfo = this.queryClient.getQueryData<TWaiverSignedInfo>(this.queryKeys.getWaiversSignedInfo());
			if (!waiverSignedInfo?.isAnySigned) {
				this.queryClient.invalidateQueries(this.queryKeys.getWaiversSignedInfo());
			}
		}
	}

	onNoFoundResponseError(error: AxiosError, onError: () => void) {
		if (error.response?.status === httpCodes.NOT_FOUND) {
			onError();
		}
	}

	removeNetworkConnectionFromCache(networkConnectionId: TNetworkConnection['id'], communityId: TCommunity['id']) {
		this.queryClient.removeQueries(this.queryKeys.getNetworkConnection(networkConnectionId));

		this.queryClient.setQueriesData<IInfinitePaginatedListData<TNetworkConnection>>(
			this.queryKeys.getNetworkConnections(communityId),
			this.removeEntityListInfiniteQueryCache<TNetworkConnection>(networkConnectionId),
		);
	}

	refetchNetworkConnectionReactionsMutation(updatedActivity: TActivity, communityId: TCommunity['id']) {
		this.queryClient.setQueriesData(this.queryKeys.getNetworkConnections(communityId), (cache) =>
			cache
				? updateNetworkConnectionsInfiniteQueryCache(
						cache as IInfinitePaginatedListData<TNetworkConnection>,
						updatedActivity,
					)
				: cache,
		);
	}

	updateReactionsInInfiniteQueryCache<
		E extends (TNetworkConnectionReactions | TDealReactions) & { streamActivityId: string },
	>(cache: IInfinitePaginatedListData<E>, updatedActivity: TActivity) {
		return produce(cache, (draft) => {
			draft.pages.forEach((page) => {
				page.data.forEach((entity, index) => {
					if (updatedActivity.id === entity.streamActivityId) {
						page.data[index] = { ...entity, ...extractReactionsFormActivity(updatedActivity) };
					}
				});
			});
		});
	}

	updateNetworkConnectionReactionsInCache(updatedActivity: TActivity, communityId: TCommunity['id']) {
		this.queryClient.setQueriesData<IInfinitePaginatedListData<TNetworkConnectionWithReactions>>(
			this.queryKeys.getNetworkConnections(communityId),
			(cache) => {
				if (!cache) return cache;
				return this.updateReactionsInInfiniteQueryCache(cache, updatedActivity);
			},
		);
	}

	async filterEntityFromAlgoliaSearchCache(queryKey: QueryKey, entityId: string) {
		await this.queryClient.setQueriesData<{
			pages: Array<TAlgoliaSearchResponse<{ objectID: string }>>;
			pageParams: unknown[];
		}>(queryKey, (cache) => {
			if (!cache) return cache;
			return produce(cache, (draft) => {
				draft.pages.forEach((page) => {
					page.hits = page.hits.filter((entity) => entity.objectID !== entityId);
				});
			});
		});
	}

	hasAnyDataInInfiniteQueryCache(queryKey: QueryKey) {
		const showSortOptions = this.queryClient.getQueriesData<IInfinitePaginatedListData<unknown>>(queryKey);
		const hasAnyData = showSortOptions.some((cacheItem) => !!cacheItem?.[1]?.pages?.[0]?.data?.length);

		return hasAnyData;
	}
}
