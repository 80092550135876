import { useTranslation } from '@hooks';
import styles from './MeetingTipsList.module.css';
import { useMeetingTipsQuery } from '@ui-modules/tipJar/hooks/useMeetingTipsQuery';
import { ActivityIndicator, Button, EditIcon } from '@ui-kit';
import React, { useState } from 'react';
import type { TProfile } from '@typings';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';
import { MEETING_TIPS_COUNT } from '@constants';
import clsx from 'clsx';

const MeetingTipsList = ({ calendarId }: IMeetingTipsListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [tipsToShow, setTipsToShow] = useState<number>(MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT);

	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	const { data: tips = [], isLoading, isFetched } = useMeetingTipsQuery(calendarId);
	const visibleTips = tips?.slice(0, tipsToShow) || [];

	const handleToggleShowAll = () => {
		setTipsToShow(
			tipsToShow !== MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT : tips.length,
		);
	};

	return (
		<>
			{isLoading && <ActivityIndicator size="medium" type="fit" />}
			{!isLoading && isFetched && visibleTips.length > 0 && (
				<>
					<div className={styles.meetingTipsList__header}>
						<h3 className={styles.meetingTipsList__headerTitle}>{t('Tips')}</h3>
						<Button icon={<EditIcon height={14} width={14} />} title={t('New')} type="outline" variant="small" />
					</div>
					<div className={clsx(styles.meetingTipsList__content, tipsToShow && styles.meetingTipsList__contentExpanded)}>
						{visibleTips.map((tip) => (
							<TipCard
								className={styles.meetingTipsList__card}
								key={tip.id}
								tip={tip}
								onClick={() => navigate(ROUTES.tipPage(tip.id, undefined, calendarId))}
								onProfileClick={() => openProfile(tip.owner.profileID)}
							/>
						))}
					</div>
					{tips.length > MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? (
						<button className={styles.meetingTipsList__button} onClick={handleToggleShowAll}>
							{tipsToShow !== MEETING_TIPS_COUNT.DEFAULT_TIPS_COUNT ? t('Show Less') : t('Show More')}
						</button>
					) : null}
				</>
			)}
		</>
	);
};

export interface IMeetingTipsListProps {
	calendarId: string | undefined;
}

export default MeetingTipsList;
