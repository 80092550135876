import type { TTipWithCommunity } from '@typings';
import { breakdownListForVirtuosoSections } from '@utils';

// Type guard to check if a record has a 'community' property
function hasCommunity(record: any): record is TTipWithCommunity {
	return record && record.community && typeof record.community.name === 'string';
}

export function prepareDataForGroupTipsList<T extends Record<string, unknown>, R extends Record<string, unknown> = T>(
	sortedRecords: T[],
	getSection: (record: T) => string,
	formatRecord: (record: T) => R,
): readonly [R[], number[], string[]] {
	if (!sortedRecords?.length) return [[], [], []];

	const sectionsMap = new Map<string, number>();
	const preparedData: R[] = [];

	for (let index = 0; index < sortedRecords.length; index++) {
		const record = sortedRecords[index];
		const section = getSection(record);
		if (!sectionsMap.has(section)) {
			const sectionsIndexShift = sectionsMap.size;
			const stickyIndex = index + sectionsIndexShift;
			sectionsMap.set(section, stickyIndex);
		}
		preparedData.push(formatRecord(record));
	}

	const { groups, groupCounts } = breakdownListForVirtuosoSections(sortedRecords, (tip) => {
		if (hasCommunity(tip)) {
			return tip.community.name;
		} else {
			return 'unknown';
		}
	});

	return [preparedData, groupCounts, groups] as const;
}
