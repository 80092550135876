import styles from './CollapsibleText.module.css';
import type { MouseEventHandler, KeyboardEventHandler } from 'react';
import clsx from 'clsx';

/** Text block which is collapsed to collapsedNumberOfLines and can be expanded via link-button. */
const CollapsibleTextButton = ({ onToggle, title, isTextButtonLight }: ICollapsibleTextProps) => {
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onToggle();
	};

	return (
		<span
			className={clsx(styles.collapsibleText__button, isTextButtonLight && styles.collapsibleText__buttonLight)}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			{title}
		</span>
	);
};

export interface ICollapsibleTextProps {
	/** Text content. */
	title: string;
	/** Action callback. */
	onToggle: () => void;
	isTextButtonLight?: boolean;
}

export default CollapsibleTextButton;
