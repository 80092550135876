import { useService, useMutation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TTip } from '@typings';
import type { TPatchTipForm } from '@schemas';

export const useEditOwnerTipMutation = (
	tipId: TTip['id'],
	mutationOptions?: MutationOptions<unknown, Error, TPatchTipForm>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	return useMutation<unknown, Error, TPatchTipForm>(
		async ({ title, description, images }) => {
			// Simulate API delay; replace with actual API call
			await new Promise((resolve) => setTimeout(resolve, 1000));
		},
		{
			...mutationOptions,
			onSuccess: async (data, variables, context) => {
				if (tipId) {
					// Update cache with new title and description
					reactQuery.queryClient.setQueryData<TTip>(reactQuery.queryKeys.tip(tipId), (existingTip) => {
						if (!existingTip) return existingTip;
						return { ...existingTip, ...variables } as TTip;
					});
				}
				await mutationOptions?.onSuccess?.(data, variables, context);

				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.communityTips());
			},
		},
	);
};
