import type { TAddressBookRecord, TUser } from '@typings';
import type { QueryClient } from '@tanstack/react-query';

export const updateAddressBookContactId = (
	queryClient: QueryClient,
	userId: TUser['id'],
	contactId: TAddressBookRecord['contact_id'],
) => {
	queryClient.setQueryData<TAddressBookRecord[] | undefined>(['profile.getAddressBook'], (addressBook) => {
		if (!addressBook) return addressBook;

		return addressBook.map((addressBookRecord) =>
			addressBookRecord.user_id === userId
				? {
						...addressBookRecord,
						contact_id: contactId,
					}
				: addressBookRecord,
		);
	});
};
