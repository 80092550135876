import { useService, useQuery } from '@hooks';

export const useTipCategoriesQuery = () => {
	const api = useService('ApiService');

	return useQuery<string[], Error>(
		['tips', 'categories'],
		async () => {
			// TODO fetch from API or put to constants
			await new Promise((resolve) => setTimeout(resolve, 1000));
			return [
				'Books',
				'TV & Movies',
				'Hotels',
				'Restaurants',
				'Experiences',
				'Travel',
				'Health',
				'Cars',
				'Investments',
				'Philanthropy',
				'Golf',
				'Other',
			];
		},
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		},
	);
};
