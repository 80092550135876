import styles from './QuotedMessage.module.css';
import type { MessageResponse } from 'stream-chat';
import ChatAttachment from '@ui-modules/chat/components/ChatAttachment';
import ChatEventMessage from '@ui-modules/chat/components/ChatEventMessage';
import QuotedMessageAvatar from '@ui-modules/chat/components/QuotedMessageAvatar';
import ChatMessageText from '../ChatMessageText';
import { Attachment, useMessageContext } from 'stream-chat-react';
import { messageHasGiphyAttachment } from './../Message/Message.utils';
import ReactMarkdown from 'react-markdown';
import { GIF_SENDING_HELPER_MARKDOWN } from '@constants';
import { useTranslation } from '@hooks';
import ChatLinksPreview from '../ChatLinksPreview';

const QuotedMessage = ({ quotedMessage }: IQuotedMessageProps) => {
	const eventId = quotedMessage?.eventId as string | undefined;
	const { handleAction } = useMessageContext();
	const { t } = useTranslation();

	if (quotedMessage?.type === 'deleted') {
		return (
			<div className={styles.deleteMessageContainer} key={quotedMessage?.id}>
				<div className={styles.textMessage}>{t('This message was deleted...')}</div>{' '}
			</div>
		);
	}

	return (
		<div>
			<button className={styles.quotedMessage}>
				{quotedMessage?.attachments && messageHasGiphyAttachment(quotedMessage as MessageResponse) && (
					<>
						<Attachment actionHandler={handleAction} attachments={quotedMessage.attachments} />
						{quotedMessage.type === 'ephemeral' && <ReactMarkdown>{GIF_SENDING_HELPER_MARKDOWN}</ReactMarkdown>}
					</>
				)}
				<ChatLinksPreview linkStyle={styles.linkPreview} text={quotedMessage?.text} />
				{quotedMessage?.attachments && !messageHasGiphyAttachment(quotedMessage as MessageResponse) && (
					<ChatAttachment attachments={quotedMessage.attachments} text={quotedMessage.text} />
				)}
				<> {quotedMessage?.eventId && !quotedMessage.deleted_at ? <ChatEventMessage eventId={eventId} /> : null}</>
				{quotedMessage?.type !== 'ephemeral' && (
					<ChatMessageText
						className={styles.quotedMessage__textMessage}
						mentions={quotedMessage?.mentioned_users?.map((el) => `@${el.name}`)}
						text={quotedMessage?.text}
					/>
				)}
			</button>
			<QuotedMessageAvatar quotedMessage={quotedMessage} />
		</div>
	);
};
export default QuotedMessage;

export interface IQuotedMessageProps {
	quotedMessage: MessageResponse | undefined;
}
