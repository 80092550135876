import axios, { type AxiosResponse } from 'axios';
import { useService, useQuery, useNotification } from '@hooks';
import type { TTipGuess } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TPatchTipForm } from '@schemas';

export const useGuessTipInputQuery = (
	tipFormValues: TPatchTipForm,
	queryOptions?: UseQueryOptions<TTipGuess, Error>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useQuery<TTipGuess, Error>(
		['tips', 'recognizeTip', tipFormValues],
		async () => {
			// // TODO: Implement the actual API call

			try {
				// ---- James' code ----
				const body = new FormData();
				const tipUserImage = tipFormValues.images?.find((image) => image.source === 'user');
				if (tipUserImage) {
					const photoBlob = await fetch(tipUserImage.url).then((r) => r.blob());
					body.append('photo', photoBlob);
				}
				body.append('description', String(tipFormValues.description));
				const response = await axios.post<unknown, AxiosResponse<{ output: TTipGuess }>>(
					'https://n8n.tiger21.com/webhook/de8b7085-2ded-4a65-8d1e-17b5636f9aae',
					body,
					{
						headers: {
							Authorization:
								'Bearer eyJhbGciOiJIUzUxMiJ9.e30.uD_eqL-mQiovCDrnkVih9CiOy5JTZzE2bWx_V8S_-yyid1-zsyCLG_q0X8VlQFm4MlUjeKoslhHFv313vzBsdw',
						},
						transformRequest: () => body, // bypass issue (https://github.com/axios/axios/issues/4406#issuecomment-1048693170)
					},
				);
				const tipGuess = response.data.output;
				tipGuess.categories = Array.isArray(tipGuess.categories) ? tipGuess.categories : [(tipGuess as any).category];
				return tipGuess;
			} catch (error) {
				console.error(error);
				const emptyTipGuess: TTipGuess = { categories: [], description: null, title: null };
				return emptyTipGuess;
			}
		},
		{
			staleTime: 0,
			cacheTime: 0,
			...queryOptions,
			onError: (error) => {
				queryOptions?.onError?.(error);
				showUnknownError(error);
			},
		},
	);
};
