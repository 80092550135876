import React, { useCallback, useMemo } from 'react';
import { useGroupTipsQuery } from '@ui-modules/tipJar/hooks/useGroupTipsQuery';
import { prepareDataForGroupTipsList } from '@ui-modules/tipJar/utils/prepareDataForGroupTipsList';
import { ActivityIndicator, FixedWidthContainer, Gap, GroupedVirtuoso, SectionListRow } from '@ui-kit';
import type { TProfile, TTip, TTipWithCommunity } from '@typings';
import TipCard from '@ui-modules/tipJar/components/TipCard';
import styles from './GroupTips.module.css';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@constants';
import { useNavigate, useRestoreVirtuosoInitialIndex } from '@hooks';
import EmptyTipList from '@ui-modules/tipJar/components/EmptyTipList';

const GroupTipsPage = ({ selectedTipCategories }: IGroupTipsPage) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));
	const RESTORE_KEY = `groupTips-${JSON.stringify({ selectedTipCategories })}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);
	const {
		data: groupTipsData = [],
		isLoading,
		fetchMore,
		isFetchingNextPage,
	} = useGroupTipsQuery({ categories: selectedTipCategories });
	const shouldShowEmptyTipsMessage = !groupTipsData.length;
	const [groupTips = [], stickyHeaderIndices = [], groupName = []] = useMemo(() => {
		if (isLoading) return [[], [], []];
		else
			return prepareDataForGroupTipsList(
				groupTipsData,
				(tip) => tip.community.name,
				(tip) => tip,
			);
	}, [groupTipsData, isLoading]);
	const renderGroupContent = useCallback(
		(index: number) => {
			return (
				<FixedWidthContainer>
					<SectionListRow headerStyles={styles.sectionListRow} title={groupName[index]} />
				</FixedWidthContainer>
			);
		},
		[groupName],
	);
	const renderItem = useCallback(
		(index: number) => {
			const tip = groupTips[index];
			return (
				<FixedWidthContainer>
					<TipCard
						className={styles.tipCard}
						tip={tip as TTip}
						onClick={() => navigate(ROUTES.tipPage(tip.id))}
						onProfileClick={() => openProfile(tip.owner.profileID)}
					/>
				</FixedWidthContainer>
			);
		},
		[groupTips],
	);
	return (
		<GroupedVirtuoso<TTipWithCommunity>
			components={{
				EmptyPlaceholder: () =>
					isLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : shouldShowEmptyTipsMessage ? (
						<FixedWidthContainer>
							<EmptyTipList
								message={t(
									"Sorry, we couldn't find any results for the category you selected. Please alter your input.",
								)}
							/>
						</FixedWidthContainer>
					) : null,
				Footer: () => (isFetchingNextPage ? <ActivityIndicator size="medium" type="fit" /> : <Gap gap={32} />),
			}}
			data={groupTipsData}
			defaultItemHeight={137}
			endReached={fetchMore}
			groupContent={renderGroupContent}
			groupCounts={stickyHeaderIndices}
			initialTopMostItemIndex={initialTopMostItemIndex}
			itemContent={renderItem}
			rangeChanged={onRangeChanged}
			size={groupTips.length}
		/>
	);
};

interface IGroupTipsPage {
	selectedTipCategories: string[];
}

export default GroupTipsPage;
