import { Page } from '../../components';
import { useRestoreVirtuosoInitialIndex, useTranslation } from '@hooks';
import TipList from '@ui-modules/tipJar/components/TipList';
import { useMyTipsQuery } from '@ui-modules/tipJar/hooks/useMyTipsQuery';
import { ActivityIndicator, Gap } from '@ui-kit';

const MyTipsPage = ({ selectedTipCategories }: IMyTipsPageParams) => {
	const { t } = useTranslation();

	const {
		data: tips,
		isLoading,
		fetchMore,
		isFetchingNextPage,
		isFetched,
	} = useMyTipsQuery({ categories: selectedTipCategories });

	const RESTORE_KEY = `myTips-${JSON.stringify({ selectedTipCategories })}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);

	return (
		<Page title={t('My Tips Page')}>
			<>
				<Gap gap={16} />
				{isLoading ? (
					<ActivityIndicator size="medium" type="fit" />
				) : (
					<TipList
						data={tips}
						endReached={fetchMore}
						initialTopMostItemIndex={initialTopMostItemIndex}
						isFetched={isFetched}
						isFetchingNextPage={isFetchingNextPage}
						isLoading={isLoading}
						rangeChanged={onRangeChanged}
					/>
				)}
			</>
		</Page>
	);
};

interface IMyTipsPageParams {
	selectedTipCategories: string[];
}

export default MyTipsPage;
