import EventFilePicker from '@ui-modules/events/components/EventFilePicker';
import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useCallback, useMemo, useNotification, useTranslation } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import { Input, Gap, SpacedGroup, FileListItem, DeleteIcon, EyeClosedIcon, EyeIcon } from '@ui-kit';
import { dealFormFields } from '../DealForm.schema';
import { useUploadMediaObjectMutation } from '@ui-modules/files/hooks/useUploadMediaObjectMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import styles from './sections.module.css';
import { useGetDealFilesQuery } from '@ui-modules/deals/hooks/useGetDealFilesQuery';
import { useDealDocumentsQuery } from '@ui-modules/deals/hooks/useDealDocumentsQuery';
import { extractMetaFromMediaObject } from '@ui-kit/components/FileListItem/FileListItem';
import type { TFile, TMediaObject } from '@typings';
import type { ReactNode } from 'react';
import { clsx, compact } from '@utils';
import { FileEntity } from '@utils';
import { useGetDealPreviewFilesQuery } from '@ui-modules/deals/hooks/useGetDealPreviewFilesQuery';

export const AttachmentsSection = ({
	formProps,
	dealId,
	children,
	disabled,
	isFilesDisabled,
	isThirdParty,
	dealHasPreview,
	validateMutateDisabled = () => false,
}: IAttachmentsSectionProps) => {
	// Dependencies.
	const { t } = useTranslation();
	const { showError } = useNotification();

	const {
		errors,
		values: {
			files: mediaObjects = [],
			videoUrl,
			deletedFiles = [],
			makeFileReadOnlyActions = [],
			makeFileDownloadableActions = [],
			contactEmail,
		},
		setFieldError,
		setFieldValue,
	} = formProps;

	// Data.
	const { data: dealFiles = [] } = (isThirdParty ? useDealDocumentsQuery : useGetDealFilesQuery)(dealId);
	const { data: dealPreviewFiles = [] } = useGetDealPreviewFilesQuery(dealId, dealHasPreview);

	const previewFiles = useMemo(() => {
		const initialFiles = dealFiles.map((initialFile) => {
			const changedFile = dealPreviewFiles.find((previewFile) => initialFile.id === previewFile.dealFileId);
			return changedFile
				? {
						...initialFile,
						...changedFile,
						mediaObject: { ...initialFile.mediaObject, ...changedFile.mediaObject },
					}
				: initialFile;
		});

		const newAddedFiles = dealPreviewFiles
			.filter((dealPreviewFile) => !dealPreviewFile.dealFileId)
			.map((file) => ({
				...file,
				owner: file.owner || {
					firstName: contactEmail,
					lastName: '',
				},
			}));

		return ([...newAddedFiles, ...initialFiles] as Array<TFile & { status?: string }>).filter(
			(file) => file?.status !== 'deleted',
		);
	}, [dealFiles, dealPreviewFiles]);

	const files = dealHasPreview ? previewFiles : dealFiles;

	// Mutations.
	const { mutate: uploadFile, isLoading } = useUploadMediaObjectMutation({
		onSuccess: (mediaObject) => setFieldValue(dealFormFields.FILES, [...mediaObjects, mediaObject]),
	});
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		dealId: dealId as string,
		folderId: null,
	});

	// Predicates.
	const isAllFilesDeleted = mediaObjects.length + (files?.length || 0) === deletedFiles.length;
	const isFileWillBeViewOnly = (file: TFile) =>
		makeFileReadOnlyActions.includes(file.mediaObject.id)
			? true
			: makeFileDownloadableActions.includes(file.mediaObject.id)
				? false
				: !!file.mediaObject.openInViewerOnly;

	// Media object actions.
	const onRemoveMediaObject = useCallback(
		(id: string) => {
			setFieldValue(
				dealFormFields.FILES,
				mediaObjects.filter((media) => media.id !== id),
			);
		},
		[setFieldValue, mediaObjects],
	);
	const onChangeMediaObjectReadability = (id: string, openInViewerOnly: boolean) => {
		updateMediaObjectSetViewerOnly({ fileId: id, isViewerOnly: openInViewerOnly })
			.then(() => {
				setFieldValue(
					dealFormFields.FILES,
					mediaObjects.map((media) => {
						if (media.id === id) {
							return { ...media, openInViewerOnly: openInViewerOnly };
						}
						return media;
					}),
				);
			})
			.catch(showError);
	};

	// File actions.
	const deleteFromMakeFileDownloadableActions = (mediaObjectId: TMediaObject['id']) => {
		const newMakeFileDownloadableActions = makeFileDownloadableActions.filter(
			(actionFileId) => actionFileId !== mediaObjectId,
		);
		if (newMakeFileDownloadableActions.length !== makeFileDownloadableActions.length) {
			setFieldValue(dealFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, newMakeFileDownloadableActions);
		}
	};
	const deleteFromMakeFileReadOnlyActions = (mediaObjectId: TMediaObject['id']) => {
		const newMakeFileReadOnlyActions = makeFileReadOnlyActions.filter((actionFileId) => actionFileId !== mediaObjectId);
		if (newMakeFileReadOnlyActions.length !== makeFileReadOnlyActions.length) {
			setFieldValue(dealFormFields.READ_ONLY_FILES_ACTIONS, newMakeFileReadOnlyActions);
		}
	};
	const onSetFileDeleted = (file: TFile) => {
		setFieldValue(dealFormFields.DELETED_FILES, [...deletedFiles, file.id]);
		deleteFromMakeFileDownloadableActions(file.mediaObject.id);
		deleteFromMakeFileReadOnlyActions(file.mediaObject.id);
	};
	const onSetFileReadOnly = (mediaObjectId: TMediaObject['id']) => {
		setFieldValue(dealFormFields.READ_ONLY_FILES_ACTIONS, [...makeFileReadOnlyActions, mediaObjectId]);
		deleteFromMakeFileDownloadableActions(mediaObjectId);
	};
	const onSetFileDownloadable = (mediaObjectId: TMediaObject['id']) => {
		setFieldValue(dealFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, [...makeFileDownloadableActions, mediaObjectId]);
		deleteFromMakeFileReadOnlyActions(mediaObjectId);
	};

	return (
		<>
			<DealFormSection bordered={false} title={t('File Attachments (optional)')}>
				<EventFilePicker
					className={clsx(styles.fileSelector, (disabled || isFilesDisabled) && styles.fileSelector_disabled)}
					description={t('Consider attaching a deck in PDF format.')}
					disabled={disabled || isFilesDisabled}
					isLoading={isLoading}
					title={t('Attach files')}
					onUpload={(file) => uploadFile({ file })}
				/>
				{(mediaObjects?.length || files?.length) && !isAllFilesDeleted ? (
					<>
						<Gap gap={24} />
						<SpacedGroup className={styles.filesList} direction="vertical" gap={10}>
							{mediaObjects.map((mediaObject) => {
								const fileMenuOptions: TMeatballMenuOption[] = compact([
									mediaObject.mimeType === 'application/pdf' &&
										!mediaObject.openInViewerOnly && {
											text: t('Make Read Only'),
											icon: <EyeClosedIcon height={16} width={14} />,
											onClick: () => onChangeMediaObjectReadability(mediaObject.id, true),
											type: 'solid',
										},

									mediaObject.mimeType === 'application/pdf' &&
										mediaObject.openInViewerOnly && {
											text: t('Make Downloadable'),
											icon: <EyeIcon height={16} width={14} />,
											onClick: () => onChangeMediaObjectReadability(mediaObject.id, false),
											type: 'solid',
										},
									{
										text: t('Delete File'),
										icon: <DeleteIcon height={16} width={14} />,
										onClick: () => onRemoveMediaObject(mediaObject.id),
										type: 'destructive',
									},
								]);
								return (
									<FileListItem
										fileMenuOptions={fileMenuOptions}
										key={mediaObject.id}
										meta={extractMetaFromMediaObject(mediaObject)}
										openInViewerOnly={mediaObject.openInViewerOnly}
										type="fit-card"
										viewerModeText={t('read only')}
									/>
								);
							})}
							{files?.map((file) => {
								if (!deletedFiles.includes(file.id)) {
									const isWillBeViewOnly = isFileWillBeViewOnly(file);

									const fileMenuOptions: TMeatballMenuOption[] = compact([
										FileEntity.isPdfFile(file) &&
											!isWillBeViewOnly && {
												text: t('Make Read Only'),
												icon: <EyeClosedIcon height={16} width={14} />,
												onClick: () => onSetFileReadOnly(file.mediaObject.id),
												type: 'solid',
											},

										FileEntity.isPdfFile(file) &&
											isWillBeViewOnly && {
												text: t('Make Downloadable'),
												icon: <EyeIcon height={16} width={14} />,
												onClick: () => onSetFileDownloadable(file.mediaObject.id),
												type: 'solid',
											},
										{
											text: t('Delete File'),
											icon: <DeleteIcon height={16} width={14} />,
											onClick: () => onSetFileDeleted(file),
											type: 'destructive',
										},
									]);

									return (
										<FileListItem
											fileMenuOptions={
												disabled || isFilesDisabled || validateMutateDisabled(file) ? [] : fileMenuOptions
											}
											key={file.id}
											meta={extractMetaFromMediaObject({
												...file.mediaObject,
												owner: file.owner,
											} as unknown as TMediaObject)}
											openInViewerOnly={isWillBeViewOnly}
											type="fit-card"
											viewerModeText={t('read only')}
										/>
									);
								}
								return null;
							})}
						</SpacedGroup>
					</>
				) : null}
			</DealFormSection>
			<Input
				autoCapitalize="none"
				disabled={disabled}
				errorMessage={errors.videoUrl}
				label={t('Video URL (optional)')}
				placeholder={t('e.g. {{example}}', { example: 'https://www.youtube.com/kjhbc976gsdfbf2' })}
				value={videoUrl}
				onChange={(e) => {
					setFieldValue(dealFormFields.VIDEO_URL, e.target.value);
					setFieldError(dealFormFields.VIDEO_URL, undefined);
				}}
			/>
			{children}
		</>
	);
};

interface IAttachmentsSectionProps extends IDealFormSectionSectionProps {
	children?: ReactNode;
	disabled?: boolean;
	/** Temporary disabling file picker fot 3rd party deal. **/
	isFilesDisabled?: boolean;
	isThirdParty?: boolean;
	dealHasPreview?: boolean;
	validateMutateDisabled?: (file?: TFile) => boolean;
}
