import TipList from '../TipList';
import { useRestoreVirtuosoInitialIndex } from '@hooks';
import { useSearchTipsQuery } from '@ui-modules/tipJar/hooks/useSearchTipsQuery';
import type { TCommunity, TTipsFilter } from '@typings';
import type { ITipListProps } from '../TipList/TipList';

const TipSearchResultList = ({ searchQuery, tipFilters, ...tipListProps }: ITipSearchResultListProps) => {
	const {
		data: foundTips,
		isFetching,
		fetchMore,
		isFetchingNextPage,
		isSearchEnabled,
	} = useSearchTipsQuery(searchQuery, tipFilters);

	const RESTORE_KEY = `searchTips-${searchQuery}-${JSON.stringify(tipFilters)}`;
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(RESTORE_KEY);

	return (
		<TipList
			data={foundTips}
			endReached={fetchMore}
			hideEmptyState={!isSearchEnabled}
			highlightedText={searchQuery}
			initialTopMostItemIndex={initialTopMostItemIndex}
			isFetchingNextPage={isFetchingNextPage}
			isLoading={isFetching}
			rangeChanged={onRangeChanged}
			{...tipListProps}
		/>
	);
};

export interface ITipSearchResultListProps
	extends Omit<ITipListProps, 'endReached' | 'isLoading' | 'isFetchingNextPage'> {
	searchQuery: string;
	tipFilters: TTipsFilter & { communityId?: TCommunity['id'] };
}

export default TipSearchResultList;
