import { Formik } from '@ui-kit';
import TipCategoriesInput from '@ui-modules/tipJar/components/TipCategoriesInput';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import { useTranslation } from '@hooks';
import styles from './NewTipCategoriesForm.module.css';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import { patchTipFormSchema, type TPatchTipForm } from '@schemas';

const NewTipCategoriesForm = ({
	initialValues,
	categories = [],
	onSubmit,
	renderWrapper = ({ children }) => children,
}: INewTipCategoriesFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TPatchTipForm>
			initialValues={initialValues as TPatchTipForm}
			validateOnBlur={false}
			validateOnChange={false}
			validateOnMount={false}
			validationSchema={patchTipFormSchema}
			onSubmit={onSubmit}
		>
			{(formProps) => {
				const { values, setFieldValue, submitForm, setFieldTouched, errors, isSubmitting } = formProps;
				return renderWrapper({
					formProps,
					children: (
						<div className={styles.newTipCategoriesForm}>
							<TipCategoriesInput
								errorMessage={errors.categories}
								tipCategories={categories}
								value={values.categories ?? []}
								onChange={(newCategories) => {
									setFieldValue('categories', newCategories);
									setFieldTouched('categories', true);
								}}
							/>
							<TipFormsSubmitButton loading={isSubmitting} title={t('Next')} onClick={submitForm} />
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface INewTipCategoriesFormProps {
	/** Initial form values. */
	initialValues: Partial<TPatchTipForm>;
	/** Form submit handler. */
	onSubmit: (values: TPatchTipForm) => Promise<unknown>;
	/** Categories to display in the form. */
	categories: string[];
	/** Optional renderer to wrap form content and pass formProps to a wrapper React component. */
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TPatchTipForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default NewTipCategoriesForm;
