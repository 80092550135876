import TipCard from '@ui-modules/tipJar/components/TipCard';
import type { TProfile, TTip, TCommunity } from '@typings';
import type { ComponentType } from 'react';
import { useCallback } from 'react';
import { useBlockedAndBlockingUsers, useNavigate, useTranslation } from '@hooks';
import type { ItemContent, VirtuosoProps } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { Gap } from '@ui-kit';
import { ROUTES } from '@constants';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';
import styles from './TipList.module.css';
import EmptyTipList from '../EmptyTipList';

const TipList = ({
	data: tips = [],
	highlightedText,
	isFetchingNextPage,
	isLoading,
	contextCommunityId,
	hideEmptyState = false,
	HeaderComponent,
	...virtuosoProps
}: ITipListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	const renderTipItem = useCallback<ItemContent<TTip, unknown>>(
		(_, tip) => {
			if (!tip || blockedAndBlockingUsers.some((blockedUser) => blockedUser.id === tip.owner.id)) {
				return <Gap gap={1} />;
			}
			return (
				<TipCard
					className={styles.tipCard}
					highlightedText={highlightedText}
					key={tip.id}
					tip={tip}
					onClick={() => navigate(ROUTES.tipPage(tip.id, contextCommunityId))}
					onProfileClick={() => openProfile(tip.owner.profileID)}
				/>
			);
		},
		[blockedAndBlockingUsers, highlightedText],
	);

	return (
		<>
			{!hideEmptyState && !isLoading && !tips.length ? (
				<EmptyTipList
					message={t("Sorry, we couldn't find any results for the category you selected. Please alter your input.")}
				/>
			) : null}
			{tips.length ? (
				<Virtuoso
					defaultItemHeight={137}
					{...virtuosoProps}
					components={{
						Header: HeaderComponent,
						Footer: () => (isFetchingNextPage ? <ActivityIndicator size={'small'} type="fit" /> : <Gap gap={8} />),
					}}
					data={tips}
					itemContent={renderTipItem}
					totalCount={tips?.length}
				/>
			) : null}
		</>
	);
};

export interface ITipListProps extends Omit<VirtuosoProps<TTip, unknown>, 'components' | 'totalCount' | 'itemContent'> {
	contextCommunityId?: TCommunity['id'];
	isFetched?: boolean;
	isLoading: boolean;
	isFetchingNextPage: boolean;
	highlightedText?: string;
	hideEmptyState?: boolean;
	HeaderComponent?: ComponentType;
}

export default TipList;
