import { FixedWidthContainer, Virtuoso } from '@ui-kit';
import { useCallback, useRef, useRestoreVirtuosoScroll, useTranslation } from '@hooks';
import { useFeedSearchQuery } from '@ui-modules/feed/hooks/useFeedSearchQuery';
import styles from './FeedSearchResultList.module.css';
import FeedActivitySearchCard from '../FeedActivitySearchCard';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import { clsx, once } from '@utils';
import type { TCommunity, TSearchDiscussion } from '@typings';
import type { ItemContent } from 'react-virtuoso';

const FeedSearchResultList = ({
	searchQuery,
	communityName,
	isLearning = false,
	streamFeedId,
	containerClassName,
	onSearch,
}: IFeedSearchResultListProps) => {
	const { t } = useTranslation();

	const {
		data: feedSearchResults = [],
		preparedSearchQuery,
		isSearchEnabled,
		isFetching,
		fetchMore,
	} = useFeedSearchQuery({ searchQuery, communityName, isLearning, streamFeedId }, { onSearch });

	// Handle scroll restoration.
	const { scrollerRef, restoreScroll, listRef } = useRestoreVirtuosoScroll(
		`FeedSearchResultList-${communityName || isLearning.toString()}`,
	);
	const restoreScrollOnce = useRef(once(() => setTimeout(restoreScroll, 700))).current;

	// Render list callbacks.
	const renderItem = useCallback<ItemContent<TSearchDiscussion, unknown>>(
		(index, feedActivitySearchResult) =>
			feedActivitySearchResult ? (
				<FixedWidthContainer autoSize>
					<FeedActivitySearchCard
						feedActivitySearchResult={feedActivitySearchResult}
						searchQuery={preparedSearchQuery}
					/>
				</FixedWidthContainer>
			) : (
				<ItemPlaceholder />
			),
		[preparedSearchQuery],
	);

	return (
		<Virtuoso<TSearchDiscussion>
			className={clsx(styles.feedSearchResultList, containerClassName)}
			components={{
				EmptyPlaceholder: () =>
					isFetching ? (
						<ActivityIndicator size="medium" type="fit" />
					) : isSearchEnabled ? (
						<EmptyStateMessage text={t('No posts found')} />
					) : null,
			}}
			data={feedSearchResults}
			endReached={fetchMore}
			itemContent={renderItem}
			itemsRendered={(items) => {
				if (items.length > 1) {
					restoreScrollOnce();
				}
			}}
			ref={(ref) => (listRef.current = ref)}
			scrollerRef={(ref) => (scrollerRef.current = ref as HTMLElement)}
			totalCount={feedSearchResults.length}
		/>
	);
};

// Used a height 1px element to resolve Virtuoso list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko]
const ItemPlaceholder = () => <div className={styles.feedSearchResultList__itemPlaceholder} />;

export interface IFeedSearchResultListProps {
	searchQuery: string;
	communityName: TCommunity['name'];
	isLearning?: boolean;
	streamFeedId?: string;
	containerClassName?: string;
	onSearch?: (searchQuery: string) => void;
}

export default FeedSearchResultList;
