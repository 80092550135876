import { useState, useClickAway, useRef, useMeasure } from '@hooks';
import { clsx } from '@utils';
import type { MutableRefObject, ReactNode } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import Portal from '../Portal';
import styles from './MeatballMenu.module.css';
import type { TMeatBallPosition } from './MeatballMenu.utils';
import { getMenuPosition } from './MeatballMenu.utils';

const MeatballButton = ({ type, meatBallColor = 'black' }: IMeatballButtonProps) => {
	return (
		<div className={clsx(styles.meatball__container)} data-type={type}>
			<div className={styles.meatball__dot} style={{ backgroundColor: meatBallColor }}></div>
			<div className={styles.meatball__dot} style={{ backgroundColor: meatBallColor }}></div>
			<div className={styles.meatball__dot} style={{ backgroundColor: meatBallColor }}></div>
			{/* TODO: The look of `...` icon needs to be improved here and affects many other places. */}
		</div>
	);
};

const MeatballMenu = ({
	children,
	options,
	position = 'center',
	testID,
	disabled = false,
	type = 'default',
	onMenuVisible,
	onMenuOpened,
	CustomMenuContainer,
	meatBallColor = 'black',
	customIconStyle,
}: IMeatballMenuProps) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [coords, setCoords] = useState<{ left?: number; top?: number; height: number }>({ left: 0, top: 0, height: 0 });
	const meatballMenuRef = useRef(null);
	const [optionsRef, { height: optionsHeight }] = useMeasure<HTMLDivElement>();
	useClickAway(meatballMenuRef, () => setMenuVisible(false));

	useEffect(() => {
		onMenuVisible?.(menuVisible);
	}, [menuVisible, onMenuVisible]);

	const menuOptions = useMemo(() => {
		return options.map(
			(
				{ text, onClick, icon: IconComponent, type = 'default', customOption, description, disabled = false },
				index,
			) => {
				return (
					<button
						className={clsx(styles.meatball__menuOption, styles.meatball__button)}
						disabled={disabled}
						key={String(text) + type + index}
						onClick={() => {
							setMenuVisible(false);
							onClick();
						}}
					>
						{customOption ? (
							customOption
						) : (
							<div
								className={clsx(
									styles.meatball__menuOptionItem,
									description && styles.meatball__menuOptionItem_withDesc,
								)}
							>
								{IconComponent ? (
									<div className={customIconStyle ? customIconStyle : styles.meatball__menuOptionIcon}>
										{IconComponent}
									</div>
								) : null}
								<div className={styles.meatball_menuItem}>
									<span
										className={clsx(
											styles.meatball__menuOptionLabel,
											type === 'destructive' && styles.meatball__menuOptionLabel_destructive,
											type === 'solid' && styles.meatball__menuOptionLabel_solid,
										)}
									>
										{text}
									</span>
									{description && <span className={styles.meatball_description}>{description}</span>}
								</div>
							</div>
						)}
					</button>
				);
			},
		);
	}, [options, setMenuVisible]);

	return (
		<div
			className={styles.meatball}
			onClick={(event) => event.stopPropagation()}
			onKeyPress={(event) => event.stopPropagation()}
		>
			<button
				className={styles.meatball__button}
				data-testid={testID}
				data-type={type}
				disabled={disabled}
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					const rect = e?.currentTarget?.getBoundingClientRect();
					const positionCoordinates = getMenuPosition(rect, position);
					setCoords(positionCoordinates);
					setMenuVisible(true);
					onMenuOpened?.();
				}}
			>
				{children ? children : <MeatballButton meatBallColor={meatBallColor} type={type} />}
			</button>
			{menuVisible ? (
				<>
					<Portal>
						<div className={styles.meatball__backdrop} /> {/* To prevent clicking on elements under menu. */}
					</Portal>
					{CustomMenuContainer ? (
						<CustomMenuContainer menuOptions={menuOptions} menuRef={meatballMenuRef} />
					) : (
						<Portal>
							<div
								className={styles.meatball__menuContainer}
								data-position={position}
								ref={meatballMenuRef}
								style={
									position.includes('top')
										? { top: coords.top, left: coords?.left }
										: {
												left: coords.left,
												// Calculate if content overlaps screen that change position to be full visible.
												top: window.innerHeight < (coords?.top || 0) + optionsHeight ? 'unset' : coords?.top,
												bottom: window.innerHeight < (coords?.top || 0) + optionsHeight ? 12 : 'unset',
											}
								}
							>
								<div ref={optionsRef}>{menuOptions}</div>
							</div>
						</Portal>
					)}
				</>
			) : null}
		</div>
	);
};

type ButtonType = 'default' | 'solid';

interface IMeatballMenuProps {
	/** Meatball custom button */
	children?: ReactNode;
	/** Options that will be displayed inside Meatball menu list */
	options: TMeatballMenuOption[];
	/** Meatball menu position to the button*/
	position?: TMeatBallPosition;
	/** ID for test queries. */
	testID?: string;
	/** Icon color of the meatball button */
	meatBallColor?: string;
	/** Should it be able to be clicked. Default=false. */
	disabled?: boolean;
	/** Button type */
	type?: ButtonType;
	/** Menu visible handler */
	onMenuVisible?: (visible: boolean) => void;
	/** Menu opened callback */
	onMenuOpened?: () => void;
	/** Custom menu container */
	CustomMenuContainer?: React.FC<TCustomMenuContainerProps>;
	customIconStyle?: string;
}

interface IMeatballButtonProps {
	/** Button type */
	type?: ButtonType;
	meatBallColor?: string;
}

export type TMeatballMenuOption = {
	text: string | JSX.Element;
	onClick: () => void;
	icon?: ReactNode;
	type?: 'default' | 'destructive' | 'solid';
	customOption?: ReactNode;
	description?: string | JSX.Element;
	disabled?: boolean;
};

export type TCustomMenuContainerProps = {
	menuOptions: JSX.Element[];
	menuRef: MutableRefObject<null>;
};

export default MeatballMenu;
