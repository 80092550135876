import { DeleteIcon, PencilBoldIcon, ReportIcon } from '@ui-kit/icons';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, useMe } from '@hooks';
import { compact } from '@utils';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import type { TTip } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { Modal } from '@ui-kit';
import { useDeleteTipMutation } from '@ui-modules/tipJar/hooks/useDeleteTipMutation';
import { ROUTES } from '@constants';

export const useGetTipMeatballMenuOptions = (
	callbacks?: {
		onDeleted?: () => void;
	},
	onClick?: () => void,
) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { mutate: deleteTip } = useDeleteTipMutation({
		onSuccess: () => callbacks?.onDeleted?.(),
	});
	const [showDeleteTipDialog, setShowDeleteTipDialog] = useState(false);
	const [tipToDelete, setTipToDelete] = useState<TTip | null>(null);

	const toggleShowDeleteTipDialog = (tip?: TTip) => {
		setShowDeleteTipDialog(!showDeleteTipDialog);
		setTipToDelete(tip || null);
	};

	const meatballMenuOptions = useCallback(
		(tip: TTip): TMeatballMenuOption[] => {
			return compact([
				TipEntity.canEditTip(tip, user) && {
					text: t('Edit Tip'),
					onClick: () => {
						onClick && onClick();
						navigate(ROUTES.tipEdit(tip.id));
					},
					icon: <PencilBoldIcon fill="black" height={16} strokeWidth={4} width={16} />,
					description: t('Change the information on this Deal.'),
					type: 'solid',
				},
				TipEntity.canDeleteTip(tip, user) && {
					text: t('Delete Tip'),
					type: 'destructive',
					onClick: () => toggleShowDeleteTipDialog(tip),
					icon: <DeleteIcon />,
				},
				TipEntity.canReportTip(tip, user) && {
					text: t('Report Tip'),
					onClick: () => navigate(ROUTES.reportContent('tip', tip.id)),
					icon: <ReportIcon fill="black" height={16} width={16} />,
				},
			]);
		},
		[user, t, onClick, navigate, toggleShowDeleteTipDialog],
	);

	const ConfirmationModals = (
		<>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Delete Review')}
				subTitle={t(
					`Deleting your Review will remove you as the Tip's original creator, and can't be undone. Instead, you can go back and edit your Review.`,
				)}
				title={t('Are You Sure?')}
				titleCentered
				variant="medium"
				visible={showDeleteTipDialog}
				onCancel={() => toggleShowDeleteTipDialog()}
				onConfirm={async () => {
					if (tipToDelete) await deleteTip(tipToDelete);
					toggleShowDeleteTipDialog();
				}}
			/>
		</>
	);
	return [meatballMenuOptions, ConfirmationModals] as const;
};
