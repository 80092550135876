import React from 'react';

import { Tooltip } from '@ui-kit';
import { useNavigate } from '@hooks';

import styles from './ProfileHeaderTooltip.module.css';

const ProfileHeaderTooltip = ({ showTooltip, setShowTooltip, title, description }: IProfileHeaderTooltipProps) => {
	const navigate = useNavigate();

	const handleTooltipClick = () => {
		navigate('/asset-allocation/me');
		setShowTooltip(false);
	};

	return (
		<>
			{showTooltip && (
				<Tooltip
					className={styles.tooltip}
					classNameArrow={styles.tooltip__arrow}
					clickable
					closeEvents={{
						mouseleave: false,
						blur: false,
						click: false,
						dblclick: false,
						mouseup: false,
					}}
					data-tooltip-id="my-tooltip"
					id="my-tooltip"
					isOpen={showTooltip}
					openEvents={{
						mouseenter: false,
						focus: false,
						click: false,
						dblclick: false,
						mousedown: false,
					}}
					place="top-end"
				>
					<button className={styles.tooltipContent} onClick={handleTooltipClick}>
						<p className={styles.tooltipTitle}>{title}</p>
						<p className={styles.tooltipDescription}>{description}</p>
					</button>
				</Tooltip>
			)}
		</>
	);
};

export interface IProfileHeaderTooltipProps {
	showTooltip: boolean;
	setShowTooltip: (showTooltip: boolean) => void;
	title: string;
	description: string;
}

export default ProfileHeaderTooltip;
