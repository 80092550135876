import { useTranslation } from '@hooks';
import styles from './FourthPageBody.module.css';
import { ArrowRightIcon, Button } from '@ui-kit';

const FourthPageBody = ({ onDismiss, onCreateTip }: IFourthPageBodyProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p> {t('Try it now.')}</p>
			<p> {t('Create your first Tip!')}</p>
			<div className={styles.wrapper__ctaWrapper}>
				<Button
					className={styles.wrapper__ctaWrapperClose}
					title={t('Close')}
					titleClassName={styles.wrapper__ctaWrapperCloseTitle}
					type={'blurred'}
					onClick={() => {
						onDismiss();
					}}
				/>
				<Button
					className={styles.wrapper__ctaWrapperCreate}
					icon={<ArrowRightIcon fill={'white'} height={14} width={14} />}
					iconPosition="right"
					title={t('Create Tip')}
					titleClassName={styles.wrapper__title}
					type={'dark'}
					onClick={() => {
						onCreateTip();
					}}
				/>
			</div>
		</div>
	);
};

interface IFourthPageBodyProps {
	onDismiss: () => void;
	onCreateTip: () => void;
}

export default FourthPageBody;
