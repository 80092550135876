import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

export function updateCheckMarksBooleanValues(arr: ICheckmarkState[], newValue: boolean) {
	return arr.map((obj) => {
		if (typeof obj === 'object' && obj !== null) {
			const newObj = { ...obj };
			newObj.value = newValue;
			if (newObj?.children && Array?.isArray(newObj?.children)) {
				newObj.children = updateCheckMarksBooleanValues(newObj.children, newValue);
			}
			return newObj;
		}
		return obj;
	});
}
