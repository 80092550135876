import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ROUTES } from '@constants';
import type { TTipsTabName } from '@typings';

const TipJarRootPage = () => {
	const tipsTabName = useParams()?.tipsTabName;
	const initTipTab: TTipsTabName = 'all';

	if (!tipsTabName) return <Navigate replace to={ROUTES.tipJarTab(initTipTab)} />;
	return <Outlet />;
};

export default TipJarRootPage;
