import styles from './TipJarIntroModal.module.css';
import { useTranslation } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { FeatureSlider } from '@ui-kit';
import {
	ModalHeader,
	FourthPageBody,
	ThirdPageBody,
	SecondPageBody,
} from '@ui-modules/tipJar/components/TipJarIntroModal/FeatureComponents';
import FiltersPageBody from '@ui-modules/tipJar/components/TipJarIntroModal/FeatureComponents/FiltersPageBody';
import { useTipsSliderIntroduced } from '@ui-modules/tipJar/hooks/useTipsSliderIntroduced';

const TipJarIntroModal = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isTipsSliderIntroduced, setTipsSliderIntroduced] = useTipsSliderIntroduced();

	const dismissModal = () => {
		setTipsSliderIntroduced();
	};

	const onOpenNewTip = () => {
		navigate(ROUTES.newTip());
		setTipsSliderIntroduced();
	};

	return (
		<FeatureSlider
			slides={[
				{
					header: (
						<div className={styles.firstPageHeader}>
							<ModalHeader mode="large" />
						</div>
					),
					body: (
						<div className={styles.firstPageBody}>
							<p>{t('Swipe to learn what you can do...')}</p>
						</div>
					),
					name: 'Swipe to learn what you can do',
				},
				{
					header: <ModalHeader />,
					body: <SecondPageBody />,
					name: 'Share recommendations  with your Group and others',
				},
				{
					header: <ModalHeader />,
					body: <FiltersPageBody />,
					name: 'Find your next discovery',
				},
				{
					header: <ModalHeader />,
					body: <ThirdPageBody />,
					name: 'Our secure AI makes it fast and easy',
				},
				{
					header: <ModalHeader />,
					body: <FourthPageBody onCreateTip={onOpenNewTip} onDismiss={dismissModal} />,
					name: 'Try it now. Create your first Tip!',
				},
			]}
			visible={!isTipsSliderIntroduced}
		/>
	);
};

export default TipJarIntroModal;
