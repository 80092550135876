import React from 'react';
import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import { LampIcon } from '@ui-kit';
import styles from './ModalHeader.module.css';

const ModalHeader = ({ mode = 'small' }: TTipJarModalHeaderProps) => {
	const { t } = useTranslation();
	const iconSize = mode === 'large' ? 60 : 40;
	return (
		<div
			className={clsx(
				styles.slider__headerWrapper,
				mode === 'large' ? styles.slider__headerWrapper_large : styles.slider__headerWrapper_small,
			)}
		>
			<LampIcon width={iconSize} />
			<div
				className={clsx(
					styles.slider__headerWrapperBody,
					mode === 'large' ? styles.slider__headerWrapperBody_large : styles.slider__headerWrapperBody_small,
				)}
			>
				<p className={styles.slider__headerWrapperBodyTitle}>{t('Introducing')}...</p>
				<p className={clsx(styles.slider__headerWrapperBodySubTitle)}>{t('Tips')}</p>
			</div>
		</div>
	);
};

type TTipJarModalHeaderProps = {
	mode?: 'large' | 'small';
};

export default ModalHeader;
