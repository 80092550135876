import { memo } from 'react';
import { useMemo } from '@hooks';
import { clsx, compact, parseUrlRegexp, protocolifyUrl, uniq } from '@utils';
import LinkPreview from '@ui-modules/feed/components/PostLinksPreview/LinkPreview';
import styles from './ChatLinksPreview.module.css';

const ChatLinksPreview = ({ text, linkStyle }: { text?: string; linkStyle?: string }) => {
	const links = useMemo(() => {
		const matches = text?.match(parseUrlRegexp) ?? [];
		const linksAndNulls = matches.map((url) => {
			return protocolifyUrl(url);
		});
		const links = compact(linksAndNulls);

		return links?.length > 3 ? uniq(links)?.slice(0, 3) : uniq(links);
	}, [text]);

	if (!links?.length) return null;

	return (
		<div
			className={styles.chatLinksPreview}
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => event.stopPropagation()}
		>
			{links.map((link, index) => (
				<LinkPreview key={link + index} link={link} style={clsx(linkStyle, styles.chatLinkItem)} />
			))}
		</div>
	);
};

export default memo(ChatLinksPreview);
