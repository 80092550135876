import { useCalendarGrid } from 'react-aria';
import type { AriaCalendarGridProps } from 'react-aria';
import type { CalendarDate } from '@internationalized/date';
import { getWeeksInMonth } from '@internationalized/date';
import { CalendarCell } from './CalendarCell';
import styles from './Calendar.module.css';
import { useMemo } from 'react';
import { chunk } from 'lodash';
import type { CalendarState } from 'react-stately';
import { today, getLocalTimeZone } from '@internationalized/date';
import type { TCellPropsMapper } from './Calendar.types';

const DATES_PER_ROW = 7;

const isCurrentDate = (date: CalendarDate) => {
	const current = today(getLocalTimeZone());
	return current.compare(date) === 0;
};

export const CalendarGrid = ({ state, title, daysLabels, getCellProps = () => ({}), ...props }: ICalendarGridProps) => {
	const { gridProps, headerProps } = useCalendarGrid(props, state);
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, 'es-US');
	const [monthLabel, yearLabel] = title.split(' ');

	const daysRows = useMemo(() => {
		const allDays = [...new Array(weeksInMonth).keys()].reduce<Array<CalendarDate | null>>((acc, index) => {
			return [...acc, ...state.getDatesInWeek(index)];
		}, []);
		return chunk(allDays, DATES_PER_ROW);
	}, [state, weeksInMonth]);

	return (
		<div className={styles.calendar__content}>
			<div className={styles.calendar__labelWrapper}>
				<span>{monthLabel}</span>
				<span>{yearLabel}</span>
			</div>
			<table {...gridProps} className={styles.calendar__grid}>
				<thead {...headerProps}>
					<tr>
						{daysLabels.map((day, index) => (
							<th className={styles.calendar__weekDay} key={index}>
								{day}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{daysRows.map((datesInWeek, index) => {
						return (
							<tr key={index}>
								{datesInWeek.map((date, i: number) => {
									if (date) {
										const isCurrent = isCurrentDate(date);
										return (
											<CalendarCell
												date={date}
												isCurrent={isCurrent}
												isSelected={state.value?.compare(date) === 0}
												key={i}
												state={state}
												{...getCellProps(date.toString())}
											/>
										);
									}
									return <td key={i} />;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export interface ICalendarGridProps extends AriaCalendarGridProps {
	state: CalendarState;
	getCellProps?: TCellPropsMapper;
	daysLabels: string[];
	title: string;
}
