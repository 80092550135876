/** This module is enhanced version of the 'formik-persist-values' npm package
 * 	The reason why it was updates is providing new features:
 * 		- can use "mergingValues" to pass values which will overwrite initial and persisted ones.
 * 		- persisted values are removed after submitting a form.
 *  @see https://github.com/kolengri/formik-persist-values
 *  @author Dmitriy Nikolenko
 */
import type { ForwardedRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import { useDebounce } from 'react-use';
import { omit } from 'lodash';

const KEY_DELIMITER = '_';

export interface IPersistFormikValuesProps {
	name: string;
	// Debounce in ms
	debounce?: number;
	// Possible provide own storage
	storage?: 'localStorage' | 'sessionStorage' | Storage;
	// By default persisting only if form is valid
	persistInvalid?: boolean;
	// Hash form initial values for storage key generation
	hashInitials?: boolean;
	// Number of hash specificity must be increased if there is some problem with wrong cache hashes
	hashSpecificity?: number;
	// List of not persisted values
	ignoreValues?: string[];
	// Values to merge with persisted.
	mergingValues?: Record<string, unknown>;
	/** Should the loading and applying of persisted values be skipped. Default 'false'. */
	skipRehydrationOnMount?: boolean;
}

/**
 * Hash function to do not persist different initial values
 * @param obj
 */
const getHash = (obj: Record<string, unknown>, specificity = 7) => {
	let hc = 0;
	try {
		const chars = JSON.stringify(obj).replace(/[{"}:,]/g, '');
		const len = chars.length;
		for (let i = 0; i < len; i++) {
			// Bump 7 to larger prime number to increase uniqueness
			hc += chars.charCodeAt(i) * specificity;
		}
	} catch (error) {
		hc = 0;
	}
	return hc;
};

// Controls is working in browser
const useBrowser = () => !!window;

const useStorage = (props: IPersistFormikValuesProps): Storage | undefined => {
	const { storage = 'localStorage' } = props;
	const isBrowser = useBrowser();

	switch (storage) {
		case 'sessionStorage':
			return isBrowser ? window.sessionStorage : undefined;
		case 'localStorage':
			return isBrowser ? window.localStorage : undefined;
		default:
			return storage;
	}
};

const usePersistedString = (props: IPersistFormikValuesProps): [string | null, (values: FormikValues) => void] => {
	const { name: defaultName, hashInitials, hashSpecificity } = props;
	const { initialValues } = useFormikContext<Record<string, unknown>>();
	const keyName = `${defaultName}${KEY_DELIMITER}`;

	const name = useMemo(
		() => (hashInitials ? `${keyName}${getHash(initialValues, hashSpecificity)}` : defaultName),
		[defaultName, hashInitials, JSON.stringify(initialValues), hashSpecificity],
	);

	const storage = useStorage(props);

	const state = useMemo(() => {
		if (storage) {
			return storage.getItem(name);
		}
		return null;
	}, [name, storage]);

	const handlePersistValues = useCallback(
		(values: FormikValues) => {
			if (storage) {
				storage.setItem(name, JSON.stringify(values));
				// Remove all past cached values for this form
				Object.keys(storage).forEach((key) => {
					if (key.indexOf(keyName) > -1 && key !== name) {
						storage.removeItem(key);
					}
				});
			}
		},
		[storage],
	);

	return [state, handlePersistValues];
};

const PersistFormikValuesMemo = (props: IPersistFormikValuesProps, ref: ForwardedRef<IPersistFormikValuesApi>) => {
	const {
		debounce = 300,
		persistInvalid,
		ignoreValues,
		mergingValues = {},
		skipRehydrationOnMount: skipLoadOnMount,
	} = props;
	const { values, setValues, isValid, initialValues, submitCount, validateForm } = useFormikContext<any>();
	const [persistedString, persistValues] = usePersistedString(props);
	const stringValues = JSON.stringify(values);

	const handlePersist = useCallback(() => {
		if (isValid || persistInvalid) {
			const valuesToPersist = ignoreValues ? omit(values, ignoreValues) : values;
			persistValues(valuesToPersist);
		}
	}, [stringValues, isValid, persistInvalid]);

	useEffect(() => {
		if (persistedString && !skipLoadOnMount) {
			// Catches invalid json
			try {
				const persistedValues = JSON.parse(persistedString);
				const newValues = { ...initialValues, ...persistedValues, ...mergingValues };
				// Initial values should be merged with persisted
				if (stringValues !== JSON.stringify(newValues)) {
					setValues(newValues, true);
					setTimeout(() => validateForm(newValues));
				}
			} catch (error) {
				console.error('Parse persisted values is not possible', error);
			}
		}
	}, [persistedString]);

	useDebounce(handlePersist, debounce, [stringValues, isValid, persistInvalid]);

	useEffect(
		function resetStorageOnSubmit() {
			if (submitCount) {
				persistValues({});
			}
		},
		[submitCount],
	);

	useImperativeHandle(ref, () => ({
		clear: () => persistValues({}),
	}));

	return null;
};

export interface IPersistFormikValuesApi {
	clear(): void;
}

export const PersistFormikValues = forwardRef(PersistFormikValuesMemo);
