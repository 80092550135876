import { Page, TabWrapper } from '../../components';
import FeedDiscussions from '@ui-modules/feed/components/FeedDiscussions';
import { useNavigate, useRestoreScroll, useService, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import type { TDeal } from '@typings';
import DealAlertsButton from '@ui-modules/deals/components/DealAlertsButton';
import styles from './DealDiscussions.module.css';
import { useDoReactionsFilterRequest } from '@ui-modules/feed/hooks/useDoReactionsFilterRequest';
import { scrollToComment } from '@ui-modules/feed/utils/scrollToComment';

const DealDiscussions = ({ deal, onToggleAlerts }: IDealDiscussionsPage) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const communitySlug = deal.community.slug;
	const currentDealMemberRelation = deal.currentDealMemberRelation;
	const streamActivityId = deal.streamActivityId;
	const feedSlug = `C--INVEST_${communitySlug?.slice(3)}`;

	const goHome = () => navigate(ROUTES.home(), { replace: true });
	const { scrollerRef, restoreScroll } = useRestoreScroll(`singleFeed-${streamActivityId}`);

	const doReactionsFilterRequest = useDoReactionsFilterRequest({
		activityId: streamActivityId,
		onLoadedFromCache: restoreScroll,
	});

	return (
		<Page title={t('Post Page')}>
			<TabWrapper ref={scrollerRef}>
				<div className={styles.dealDiscussions__wrapper}>
					<div className={styles.dealDiscussions__controls}>
						<DealAlertsButton dealMemberRelation={currentDealMemberRelation} isButtonMode onToggle={onToggleAlerts} />
					</div>
					<FeedDiscussions
						doReactionsFilterRequest={doReactionsFilterRequest}
						feedSlug={feedSlug}
						streamActivityId={streamActivityId}
						onCommentLikeToggled={(liked) => {
							if (liked) {
								analytics.trackEvent('DealInteractions', {
									interaction_type: 'discussions',
									interaction_action: 'discussion like',
									deal_id: deal.id,
									deal_name: deal.name,
								});
							}
						}}
						onCommentSent={(isEditing, commentId) => {
							if (!isEditing) {
								analytics.trackEvent('DealInteractions', {
									interaction_type: 'discussions',
									interaction_action: 'discussion comment',
									deal_id: deal.id,
									deal_name: deal.name,
								});
							}
							scrollToComment(scrollerRef, isEditing, commentId);
						}}
						onErrorDisplaying={goHome}
					/>
				</div>
			</TabWrapper>
		</Page>
	);
};

interface IDealDiscussionsPage {
	deal: TDeal;
	onToggleAlerts: (data: { relationId: string; newPostAlerts: boolean }) => void;
}

export default DealDiscussions;
