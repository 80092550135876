import type { FormikProps } from 'formik';
import { FormSection, Input, TextArea, DropdownMenu, Gap, When } from '@ui-kit';
import { NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT } from '@constants';
import { useTranslation } from '@hooks';
import { isUndefined, get } from '@utils';
import type { TDynamicField, TDynamicFieldLink, TDynamicFieldMultiSelect } from '@typings';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import DetailsDynamicField from '../DetailsDynamicField/DetailsDynamicField';
import styles from './ConnectionDetails.module.css';
import { useCurrencyCodes } from '@ui-modules/deals/components/DealForm/sections/useCurrencyCodes';

const ConnectionDetails = ({
	formProps,
	isEdit,
	networkConnectionName,
	dynamicFields = [],
	hideCurrency,
	showDonationLink,
	donationTitle,
}: IConnectionDetailsProps) => {
	const { t } = useTranslation();
	const { values, errors, setFieldValue, setFieldError } = formProps;
	const currencyOptions = useCurrencyCodes();

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	return (
		<FormSection title={t('{{networkConnectionName}} Details', { networkConnectionName })}>
			<Input
				errorMessage={errors.title}
				label={t('{{networkConnectionName}} name', { networkConnectionName })}
				placeholder={t('e.g. {{example}}', { example: `"Cybersecurity Startup VC Fund"` })}
				value={values.title}
				onChange={(e) => handleChangeField(connectionFormFields.TITLE, e.target.value)}
			/>
			<TextArea
				errorMessage={
					values.description && values.description?.length > NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT
						? `${values.description?.length ?? 0}/${NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT}`
						: !values.description?.trim?.()?.length && errors.description
							? errors.description
							: ''
				}
				errorMessagePosition={
					values.description?.length > NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT ? 'right' : 'left'
				}
				label={t('{{networkConnectionName}} description', { networkConnectionName })}
				minRows={4}
				rounded
				value={values.description}
				onChange={(e) => handleChangeField(connectionFormFields.DESCRIPTION, e.target.value)}
			/>
			{(values.description?.length ?? 0) <= NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT && (
				<p className={styles.description__counter}>
					{`${values.description?.length ?? 0}/${NETWORK_CONNECTION_DESCRIPTION_CHARACTER_LIMIT}`}
				</p>
			)}
			<When condition={!hideCurrency}>
				<>
					<DropdownMenu
						customDescription={
							!errors.currency ? (
								<span className={styles.currencyDescription}>{t('Select currency to enable subsequent fields')}</span>
							) : null
						}
						errorMessage={errors.currency}
						iconType="clear"
						isSearchable
						label={t('Currency')}
						options={currencyOptions}
						placeholder={t('Find the currency')}
						showSearchIcon
						value={values.currency}
						variant="medium"
						onChange={(value) => handleChangeField(connectionFormFields.CURRENCY, value as string)}
					/>
					{dynamicFields.length || showDonationLink ? <Gap gap={12} /> : null}
				</>
			</When>
			<When condition={showDonationLink}>
				<Input
					autoCapitalize="none"
					errorMessage={errors.donationButtonLink}
					label={t(`{{label}} (optional)`, { label: donationTitle ?? 'Donation link' })}
					placeholder={t('e.g. {{example}}', { example: 'https://www.financialreports.com/report_2022' })}
					value={values.donationButtonLink}
					onChange={(e) => {
						setFieldValue(connectionFormFields.DONATION_BUTTON_LINK, e.target.value);
						setFieldError(connectionFormFields.DONATION_BUTTON_LINK, undefined);
					}}
				/>
			</When>
			<div className={styles.connectionSection__formSectionBody}>
				{dynamicFields?.map((field) => {
					const fieldPath = `${connectionFormFields.DYNAMIC_FIELDS}.${field.name}`;
					const valuePath = `${fieldPath}.value`;
					const isRequired = isUndefined(field.required) || field.required;
					const value = get(values, valuePath);

					return (
						<DetailsDynamicField
							choices={(field as TDynamicFieldMultiSelect)?.meta.choices}
							errorMessage={get(errors, fieldPath) as string}
							fieldPath={fieldPath}
							isRequired={isRequired}
							key={field.name}
							label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
							meta={'meta' in field ? field.meta : undefined}
							placeholder={(field as TDynamicFieldLink).placeholder}
							setFieldError={setFieldError}
							setFieldValue={setFieldValue}
							slug={field.name}
							type={field.type}
							value={value}
							valuePath={valuePath}
						/>
					);
				})}
			</div>
		</FormSection>
	);
};

export default ConnectionDetails;

interface IConnectionDetailsProps {
	formProps: FormikProps<TNetworkConnectionFormValues>;
	isEdit?: boolean;
	networkConnectionName?: string;
	dynamicFields?: TDynamicField[];
	hideCurrency?: boolean;
	showDonationLink?: boolean;
	donationTitle?: string;
}
