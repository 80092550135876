import axios, { type AxiosResponse } from 'axios';
import { useService, useQuery } from '@hooks';
import { dayjs, compact, toString } from '@utils';
import { makeTipMock } from '@mocks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TPatchTipForm } from '@schemas';

export const useAiTipsQuery = (newTipForm: TPatchTipForm, options?: UseQueryOptions<TPatchTipForm[], Error>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TPatchTipForm[], Error>(
		['aiTips', newTipForm],
		async () => {
			// TODO: Implement API call to get AI tips
			await new Promise((resolve) => setTimeout(resolve, 7000));
			if (newTipForm.categories?.length === 1 && newTipForm.categories[0] === 'Other') return [];

			let result: TPatchTipForm[] = [];

			try {
				const body = new FormData();
				body.append('category', String(newTipForm.categories));
				newTipForm.title && body.append('title', newTipForm.title);
				newTipForm.description && body.append('description', newTipForm.description);
				newTipForm.location && body.append('latitude', toString(newTipForm.location?.lat));
				newTipForm.location && body.append('longitude', toString(newTipForm.location?.lng));

				const response = await axios.post<unknown, AxiosResponse<{ output: { output: { items: TAiResponseItem[] } } }>>(
					'https://n8n.tiger21.com/webhook/1d6a520d-afd9-4169-a5f1-1c593832026b',
					body,
					{
						headers: {
							Authorization:
								'Bearer eyJhbGciOiJIUzUxMiJ9.e30.uD_eqL-mQiovCDrnkVih9CiOy5JTZzE2bWx_V8S_-yyid1-zsyCLG_q0X8VlQFm4MlUjeKoslhHFv313vzBsdw',
						},
						transformRequest: () => body, // bypass issue (https://github.com/axios/axios/issues/4406#issuecomment-1048693170)
					},
				);
				const aiOutput = response.data?.output?.output?.items ?? [];
				result = aiOutput.map((aiItem) => {
					const aiImage = aiItem.image_url_1 || aiItem.image_url_2;
					return {
						categories: newTipForm.categories ?? undefined,
						title: aiItem.Title ?? undefined,
						description: aiItem.Description ?? undefined,
						images: compact([aiImage && { source: 'ai', url: aiImage }]),
						link: aiItem.URL ?? undefined,
					};
				});
			} catch (error) {
				console.error(error);
				result = Array.from({ length: 5 }).map(() => makeTipMock());
			}

			return result;
		},
		{
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			...options,
		},
	);
};

type TAiResponseItem = {
	Actors: [];
	Author: string | null;
	Description: string | null;
	Director: string | null;
	Location: string | null;
	Person: string | null;
	Title: string | null;
	URL: string | null;
	image_url_1: string | null;
	image_url_2: string | null;
};
