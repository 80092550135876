import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import TipReviewForm from '@ui-modules/tipJar/components/TipReviewForm';
import { useTranslation, useNavigate, useParams, useLayoutEffect } from '@hooks';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';
import { useSendTipReviewMutation } from '@ui-modules/tipJar/hooks/useSendTipReviewMutation';
import { ROUTES } from '@constants';
import styles from './TipReviewPage.module.css';
import type { TUid } from '@tiger21-llc/connect-shared/src/typings/base/Uid.type';

const TipReviewPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { tipId, tipReviewIdOrNew } = useParams<Partial<ITipReviewPageParams>>() as ITipReviewPageParams;
	const tipReviewId = tipReviewIdOrNew === 'new' ? undefined : tipReviewIdOrNew;
	const pageTitle = tipReviewId ? t('Edit Review') : t('Add Review');

	useLayoutEffect(() => {
		if (!tipId) navigate(-1);
	}, [tipId]);

	const tipQuery = useTipQuery(tipId);
	const tipReviewQuery = useTipReviewQuery(tipId, tipReviewId);

	const { mutateAsync: sendTipReview } = useSendTipReviewMutation({
		onSuccess: () => {
			navigate(-1);
		},
	});

	return (
		<Page title={pageTitle}>
			{tipQuery.isLoading || tipReviewQuery.isFetching ? (
				<ActivityIndicator type="fit" />
			) : tipQuery.isError || tipReviewQuery.isError ? (
				<EmptyStateMessage text={t('Something went wrong')} />
			) : (
				<TipReviewForm
					initialValues={tipReviewQuery.data}
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.createTipReview()]}
							formProps={formProps}
							headerTitle={pageTitle}
							isSaveButtonHidden
							scrollEnabled
						>
							<div className={styles.tipReviewPage}>
								{children}
								<Gap gap={24} />
							</div>
						</FormPageLayout>
					)}
					submitLabel={tipReviewId ? t('Save') : t('Submit')}
					targetMeetings={null}
					title={tipReviewId ? t('Review Details') : t('Review “Private Equity Edition 1 by Oliver Burkeman”')}
					onSubmit={(formValues) => sendTipReview({ tipId, ...formValues })}
				/>
			)}
		</Page>
	);
};

export interface ITipReviewPageParams {
	tipId: TUid;
	tipReviewIdOrNew: TUid | 'new';
}

export default TipReviewPage;
