import LightBox from 'react-image-lightbox';
import { MentionsInput, Mention } from 'react-mentions';
import { useMemo, useState, useRef, forwardRef, useEffect } from 'react';

import { useSearchUsersQuery, useTranslation, useUserGroups } from '@hooks';
import { CloseIcon, VideoAttachmentIcon } from '@ui-kit';

import { useImageView } from './useImageView';
import styles from './AttachmentTextArea.module.css';

import './AttachmentTextArea.overwrite.css';

import {
	filterImageUrls,
	suggestionTitle,
	mapSuggestionData,
	handleRemoveAttachment as removeAttachmentUtil,
} from './utils';

import SuggestionsWrapper from './SuggestionsWrapper';
import { SuggestionListItem, FileTypeAvatar } from '@ui-kit';

import type { ForwardedRef } from 'react';
import type { TAttachments, TCommunity, TFullUser } from '@typings';
import type { MentionsInputProps, SuggestionDataItem } from 'react-mentions';
import { useDebouncedMentionSearchQuery } from '@ui-modules/feed/hooks/useDebouncedMentionSearchQuery';

const AttachmentTextArea = (
	{
		value,
		placeholder,
		disabled,
		attachments,
		attachmentsError,
		attachmentEditingDisabled,
		onChange,
		onRemoveAttachment,
		resize,
		delayedResize,
		onPaste,
		networkId,
		...props
	}: IAttachmentTextAreaProps,
	ref: ForwardedRef<HTMLTextAreaElement>,
) => {
	const { t } = useTranslation();
	const containerRef = useRef<HTMLDivElement>(null);
	const mentionInputRef = useRef<any | null>(null); // FIXME

	const [containerHeight, setContainerHeight] = useState(0);
	const searchFilter = useDebouncedMentionSearchQuery(mentionInputRef);

	useEffect(() => {
		if (containerRef.current) {
			setContainerHeight(containerRef.current.offsetHeight);
		}
	}, [attachments, value]);

	const [isFocused, setIsFocused] = useState(false);
	const userGroups = useUserGroups();

	const imagesUrls = useMemo(() => filterImageUrls(attachments), [attachments]);

	const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
		useImageView(imagesUrls);

	const {
		data: users,
		isLoading,
		isFetching,
	} = useSearchUsersQuery({
		communityId: networkId,
		searchFilter: searchFilter,
	});

	const suggestionData = useMemo(() => mapSuggestionData(users), [users]);

	useEffect(() => {
		if (suggestionData?.length > 0 && !isFetching && !isLoading && mentionInputRef?.current) {
			const mentionState = mentionInputRef.current.state;
			const suggestionsList = mentionState?.suggestions || [];
			const currentSuggestions = suggestionsList[0];
			if (currentSuggestions) {
				currentSuggestions.results = suggestionData;
				mentionInputRef.current.updater.enqueueForceUpdate(mentionInputRef.current);
			}
		}
	}, [isFetching, isLoading, suggestionData, searchFilter]);

	return (
		<>
			<div
				className={styles.textarea__container}
				data-focused={isFocused}
				ref={containerRef}
				onClick={() => {
					setTimeout(mentionInputRef.current.updateHighlighterScroll(), 0); //fixes desync of textarea and mentions in edge cases, when user click and scroll see T21C-7953
				}}
				onKeyDown={() => {
					setTimeout(mentionInputRef.current.updateHighlighterScroll(), 0);
				}}
			>
				{attachments.length > 0 && (
					<>
						{attachmentsError && <span className={styles.attachment__error}>{attachmentsError}</span>}
						<div className={styles.attachment__container}>
							{attachments.map((attachment, index) => (
								<div className={styles.attachment} key={`${attachment.name}-${attachment.url}-${index}`}>
									{!attachmentEditingDisabled && (
										<button
											className={styles.attachment__button}
											onClick={() => removeAttachmentUtil(onRemoveAttachment, attachment)}
										>
											<CloseIcon height={8} width={8} />
										</button>
									)}
									{attachment.fileType === 'image' ? (
										<button
											className={styles.attachment__buttonImg}
											onClick={() =>
												handleClick(imagesUrls.findIndex((img: string | undefined) => img === attachment?.url))
											}
										>
											<img alt={attachment.name} className={styles.attachment} src={attachment.url} />
										</button>
									) : attachment.fileType === 'file' ? (
										<div className={styles.attachment__file}>
											<FileTypeAvatar mimeType={attachment?.mimeType || ''} />
										</div>
									) : (
										<div>
											<video controls height="60" width="60">
												<source src={attachment.url} />
												<track default kind="captions" src={attachment.url} srcLang="en" />
											</video>
											<VideoAttachmentIcon className={styles.attachment__videoIcon} height={16} width={16} />
										</div>
									)}
								</div>
							))}
						</div>
					</>
				)}
				<MentionsInput
					autoFocus={false}
					className="mentionTextarea"
					customSuggestionsContainer={(children) =>
						typeof searchFilter === 'string' ? (
							<SuggestionsWrapper
								attachments={attachments}
								containerHeight={containerHeight}
								isLoading={isLoading}
								title={suggestionTitle(t)(searchFilter)}
							>
								{children}
							</SuggestionsWrapper>
						) : null
					}
					disabled={disabled}
					EmptyStateComponent={() => (
						<div className={styles.suggestions__notFound}>
							<p className={styles.suggestions__notFoundInfo}>{t('No Matching Users Found')}</p>
						</div>
					)}
					forceSuggestionsAboveCursor
					inputRef={ref}
					placeholder={placeholder}
					ref={mentionInputRef}
					rows={1}
					style={{
						suggestions: {
							list: {
								maxHeight: 284,
								overflowY: 'auto',
							},
						},
					}}
					value={value}
					onBlur={() => setIsFocused(false)}
					onChange={(event) => {
						resize();
						onChange?.(event.target.value);
					}}
					onCut={delayedResize}
					onDrop={delayedResize}
					onFocus={() => setIsFocused(true)}
					onKeyDown={delayedResize}
					onPaste={(event) => {
						onPaste?.(event);
						delayedResize();
					}}
					{...props}
				>
					<Mention
						appendSpaceOnAdd
						className={styles.mentionHighlighted}
						data={suggestionData}
						isLoading={isLoading && typeof searchFilter === 'string'}
						key={mentionInputRef.current}
						renderSuggestion={(suggestion, search, highlightedDisplay, _, focused) => {
							return (
								<SuggestionListItem
									focused={focused}
									suggestion={suggestion as TFullUser & SuggestionDataItem}
									textToHighlight={searchFilter || ''}
									userSubtitle={userGroups[suggestion.id]}
								/>
							);
						}}
						trigger={'@'}
					/>
				</MentionsInput>
			</div>
			{!!currentImage && (
				<LightBox
					enableZoom={false}
					mainSrc={currentImage}
					mainSrcThumbnail={currentImage}
					nextSrc={nextImage}
					nextSrcThumbnail={nextImage}
					prevSrc={prevImage}
					prevSrcThumbnail={prevImage}
					onCloseRequest={handleClose}
					onMoveNextRequest={handleMoveNext}
					onMovePrevRequest={handleMovePrev}
				/>
			)}
		</>
	);
};

export interface IAttachmentTextAreaProps {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	attachmentEditingDisabled?: boolean;
	attachmentsError?: string;
	attachments: TAttachments[];
	onRemoveAttachment: (file: TAttachments) => void;
	delayedResize: () => void;
	resize: () => void;
	onChange: (value: string) => void;
	onPaste: MentionsInputProps['onPaste'];
	networkId?: TCommunity['id'];
}

export default forwardRef(AttachmentTextArea);
