import type { TEvent } from '@typings';
import type { ISectionListProps } from '@ui-kit';
import { SectionList } from '@ui-kit';
import { useCallback, useTranslation } from '@hooks';
import EventItem from '../EventItem';
import { useEventAttendanceMutations } from '@ui-modules/events/hooks/useEventAttendanceMutations';

const EventSectionList = ({
	eventSections,
	isLoading,
	ListFooterComponent,
	selectedEventId,
	onSelectEvent,
	onScroll,
}: IEventSectionListProps) => {
	const { t } = useTranslation();

	const {
		changeEventAttendanceMutation,
		requestEventAttendanceMutation,
		cancelEventAttendanceMutation,
		launchVirtualMeeting,
	} = useEventAttendanceMutations();

	const renderItem = useCallback(
		(event: TEvent) => {
			return (
				<EventItem
					event={event}
					isAttending={event?.currentUserAttendance?.status === 'going' && event['@type'] !== 'Meeting'}
					isCancelled={event.isCanceled}
					isSelected={selectedEventId === event.id}
					shouldShowMeetingAction={event['@type'] === 'Meeting'}
					onCancelRequestAttendance={cancelEventAttendanceMutation.mutateAsync}
					onChangeAttendance={changeEventAttendanceMutation.mutateAsync}
					onClick={() => onSelectEvent(event)}
					onLaunchVirtualMeeting={launchVirtualMeeting}
					onRequestAttendance={requestEventAttendanceMutation.mutateAsync}
				/>
			);
		},
		[
			selectedEventId,
			onSelectEvent,
			changeEventAttendanceMutation,
			requestEventAttendanceMutation,
			launchVirtualMeeting,
			cancelEventAttendanceMutation,
		],
	);

	return (
		<SectionList<TEvent>
			data={eventSections}
			emptyMessage={t('Sorry, we couldn’t find any matching results')}
			headerPadded
			isLoading={isLoading}
			ListFooterComponent={ListFooterComponent}
			renderItem={renderItem}
			onScroll={onScroll}
		/>
	);
};

interface ISectionListItem {
	title: string;
	data: TEvent[];
}

interface IEventSectionListProps
	extends Pick<ISectionListProps<ISectionListItem>, 'isLoading' | 'isFetching' | 'ListFooterComponent' | 'onScroll'> {
	eventSections: ISectionListItem[];
	onSelectEvent: (event: TEvent) => void;
	selectedEventId?: string;
}

export default EventSectionList;
