import { ActivityIndicator, DropdownIcon, MeatballMenu, TickIcon } from '@ui-kit';
import { useMemo } from '@hooks';
import { clsx } from '@utils';
import styles from './DropdownButton.module.css';
import type { TCustomMenuContainerProps } from '@ui-kit/components/MeatballMenu/MeatballMenu';
import type { TMeatballMenuOption, IButtonProps } from '@ui-kit';

/** Button with arrow icon, after clicking the select dropdown with options is shown. */
const DropdownButton = <TValue extends string = string>({
	options = [],
	value,
	onChange,
	loading: isLoading,
}: IDropdownButtonProps<TValue>) => {
	const currentValue = useMemo(() => options.find((option) => option.id === value), [options, value]);

	const menuOptions: TMeatballMenuOption[] = useMemo(
		() =>
			options.map((option) => {
				const isSelected = option.id === value;
				return {
					text: (
						<div
							className={clsx(
								styles.dropdownButton__title,
								option.destructive && styles.dropdownButton__title_destructive,
								option.style,
							)}
						>
							{option.title}
							{option?.titleStatus ? (
								<span className={styles.dropdownButton__titleStatus}>{` (${option?.titleStatus})`}</span>
							) : null}
						</div>
					),
					onClick: () => onChange(option.id),
					icon: <TickIcon fill={isSelected ? '#000000' : 'transparent'} height={16} width={16} />,
					description: <p className={styles.dropdownButton__description}>{option.description}</p>,
					destructive: option.destructive,
					disabled: isSelected,
				};
			}),
		[options, value, onChange],
	);

	return (
		<MeatballMenu
			customIconStyle={styles.dropdownButton__icon}
			CustomMenuContainer={useMemo(
				() =>
					function Container({ menuOptions, menuRef }: TCustomMenuContainerProps) {
						return (
							<div className={styles.dropdownButton__menu} ref={menuRef}>
								{menuOptions}
							</div>
						);
					},
				[],
			)}
			options={menuOptions}
		>
			<div className={styles.dropdownButton__button}>
				{isLoading ? (
					<ActivityIndicator />
				) : (
					<p className={clsx(styles.dropdownButton__buttonTitle, currentValue?.style)}>{currentValue?.title}</p>
				)}
				<div>
					<DropdownIcon fill="#000000" height={8} width={8} />
				</div>
			</div>
		</MeatballMenu>
	);
};

export type TDropdownButtonOption<TValue extends string = string> = {
	/** The value of the option. Will be passed to onChange callback when the option is selected. */
	id: TValue;
	/** The main text of the option. */
	title: string;
	/** The text shown in brackets near the title. */
	titleStatus?: string | null;
	/** The text shown below the title. */
	description?: string | null;
	/** If true, the option will be highlighted in red. */
	destructive?: boolean;
	/** Custom style for specific option */
	style?: string;
};

export interface IDropdownButtonProps<TValue extends string = string>
	extends Pick<IButtonProps, 'loading' | 'disabled'> {
	/** The list of options. */
	options: TDropdownButtonOption<TValue>[];
	/** The value of the selected option. */
	value: string | undefined;
	/** The callback called when the option is selected. */
	onChange: (value: TValue) => void;
}

export default DropdownButton;
