import type { Components, ItemContent, TableVirtuosoProps } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import type { TTipReviewWithReaction, TProfile, TTip, TCommunity, TEvent } from '@typings';
import { useTipReviewsQuery } from '@ui-modules/tipJar/hooks/useTipReviewsQuery';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import TipReviewCard from '@ui-modules/tipJar/components/TipReviewCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useTipReviewThumbUpMutation } from '@ui-modules/tipJar/hooks/useTipReviewThumbUpMutation';
import { useMe } from '@hooks';
import styles from './TipReviewList.module.css';
import { Gap } from '@ui-kit/components';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';
import { useGetTipReviewMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipReviewMeatballMenuOptions';

const TipReviewList = ({
	tipId,
	contextCommunityId,
	contextCalendarIdItemId,
	Header,
	...virtuosoListProps
}: ITipReviewListProps) => {
	const navigate = useNavigate();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));
	const { user } = useMe();

	const {
		data: tipReviews = [],
		isLoading,
		fetchMore,
		isFetchingNextPage,
	} = useTipReviewsQuery(tipId, {
		contextCommunityId,
		contextCalendarIdItemId,
	});
	const tipReviewThumbUpMutation = useTipReviewThumbUpMutation(tipId);
	const [meatballMenuOptions] = useGetTipReviewMeatballMenuOptions(tipId);

	const renderTipReviewItem = useCallback<ItemContent<TTipReviewWithReaction, unknown>>(
		(_, tipReview) => {
			return (
				<div className={styles.tipReviewList__container}>
					<TipReviewCard
						canThumbUp={TipReviewEntity.canThumbUp(tipReview, user)}
						meatballsMenuOptions={meatballMenuOptions(tipReview)}
						tipReview={tipReview}
						onClickUser={() => openProfile(tipReview.owner.profileID)}
						onThumbUp={() => tipReviewThumbUpMutation.mutate(tipReview)}
					/>
				</div>
			);
		},
		[tipReviews],
	);

	return (
		<Virtuoso<TTipReviewWithReaction>
			components={{
				Header: Header,
				Footer: () =>
					isLoading || isFetchingNextPage ? <ActivityIndicator size={'small'} type="fit" /> : <Gap gap={8} />,
			}}
			{...virtuosoListProps}
			data={tipReviews}
			endReached={fetchMore}
			itemContent={renderTipReviewItem}
		/>
	);
};

export interface ITipReviewListProps
	extends Omit<
		TableVirtuosoProps<TTipReviewWithReaction, ReactNode>,
		'data' | 'components' | 'onEndReached' | 'headerFooterTag' | 'topItemCount'
	> {
	tipId: TTip['id'];
	contextCommunityId?: TCommunity['id'];
	contextCalendarIdItemId?: TEvent['id'];
	Header?: Components['Header'];
}

export default TipReviewList;
