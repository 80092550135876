// import { switchArrayItem } from '@utils';
import { useCallback, useMemo } from '@hooks';
import { useUpdateProfileMutation } from './useUpdateProfileMutation';
import type { THidableProfileProperties } from '@typings';
import { uniq } from '@utils';

/** It either adds or removes an element from the array keeping the array having unique values. */
const switchArrayItem = <T extends TValue>(array: T[], item: T, shouldAdd: boolean): T[] => {
	return shouldAdd ? uniq([...array, item]) : array.filter((arrayItem) => arrayItem !== item);
};

type TValue = string | number;

/** Prepare data to work with { showOnProfile: boolean } like forms on Profile. */
export const useUpdateProfileHidePropertyMutation = (hideProperty: THidableProfileProperties) => {
	const { profile, mutateAsync } = useUpdateProfileMutation();
	const initialValues = useMemo(
		() => ({
			showOnProfile: !profile.hideProperties.includes(hideProperty),
		}),
		[profile],
	);

	const submit = useCallback(
		async ({ showOnProfile }: IProfileGenericHidePropertyForm) =>
			await mutateAsync({
				hideProperties: switchArrayItem(profile.hideProperties, hideProperty, !showOnProfile),
			}),
		[profile],
	);

	return { initialValues, submit, profile };
};

interface IProfileGenericHidePropertyForm {
	showOnProfile: boolean;
}
