import { EditIcon, ReportIcon } from '@ui-kit/icons';
import { useCallback, useTranslation, useMe } from '@hooks';
import { compact } from '@utils';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import type { TTip, TTipReview } from '@typings';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';
import type { TMeatballMenuOption } from '@ui-kit';

export const useGetTipReviewMeatballMenuOptions = (tipId: TTip['id']) => {
	const { user } = useMe();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const meatballMenuOptions = useCallback((tipReview: TTipReview): TMeatballMenuOption[] => {
		return compact([
			TipReviewEntity.canEdit(tipReview, user) && {
				text: t('Edit Review'),
				onClick: () => navigate(ROUTES.tipReview(tipId, tipReview.id)),
				icon: <EditIcon fill="black" height={16} width={16} />,
			},
			TipReviewEntity.canReport(tipReview, user) && {
				text: t('Report Review'),
				onClick: () => navigate(ROUTES.reportContent('tip-review', tipReview.id, tipReview.tipId)),
				icon: <ReportIcon fill="black" height={16} width={16} />,
			},
		]);
	}, []);
	return [meatballMenuOptions] as const;
};
