import Bottle from 'bottlejs';

export class ServicesContainer<S extends Record<string, IInjectableClass>> {
	#services: S;
	bottle: Bottle;

	constructor(serviceClasses: S) {
		this.#services = serviceClasses;
		const bottle = new Bottle();

		for (const depName in this.#services) {
			const serviceConstructor = this.#services[depName as keyof S];
			bottle.service(depName, serviceConstructor as never, ...(serviceConstructor.inject ?? []));
		}

		this.bottle = bottle;
	}

	resolve<T extends keyof S>(depName: T) {
		return this.bottle.container[depName as string] as InstanceType<S[T]>;
	}
}

export interface IInjectableClass {
	new (...args: any): any;
	inject?: readonly string[];
}
