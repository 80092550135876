import styles from '@ui-modules/tipJar/components/TipsImageCarousel/TipsImageCarousel.module.css';
import type { TCarouselItem } from '@ui-modules/tipJar/components/TipsImageCarousel/TipsImageCarousel';

const CarouselItem = ({ item, longPressProps }: ICarouselItemProps) => {
	if (item.type === 'image' && item.source) {
		return (
			<div className={styles.tipsImageCarousel__itemImage} {...longPressProps}>
				<img alt={`Img`} className={styles.tipsImageCarousel__itemImage_img} src={item.source} />
				<span className={styles.tipsImageCarousel__icon}></span>
			</div>
		);
	} else if (item.type === 'view') {
		return item.Component;
	}
};

interface ICarouselItemProps {
	item: TCarouselItem;
	longPressProps?: object;
}

export default CarouselItem;
