import { InputLabel } from '@ui-kit';
import { CloseIcon } from '@ui-kit/icons';
import { dayjs } from '@utils';
import styles from './FilePickerField.module.css';
import type { IFilePickerProps } from '../FilePicker/FilePicker';
import FilePicker from '../FilePicker';
import type { TFile } from '@tiger21-llc/connect-shared/src/typings';

const FilePickerField = ({
	label,
	value: file,
	onReset,
	isLoading,
	title,
	description,
	accept,
	onSelect,
}: IFilePickerFieldProps) => {
	return (
		<div>
			{label ? <InputLabel text={label} /> : null}
			{file ? (
				<div className={styles.filePickerField__file}>
					<img alt="tip" className={styles.filePickerField__fileImage} src={file.mediaObject.contentUrl} />
					<div className={styles.filePickerField__fileBody}>
						<h4>{file.mediaObject.originalName}</h4>
						<span className={styles.filePickerField__fileSubtitle}>{getFileSubtitle(file)}</span>
					</div>
					<button aria-label="Delete file" className={styles.filePickerField__deleteButton} onClick={() => onReset()}>
						<CloseIcon className={styles.filePickerField__deleteButtonIcon} />
					</button>
				</div>
			) : (
				<FilePicker accept={accept} description={description} isLoading={isLoading} title={title} onSelect={onSelect} />
			)}
		</div>
	);
};

const getFileSubtitle = (file: TFile) => {
	const ownerInfo =
		file?.owner?.firstName?.length || file?.owner?.lastName?.length
			? `by ${file?.owner?.firstName} ${file.owner.lastName}`
			: '';
	const subtitle = `${dayjs(file.createdAt).format('D MMM YYYY, h:mma')} ${ownerInfo}`;
	return subtitle;
};

export interface IFilePickerFieldProps extends IFilePickerProps {
	label?: string;
	value: TFile | undefined;
	onReset: () => void;
}

export default FilePickerField;
