import { useCallback } from 'react';
import { useService } from '@services';
import axios, { type AxiosError } from 'axios';
import { useMutation } from '@hooks';
import { isEmpty } from '@utils';
import type { FormikErrors, FormikProps } from 'formik';
import type { TEditContactCard } from '@schemas';

const useAddressValidation = () => {
	const appConfig = useService('AppConfigService');

	const {
		mutateAsync: validateAddress,
		isLoading,
		error,
	} = useMutation<IValidatedAddress | null, AxiosError, IValidatedAddress>(async (address: IValidatedAddress) => {
		const apiKey = appConfig.GOOGLE_PLACE_API_KEY;
		const requestBody = {
			address: {
				addressLines: [address.streetAddress1],
				locality: address.city,
				administrativeArea: address.state,
				postalCode: address.postalCode,
				regionCode: address.country,
			},
		};
		const response = await axios.post(
			`https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`,
			requestBody,
			{ headers: { 'Content-Type': 'application/json' } },
		);
		return response.data.result;
	});

	return { validateAddress, isLoading, error };
};

export const useValidateAddressComponents = (formProps: FormikProps<TEditContactCard>) => {
	const { validateAddress } = useAddressValidation();

	// Maps unconfirmed or modified components to errors
	const mapUnconfirmedComponentsToErrors = (components: IAddressComponent[], index: number): Record<string, string> => {
		const errors: Record<string, string> = {};
		components.forEach(({ componentType, replaced, spellCorrected }) => {
			const fieldName = getFieldNameByComponentType(componentType);

			if (fieldName) {
				if (replaced) {
					errors[`address[${index}].address.${fieldName}`] =
						`The ${fieldName} was replaced during validation. Please verify it.`;
				} else if (spellCorrected) {
					errors[`address[${index}].address.${fieldName}`] =
						`The ${fieldName} was spell-corrected during validation. Please verify it.`;
				} else {
					errors[`address[${index}].address.${fieldName}`] = `The ${fieldName} is unconfirmed.`;
				}
			}
		});
		return errors;
	};

	// Maps component type to form field names
	const getFieldNameByComponentType = (componentType: string): string | undefined => {
		switch (componentType) {
			case 'locality':
				return 'city';
			case 'postal_code':
				return 'postalCode';
			case 'regionCode':
				return 'country';
			case 'subpremise':
				return 'streetAddress1';
			default:
				console.warn(`Unhandled component type: ${componentType}`);
				return undefined;
		}
	};

	// Handles API errors
	const handleApiError = (
		error: unknown,
		index: number,
		headerTitle?: string,
		onSaveFailed?: (errors: FormikErrors<TEditContactCard>) => void,
	): boolean => {
		const apiError = error as AxiosError<{ error: { code: number; message: string; status: string } }>;
		if (
			apiError?.response?.data?.error?.code === 400 &&
			apiError?.response?.data?.error?.message.includes('Unsupported region code') &&
			apiError?.response?.data?.error?.status === 'INVALID_ARGUMENT'
		) {
			return false; // Ignore specific error
		} else {
			console.error(`Validation error (Block: ${headerTitle}):`, error);
			onSaveFailed?.({ [`address[${index}].address`]: 'An unexpected validation error occurred.' });
			return true;
		}
	};

	return useCallback(
		async (
			onSaveFailed?: (errors: FormikErrors<TEditContactCard>) => void,
			onSuccessSubmit?: () => void,
			onBackButtonPress?: () => void,
			headerTitle?: string,
		) => {
			const addresses = formProps.values?.address ?? [];
			let hasErrors = false;

			const validateAddressAndHandleErrors = async (address: IValidatedAddress, index: number): Promise<boolean> => {
				try {
					const validatedAddress = await validateAddress(address);
					const unconfirmedComponents =
						validatedAddress?.address?.addressComponents?.filter(
							(c) => c.confirmationLevel !== 'CONFIRMED' || c.replaced || c.spellCorrected,
						) ?? [];
					const errors = mapUnconfirmedComponentsToErrors(unconfirmedComponents, index);

					if (!isEmpty(errors)) {
						Object.entries(errors).forEach(([field, message]) => formProps.setFieldError(field, message));
						return true;
					}
				} catch (error) {
					return handleApiError(error, index, headerTitle, onSaveFailed);
				}
				return false;
			};

			for (let i = 0; i < addresses.length; i++) {
				const address = addresses[i]?.address;
				if (!address) continue;

				const validationErrors = await formProps.validateForm();
				if (!isEmpty(validationErrors)) {
					onSaveFailed?.(validationErrors);
					return;
				}

				const hasAddressErrors = await validateAddressAndHandleErrors(address as IValidatedAddress, i);
				if (hasAddressErrors) {
					hasErrors = true;
					break;
				}
			}

			if (hasErrors) {
				onSaveFailed?.(formProps.errors);
			} else {
				await formProps.submitForm();
				onBackButtonPress ? onBackButtonPress() : onSuccessSubmit?.();
			}
		},
		[formProps, validateAddress],
	);
};

export interface IValidatedAddress extends IValidateAddressResponse {
	streetAddress1: string;
	streetAddress2?: string;
	city: string;
	postalCode: string;
	country: string;
	state: string;
}

export interface IValidateAddressResponse {
	address?: IValidatedAddressDetails;
	originalAddress?: IAddress;
	geocode?: IGeocode;
	metadata?: IAddressMetadata;
	validationResult?: IValidationResult;
}

export interface IValidatedAddressDetails {
	formattedAddress?: string;
	addressComponents?: IAddressComponent[];
	missingComponentTypes?: string[];
	unconfirmedComponentTypes?: string[];
	unresolvedTokens?: string[];
}

export interface IAddress {
	organization?: string;
	recipient?: string;
	addressLines?: string[];
	locality?: string;
	administrativeArea?: string;
	postalCode?: string;
	sortingCode?: string;
	country?: string;
}

export interface IAddressComponent {
	componentName: string;
	componentType: string;
	confirmationLevel: string;
	replaced: boolean;
	spellCorrected?: boolean;
}

export interface IGeocode {
	location?: ILatLng;
	plusCode?: IPlusCode;
}

export interface ILatLng {
	latitude: number;
	longitude: number;
}

export interface IPlusCode {
	globalCode: string;
	compoundCode?: string;
}

export interface IAddressMetadata {
	business?: boolean;
	poBox?: boolean;
	residential?: boolean;
}

export interface IValidationResult {
	verdict?: IVerdict;
	addressComplete?: boolean;
	additionalInfo?: IAdditionalInfo[];
}

export interface IVerdict {
	inputGranularity: string;
	validationGranularity: string;
	geocodeGranularity: string;
}

export interface IAdditionalInfo {
	key: string;
	value: string;
}
