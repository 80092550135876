import styles from '../CameraPhotoInput.module.css';
import { TapIcon } from '../../../icons';

const StartCameraButton = ({ title, onClick }: IStartCameraButtonProps) => {
	return (
		<button aria-label="Start camera" className={styles.cameraPhotoInput__retakeImageButton} onClick={onClick}>
			<TapIcon className={styles.cameraPhotoInput__retakeImageButtonIcon} height={12} width={12} />
			<span className={styles.cameraPhotoInput__retakeImageButtonLabel}>{title}</span>
		</button>
	);
};

export interface IStartCameraButtonProps
	extends Pick<
		React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		'title' | 'onClick'
	> {}

export default StartCameraButton;
