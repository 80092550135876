import { useTranslation } from '@hooks';
import type { TLatestActivity } from '@typings';
import { Button, Card, ActivityIndicator } from '@ui-kit';
import ActivityCard from '../ActivityCard';
import styles from './ProfileActivities.module.css';

const ProfileActivities = ({
	canEdit,
	onEdit,
	activities,
	isHidden,
	onPressActivity,
	isInvisible,
	isLoading,
}: IProfileActivitiesProps) => {
	const { t } = useTranslation();

	if (isInvisible) return null;

	return (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			articleMode="transparent"
			backgroundPlacement="article"
			title={t('My Activity')}
		>
			{isHidden ? (
				<span>{t('This section is currently hidden from others')}</span>
			) : isLoading ? (
				<div className={styles.loading__container}>
					<ActivityIndicator />
				</div>
			) : activities.length ? (
				<div className={styles.activities__container}>
					{activities
						?.slice(0, 3)
						.map((activity) => <ActivityCard activity={activity} key={activity['@id']} onPress={onPressActivity} />)}
				</div>
			) : (
				<span>{t('No recent activity')}</span>
			)}
		</Card>
	);
};

export interface IProfileActivitiesProps {
	isInvisible?: boolean;
	activities: TLatestActivity[];
	isHidden: boolean;
	canEdit: boolean;
	isLoading?: boolean;
	onEdit: () => void;
	onPressActivity: (activity: TLatestActivity) => void;
}

export default ProfileActivities;
