import { ActivityIndicator, Tabs } from '@ui-kit';
import { useNavigate, useParams, useService, useTranslation } from '@hooks';
import { useLearnFeedTabsQuery } from '@ui-modules/feed/hooks/useLearnFeedTabsQuery';
import { ROUTES } from '@constants';
import styles from './LearnTabList.module.css';
import LearnListItem from '@ui-modules/feed/components/LearnListItem';

const LearnTabList = () => {
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');
	const { data: learnTabs = [], isLoading } = useLearnFeedTabsQuery();
	const feedName = useParams()?.feedName;
	const currentTabIndex = learnTabs.findIndex((learnTab) => learnTab.feedName === feedName);
	const handleChangeTab = (index: number) => {
		const nextFeedName = learnTabs[index].feedName;
		navigate(ROUTES.networkLearnTab(nextFeedName));
		analytics.trackEvent('LearnInteractions', { learn_category: nextFeedName });
	};

	if (isLoading || !learnTabs?.length) return <ActivityIndicator size="medium" type="fit" />;

	return (
		<Tabs selectedIndex={currentTabIndex} onSelect={handleChangeTab}>
			<div>
				<Tabs.List className={styles.learnTabList}>
					{learnTabs.map((learnTab, index) => (
						<Tabs.Tab
							key={learnTab.title}
							nonSelectedClassName={styles.learnTabList__tabNotSelected}
							selectedClassName={styles.learnTabList__tabSelected}
						>
							<LearnListItem
								description={learnTab.description}
								iconUrl={learnTab.iconUrl}
								isActive={index === currentTabIndex}
								title={learnTab.title}
							/>
						</Tabs.Tab>
					))}
				</Tabs.List>
			</div>
		</Tabs>
	);
};

export default LearnTabList;
