import { CommunityEntity, memoize } from '@utils';
import type { TChapterTabName, TCommunity, TRole } from '@typings';

/** Determines abilities to see single group tabs in order to restrict access. */
export const getChapterTabsPermissions = memoize((group: TCommunity | undefined, userRoles: TRole[]) => {
	const tabs: TChapterTabName[] = [];

	if (group) {
		const isGroupUpcoming = CommunityEntity.isUpcomingGroup(group);
		const isMeetingsShowed = CommunityEntity.shouldShowMeetingsOrEvents(group, userRoles) || isGroupUpcoming;
		const isMembersShowed = CommunityEntity.shouldShowMembers(group, userRoles) || isGroupUpcoming;
		const isDocumentsShowed = CommunityEntity.shouldShowDocumentsV2(group, userRoles);
		const isDiscussionsShowed = CommunityEntity.shouldShowDiscussionsV2(group, userRoles);

		if (isMeetingsShowed) tabs.push('events');
		if (isMembersShowed) tabs.push('members');
		if (isDiscussionsShowed) tabs.push('discussions');
		if (isDocumentsShowed) tabs.push('files');
		tabs.push('tips');

		return {
			tabs,
			isGroupUpcoming,
			isMeetingsShowed,
			isMembersShowed,
			isDocumentsShowed,
			isDiscussionsShowed,
		};
	} else {
		return {
			tabs,
		};
	}
});
