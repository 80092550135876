import { DefaultSuggestionList } from 'stream-chat-react';

import { useBlockedAndBlockingUsers, useMe, useTranslation } from '@hooks';
import { suggestionTitle } from '@ui-modules/feed/components/AttachmentTextArea/utils';

import styles from './AutocompleteSuggestionList.module.css';

import type { SuggestionListProps } from 'stream-chat-react/dist/components/ChatAutoComplete/ChatAutoComplete';
import type { ComponentType } from 'react';
import type { DefaultGenerics } from 'stream-chat';
import type { CustomTrigger } from 'stream-chat-react/dist/types/types';
import type { TFullUser } from '@tiger21-llc/connect-shared/src/typings';
import { filterSuggestions } from '@ui-modules/chat/components/AutocompleteSuggestionList/utils';
import { useChatMentionsVisibility } from '@ui-modules/chat/hooks/useChatMentionsVisibility';

const AutocompleteSuggestionList = (props: IAutocompleteSuggestionListProps) => {
	const { user: myUser } = useMe();
	const { t } = useTranslation();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	const extractedMention = useChatMentionsVisibility(props);

	const filteredSuggestions = filterSuggestions({
		values: props.values,
		blockedAndBlockingUsers,
		myUserId: myUser.id,
	});

	return (
		<div>
			<div className={styles.suggestions__titleWrapper}>
				<p>{suggestionTitle(t)(extractedMention)}</p>
			</div>
			{!filteredSuggestions?.length ? (
				<div className={styles.suggestions__notFound}>
					<p className={styles.suggestions__notFoundInfo}>{t('No Matching Users Found')}</p>
				</div>
			) : (
				props?.currentTrigger && <DefaultSuggestionList {...props} currentTrigger={'@'} values={filteredSuggestions} />
			)}
		</div>
	);
};

/** Not extending the type SuggestionListProps because we cannot extend from `type SuggestionListProps`  */
export interface IAutocompleteSuggestionListProps {
	component: SuggestionListProps['component'];
	className: SuggestionListProps['className'];
	currentTrigger: SuggestionListProps['currentTrigger'];
	dropdownScroll: SuggestionListProps['dropdownScroll'];
	getSelectedItem: SuggestionListProps['getSelectedItem'];
	getTextToReplace: SuggestionListProps['getSelectedItem'];
	Header: SuggestionListProps['Header'];
	itemClassName: SuggestionListProps['itemClassName'];
	itemStyle: SuggestionListProps;
	onSelect: SuggestionListProps['onSelect'];
	selectionEnd: SuggestionListProps['selectionEnd'];
	style: SuggestionListProps['style'];
	SuggestionItem: SuggestionListProps['SuggestionItem'];
	value: SuggestionListProps['value'];
	values: SuggestionListProps['values'];
}

export type TFullUserForAutocomplete = TFullUser & { userId: string };

export default AutocompleteSuggestionList as unknown as ComponentType<
	SuggestionListProps<DefaultGenerics, CustomTrigger>
>;
