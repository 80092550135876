import { ArrowDownIcon } from '@ui-kit/icons';
import styles from './SortWidget.module.css';
import { noop } from '@utils';
import clsx from 'clsx';

const SortWidget = <TField extends string>({
	title,
	sortOptions,
	selectedOption,
	onSelect,
	sortDisabled = false,
}: ISortWidgetProps<TField>) => {
	const handleSelectOption = (option: SortOption<TField>) => {
		onSelect({
			field: option.value,
			order: selectedOption.field === option.value && selectedOption.order === 'asc' ? 'desc' : 'asc',
		});
	};

	return (
		<div className={clsx(styles.sortWidget__container, sortDisabled && styles.sortWidget__container_disabled)}>
			<h3 className={styles.sortWidget__title}>{title}</h3>
			<div className={styles.sortWidget__options_box}>
				{sortOptions.map((option) => {
					const isSelected = selectedOption.field === option.value;

					return (
						<div
							className={clsx(styles.sortWidget__option, isSelected && styles.sortWidget__option_selected)}
							key={option.value}
							role="button"
							tabIndex={0}
							onClick={() => handleSelectOption(option)}
							onKeyDown={noop}
						>
							{isSelected && (
								<ArrowDownIcon
									className={selectedOption.order === 'asc' ? styles.sortWidget__iconAsc : styles.sortWidget__iconDesc}
									fill="var(--color-link)"
								/>
							)}
							<span className={styles.sortWidget__text}>{option.title}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

type SortOrder = 'asc' | 'desc';

type QuerySort<TField> = {
	field: TField;
	order: SortOrder;
};

type SortOption<TField> = {
	title: string;
	value: TField;
};

export interface ISortWidgetProps<TField extends string> {
	title: string;
	selectedOption: QuerySort<TField>;
	sortOptions: SortOption<TField>[];
	onSelect: (option: QuerySort<TField>) => void;
	sortDisabled?: boolean;
}

export default SortWidget;
