import { ALGOLIA_INDEX_NAME } from '@constants';
import { compact, getAlgoliaIndexName } from '@utils';
import type {
	IAlgoliaAnalyticsService,
	TAlgoliaEvent,
	TEventBuilder,
} from '../interfaces/AnalyticsService.Algolia.interface';
import type { TAlgoliaAnalyticsEvents } from '@typings';
import type { TAnalyticsPartialUser } from '../interfaces/AnalyticsService.interface';
import type { IAlgoliaConfig } from '../interfaces/AppConfig.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { ReduxService } from './ReduxService';

export class AlgoliaAnalyticsService implements IAlgoliaAnalyticsService<TAlgoliaAnalyticsEvents> {
	static inject = ['AppConfigService', 'logger', 'ReduxService'] as const;
	constructor(
		appConfig: IAlgoliaConfig,
		logger: IDevLogger,
		private readonly redux: ReduxService,
	) {
		this.logger = logger.child('AlgoliaAnalyticsService');
		this.#algoliaAppId = appConfig.ALGOLIA_APP_ID;
		this.#algoliaIndexPrefix = appConfig.ALGOLIA_INDEX_PREFIX;
	}

	private logger: IDevLogger;
	#userId = '';
	#algoliaApiKey: string | undefined;
	#algoliaAppId: string;
	#algoliaIndexPrefix: string;

	init(algoliaApiKey: string): void {
		this.#algoliaApiKey = algoliaApiKey;
		this.logger.debug('init');
	}

	setUser(user: TAnalyticsPartialUser): void {
		this.#userId = user.id;
		this.logger.debug('user was set', user.id);
	}

	resetUser(): void {
		this.#userId = '';
		this.logger.debug('user was reset');
	}

	trackEvent<TEventName extends keyof TAlgoliaAnalyticsEvents>(
		eventName: TEventName,
		props: TAlgoliaAnalyticsEvents[TEventName],
	): void {
		const hasImpersonation = !!this.redux.store.getState().me.impersonateToken;
		if (hasImpersonation) return this.logger.debug(`${eventName} event not sent because of impersonation`);

		try {
			const event: Omit<TAlgoliaEvent, 'userToken'> = this.eventBuilder[eventName](props);

			this.sendEvent(event);
		} catch (error) {
			this.logger.error(new Error(`Unknown event name for Algolia Analytics => ${eventName}`));
			return;
		}
	}

	private sendEvent(partialAlgoliaEvent: Omit<TAlgoliaEvent, 'userToken'>) {
		const algoliaEvent = { ...partialAlgoliaEvent, userToken: this.#userId };

		if (algoliaEvent.userToken) {
			this.logger.debug('send event', algoliaEvent);

			fetch('https://insights.algolia.io/1/events', {
				method: 'POST',
				body: JSON.stringify({
					events: [algoliaEvent],
				}),
				headers: {
					'Content-Type': 'application/json',
					'X-Algolia-Application-Id': this.#algoliaAppId,
					'X-Algolia-API-Key': String(this.#algoliaApiKey),
				},
			});
		}
	}

	private eventBuilder: TEventBuilder = {
		'User Profile Viewed': (props) => ({
			eventName: 'User Profile Viewed',
			eventType: 'view',
			objectIDs: [props.userSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
		'User Contact Viewed': (props) => ({
			eventName: 'User Contact Viewed',
			eventType: 'view',
			objectIDs: [props.userSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
		'User Contact Added': (props) => ({
			eventName: 'User Contact Added',
			eventType: 'conversion',
			objectIDs: [props.userSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
		'User Contact Removed': (props) => ({
			eventName: 'User Contact Removed',
			eventType: 'conversion',
			objectIDs: [props.userSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
		'Network Viewed': (props) => ({
			eventName: 'Network Viewed',
			eventType: 'view',
			objectIDs: [props.networkSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.communities),
		}),
		'Group Viewed': (props) => ({
			eventName: 'Group Viewed',
			eventType: 'view',
			objectIDs: [props.communitySlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.communities),
		}),
		'Chapter Viewed': (props) => ({
			eventName: 'Chapter Viewed',
			eventType: 'view',
			objectIDs: [props.communitySlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.communities),
		}),
		'Event Viewed': (props) => ({
			eventName: 'Event Viewed',
			eventType: 'view',
			objectIDs: [props.eventSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.events),
		}),
		'Meeting Viewed': (props) => ({
			eventName: 'Meeting Viewed',
			eventType: 'view',
			objectIDs: [props.meetingSlug],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.meetings),
		}),
		'Message Sent': (props) => ({
			eventName: 'Message Sent',
			eventType: 'conversion',
			objectIDs: props.messagersSlugs,
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
		'Discussion Created': (props) => ({
			eventName: 'Discussion Created',
			eventType: 'conversion',
			objectIDs: [props.discussionId],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.discussions),
		}),
		'Discussion Liked': (props) => ({
			eventName: 'Discussion Liked',
			eventType: 'conversion',
			objectIDs: [props.discussionId],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.discussions),
		}),
		'Reply Created': (props) => ({
			eventName: 'Reply Created',
			eventType: 'conversion',
			objectIDs: compact([props.replySlug, props.discussionId]),
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.discussions),
		}),
		'Reply Liked': (props) => ({
			eventName: 'Reply Liked',
			eventType: 'conversion',
			objectIDs: [props.discussionId],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.discussions),
		}),
		'Discussion Viewed': (props) => ({
			eventName: 'Discussion Viewed',
			eventType: 'view',
			objectIDs: [props.discussionId],
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.discussions),
		}),
		'User Interacted': (props) => ({
			eventName: 'User Interacted',
			eventType: 'conversion',
			objectIDs: compact([props.replyOwnerId, props.discussionOwnerId]),
			index: getAlgoliaIndexName(this.#algoliaIndexPrefix, ALGOLIA_INDEX_NAME.users),
		}),
	};
}
