import { ActivityIndicator, Button, CloseIcon, Gap, Virtuoso } from '@ui-kit';
import { useTranslation, useRestoreVirtuosoInitialIndex, useCallback, useNavigate } from '@hooks';

import { ROUTES } from '@constants';
import { useGetNetworkConnectionBookmarks } from '@ui-modules/connections/hooks/useGetNetworkConnectionBookmarks';

import styles from './NetworkConnectionBookmarks.module.css';

import type { ItemContent } from 'react-virtuoso';
import type { TTNetworkConnectionBookmark } from '@typings';
import { textRegisterPluralize } from '@utils';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';

import BookmarkItem from '../BookmarkItem';

const NetworkConnectionBookmarks = ({ communityId }: INetworkConnectionBookmarksProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { data: network } = useCommunityQuery(communityId);

	const { data: bookmarks, fetchMore, isFetching } = useGetNetworkConnectionBookmarks(communityId);

	const RESTORE_KEY = `networkConnectionBookmarks-${communityId}`;
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } =
		useRestoreVirtuosoInitialIndex(RESTORE_KEY);

	const renderBookmarkItem = useCallback<ItemContent<TTNetworkConnectionBookmark, unknown>>(
		(_, bookmark) => {
			return bookmark ? (
				<BookmarkItem
					bookmark={bookmark}
					bookmarks={bookmarks}
					communityId={communityId}
					setInitialTopMostItemIndex={setInitialTopMostItemIndex}
				/>
			) : null;
		},
		[bookmarks],
	);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<span className={styles.header__title}>{t('My Bookmarks')}</span>
				<Button
					icon={<CloseIcon fill="#000000" height={10} width={10} />}
					iconPosition="left"
					title={'Close'}
					variant="small"
					onClick={() => navigate(ROUTES.networkTab(communityId, 'connections'))}
				/>
			</div>
			<Virtuoso<TTNetworkConnectionBookmark>
				className={styles.listContainer}
				components={{
					EmptyPlaceholder: () => (
						<div className={styles.emptyBlock}>
							{isFetching ? (
								<ActivityIndicator size="medium" type="fit" />
							) : (
								<span className={styles.emptyBlock__title}>
									{t(
										'No {{networkConnectionName}} have been bookmarked yet. {{networkConnectionName}} that you bookmark will be listed here.',
										{ networkConnectionName: textRegisterPluralize(network?.networkConnectionsName) },
									)}
								</span>
							)}
						</div>
					),
					Footer: () => <Gap gap={8} />,
				}}
				data={bookmarks}
				defaultItemHeight={137}
				endReached={fetchMore}
				initialTopMostItemIndex={initialTopMostItemIndex}
				itemContent={renderBookmarkItem}
				rangeChanged={onRangeChanged}
				totalCount={bookmarks?.length}
			/>
		</div>
	);
};

export interface INetworkConnectionBookmarksProps {
	communityId: string;
}

export default NetworkConnectionBookmarks;
