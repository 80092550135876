import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TTip, TTipContributor } from '@typings';

export const useTipContributorsQuery = (tipId: TTip['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TTipContributor[], Error>(
		reactQuery.queryKeys.tipContributors(tipId),
		async () => {
			// TODO put real API call
			await new Promise((resolve) => setTimeout(resolve, 500));
			return [
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Andrii Shevchenko',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl: 'https://cdn-icons-png.flaticon.com/512/6858/6858504.png',
					roles: ['ROLE_MEMBER'],
				},
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Zinadin Zidan',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl: 'https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png',
					roles: ['ROLE_MEMBER'],
				},
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Leo Messi',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl:
						'https://png.pngtree.com/png-vector/20230903/ourmid/pngtree-man-avatar-isolated-png-image_9935819.png',
					roles: ['ROLE_MEMBER'],
				},
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Dmitriy Nikolenko!',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl: 'https://chuckjones.com/wp-content/uploads/daffy-duck-06.png',
					roles: ['ROLE_MEMBER'],
				},
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Ivan Petrov!',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl: 'https://i.pinimg.com/originals/2b/73/10/2b7310faa95496489664af8dd90b70f3.png',
					roles: ['ROLE_MEMBER'],
				},
				{
					id: '2237c1a5-47e9-4d02-adec-6c209674fa73',
					fullName: 'Anton Shevchenko',
					profileId: '41d2a120-359a-49ad-8418-7e45458a270d',
					avatarUrl: 'https://chuckjones.com/wp-content/uploads/daffy-duck-06.png',
					roles: ['ROLE_MEMBER'],
				},
			] as TTipContributor[];
		},
		{
			staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
		},
	);
};
