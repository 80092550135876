import { useCallback } from 'react';
import { useEditOwnerTipMutation } from '@ui-modules/tipJar/hooks/useEditOwnerTipMutation';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TCarouselItem } from '@ui-modules/tipJar/components/TipsImageCarousel/TipsImageCarousel';
import type { TCreateTipForm, TPatchTipForm, TPatchTipImage } from '@schemas';

const useHandleRemoveImage = ({
	tipImages,
	tipId,
	onTipPatched,
}: IPatchTipFormMainProps & { tipImages: TPatchTipImage[] }) => {
	const { mutateAsync } = useEditOwnerTipMutation(String(tipId)); //

	const handleRemoveImage = useCallback(
		async (item: TCarouselItem) => {
			if (item.type === 'image') {
				const updatingData: TPatchTipForm = {
					images: tipImages.filter((image) => image.source !== 'user'),
				};
				if (tipId) {
					await mutateAsync(updatingData);
				}
				onTipPatched?.(updatingData);
			}
		},
		[tipId],
	);

	return handleRemoveImage;
};

export default useHandleRemoveImage;
