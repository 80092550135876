import { Page } from '../../components';
import {
	useTranslation,
	useRestoreVirtuosoInitialIndex,
	useEffect,
	useFloatingHeader,
	useEvent,
	useRef,
	useCallback,
	useNavigate,
	useMe,
	useService,
	useToggleLikeMutation,
	useBlockedAndBlockingUsers,
} from '@hooks';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import { ActivityIndicator, Virtuoso, Gap, EmptyStateMessage, When } from '@ui-kit';
import ReviewSolicitationWaiverWrapper from '@ui-modules/deals/components/ReviewSolicitationWaiverWrapper';
import AllDealsZeroState from '@ui-modules/deals/components/AllDealsZeroState';
import AllDealsZeroNetworks from '@ui-modules/deals/components/AllDealsZeroNetworks';
import DealsSelectWidget from '@ui-modules/deals/components/DealsSelectWidget';
import { useAllDealsQuery } from '@ui-modules/deals/hooks/useAllDealsQuery';
import type { TDealFilter, TDealWithReactions } from '@typings';
import DealPost from '@ui-modules/deals/components/DealPost';
import type { ItemContent, VirtuosoHandle } from 'react-virtuoso';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';

import { useDealAlertsMutation } from '@ui-modules/deals/hooks/useDealAlertsMutation';
import styles from './AllDealsPage.module.css';
import { camelCase, DealEntity, startCase } from '@utils';
import { useDealsSearch } from '@ui-modules/deals/hooks/useDealsSearch';
import { useGetDealSettings } from '@ui-modules/deals/hooks/useGetDealSettings';
import useAllDealFilterConfig from '@ui-modules/deals/hooks/useAllDealFilterConfigQuery';

const HEADER_PLACEHOLDER_ITEM_COUNT = 2; // to handle shifts in list indexes & scrolls.

const AllDealsPage = () => {
	const reactQuery = useService('ReactQueryService');
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	const { mutate: toggleAlerts } = useDealAlertsMutation();

	const { mutate: toggleLike } = useToggleLikeMutation({
		onSuccess: (updatedActivity) => reactQuery.refetchDealsAfterReactionsMutation(updatedActivity),
	});

	const { isMessageShown, isLoading: isWaiverLoading } = useSolicitationWaiver();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const excludeNotInterested = true;
	const {
		data: deals,
		filterIdWithUpdatedDate,
		fetchMore,
		isLoading: isDealsLoading,
		isFetching,
		isRefetching: areAllDealsRefetching,
		refetch,
		isFetched,
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		allDealFilters,
		isAllDealFilterLoading,
		isAllDealFilterFetching,
		hasDataByFilter,
	} = useAllDealsQuery(excludeNotInterested);
	const RESTORE_KEY = `allDeals-${filterIdWithUpdatedDate}-${JSON.stringify(sortOptions)}`;
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(
		RESTORE_KEY,
		HEADER_PLACEHOLDER_ITEM_COUNT,
	);

	const listRef = useRef<VirtuosoHandle>(null);

	const showMessage = isMessageShown && !isWaiverLoading;
	const showActivityIndicator =
		(isWaiverLoading && !deals.length) || (isDealsLoading && !showMessage && !isWaiverLoading);

	// here we have ALL asset classes available for user
	const { data: allDealFilterConfig, isFetched: filterConfigFetched } = useAllDealFilterConfig();
	const allAssetClassesTitle = allDealFilterConfig?.assetClasses
		?.map((item) => startCase(camelCase(item.name)))
		.join(', ');

	// here we format ONLY picked asset classes by user
	const {
		data: dealSettings,
		isLoading: isLoadingDealSettings,
		isRefetching: isRefetchingDealSettings,
	} = useGetDealSettings();
	const dealSettingsBlockForAllDeals = dealSettings?.find((deal) => deal.isFilterForSpecificAssetClass === false);
	// if we have picked ALL asset classes back-end returns []
	// if partially --- object of objects...
	const dealAssetClassesListForAllDeals =
		(dealSettingsBlockForAllDeals && Object.values(dealSettingsBlockForAllDeals?.dealAssetClasses)) || [];
	const filterTitleWithPickedAssetsByUser = dealAssetClassesListForAllDeals
		.map((item) => startCase(camelCase(item.name)))
		.join(', ');

	const filterTitle = dealAssetClassesListForAllDeals.length ? filterTitleWithPickedAssetsByUser : allAssetClassesTitle;

	const {
		searchResult,
		searchQuery,
		showSearchBar,
		showSearchResults,
		isSearchFetching,
		isSearchFetched,
		searchMore,
		setSearchQuery,
		setIsSearchFocused,
	} = useDealsSearch(null);
	const {
		floatingHeaderRef,
		floatingHeaderClassName,
		Placeholder,
		onScroll,
		itemsRendered,
		dataWithPlaceholders: dealsDataWithFloatingHeader,
		floatingHeaderHeight,
	} = useFloatingHeader<TDealWithReactions>(
		showSearchResults ? (searchResult as TDealWithReactions[]) : deals,
		initialTopMostItemIndex < HEADER_PLACEHOLDER_ITEM_COUNT,
		showSearchBar ? DealsSelectWidget.SEARCH_BAR_HEIGHT : undefined,
	);

	const renderDealItem = useEvent<ItemContent<TDealWithReactions, unknown>>((index, deal) => {
		if (index === 0) {
			return (
				<DealsSelectWidget
					filters={allDealFilters?.[0] as Partial<TDealFilter>}
					filterTitle={filterTitle as string}
					floatingHeaderClassName={deals?.length ? floatingHeaderClassName : undefined}
					floatingHeaderRef={floatingHeaderRef}
					isFiltersLoading={isAllDealFilterLoading || isLoadingDealSettings || isRefetchingDealSettings}
					searchQuery={searchQuery}
					selectedSortOption={selectedSortOption}
					showSearchBar={showSearchBar}
					sortDisabled={!hasDataByFilter} // show sort bar only when data by filter exists and there is no reloading with new sort options.
					sortOptions={sortOptions}
					title={t('All Deals')}
					onChangeFocus={setIsSearchFocused}
					onChangeSearchQuery={setSearchQuery}
					onSelectSortOption={setSelectedSortOption}
				/>
			);
		} else if (index === 1) {
			return <Placeholder />;
		}
		if (!deal || DealEntity.isBlocked(blockedAndBlockingUsers, deal))
			return (
				/* Used to resolve a Virtuoso list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko] */
				<Gap gap={1} />
			);
		return (
			<DealPost
				canSeePreview={DealEntity.canSeeReviewUpdates(deal, user)}
				deal={deal}
				highlightedText={searchQuery}
				likingDisabled={!('latestLikes' in deal)} // duck typing to ensure it is TDealWithReaction but not a TDeal as when searching.}
				restoreScrollRoute={selectedSortOption.field === 'updatedAt' ? RESTORE_KEY : ''}
				onClick={() => {
					const clickedDealIndex = dealsDataWithFloatingHeader?.indexOf(deal) || 0;
					setInitialTopMostItemIndex(clickedDealIndex);
				}}
				onLike={(liked) => toggleLike({ activity: deal, liked })}
				onTextCollapse={() =>
					listRef.current?.scrollToIndex({
						index: dealsDataWithFloatingHeader?.indexOf(deal) || 0,
						offset: -floatingHeaderHeight,
						behavior: 'smooth',
					})
				}
				onToggleAlerts={(data) => toggleAlerts({ ...data, dealId: deal.id })}
			/>
		);
	});
	const handleRefetch = useCallback(() => {
		if (!showActivityIndicator && !isFetched) {
			const timeoutId = setTimeout(() => {
				refetch();
			}, 500);

			return () => {
				clearTimeout(timeoutId); // Clear the timeout when the effect is cleaned up
			};
		}
	}, [showActivityIndicator, refetch, isFetched]);

	useEffect(handleRefetch, [handleRefetch]);

	return (
		<Page tabName="invest" title={t('Deals')}>
			<ReviewSolicitationWaiverWrapper
				activityIndicatorColor="white"
				loading={isAllDealFilterLoading && isAllDealFilterFetching} // if waiver 'isAllDealFilterLoading' stuck on 'true' that's why we take into account 'isFetching' as well. But 'isFetching' is false from start so only 'isFetching' causes flickering
			>
				{filterConfigFetched && !allDealFilterConfig?.assetClasses?.length ? (
					// display when no deals exits and user don't join any network.
					<AllDealsZeroNetworks onGoToInvestNetworks={() => navigate(ROUTES.networksType(INVEST_NETWORKS_TAB_SLUG))} />
				) : (
					<>
						<Virtuoso<TDealWithReactions>
							className={styles.allDealsPage__list}
							components={{
								Footer: () => {
									const loading = isDealsLoading || isFetching || isSearchFetching || areAllDealsRefetching;
									return (
										<>
											<ActivityIndicator
												className={styles.allDealsPage__activityIndicator}
												color="white"
												invisible={!loading} // not hide but make invisible to prevent calling Virtuoso.onScroll when list is fetching which causes issues with floating header (@see T21C-6861) [@DmitriyNikolenko]
												size="medium"
												type="fit"
											/>
											<When condition={!searchResult.length && isSearchFetched}>
												<EmptyStateMessage colorMode="white" hidden={false} text={t('Sorry, no deals were found.')} />
											</When>
											<Gap gap={8} />
										</>
									);
								},
							}}
							computeItemKey={(index, item) => `${index}_${item?.id}`}
							data={dealsDataWithFloatingHeader}
							defaultItemHeight={480}
							endReached={showSearchResults ? searchMore : fetchMore}
							initialTopMostItemIndex={initialTopMostItemIndex}
							itemContent={renderDealItem}
							itemsRendered={itemsRendered}
							rangeChanged={onRangeChanged}
							ref={listRef}
							topItemCount={1}
							totalCount={dealsDataWithFloatingHeader?.length}
							onScroll={onScroll}
						/>
					</>
				)}
				{!!allDealFilterConfig?.assetClasses?.length && !deals.length && !isDealsLoading && !showSearchBar ? (
					<>
						<Gap gap={64} />
						<AllDealsZeroState />
					</>
				) : null}
			</ReviewSolicitationWaiverWrapper>
		</Page>
	);
};

export default AllDealsPage;
