import PatchTipForm from '@ui-modules/tipJar/components/PatchTipForm';
import { ActivityIndicator } from '@ui-kit';
import React, { useRef } from 'react';
import styles from './ImageEditTip.module.css';
import { useTranslation } from 'react-i18next';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import type { FormikProps } from 'formik';
import { compact, noop } from '@utils';
import clsx from 'clsx';
import type { TCreateTipForm } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TPatchTipForm } from '@schemas';

const ImageEditTip = ({ tipId, initialValue = [], onTipPatched }: IImageEditTip) => {
	const { t } = useTranslation();
	const formikRef = useRef<FormikProps<Pick<TPatchTipForm, 'images'>>>(null);

	const { getInputProps } = usePickUpFile((file) => {
		formikRef.current?.setFieldValue(
			'images',
			compact([
				(formikRef.current.values.images ?? []).find((image) => image.source === 'ai'),
				{
					source: 'user',
					url: URL.createObjectURL(file),
				},
			]),
		);
		formikRef.current?.setFieldTouched('images', true);
		formikRef.current?.submitForm();
	});
	return (
		<PatchTipForm<'images'>
			formikRef={formikRef}
			initialValues={{ images: initialValue } as TPatchTipForm} // TODO fix this later
			propertyName="images"
			tipId={tipId}
			onCancel={noop}
			onTipPatched={onTipPatched}
		>
			{(formikProps) => {
				return (
					<div className={styles.imageEditTip}>
						{formikProps.isSubmitting ? (
							<div className={styles.imageEditTip__title}>
								<ActivityIndicator color="#000" size="extraSmall" />
								<span>{`${t('Uploading')} ...`}</span>
							</div>
						) : (
							<div className={styles.imageEditTip__title}>
								<span>{t('Attach')}</span>
								<span>{t('Image')}</span>
							</div>
						)}

						<label
							className={clsx(
								styles.imageEditTip__label,
								formikProps.isSubmitting && styles.imageEditTip__labelDisabled,
							)}
							htmlFor="fileInput"
						>
							<input
								className={styles.imageEditTip__input}
								disabled={!formikProps.isValid || formikProps.isSubmitting}
								id="fileInput"
								type="file"
								onChange={(photoUrl) => {
									formikProps.setFieldValue(
										'images',
										compact([
											(formikProps.values.images ?? []).find((image) => image.source === 'ai'),
											{
												source: 'user',
												url: photoUrl,
											},
										]),
									);
									formikProps.setFieldTouched('images', true);
								}}
								{...getInputProps()}
							/>
							<span className={styles.imageEditTip__selectFileText}>{t('Select a File')}</span>
						</label>
					</div>
				);
			}}
		</PatchTipForm>
	);
};

interface IImageEditTip extends IPatchTipFormMainProps {
	initialValue: TCreateTipForm['images'];
}

export default ImageEditTip;
