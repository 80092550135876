import React from 'react';
import { useTranslation } from '@hooks';
import styles from './SecondPageBody.module.css';
import TipShareImage from '../../assets/tip-share-insights-slide.png';

const SecondPageBody = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Share recommendations  with your Group and others')}</p>
			<div className={styles.wrapper__imageWrapper}>
				<img alt="TipShareImage" loading="lazy" src={TipShareImage} />
			</div>
		</div>
	);
};

export default SecondPageBody;
