import DealFilters from '@ui-modules/deals/components/DealFilters';
import DealSortWidget from '@ui-modules/deals/components/DealSortWidget';
import DealsTabHeader from '@ui-modules/deals/components/DealsTabHeader';
import { useTranslation } from '@hooks';
import styles from './DealsSelectWidget.module.css';
import type { IDealFiltersProps } from '@ui-modules/deals/components/DealFilters';
import type { IDealSortWidgetProps } from '@ui-modules/deals/components/DealSortWidget';
import type { IDealsTabHeaderProps } from '@ui-modules/deals/components/DealsTabHeader';
import type { LegacyRef, HTMLAttributes } from 'react';

const DealsSelectWidget = ({
	title,
	floatingHeaderRef,
	floatingHeaderClassName,
	isFiltersLoading,
	searchQuery,
	showSearchBar,
	filters,
	filterTitle,
	selectedSortOption,
	sortOptions,
	communityId,
	sortDisabled,
	onChangeSearchQuery,
	onSelectSortOption,
	onAddNewDeal,
	onChangeFocus,
}: IDealsSelectWidgetProps) => {
	const { t } = useTranslation();

	const hideFiltersStyle = { height: showSearchBar ? 0 : 'auto', overflow: 'hidden' };

	return (
		<div className={styles.dealsSelectWidget__floatingHeaderContainer}>
			<div className={floatingHeaderClassName} ref={floatingHeaderRef}>
				<DealsTabHeader
					placeholder={t('Search deals')}
					searchQuery={searchQuery}
					title={title}
					onAddNewDeal={onAddNewDeal}
					onChangeFocus={onChangeFocus}
					onChangeSearchQuery={onChangeSearchQuery}
				/>
				<div style={hideFiltersStyle}>
					<DealFilters
						communityId={communityId}
						filters={filters}
						filterTitle={filterTitle}
						isLoading={isFiltersLoading}
						key={JSON.stringify(filters)}
						title={title}
					/>
					<div className={styles.dealsSelectWidget__sortBar}>
						<DealSortWidget
							selectedOption={selectedSortOption}
							sortDisabled={sortDisabled}
							sortOptions={sortOptions}
							title={t('Sort By')}
							onSelect={onSelectSortOption}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export interface IDealsSelectWidgetProps {
	title: string;
	floatingHeaderRef: LegacyRef<HTMLDivElement>;
	floatingHeaderClassName: HTMLAttributes<HTMLDivElement>['className'];
	isFiltersLoading: boolean;
	showSearchBar: boolean;
	searchQuery: IDealsTabHeaderProps['searchQuery'];
	filters: IDealFiltersProps['filters'];
	filterTitle: IDealFiltersProps['filterTitle'];
	selectedSortOption: IDealSortWidgetProps['selectedOption'];
	sortOptions: IDealSortWidgetProps['sortOptions'];
	sortDisabled: IDealSortWidgetProps['sortDisabled'];
	communityId?: IDealFiltersProps['communityId'];
	onChangeSearchQuery: IDealsTabHeaderProps['onChangeSearchQuery'];
	onChangeFocus: IDealsTabHeaderProps['onChangeFocus'];
	onSelectSortOption: IDealSortWidgetProps['onSelect'];
	onAddNewDeal?: IDealsTabHeaderProps['onAddNewDeal'];
}

DealsSelectWidget.SEARCH_BAR_HEIGHT = DealsTabHeader.height;
export default DealsSelectWidget;
