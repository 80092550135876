import { useState } from '@hooks';
import { noop } from '@utils';
import styles from './TipScreenHeader.module.css';
import InputEditTip from '@ui-modules/tipJar/components/InputEditTip';
import { useTranslation } from 'react-i18next';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';

const TipScreenHeader = ({ tipId, title, isTipEditable = false, onTipPatched }: IScreenHeaderProps) => {
	const { t } = useTranslation();
	const [isEditing, setIsEditing] = useState(false);

	const toggleEditing = () => setIsEditing((prev) => !prev);

	return (
		<header className={styles.tipScreenHeader__header}>
			<div className={styles.tipScreenHeader__title}>
				<h4 className={styles.tipScreenHeader__secondaryTitle}>{t('Title')}</h4>
				{isTipEditable && !isEditing ? (
					<span className={styles.tipScreenHeader__edit} onClick={toggleEditing} onKeyDown={noop}>
						{`(${t('Edit')})`}
					</span>
				) : null}
				{isEditing ? (
					<InputEditTip<'title'>
						initialValue={title}
						propertyName="title"
						tipId={tipId}
						onCancel={toggleEditing}
						onTipPatched={(formValues) => {
							toggleEditing();
							onTipPatched?.(formValues);
						}}
					/>
				) : (
					<h2>{title}</h2>
				)}
			</div>
		</header>
	);
};

export interface IScreenHeaderProps extends IPatchTipFormMainProps {
	title: string;
	isTipEditable?: boolean;
}

export default TipScreenHeader;
