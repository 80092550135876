import { Button, type IButtonProps } from '@ui-kit';
import styles from './TipFormsSubmitButton.module.css';

/** The same outline Button but with extended paddings and positioned as flex-end.
 *  Created to DRY the code and avoid repeating the same styles in every tip's form.
 */
const TipFormsSubmitButton = (buttonProps: IButtonProps) => {
	return (
		<div className={styles.tipFormsSubmitButton__wrapper}>
			<Button
				block="fit"
				className={styles.tipFormsSubmitButton__button}
				type="outline"
				variant="medium"
				{...buttonProps}
			/>
		</div>
	);
};

export default TipFormsSubmitButton;
