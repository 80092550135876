import { useTranslation, useMe, useState } from '@hooks';
import type { TCommunity } from '@typings';
import { RadioGroupInput, FormSection, InputErrorMessage, Gap } from '@ui-kit';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { clsx, NetworkConnectionEntity } from '@utils';
import type { FormikProps } from 'formik';
import type { IAutocompleteSearchUser } from '../../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import UserSearchAutocomplete from '../../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import {
	getInitialCreatorFormFieldValue,
	getPostingOwnershipOptions,
	getInitialPostingOwnership,
} from '../../ConnectionForm.utils';
import styles from './UserSection.module.css';
import type { TPostingOwnership } from '../../ConnectionForm.utils';

const UserSection = ({ isEdit, formProps, networkConnectionName, communityId }: IUserSectionProps) => {
	const { t } = useTranslation();
	const { user } = useMe();

	const [selectedUser, setSelectedUser] = useState<IAutocompleteSearchUser | null>(null);
	const [postingOwnership, setPostingOwnership] = useState(() => getInitialPostingOwnership(user));
	const { values, errors, setFieldError, setFieldValue } = formProps;
	const { data: memberRelations = [] } = useNetworkConnectionMemberRelationsQuery(communityId, {
		select: (relations) => relations.map((relation) => ({ label: relation.title, value: relation.id })),
	});
	const [ownershipOptions] = useState(() => getPostingOwnershipOptions(t));

	const onSelectPostOwnershipType = (newPostingOwnershipType: TPostingOwnership) => {
		setSelectedUser(null);
		setFieldValue(connectionFormFields.CREATOR, getInitialCreatorFormFieldValue(newPostingOwnershipType, user));
		setPostingOwnership(newPostingOwnershipType);
	};
	const onSelectUser = (selectedUser: IAutocompleteSearchUser) => {
		setSelectedUser(selectedUser);
		if (selectedUser) {
			setFieldValue(connectionFormFields.CREATOR, { iriId: selectedUser.id, name: selectedUser.fullName });
		} else {
			setFieldValue(connectionFormFields.CREATOR, getInitialCreatorFormFieldValue(postingOwnership, user));
		}
		setFieldError(connectionFormFields.CREATOR, undefined);
	};

	return (
		<FormSection>
			{NetworkConnectionEntity.canCreateOnBehalfOfOtherMember(user) && !isEdit && (
				<>
					<RadioGroupInput<TPostingOwnership>
						backgroundColor="white"
						label={t('Owner')}
						labelType="bold"
						options={ownershipOptions}
						value={postingOwnership}
						onChange={onSelectPostOwnershipType}
					/>
					{postingOwnership === 'on_behalf_of' && (
						<div className={clsx(styles.userSearchAutocomplete, errors.creator?.iriId && styles.input_error)}>
							<UserSearchAutocomplete
								communityId={communityId}
								defaultValue={
									isEdit
										? {
												fullName: values.creator.name,
												role: '',
												avatar: '',
												id: values.creator.iriId,
												enabled: true,
											}
										: selectedUser
								}
								disabled={!!isEdit}
								indicateDisabledDealsUsers
								indicateNetworkUsers
								isClearable
								isErrored={!!errors.creator?.iriId}
								placeholder={t('Type a name')}
								showSearchIcon
								onSelect={onSelectUser}
							/>
							{errors.creator?.iriId ? <InputErrorMessage text={errors.creator.iriId} /> : <Gap gap={16} />}
						</div>
					)}
					<Gap gap={16} />
				</>
			)}

			<RadioGroupInput
				backgroundColor="white"
				errorMessage={errors.relation}
				label={
					postingOwnership === 'on_behalf_of'
						? t("{{name}}'s relation to this {{networkConnectionName}}", {
								networkConnectionName,
								name: values.creator?.name || t('User'),
							})
						: postingOwnership === 'as_an_owner'
							? t('Your ({{userName}}) relation to this {{networkConnectionName}}', {
									userName: values?.creator?.name,
									networkConnectionName,
								})
							: ''
				}
				labelType="bold"
				options={memberRelations}
				value={values.relation}
				onChange={(value) => {
					setFieldValue(connectionFormFields.RELATION, value);
					setFieldError(connectionFormFields.RELATION, undefined);
				}}
			/>
		</FormSection>
	);
};

interface IUserSectionProps {
	isEdit?: boolean;
	formProps: FormikProps<TNetworkConnectionFormValues>;
	networkConnectionName: string;
	communityId: TCommunity['id'];
}

export default UserSection;
