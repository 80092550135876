import { useService, useQuery } from '@hooks';
import { makeTipReviewMock } from '@mocks';
import { dayjs } from '@utils';
import type { TTipReview, TTipReviewWithReaction, TTip } from '@typings';

export const useTipReviewQuery = (tipId: TTip['id'], tipReviewId: TTipReview['id'] | undefined | null) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return useQuery<TTipReviewWithReaction, Error>(
		reactQuery.queryKeys.tipReview(tipId, String(tipReviewId)),
		async () => {
			// TODO put real API call
			await new Promise((resolve) => setTimeout(resolve, 1000));
			return makeTipReviewMock({
				tipId,
				...(tipReviewId ? { id: tipReviewId } : null),
			});
		},
		{
			enabled: !!tipReviewId,
			staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
		},
	);
};
