import { useService, useQuery } from '@hooks';
import type { TAddressBookRecord, TUserProfileRecord, TRole, TMediaObjectCompact, TProfile } from '@typings';

/** Returns all users on the app and maps data following TUserProfileRecord type. */
export const useAddressBookQuery = () => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery(queryKeys.getAddressBook(), () => api.profile.getAddressBook(), {
		staleTime: Infinity,
		keepPreviousData: true,
		select: (users) => users.map((record) => adaptAddressBookRecordToUserProfileRecord(record)),
	});
};

// Convert helpers.

const adaptAddressBookRecordToUserProfileRecord = (record: TAddressBookRecord): TUserProfileRecord => ({
	id: record.user_id,
	roles: [convertProfileTypeToRole(record.type)],
	firstName: record.first_name,
	lastName: record.last_name,
	communitiesForBadge: record.communities_for_badge,
	slug: record.slug,
	avatar: record.avatar
		? ({
				contentUrl: record.avatar,
			} as TMediaObjectCompact)
		: null,
	profile: {
		id: record.profile_id,
		'@type': record.type,
		title: record.title,
	},
	contactId: record.contact_id,
});

const convertProfileTypeToRole = (profileType: TProfile['@type']): TRole =>
	profileType.replace('Profile', 'ROLE_').toUpperCase() as TRole;
