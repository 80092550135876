import { LampIcon } from '@ui-kit';
import styles from './EmptyTipList.module.css';

const EmptyTipList = ({ message }: IEmptyTipListProps) => {
	return (
		<div className={styles.emptyTipList}>
			<div className={styles.emptyTipList__inner}>
				<div className={styles.emptyTipList__icon}>
					<LampIcon fill={'#C8C8C8'} height={100} width={125} />
				</div>
				<span className={styles.emptyTipList__title}>{message}</span>
			</div>
		</div>
	);
};

interface IEmptyTipListProps {
	message: string;
}

export default EmptyTipList;
