import { useState, useTranslation } from '@hooks';
import type { TDealPreviewStatus } from '@typings';
import { Toast } from '@ui-kit';
import styles from './ThirdPartyDealForm.module.css';

export const DealRejectionStatus = ({ previewDealStatus, dealCreator }: IDealRejectionStatusProps) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(true);

	if (!visible || previewDealStatus?.status !== 'rejected') return null;
	return (
		<Toast
			className={styles.toastMessage}
			shouldShowClose
			subtitle={
				previewDealStatus?.rejectionReason
					? `"${previewDealStatus?.rejectionReason}"`
					: t('{{creatorName}} has rejected your proposed changes.', { creatorName: dealCreator })
			}
			title={
				previewDealStatus?.rejectionReason
					? t('{{creatorName}} has rejected your proposed changes and left a message:', {
							creatorName: dealCreator,
						})
					: t('Rejected')
			}
			type="error"
			onClose={() => setVisible(false)}
		/>
	);
};

interface IDealRejectionStatusProps {
	previewDealStatus: TDealPreviewStatus | undefined;
	dealCreator: string;
}
