import { Gap, InputErrorMessage, RadioGroupInput } from '@ui-kit';
import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useTranslation, useMe, useCallback, useState } from '@hooks';
import { clsx, DealEntity, extractIdFromIri } from '@utils';
import { dealFormFields } from '../DealForm.schema';
import type { IAutocompleteSearchUser } from '../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import UserSearchAutocomplete from '../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import type { TCommunity } from '@typings';
import styles from './DealInitiatorSection.module.css';

export const DealInitiatorSection = ({
	formProps,
	isUserSearchShown,
	isEdit,
	communityId,
}: IDealInitiatorSectionProps) => {
	const { t } = useTranslation();
	const { user, fullName: myFullName } = useMe();
	const [selectedUser, setSelectedUser] = useState<IAutocompleteSearchUser | null>(null);

	const {
		setFieldValue,
		setErrors,
		values: { creator, creatorOption },
		errors,
	} = formProps;

	const clearErrors = useCallback(() => {
		setErrors({
			dealAssetClass: undefined,
			community: undefined,
			dealSubClass: undefined,
			dealType: undefined,
			// Clean up template errors while template schema is changing
			detailsTemplate: undefined,
			financialDetailsTemplate: undefined,
		});
	}, [setErrors]);

	const clearDependentValues = useCallback(() => {
		if (!communityId) {
			setFieldValue(dealFormFields.ASSET_CLASS, undefined);
			setFieldValue(dealFormFields.COMMUNITY, undefined);
		}
		setFieldValue(dealFormFields.SUB_CLASS, undefined);
		setFieldValue(dealFormFields.TYPE, undefined);
		// Clean up template values while template schema is changing
		setFieldValue(dealFormFields.DYNAMIC_DETAILS, {});
		setFieldValue(dealFormFields.DYNAMIC_FINANCIAL_DETAILS, {});
	}, [setFieldValue]);

	const onSelectUser = useCallback(
		(selectedUser: IAutocompleteSearchUser) => {
			setSelectedUser(selectedUser);
			if (selectedUser) {
				setFieldValue(dealFormFields.CREATOR, {
					iriId: selectedUser.id,
					name: selectedUser.fullName,
				});
			} else {
				setFieldValue(
					dealFormFields.CREATOR,
					DealEntity.canCreateOnBehalfOf(user)
						? undefined
						: {
								iriId: user['@id'],
								name: myFullName,
							},
				);
			}
			clearErrors();
			clearDependentValues();
		},
		[setFieldValue, clearErrors, clearDependentValues, myFullName, user],
	);

	return (
		<DealFormSection>
			{isUserSearchShown && (
				<div className={clsx(errors.creator?.iriId && styles.input_error)}>
					<UserSearchAutocomplete
						communityId={communityId ? extractIdFromIri(communityId) : undefined}
						defaultValue={selectedUser}
						disabled={!!isEdit}
						indicateDisabledDealsUsers
						isClearable
						isErrored={!!errors.creator?.iriId}
						label={t('Post on behalf of...')}
						placeholder={t('Type a name')}
						showSearchIcon
						onSelect={onSelectUser}
					/>
					{errors.creator?.iriId ? <InputErrorMessage text={errors.creator.iriId} /> : <Gap gap={24} />}
				</div>
			)}
			<RadioGroupInput
				backgroundColor="white"
				disabled={isEdit}
				label={t('Who has the details for this Deal?')}
				labelType="bold"
				options={[
					{
						label: t('A third-party individual'),
						value: 'third-party',
						subLabel: t('Input the details you have, then send it for completion.'),
					},
					{
						label:
							user['@id'] === creator?.iriId
								? t('Me, {{fullName}}', { fullName: myFullName })
								: creator?.name || t('Selected user'),
						value: 'me',
						subLabel: t('Input the details and post this Deal yourself.'),
					},
				]}
				value={creatorOption || 'me'}
				onChange={(value) => setFieldValue(dealFormFields.CREATOR_OPTION, value || 'me')}
			/>
		</DealFormSection>
	);
};

interface IDealInitiatorSectionProps extends IDealFormSectionSectionProps {
	isUserSearchShown: boolean;
	communityId?: TCommunity['id'];
}
