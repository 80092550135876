import { Navigate } from 'react-router-dom';
import { useService } from '@hooks';
import { useRefreshTokenRefetchingQuery } from '@ui-modules/auth/hooks/useRefreshTokenRefetchingQuery';
import { REDIRECT_TO_PARAM_NAME, ROUTES } from '@constants';
import type { PropsWithChildren } from 'react';

const CheckAuthWrapper = ({ children }: PropsWithChildren) => {
	const redux = useService('ReduxService');
	const accessToken = redux.useAppSelector((state) => state.auth.accessToken);

	useRefreshTokenRefetchingQuery({
		suspense: true,
		notifyOnChangeProps: [],
	});

	if (!accessToken) {
		const redirectToParam = window.location.pathname;
		return <Navigate replace to={ROUTES.signIn({ [REDIRECT_TO_PARAM_NAME]: redirectToParam })} />;
	}

	const redirectToParam = new URLSearchParams(window.location.search).get(REDIRECT_TO_PARAM_NAME);
	if (redirectToParam) {
		return <Navigate to={redirectToParam} />;
	}

	return <>{children}</>;
};

export default CheckAuthWrapper;
