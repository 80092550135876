import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useTranslation } from '@hooks';
import { Input, Gap, PhoneInput } from '@ui-kit';
import { dealFormFields } from '../DealForm.schema';
import styles from './sections.module.css';

export const DealContactSection = ({ formProps, title, hideHints, disabled }: IDealContactSectionProps) => {
	const { t } = useTranslation();
	const { errors, values, setFieldValue, setFieldError } = formProps;

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	return (
		<DealFormSection title={title || t('Deal Contact')}>
			{!hideHints && (
				<>
					<p className={styles.dealContactHint}>
						{t(
							'The person listed as the primary contact for this Deal. This may be yourself, or a 3rd party. (Visible to Members)',
						)}
					</p>
					<Gap gap={24} />
				</>
			)}
			<Input
				disabled={disabled}
				errorMessage={errors.contactName}
				label={t('Name')}
				placeholder={t('e.g. {{example}}', { example: 'Pat Smith or Smith Holdings' })}
				value={values.contactName}
				onChange={(e) => handleChangeField(dealFormFields.CONTACT_NAME, e.target.value)}
			/>
			<PhoneInput
				disabled={disabled}
				errorMessage={errors.contactPhone}
				label={t('Phone')}
				placeholder={t('e.g. {{example}}', { example: '(123) 456-7890' })}
				value={values.contactPhone}
				onChange={(value: string) => handleChangeField(dealFormFields.CONTACT_PHONE, value || '')}
			/>
			<Input
				autoCapitalize="none"
				disabled={disabled}
				errorMessage={errors.contactEmail}
				errorMessagePosition={errors.contactEmail ? 'left' : 'none'}
				keepMarginBottom={false}
				label={t('Email')}
				placeholder={t('e.g. {{example}}', { example: 'pat.smith@smith.com' })}
				type="email"
				value={values.contactEmail}
				onChange={(e) => handleChangeField(dealFormFields.CONTACT_EMAIL, e.target.value)}
			/>
			{!errors.contactEmail && !hideHints && (
				<>
					<Gap gap={4} />
					<span className={styles.contactEmailHint}>
						{t('The email address where we will send notifications of interest in this Deal.')}
					</span>
				</>
			)}
		</DealFormSection>
	);
};

interface IDealContactSectionProps extends IDealFormSectionSectionProps {
	hideHints?: boolean;
	disabled?: boolean;
}
