import { useService } from '@services';
import { useQuery } from '@tanstack/react-query';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import type { TDealFilterAllConfig } from '@typings';
import type { AxiosError } from 'axios';
import { dayjs } from '@utils';

const useAllDealFilterConfig = (enabled = true) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();

	const query = useQuery<TDealFilterAllConfig, AxiosError>(
		reactQuery.queryKeys.getAllDealFilterConfig(),
		async () => api.invest.getAllDealConfigs(),
		{
			enabled: enabled && !isWaiverLoading && !isMessageShown,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
			staleTime: dayjs.duration(5, 'minute').asMilliseconds(),
		},
	);

	return {
		...query,
	};
};

export default useAllDealFilterConfig;
