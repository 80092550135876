import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import CreateTipForm from '@ui-modules/tipJar/components/CreateTipForm';
import { useTranslation, useNavigate, useRef } from '@hooks';
import { useTipCategoriesQuery } from '@ui-modules/tipJar/hooks/useTipCategoriesQuery';
import { useTipFormSearchParams } from '@ui-modules/tipJar/hooks/useTipFormSearchParams';
import { ROUTES } from '@constants';
import type { FormikProps } from 'formik';
import type { TCreateTipForm } from '@schemas';
import styles from './CreateTipPage.module.css';

const CreateTipPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipFormParamsValidationError, tipFormValues] = useTipFormSearchParams();
	const formRef = useRef<FormikProps<TCreateTipForm>>(null);

	const tipCategoriesQuery = useTipCategoriesQuery();

	return (
		<Page title={t('Create Tip')}>
			{tipCategoriesQuery.isLoading ? (
				<ActivityIndicator type="fit" />
			) : tipCategoriesQuery.isError || tipFormParamsValidationError ? (
				<EmptyStateMessage
					text={tipCategoriesQuery.error?.message || tipFormParamsValidationError?.message || t('Something went wrong')}
				/>
			) : (
				<CreateTipForm
					initialValues={tipFormValues}
					ref={formRef}
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.createTip()]}
							formProps={formProps}
							headerTitle={t('Create Tip')}
							isSaveButtonHidden
							scrollEnabled
						>
							<div className={styles.createTipPage}>
								<p className={styles.createTipPage__infoText}>{t('All fields required unless marked “optional”')}</p>
								<h3>{t('Tip Details')}</h3>
								<p className={styles.createTipPage__subtitle}>
									{t('We pre-filled the data you entered during previous steps. ')}
								</p>
								{children}
								<Gap gap={24} />
							</div>
						</FormPageLayout>
					)}
					tipCategories={tipCategoriesQuery.data}
					onSubmit={(values) =>
						navigate(ROUTES.createTipReview(values), {
							replace: true,
						})
					}
				/>
			)}
		</Page>
	);
};

export interface ICreateTipPageParams extends Record<string, string | undefined> {
	id: string;
}

export default CreateTipPage;
