import { dayjs } from '@utils';
import styles from './TipDetails.module.css';
import TipContributors from '@ui-modules/tipJar/components/TipContributors';
import StarsRating from '@ui-kit/components/StarsRating';
import { useTranslation } from 'react-i18next';
import type { TTip, TTipShare } from '@typings';
import { Button, RightTipsIcon, When } from '@ui-kit';
import TipsImageCarousel from '@ui-modules/tipJar/components/TipsImageCarousel';
import clsx from 'clsx';
import TipCollapsibleTextSection from '@ui-modules/tipJar/components/TipCollapsibleTextSection';
import useTipCarouselItems from '@ui-modules/tipJar/hooks/useTipCarouselItems';
import type { TCarouselItem } from '@ui-modules/tipJar/components/TipsImageCarousel/TipsImageCarousel';
import useHandleRemoveImage from '@ui-modules/tipJar/hooks/useHandleRemoveImage';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';
import type { TCreateTipForm } from '@schemas';

const TipDetails = ({ tip, tipShare, isTipEditable = false, onTipPatched }: ITipDetailProps) => {
	const { t } = useTranslation();

	const openUrl = (url: string) => window.open(url, 'blank');
	const openTipLink = () => tip.link && openUrl(String(tip.link));
	const tipId = 'id' in tip ? tip.id : undefined;

	const tipCarouselItems = useTipCarouselItems({
		tipId,
		tipImages: tip.images ?? [],
		isTipEditable,
		onTipPatched,
	});
	const handleRemoveImage = useHandleRemoveImage({ tipImages: tip.images, tipId, onTipPatched });

	return (
		<div className={styles.tipDetails}>
			<div className={styles.tipDetails__info}>
				<div className={styles.tipDetails__containerInner}>
					<div>
						<div className={styles.tipDetails__titleContainer}>
							<h3 className={styles.tipDetails__title}>{t('Category')}</h3>
							<span>{tip.categories.join(', ')}</span>
						</div>
						<div className={styles.tipDetails__titleContainer}>
							<h3 className={styles.tipDetails__title}>{t('Post Date')}</h3>
							<span>{'createdAt' in tip ? dayjs(tip.createdAt).format('D MMM YYYY') : <i>{t('Pending')}</i>}</span>
						</div>
					</div>
					<div className={styles.tipDetails__reviewsContainer}>
						{'id' in tip && (
							<div className={styles.tipDetails__titleContainer}>
								<TipContributors tipId={tip.id} />
							</div>
						)}
						<div className={styles.tipDetails__memberRating}>
							<h3 className={styles.tipDetails__title}>{t('Member Rating')}</h3>
							<StarsRating
								label={t('{{reviewsCount}} reviews', {
									reviewsCount: tipShare?.reviewsCount ?? ('reviewsCount' in tip ? tip.reviewsCount : 0),
								})}
								labelHref={'#review'}
								rating={tipShare?.rating || ('rating' in tip ? tip.rating : 0)}
							/>
						</div>
					</div>
				</div>
				<div className={clsx(styles.tipDetails__titleContainer, styles.tipDetails__description)}>
					<TipCollapsibleTextSection
						collapsedTitle={t('Read More')}
						expandedTitle={t('Show Less')}
						isTextButtonLight={true}
						isTipEditable={isTipEditable}
						text={String(tip.description)}
						tipId={tipId}
						title={t('Description')}
						onTipPatched={onTipPatched}
					/>
				</div>
				<When condition={!!tip?.link}>
					{'linkLabel' in tip ? (
						<Button
							className={styles.tipDetails__viewButton}
							icon={<RightTipsIcon />}
							iconPosition="right"
							title={t('View on {{source}}', { source: tip.linkLabel })}
							titleClassName={styles.tipDetails__viewButtonTitle}
							type="outline"
							variant="medium"
							onClick={openTipLink}
						/>
					) : (
						<div className={styles.tipDetails__titleContainer}>
							<h3 className={styles.tipDetails__title}>{t('Action link')}</h3>
							<a href={tip.link} rel="noreferrer" target="_blank">
								{tip.link}
							</a>
						</div>
					)}
				</When>
			</div>
			<div className={styles.tipDetails__imageContainer}>
				{tipCarouselItems.length ? (
					<TipsImageCarousel
						isEditable={isTipEditable}
						items={tipCarouselItems as TCarouselItem[]}
						onItemRemove={(item) => handleRemoveImage(item)}
					/>
				) : null}
			</div>
		</div>
	);
};

interface ITipDetailProps extends Pick<IPatchTipFormMainProps, 'onTipPatched'> {
	tip: TTip | TCreateTipForm;
	tipShare?: TTipShare;
	isTipEditable?: boolean;
}

export default TipDetails;
