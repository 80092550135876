import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { TTip, TTipReviewData } from '@typings';
import type { MutateOptions } from '@tanstack/react-query';
import type { TTipReviewWithSharingForm } from '@schemas';

export const useSendTipReviewMutation = (options?: MutateOptions<void, Error, TSendTipMutationPayload>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<void, Error, TSendTipMutationPayload>(
		['sendTipReview'],
		async ({ tipId, ...tipReviewForm }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1500));
		},
		{
			...options,
			onSuccess: (response, variables, context) => {
				showSuccess({ title: t('Review added') });
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipReviews(variables.tipId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipCommunityReviews(variables.tipId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipCalendarItemReviews(variables.tipId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tip(variables.tipId));
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TSendTipMutationPayload = (TTipReviewData | TTipReviewWithSharingForm) & { tipId: TTip['id'] };
