import { PHONE_LABELS } from '@constants';
import { useTranslation } from '@hooks';
import type { TEditContactCard } from '@schemas';
import { emptyPhoneContactValue } from '@schemas';
import type { TPhoneContact, TPhoneContactType } from '@typings';
import { Checkbox, FieldArray, PhoneInput } from '@ui-kit';
import { isUndefined, translatePhoneLabelToTitle } from '@utils';
import type { FormikProps } from 'formik';
import ContactCard from '../ContactCard';
import LabeledArrayField from '../LabeledArrayField';
import type { TBaseOption } from '../LabeledArrayField/LabeledArrayField';
import styles from './PhonesForm.module.css';

const isMobile = (option: TBaseOption) => option.type === 'mobile';
const PHONE_REGEXP = /[^0-9+*#]+/g;

const PhonesForm = ({ formProps }: IPhonesFormProps) => {
	const { t } = useTranslation();
	const { values, setFieldValue, errors, setFieldError } = formProps;

	return (
		<ContactCard secondaryTitle={t('Visible to all members')} title={t('Phone')}>
			<FieldArray
				name="phones"
				render={(arrayHelpers) => (
					<LabeledArrayField
						addButtonTitle={t('Add Phone')}
						arrayHelpers={arrayHelpers}
						canRemoveOption={(option) => !isMobile(option)}
						emptyValue={emptyPhoneContactValue}
						getFieldError={(index: number) =>
							(errors?.phones?.[index] as TPhoneContact)?.tel
								? t('The phone number field is empty or invalid. Please check your input.')
								: ''
						}
						modalTitle={t('Phone Label')}
						options={values.phones}
						translateType={(type) => translatePhoneLabelToTitle(type as TPhoneContactType, t)}
						types={PHONE_LABELS}
						onOptionRemoved={() => setFieldError(`phones`, undefined)}
					>
						{(option, index) => {
							const visibleFormValue = values.phones[index].visibleToAll;
							const checkedValue = isUndefined(visibleFormValue) ? true : !!visibleFormValue;

							return (
								<div className={styles.inputRow} key={option.type}>
									<PhoneInput
										className={styles.phoneInput}
										keepMarginBottom={true}
										value={values.phones[index].tel}
										onChange={(value) => {
											setFieldValue(`phones[${index}].tel`, value);
											setFieldError(`phones`, undefined);
										}}
									/>
									<Checkbox
										value={checkedValue}
										onChange={(checked: boolean) => setFieldValue(`phones[${index}].visibleToAll`, checked)}
									/>
								</div>
							);
						}}
					</LabeledArrayField>
				)}
			/>
		</ContactCard>
	);
};

export interface IPhonesFormProps {
	formProps: FormikProps<TEditContactCard>;
}

export default PhonesForm;
