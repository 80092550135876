import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import TipReviewForm from '@ui-modules/tipJar/components/TipReviewForm';
import TipScreenHeader from '@ui-modules/tipJar/components/TipScreenHeader';
import TipDetails from '@ui-modules/tipJar/components/TipDetails';
import { useTranslation, useNavigate, useParams, useLayoutEffect, useSetState, useSearchParams } from '@hooks';
import { useTipCreateDataSearchParams } from '@ui-modules/tipJar/hooks/useTipCreateDataSearchParams';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useSendTipReviewMutation } from '@ui-modules/tipJar/hooks/useSendTipReviewMutation';
import { useNextGroupMeetingsQuery } from '@ui-modules/events/hooks/useNextGroupMeetingsQuery';
import { useCreateTipMutation } from '@ui-modules/tipJar/hooks/useCreateTipMutation';
import { ROUTES } from '@constants';
import styles from './CreateTipReviewPage.module.css';
import { makeEventMock } from '@tiger21-llc/connect-shared/src/mockFactories/makeEventMock';
import { makeCommunityMock } from '@tiger21-llc/connect-shared/src/mockFactories/makeCommunityMock';

const CreateTipReviewPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [tipCreateFormValidationError, initialTipCreateFormValues] = useTipCreateDataSearchParams();
	const [tipCreateFormValues, setTipCreateFormValues] = useSetState(initialTipCreateFormValues);
	const tipId = useSearchParams()[0].get('tipId') ?? undefined;
	useLayoutEffect(() => {
		if (!tipId && tipCreateFormValidationError) navigate(-1);
	}, [tipId, tipCreateFormValues]);

	const tipQuery = useTipQuery(tipId);
	const { data: nextGroupMeetings = [] } = useNextGroupMeetingsQuery();
	const tip = tipId ? tipQuery.data : tipCreateFormValues;
	const isTipEditable = true; // TODO think do we need here: "tipQuery.data && TipEntity.canEditTip(tipQuery.data, user);"

	const createTipMutation = useCreateTipMutation();
	const sendTipReviewMutation = useSendTipReviewMutation({
		onSuccess: () => {
			navigate(-[ROUTES.newTip, ROUTES.newTipCategories, ROUTES.newTipMatches, ROUTES.createTip].length);
		},
	});

	return (
		<Page title={t('Review Tip')}>
			{tipQuery.isFetching ? (
				<ActivityIndicator type="fit" />
			) : tipQuery.isError || tipCreateFormValidationError ? (
				<EmptyStateMessage text={tipCreateFormValidationError?.message || t('Something went wrong')} />
			) : tip ? (
				<TipReviewForm
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.createTipReview()]}
							formProps={formProps}
							headerTitle={t('Review Tip')}
							isSaveButtonHidden
							scrollEnabled
						>
							<div className={styles.createTipReviewPage}>
								<TipScreenHeader isTipEditable tipId={tipId} title={tip.title} onTipPatched={setTipCreateFormValues} />
								<TipDetails isTipEditable={isTipEditable} tip={tip} onTipPatched={setTipCreateFormValues} />
								<hr className={styles.separator} />
								{children}
								<Gap gap={24} />
							</div>
						</FormPageLayout>
					)}
					submitLabel={t('Submit')}
					// targetMeetings={nextGroupMeetings}
					// TODO just for testing purposes the mock is generated here
					targetMeetings={
						!nextGroupMeetings.length
							? [
									makeEventMock({
										startDate: '2024-12-31T00:00:00Z',
										groups: [
											{
												'@id': '123',
												'@type': 'Group',
												community: makeCommunityMock({
													shortName: 'CHG02',
												}),
											},
										],
									}),
									makeEventMock({
										startDate: '2024-12-26T00:00:00Z',
										groups: [
											{
												'@id': '123',
												'@type': 'Group',
												community: makeCommunityMock({
													shortName: 'NY01',
												}),
											},
										],
									}),
								]
							: nextGroupMeetings
					}
					title={t('Write a Review')}
					onSubmit={(formValues) => {
						if (tipId) {
							sendTipReviewMutation.mutateAsync({ ...formValues, tipId });
						} else {
							createTipMutation
								.mutateAsync(tipCreateFormValues)
								.then((createdTip) => sendTipReviewMutation.mutateAsync({ ...formValues, tipId: createdTip.id }));
						}
					}}
				/>
			) : null}
		</Page>
	);
};

export interface ICreateTipReviewPageParams {
	tipId: string;
}

export default CreateTipReviewPage;
