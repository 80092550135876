import styles from './Badge.module.css';

/** Red indicator which can display number, text, or just dot. Can be rendered with 'absolute' position or 'inline. */
const Badge = ({ hidden = false, value, position }: IBadgeProps) => {
	const finalValue = value && value > 99 ? `99+` : value;
	const finalHidden = hidden || value === 0;

	return finalHidden ? null : (
		<span className={styles.badge} data-position={position} role="status">
			{finalValue}
		</span>
	);
};

export type TBadgePosition = 'absolute' | 'inline';
export interface IBadgeProps {
	/** Badge can be rendered with 'absolute' in the right top corner for a relative container OR 'inline' as any other inline element. */
	position: TBadgePosition;
	/** Number or text value rendered within the badge. If value = 0 then Badge is hidden. If value is more than 99 then the label "99+" is rendered. */
	value?: number;
	/** Should Badge be removed from the DOM. Default false. */
	hidden?: boolean;
}

export default Badge;
