import { useTranslation } from '@hooks';
import { Input, Gap, FormSection, PhoneInput } from '@ui-kit';
import styles from './ContactSection.module.css';
import { connectionFormFields } from '../../ConnectionForm.schema';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import type { FormikProps } from 'formik';

const ContactSection = ({ formProps, networkConnectionName }: IContactSectionProps) => {
	const { t } = useTranslation();
	const { errors, values, setFieldValue, setFieldError } = formProps;

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	return (
		<FormSection title={t('{{networkConnectionName}} Contact', { networkConnectionName })}>
			<p className={styles.contactHint}>
				{t('The person listed as the primary contact for this {{networkConnectionName}}. (Visible to Members)', {
					networkConnectionName,
				})}
			</p>
			<Gap gap={24} />

			<Input
				errorMessage={errors.contactName}
				label={t('Name')}
				placeholder={t('e.g. {{example}}', { example: 'Pat Smith or Smith Holdings' })}
				value={values.contactName}
				onChange={(e) => handleChangeField(connectionFormFields.CONTACT_NAME, e.target.value)}
			/>
			<PhoneInput
				errorMessage={errors.contactPhone}
				label={t('Phone')}
				placeholder={t('e.g. {{example}}', { example: '(123) 456-7890' })}
				value={values.contactPhone}
				onChange={(value) => handleChangeField(connectionFormFields.CONTACT_PHONE, value)}
			/>
			<Input
				autoCapitalize="none"
				errorMessage={errors.contactEmail}
				errorMessagePosition={errors.contactEmail ? 'left' : 'none'}
				keepMarginBottom={false}
				label={t('Email')}
				placeholder={t('e.g. {{example}}', { example: 'pat.smith@smith.com' })}
				type="email"
				value={values.contactEmail}
				onChange={(e) => handleChangeField(connectionFormFields.CONTACT_EMAIL, e.target.value)}
			/>
			{!errors.contactEmail && (
				<>
					<Gap gap={4} />
					<span className={styles.contactEmailHint}>
						{t('The email address where we will send notifications of interest in this {{networkConnectionName}}.', {
							networkConnectionName,
						})}
					</span>
				</>
			)}
		</FormSection>
	);
};

interface IContactSectionProps {
	isEdit?: boolean;
	formProps: FormikProps<TNetworkConnectionFormValues>;
	networkConnectionName: string;
}

export default ContactSection;
