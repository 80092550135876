import { useState, useTranslation } from '@hooks';
import { signInFormSchema } from '@schemas';
import { Button, CheckboxInput, Formik, Input, PasswordInput, Form, Gap } from '@ui-kit';
import { UserIcon } from '@ui-kit/icons';
import styles from './SignInForm.module.css';
import type { TSignInForm } from '@schemas';

const SignInForm = ({
	initialValues,
	defaultShouldRememberLogin,
	onSetShouldRememberLogin,
	onResetShouldRememberLogin,
	onSubmit,
}: ISignInFormProps) => {
	const { t } = useTranslation();
	const [shouldRememberLogin, setShouldRememberLogin] = useState(defaultShouldRememberLogin, 'SignInForm:remember-me');

	return (
		<Formik<TSignInForm>
			initialValues={signInFormSchema.cast(initialValues) as TSignInForm}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={signInFormSchema}
			onSubmit={async (values) => {
				if (shouldRememberLogin) onSetShouldRememberLogin(values.login);
				else onResetShouldRememberLogin();
				return await onSubmit(values);
			}}
		>
			{({ values, errors, isSubmitting, handleChange, handleBlur, submitForm }) => {
				return (
					<Form>
						<Input
							ariaLabel="Username"
							autoComplete="email"
							disabled={isSubmitting}
							errorMessage={errors.login}
							explanation={t('Please use the provided Username.')}
							icon={<UserIcon className={styles.input__icon} height={12} width={12} />}
							iconPosition="left"
							label={<b>{t('Username')}</b>}
							placeholder={t('Email or Username')}
							value={values.login}
							onBlur={handleBlur('login')}
							onChange={handleChange('login')}
						/>
						<PasswordInput
							ariaLabel="Password"
							disabled={isSubmitting}
							errorMessage={errors.password}
							explanation={t(
								'If you have not completed the Activation process for your account, you must do so to log in.',
							)}
							hidePasswordLabel={t('Hide password')}
							label={<b>{t('Password')}</b>}
							placeholder={t('Password')}
							showPasswordLabel={t('Show password')}
							value={values.password}
							onBlur={handleBlur('password')}
							onChange={handleChange('password')}
						/>
						<Gap gap={16} />
						<CheckboxInput
							label={t('Remember me')}
							size="medium"
							value={shouldRememberLogin}
							onChange={setShouldRememberLogin}
						/>
						<Gap gap={16} />
						<Button
							block="fit"
							loading={isSubmitting}
							title={t('Sign In')}
							trackingName="auth--sign-in"
							type="outline"
							onClick={submitForm}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export interface ISignInFormProps {
	defaultShouldRememberLogin: boolean;
	initialValues: TSignInForm;
	onSetShouldRememberLogin: (login: string) => void;
	onResetShouldRememberLogin: () => void;
	onSubmit: (values: TSignInForm) => void;
}

export default SignInForm;
