import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import { makeTipMock } from '@mocks';
import type { TTip } from '@typings';
import type { TCreateTipForm } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { MutateOptions } from '@tanstack/react-query';
import { useUploadTipFileMutation } from './useUploadTipFileMutation';

export const useCreateTipMutation = (options?: MutateOptions<TTip, Error, TCreateTipForm>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	const uploadTipFileMutation = useUploadTipFileMutation();

	return useMutation<TTip, Error, TCreateTipForm>(
		['createTip'],
		async (tipData) => {
			// TODO use real API call when it's ready
			const uploadedImages = Promise.all(
				tipData.images.map(async (file) => await uploadTipFileMutation.mutateAsync(file.url)),
			);
			await new Promise((resolve) => setTimeout(resolve, 300));
			return makeTipMock();
		},
		{
			...options,
			onSuccess: (response, variables, context) => {
				showSuccess({ title: t('Tip Saved') });
				// TODO invalidate queries / set queries data
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
