import { useMe, useMutation, useNotification, useService } from '@hooks';
import { cloneDeep, set } from '@utils';
import type { TFullUser, TUserFeatures } from '@typings';

export const useTipsSliderIntroduced = () => {
	const { user } = useMe();
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();
	const isTipsSliderIntroduced = Boolean(user.features?.tips_introduction_slider?.owner);

	const { mutate: setTipsSliderIntroduced } = useMutation<TFullUser, Error, void, unknown>(
		['user.setTipsSliderIntroduced'],
		async () => {
			const features = setATipsSliderUserFeature(user.features, true);

			return await api.user.updateUser(user.id, { features });
		},
		{
			onMutate: () => {
				// Optimistically set the status to viewed to immediately close the Intro modal.
				reactQuery.queryClient.setQueryData<TFullUser | undefined>(reactQuery.queryKeys.getMe(), (user) => {
					if (!user) return user;
					const features = setATipsSliderUserFeature(user.features, true);
					return { ...user, features };
				});
			},
			onSuccess: () => {
				analytics.trackEvent('AccountInteractions', {
					interaction_type: 'Profile',
					action: `value features.aa_introduction_slider changed to "true"`,
				});
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	return [isTipsSliderIntroduced, setTipsSliderIntroduced] as const;
};

function setATipsSliderUserFeature(userFeatures: TUserFeatures | undefined, value: boolean): TUserFeatures {
	const updatingUserFeatures = userFeatures ? cloneDeep(userFeatures) : ({} as TUserFeatures);
	const updatedUserFeatures = set(updatingUserFeatures, ['tips_introduction_slider', 'owner'], value);
	return updatedUserFeatures;
}
