import { dealStatusTabs as tabs, ROUTES } from '@constants';
import { useTranslation, useNavigate, useMe, useMemo, useNotification } from '@hooks';

import styles from './AllDealFiltersPage.module.css';

import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, Formik, Navigate } from '@ui-kit';
import type { TEditDealFilters } from '@schemas';
import { getEditDealFiltersSchema } from '@schemas';

import useAllDealFilters from '@ui-modules/deals/hooks/useAllDealFiltersQuery';

import { useState } from 'reinspect';

import EditDealFiltersForm from '@ui-modules/deals/components/EditDealFiltersForm';
import useDealFiltersApi from '@ui-modules/deals/hooks/useDealFiltersApiQuery';
import { noop } from '@utils';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';

import { useIsDealFiltersFetching } from '@ui-modules/deals/hooks/useIsDealFiltersFetching';
import { useAllFilterValidation } from '@ui-modules/deals/hooks/useAllFilterValidation';
import { useApplyBlocker } from '@ui-modules/deals/hooks/useApplyBlocker';
import { getFiltersInitialOptionIndex } from '@ui-modules/deals/utils/getFiltersInitialOptionIndex';

const AllDealFiltersPage = () => {
	const [availableDealTypes, setAvailableDealTypes] = useState<ICheckmarkState[] | null>(null);

	const { data: allDealFilters, isLoading, isFetched } = useAllDealFilters();
	const isDealFiltersFetching = useIsDealFiltersFetching();

	const { patchDealFilters: applyFilters } = useDealFiltersApi();

	const { t } = useTranslation();
	const { user } = useMe();
	const { enabled } = useDealsEnabled();
	const navigate = useNavigate();
	const { showSuccess } = useNotification();

	const dealStatusTabs = tabs(t);

	// Custom hooks
	const { handleReset, isApplyBlocked } = useApplyBlocker();
	const validateFilters = useAllFilterValidation();

	useDealsDisabledEffect();

	// Find user's filter
	const filter = useMemo(
		() => allDealFilters?.find((item) => item?.ownerIri === user?.['@id']),
		[allDealFilters, user],
	);

	// Initialize form values
	const statusIndex = useMemo(
		() => dealStatusTabs?.findIndex((item) => item.value === String(filter?.status)),
		[dealStatusTabs, filter?.status],
	);

	const initialValues = useMemo(
		(): TEditDealFilters => ({
			status: statusIndex < 0 ? 0 : statusIndex,
			dealTypes: filter?.dealTypes || [],
			dealSubClasses: filter?.dealSubClasses || [],
			minInvestment: String(filter?.minInvestment || 0),
		}),
		[filter, statusIndex],
	);

	const [optionIndex, setOptionIndex] = useState<number>(
		getFiltersInitialOptionIndex(filter?.dealTypes, filter?.dealSubClasses),
	);

	const handleSubmit = async (values: TEditDealFilters) => {
		if (!validateFilters(values, optionIndex, availableDealTypes)) {
			return;
		}

		const submitData = {
			owner: user['@id'],
			dealSubClasses: values.dealSubClasses,
			minInvestment: values.minInvestment ? Number(values.minInvestment) : 0,
			status: dealStatusTabs[values?.status as number].value,
			dealTypes: values.dealTypes,
		};

		await applyFilters(submitData);
		showSuccess({ title: t('Filter updated') });
		navigate(ROUTES.deals());
	};

	if (!enabled) {
		return <Navigate replace={true} to={ROUTES.home()} />;
	}

	if (isLoading && !isFetched) {
		return <ActivityIndicator />;
	}

	return (
		<Page title={t('All Deal Filters')}>
			{filter && (
				<Formik<TEditDealFilters>
					initialValues={getEditDealFiltersSchema().validateSync(initialValues)}
					validationSchema={getEditDealFiltersSchema()}
					onSubmit={handleSubmit}
				>
					{(formProps) => (
						<FormPageLayout
							additionalDisabled={isDealFiltersFetching || isApplyBlocked}
							allowedNextPaths={[ROUTES.dealFilters(''), ROUTES.allDealFilters()]}
							buttonBordered={false}
							customHeaderStyles={styles.customHeader}
							customLeftIcon={<>{t('Cancel')}</>}
							formProps={formProps}
							headerTitle={t('Filter Deals')}
							saveButtonTitle={t('Apply')}
							onSuccessSubmit={noop}
						>
							<EditDealFiltersForm
								activeStatus={dealStatusTabs[statusIndex < 0 ? 0 : statusIndex].id}
								formProps={formProps}
								optionIndex={optionIndex}
								setAvailableDealTypes={setAvailableDealTypes}
								setOptionIndex={setOptionIndex}
								onReset={handleReset}
							/>
						</FormPageLayout>
					)}
				</Formik>
			)}
		</Page>
	);
};

export default AllDealFiltersPage;
