import styles from '../CameraPhotoInput.module.css';
import { RefreshIcon } from '../../../icons';

const RetakeImageButton = ({ title, onClick }: IRetakeImageButtonProps) => {
	return (
		<button aria-label="Retake image" className={styles.cameraPhotoInput__retakeImageButton} onClick={onClick}>
			<RefreshIcon className={styles.cameraPhotoInput__retakeImageButtonIcon} height={12} width={12} />
			<span className={styles.cameraPhotoInput__retakeImageButtonLabel}>{title}</span>
		</button>
	);
};

export interface IRetakeImageButtonProps
	extends Pick<
		React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		'title' | 'onClick'
	> {}

export default RetakeImageButton;
