import { useService, useMutation, useNotification, useMe } from '@hooks';
import { useNetworkConnectionMemberRelationStatusMutation } from './useNetworkConnectionMemberRelationStatusMutation';
import { useNetworkConnectionMemberRelationQuery } from './useNetworkConnectionMemberRelationQuery';
import type { TNetworkConnectionDonationForm } from '@schemas';
import type { TNetworkConnection, TNetworkConnectionMemberRelationStatus } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useDonateToNetworkConnectionMutation = (
	networkConnection: TNetworkConnection,
	nextNetworkConnectionRelationStatusId: TNetworkConnectionMemberRelationStatus['id'] | undefined,
	networkConnectionsName: string,
	options?: MutationOptions<void, Error, TNetworkConnectionDonationForm>,
) => {
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	const { data: networkConnectionMemberRelation } = useNetworkConnectionMemberRelationQuery(networkConnection.id);
	const { mutate: changeMemberRelation } = useNetworkConnectionMemberRelationStatusMutation(
		networkConnection.id,
		networkConnectionsName,
	);

	return useMutation<void, Error, TNetworkConnectionDonationForm>(
		['networkConnection.donateToNetworkConnection'],
		async ({ amount }) =>
			await api.networkConnection.donateToNetworkConnection({
				networkConnectionId: networkConnection.id,
				userId: user.id,
				amount,
			}),
		{
			onSuccess: async (response, variables, context) => {
				if (variables.showDonarStatus && nextNetworkConnectionRelationStatusId) {
					await changeMemberRelation(
						networkConnectionMemberRelation
							? {
									networkConnectionRelationId: networkConnectionMemberRelation.id,
									networkConnectionRelationStatusId: nextNetworkConnectionRelationStatusId,
								}
							: {
									networkConnectionRelationId: undefined,
									networkConnectionRelationStatusId: nextNetworkConnectionRelationStatusId,
								},
					);
				}
				analytics.trackGenericEvent(
					'{{connectionName}}Interactions',
					{
						interaction_type: 'donation',
						'{{connectionName}}_id': networkConnection.id,
						'{{connectionName}}_name': networkConnection.title,
						show_as_donor: variables.showDonarStatus,
						donation_amount: variables.amount ?? null,
					},
					{
						connectionName: networkConnectionsName,
					},
				);

				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
