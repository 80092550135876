import TabWrapper from '../../components/TabWrapper';
import { Button, EmailIcon, FixedWidthContainer, Gap, Tabs, When } from '@ui-kit';
import GroupTabActionBar from './GroupTabActionBar';
import CommunityResource from '@ui-modules/communities/components/CommunityResource';
import UpcomingGroupCard from '@ui-modules/groups/components/UpcomingGroupCard';
import CommunityMemberSectionList from '@ui-modules/communities/components/CommunityMemberSectionList';
import CommunityCalendarItemsSectionList from '@ui-modules/communities/components/CommunityCalendarItemsSectionList';
import CommunityFilesWidget from '@ui-modules/files/components/CommunityFilesWidget';
import { useParams, useMe, useNavigate, useEvent, useService, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useGroupTabsHandler } from '@ui-modules/groups/hooks/useGroupTabsHandler';
import { useSendEmailsToCommunityMutation } from '@ui-modules/communities/hooks/useSendEmailsToCommunityMutation';
import { CommunityEntity } from '@utils';
import { getGroupTabsPermissions } from '@ui-modules/groups/utils/getGroupTabsPermissions';
import { ROUTES } from '@constants';
import type { TEvent, TGroupTabName, TUserProfileRecord } from '@typings';
import CommunityTipsPage from '../CommunityTipsPage';

const GroupTabsPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');
	const navigate = useNavigate();
	const { user } = useMe();

	// Input group data.
	const groupId = useParams<IGroupTabsPageParams>().groupId as IGroupTabsPageParams['groupId'];
	const { data: group } = useCommunityQuery(groupId);

	// Tabs logic.
	const groupTab = useParams<IGroupTabsPageParams>().groupTab as IGroupTabsPageParams['groupTab'];
	const { tabs, isGroupUpcoming } = getGroupTabsPermissions(group, user.roles);
	const { currentTabIndex, onChangeTab } = useGroupTabsHandler(group?.id, tabs, String(groupTab));
	// User actions.
	const openMemberProfile = useEvent(({ profile }: TUserProfileRecord) => navigate(ROUTES.memberProfile(profile.id)));
	const openNominateMember = useEvent(() => navigate(ROUTES.referAssociate()));
	const openCalendarItem = useEvent((calendarItem: TEvent) => {
		navigate(ROUTES.viewMeeting(calendarItem.id));
		analytics.trackEvent('GroupInteractions', {
			interaction_type: 'group meeting clicked',
			meeting_id: calendarItem.id,
			meeting_title: calendarItem.title,
		});
	});
	const { mutate: sendEmailsToCommunity, isLoading: isCommunityEmailsLoading } = useSendEmailsToCommunityMutation();

	const heroImageUrl =
		typeof group?.backgroundUrl === 'string' ? group?.backgroundUrl : group?.backgroundUrl?.contentUrl;

	if (currentTabIndex === NOT_FOUND || !group) return null;
	return (
		<>
			<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
				<Tabs.List>
					{tabs.map((tab) => {
						switch (tab) {
							case 'meetings':
								return <Tabs.Tab key="meetings">{isGroupUpcoming ? t('About') : t('Meetings')}</Tabs.Tab>;
							case 'members':
								return <Tabs.Tab key="members">{t('Members')}</Tabs.Tab>;
							case 'files':
								return <Tabs.Tab key="files">{t('Files')}</Tabs.Tab>;
							case 'resources':
								return <Tabs.Tab key="resources">{t('Resources')}</Tabs.Tab>;
							case 'tips':
								return <Tabs.Tab key="tips">{t('Tips')}</Tabs.Tab>;
						}
					})}
				</Tabs.List>
				{tabs.map((tab) => {
					switch (tab) {
						case 'meetings':
							return isGroupUpcoming ? (
								<Tabs.Panel key={'about'}>
									<TabWrapper>
										<FixedWidthContainer>
											<Gap gap={24} />
											<UpcomingGroupCard groupName={group.name} onNominateMember={openNominateMember} />
										</FixedWidthContainer>
									</TabWrapper>
								</Tabs.Panel>
							) : (
								<Tabs.Panel key={'meetings'}>
									<TabWrapper>
										<CommunityCalendarItemsSectionList
											communityId={groupId}
											emptyMessage={t('No upcoming meetings')}
											heroImageUrl={heroImageUrl}
											nextItemsTitle={t('Next group meetings')}
											onClick={openCalendarItem}
										/>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'members':
							return (
								<Tabs.Panel key="members">
									<TabWrapper>
										<CommunityMemberSectionList
											communityId={groupId}
											ListHeaderComponent={
												isGroupUpcoming ? (
													<FixedWidthContainer>
														<UpcomingGroupCard groupName={group.name} onNominateMember={openNominateMember} />
													</FixedWidthContainer>
												) : undefined
											}
											moderatorMembersTitle={t('Chair and support')}
											regularMembersTitle={t('Members')}
											onClick={openMemberProfile}
										/>
										<GroupTabActionBar>
											<When condition={CommunityEntity.shouldShowGroupCommunicationFeatures(group)}>
												<Button
													icon={<EmailIcon fill="black" height={16} width={16} />}
													iconPosition="left"
													loading={isCommunityEmailsLoading}
													title={t('Email Group')}
													type="outline"
													variant="small"
													onClick={() => sendEmailsToCommunity(group.id)}
												/>
											</When>
										</GroupTabActionBar>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'files':
							return (
								<Tabs.Panel key="files">
									<TabWrapper>
										<Gap gap={24} />
										<CommunityFilesWidget communityDefinition="group" communityId={groupId} folderId={null} />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'resources':
							return (
								<Tabs.Panel key="resources">
									<TabWrapper>
										<FixedWidthContainer>
											<Gap gap={24} />
											<CommunityResource resourceUrl={String(group.resourceUrl)} />
										</FixedWidthContainer>
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'tips':
							return (
								<Tabs.Panel key="resources">
									<TabWrapper>
										<CommunityTipsPage communityDefinition="group" communityId={groupId} />
									</TabWrapper>
								</Tabs.Panel>
							);
					}
				})}
			</Tabs>
		</>
	);
};

const NOT_FOUND = -1; // Array.findIndex not found value.

export interface IGroupTabsPageParams extends Record<string, string | undefined> {
	groupId: string;
	groupTab: TGroupTabName;
}

export default GroupTabsPage;
