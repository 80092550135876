import EntityPageHeader from '@ui-kit/components/EntityPageHeader';
import { useNavigate } from '@hooks';
import { dayjs } from '@utils';
import { useGetTipMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipMeatballMenuOptions';
import { ROUTES } from '@constants';
import type { TTip } from '@typings';
import { useTranslation } from 'react-i18next';
import styles from './TipHeader.module.css';

const TipHeader = ({ tip, isTipEditable }: ITipHeaderProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [meatballMenuOptions, ConfirmationModals] = useGetTipMeatballMenuOptions({
		onDeleted: () => {
			return navigate(-1);
		},
	});

	const goToProfile = () => navigate(ROUTES.memberProfile(tip.owner.profileID));

	return (
		<div className={styles.tipReviewList__container}>
			<EntityPageHeader
				avatarUri={tip?.owner.avatarUrl}
				date={dayjs(tip?.createdAt).fromNow()}
				fullName={tip?.owner.fullName}
				isChair={tip.owner.roles.includes('ROLE_CHAIR')}
				location={tip?.owner.communitiesForBadge?.shortNames.join(' • ')}
				meatballMenuOptions={meatballMenuOptions(tip)}
				theme={'light'}
				title={isTipEditable ? t('Review AI Match') : t('Tip')}
				onProfilePress={goToProfile}
			/>
			{ConfirmationModals}
		</div>
	);
};

interface ITipHeaderProps {
	tip: TTip;
	isTipEditable?: boolean;
}

export default TipHeader;
