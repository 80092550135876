import { memo, useEffect, useRef } from 'react';
import { ActivityIndicator, When } from '@ui-kit';
import ApiVideoPlayer from '@api.video/react-player';
import { useIntersection, useTranslation } from '@hooks';
import { useApiVideoState } from './useApiVideoState';
import { extractVideoIdFromVideoUrl } from '@utils';
import { AspectRatioCache } from '@ui-modules/media/utils/AspectRatioCache';
import styles from './ApiVideo.module.css';
import type { ApiVideoPlayerVideo, ControlName } from '@api.video/react-player';
import { useVideoDetailsQuery } from '@ui-modules/media/components/ApiVideo/useVideoDetailsQuery';

const aspectRatioCache = new AspectRatioCache();
const defaultControls: ControlName[] = [
	'play',
	'volume',
	'fullscreen',
	'pictureInPicture',
	'progressBar',
	'more',
	'subtitles',
	'chapters',
	'playbackRate',
];

const ApiVideo = ({
	videoUrl,
	playerWidth = 330,
	controls = defaultControls,
	responsive = false,
	onVideoPlayed,
}: IApiVideoProps) => {
	const { t } = useTranslation();
	const videoId = extractVideoIdFromVideoUrl(videoUrl);

	const ref = useRef<HTMLDivElement | null>(null);
	const intersectionObserverEntry = useIntersection(ref, { threshold: 0, rootMargin: '300px' });
	const isVisible = intersectionObserverEntry?.isIntersecting || false;

	const { readiness, updateTrigger, setReady, setError, setDataPrepared } = useApiVideoState(videoId);
	const { data, isError, refetch } = useVideoDetailsQuery(videoId, {
		enabled: !!videoId && isVisible,
	});
	useEffect(
		function setDataPreparedWhenVisibleChanged() {
			if (data) {
				setDataPrepared(isVisible, refetch);
			}
		},
		[isVisible, data],
	);

	const token = videoUrl.includes('token') ? data?.assets.player.slice(data.assets.player.indexOf('token=') + 6) : '';
	const video = token?.length ? { id: videoId, token: token } : { id: videoId };

	if (!videoId || isError) return null;
	return (
		<div
			className={styles.apiVideo__container}
			ref={ref}
			style={{
				width: playerWidth,
				aspectRatio: responsive ? (aspectRatioCache.getAspectRatio(videoId) ?? 16 / 9) : undefined,
			}}
		>
			<div
				className={styles.apiVideoPlayerContainer}
				style={{
					opacity: isVisible ? 1 : 0,
				}}
			>
				{!data || isError ? null : (
					<ApiVideoPlayer
						controls={controls}
						key={videoUrl + updateTrigger}
						responsive={responsive}
						showSubtitles
						style={{
							width: playerWidth,
							minHeight: playerWidth * 0.562,
							visibility: readiness === 'ready' ? 'visible' : 'hidden', // helps to hide ERROR_PROCESSING message [@dmitriy.nikolenko]
						}}
						video={video as ApiVideoPlayerVideo}
						videoStyleObjectFit="contain"
						onError={setError}
						onFirstPlay={() => onVideoPlayed?.(videoUrl)}
						onReady={setReady}
						onVideoSizeRatioChange={(aspectRatio) => {
							aspectRatioCache.setAspectRatio(videoId, aspectRatio);
						}}
					/>
				)}
			</div>
			<When condition={readiness === 'error'}>
				<div
					className={styles.apiVideo__processingMessage}
					style={{
						backgroundImage: `url("${data?.assets.thumbnail}")`,
					}}
				>
					<ActivityIndicator size="large" type="fit" />
					<span>{t('Processing...')}</span>
				</div>
			</When>
			<When condition={readiness === 'undefined' || !data}>
				<ActivityIndicator className={styles.apiVideo__processingMessage} size="large" type="fit" />
			</When>
		</div>
	);
};

export interface IApiVideoProps {
	/** Api video URL. */
	videoUrl: string;
	/** Strict width of the player. The height will be calculated responsively. Default 330px. */
	playerWidth?: number;
	controls?: ControlName[];
	responsive?: boolean;
	/** Callback to be called when a video is played. */
	onVideoPlayed?: (videoUrl: string) => void;
}

export default memo(ApiVideo);
