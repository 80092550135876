import { AccountMenu, Page, PageLayout } from '../../components';
import { useCallback, useDebounce, useMe, useMemo, useNavigate, useState, useTranslation } from '@hooks';
import {
	ActivityIndicator,
	AddUserIcon,
	AlphabetList,
	Avatar,
	RemoveContactIcon,
	SearchableScreenHeader,
	SwitchButton,
	When,
} from '@ui-kit';
import { ROUTES } from '../../routes';
import styles from './AddressBookPage.module.css';
import { clsx, filterUserProfileRecords, formatUserSubTitle } from '@utils';
import type { TUserProfileRecord } from '@typings';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';
import { useAddressBook } from '@ui-modules/contacts/hooks/useAddressBook';
import { ADDRESS_BOOK_ITEM_CLASS_KEY } from '@constants';
import { useContact } from '@ui-modules/contacts/hooks/useContact';

const USER_LIST_ITEM_HEIGHT = 63;

const AddressBookPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
	const [onlyMyContacts, setOnlyMyContacts] = useState(false);
	const {
		fullName,
		user: { avatar: myAvatar, firstName, lastName, roles },
		profile: { id: ownerProfileId },
	} = useMe();
	const [debouncedSearchQuery] = useDebounce(searchQuery, 400);
	const { data: userProfileRecords = [], isLoading } = useAddressBookQuery();

	const setPageSavedData = (isMyContacts: boolean) => {
		setOnlyMyContacts(isMyContacts);
	};

	const { saveScrollIndex, initialPosition } = useAddressBook(setPageSavedData);

	const filteredProfiles = useMemo(
		() =>
			filterUserProfileRecords(
				onlyMyContacts ? userProfileRecords.filter((contact) => !!contact.contactId) : userProfileRecords,
				debouncedSearchQuery,
			),
		[userProfileRecords, debouncedSearchQuery, onlyMyContacts],
	);
	const { addContact, removeContact } = useContact();

	const renderUserActionButton = useCallback((userProfile: TUserProfileRecord) => {
		const isMyContact = !!userProfile.contactId;
		return (
			<button
				className={clsx(
					styles.addressUser__button,
					isMyContact ? styles.addressUser__button_remove : styles.addressUser__button_add,
				)}
				onClick={() => {
					userProfile.contactId
						? removeContact({
								contactId: userProfile.contactId,
								userId: userProfile.id,
								profileId: userProfile.profile.id,
							})
						: addContact({
								userId: userProfile.id,
								profileId: userProfile.profile.id,
							});
				}}
			>
				{isMyContact ? <RemoveContactIcon height={14} width={18} /> : <AddUserIcon height={14} width={18} />}
				<div className={styles.addressUser__buttonHint}>
					<span>{isMyContact ? t('Remove from contacts') : t('Add to contacts')}</span>
				</div>
			</button>
		);
	}, []);

	return (
		<Page title={t('Address Book')}>
			<PageLayout
				className={styles.addressBook__page}
				containerMode={false}
				paddingSize="medium"
				sidebarContent={<AccountMenu />}
			>
				<div className={styles.addressBook}>
					<div className={styles.addressBook__container}>
						<div className={styles.addressBook__header}>
							<SearchableScreenHeader
								placeholder={t('Search members')}
								searchQuery={searchQuery}
								searchTitle={t('Search')}
								spaceMode={false}
								title={t('Address Book')}
								onChangeSearchQuery={setSearchQuery}
							/>
							<When condition={!searchQuery.length}>
								<>
									<div className={styles.addressBook__userContainer}>
										<Avatar
											imageUri={myAvatar?.contentUrl}
											outline={roles.includes('ROLE_CHAIR')}
											size={40}
											title={firstName[0] + lastName[0]}
										/>
										<div className={styles.addressBook__user}>
											<span className={styles.addressBook__userName}>{fullName}</span>
											<button
												className={styles.addressBook__userButton}
												onClick={() => navigate(ROUTES.contact(ownerProfileId))}
											>
												<span>{t('View My Card')}</span>
											</button>
										</div>
									</div>
									<div className={styles.addressBook__switch}>
										<span className={styles.addressBook__switchLabel}>{t('Show only my contacts')}</span>
										<SwitchButton
											disabled={isLoading}
											size="small"
											value={onlyMyContacts}
											onChange={() => setOnlyMyContacts(!onlyMyContacts)}
										/>
									</div>
								</>
							</When>
						</div>
						<div className={styles.addressBook__list}>
							<AlphabetList
								className={clsx(ADDRESS_BOOK_ITEM_CLASS_KEY, styles.addressBook__item)}
								defaultItemHeight={USER_LIST_ITEM_HEIGHT}
								emptyListElement={
									searchQuery ? (
										<div className={styles.addressBook__listContainer}>
											<span>{t('Sorry, we couldn’t find any members that match the criteria')}</span>
										</div>
									) : undefined
								}
								headerClassName={styles.addressBook__item}
								initialPosition={initialPosition}
								isLoading={isLoading}
								loadingElement={
									<div className={styles.addressBook__listContainer}>
										<span>{t('Loading contacts')}</span>
										<ActivityIndicator />
									</div>
								}
								renderUserActionElement={renderUserActionButton}
								renderUserSubTitle={formatUserSubTitle(t)}
								showMyContactMark
								userProfileRecords={filteredProfiles}
								onClick={({ profile }) => saveScrollIndex({ profileId: profile.id, searchQuery, onlyMyContacts })}
							/>
						</div>
					</div>
				</div>
			</PageLayout>
		</Page>
	);
};

export default AddressBookPage;
