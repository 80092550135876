import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useCallback } from 'react';
import { useService } from '@services';
import ErrorFallback from '../ErrorFallback';
import type { ReactNode } from 'react';

/** Intercepts errors which appears during React rendering. */
export const ErrorBoundary = ({ children }: IErrorBoundaryProps) => {
	const sentry = useService('SentryService');
	const handleError = useCallback((error: Error) => {
		sentry.captureException(error);

		// Reload app when after new deploy links to public assets become invalid. (fix T21C-3252) [@dmitriy.nikolenko]
		if (error.message.includes('Failed to fetch dynamically imported module')) {
			window.location.reload();
		}
	}, []);

	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} onReset={reset}>
					{children}
				</ReactErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	);
};

export interface IErrorBoundaryProps {
	children: ReactNode;
}
