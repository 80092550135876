import { AccountMenu, Page, PageLayout } from '../../components';
import { useMe, useRef, useTranslation } from '@hooks';
import styles from './ReferAnAssociatePage.module.css';
import { useUpcomingGroupsQuery } from '@ui-modules/groups/hooks/useUpcomingGroupsQuery';
import { useNominations } from '@ui-modules/nominations/hooks/useNominations';
import { useNominationSectionsQuery } from '@ui-modules/nominations/hooks/useNominationSectionsQuery';
import { ROUTES } from '../../routes';
import { ActivityIndicator, Button, CollapsibleCard, CommunityCard, Gap, SpacedGroup, TopArrowIcon } from '@ui-kit';
import { dayjs, translateMembers, translateGroups } from '@utils';
import NominateMemberForm from '@ui-modules/nominations/components/NominateMemberForm';
import { useFloatingScrollToTop } from './useFloatingScrollToTop';

const ReferAnAssociatePage = () => {
	const { t } = useTranslation();
	const { data: upcomingGroups = [] } = useUpcomingGroupsQuery();
	const { profile, user } = useMe();
	const {
		getNominations: { data: nominations },
	} = useNominations();
	const { data: nominationSections = {}, isLoading } = useNominationSectionsQuery();
	const nominationsHeaders = useRef([t('name'), t('date'), t('status')]).current;
	const goToUpcomingGroup = (id: string) => window.open(ROUTES.group(id), 'blank');

	const scrollRef = useRef<HTMLDivElement | null>(null);
	const { onScroll, scrollToTop, shouldShowScrollToTop } = useFloatingScrollToTop(scrollRef);

	if (isLoading) {
		return <ActivityIndicator type="fit" />;
	}
	return (
		<Page title={t('Nominate a New Member')}>
			<PageLayout scrollRef={scrollRef} sidebarContent={<AccountMenu />} onScroll={onScroll}>
				<div className={styles.nomination}>
					<div className={styles.nomination__content}>
						<header className={styles.nomination__header}>
							<h1>{t('Nominations')}</h1>
							<h4>
								{t(
									'When you’re ready to nominate a colleague, friend or acquaintance to join TIGER 21, please use the form below.',
								)}
							</h4>
							<p>
								{t(
									'In addition to expediting the process, this form will help us track your nomination so we can keep you and your Chair updated as your nomination progresses.',
								)}
							</p>
						</header>
						<div className={styles.nomination__infoBlocksWrapper}>
							<div className={styles.nomination__infoBlocks}>
								<CollapsibleCard
									className={styles.nomination__infoBlock}
									isClickableHeader
									title={t('Nominate a New Member')}
								>
									<NominateMemberForm profileType={profile['@type']} userEntityId={user['@id']} />
								</CollapsibleCard>
								<CollapsibleCard className={styles.nomination__infoBlock} isClickableHeader title={t('My Nominations')}>
									<table className={styles.nomination__myNominations}>
										<tr className={styles.nomination__myNominationsHeader}>
											{nominationsHeaders?.map((header) => <th key={header}>{header}</th>)}
										</tr>
										{nominations?.map((nomination) => (
											<tr className={styles.nomination__myNominationsItem} key={nomination.id}>
												<td>{`${nomination.firstName} ${nomination.lastName}`}</td>
												<td>{dayjs(nomination.createdAt).format('DD MMM YYYY') + '\n'}</td>
												<td>{nomination.status.charAt(0).toUpperCase() + nomination.status.slice(1)}</td>
											</tr>
										))}
									</table>
								</CollapsibleCard>
								{nominationSections['program-overview'] ? (
									<CollapsibleCard
										className={styles.nomination__infoBlock}
										isClickableHeader
										title={t('Program Overview')}
									>
										<div
											dangerouslySetInnerHTML={{ __html: nominationSections['program-overview'].content.rendered }}
										/>
									</CollapsibleCard>
								) : null}
								{nominationSections['get-rewarded'] ? (
									<CollapsibleCard className={styles.nomination__infoBlock} isClickableHeader title={t('Get Rewarded')}>
										<div dangerouslySetInnerHTML={{ __html: nominationSections['get-rewarded'].content.rendered }} />
									</CollapsibleCard>
								) : null}
								{nominationSections['how-the-process-works'] ? (
									<CollapsibleCard
										className={styles.nomination__infoBlock}
										isClickableHeader
										title={t('How the Process Works')}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: nominationSections['how-the-process-works'].content.rendered,
											}}
										/>
									</CollapsibleCard>
								) : null}
								<CollapsibleCard
									className={styles.nomination__infoBlock}
									isClickableHeader
									title={t('Upcoming Groups')}
								>
									<div>
										{t(
											'Although we’re looking for Members around the globe, we’re particularly interested in adding Members in the following regions:',
										)}
									</div>
									<Gap gap={16} />
									<div className={styles.nomination__upcomingGroupList}>
										{upcomingGroups.map((group) => (
											<CommunityCard
												community={group}
												formatGroupsCount={translateGroups(t)}
												formatMembersCount={translateMembers(t)}
												key={group.id}
												nonSelectable
												onClick={(community) => goToUpcomingGroup(community.id)}
											/>
										))}
									</div>
								</CollapsibleCard>
								{nominationSections['additional-guidelines'] ? (
									<CollapsibleCard
										className={styles.nomination__infoBlock}
										isClickableHeader
										title={t('Additional Guidelines')}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: nominationSections['additional-guidelines'].content.rendered,
											}}
										/>
									</CollapsibleCard>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</PageLayout>
			{shouldShowScrollToTop ? (
				<div className={styles.nomination__floatingButtonContainer}>
					<Button
						icon={<TopArrowIcon />}
						iconPosition="right"
						title={t('Back to Top')}
						type="blurred"
						variant="small"
						onClick={scrollToTop}
					/>
				</div>
			) : undefined}
		</Page>
	);
};

export default ReferAnAssociatePage;
