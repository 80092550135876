import { useService, useMutation, useNotification } from '@hooks';
import type { MutateOptions } from '@tanstack/react-query';
import type { IInfinitePaginatedListData, TTip, TTipReview, TTipReviewWithReaction } from '@typings';

export const useTipReviewThumbUpMutation = (tipId: TTip['id'], options?: MutateOptions<unknown, Error, TTipReview>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();

	return useMutation<unknown, Error, TTipReviewWithReaction>(
		async (tipReview) => {
			if (tipReview.reactions.ownReaction) {
				void 0; //TODO  await api.tips.unsetThumbUpForTipReview(tipReview.id);
			} else {
				null; // TODO await api.tips.setThumbUpForTipReview(tipReview.id);
			}
			// TODO Implement the API call to thumb up a tip review when BE is ready
			await new Promise((resolve) => setTimeout(resolve, 300));
		},
		{
			...options,
			onSuccess: (response, tipReview, context) => {
				options?.onSuccess?.(response, tipReview, context);

				reactQuery.queryClient.setQueryData<TTipReviewWithReaction>(
					reactQuery.queryKeys.tipReview(tipId, tipReview.id),
					tipReviewThumbsUpdater,
				);
				reactQuery.queryClient.setQueryData<IInfinitePaginatedListData<TTipReviewWithReaction>>(
					reactQuery.queryKeys.tipReviews(tipId),
					reactQuery.mutateEntityListInfiniteQueryCache<TTipReviewWithReaction>(tipReview.id, tipReviewThumbsUpdater),
				);
				reactQuery.queryClient.setQueriesData<IInfinitePaginatedListData<TTipReviewWithReaction>>(
					reactQuery.queryKeys.tipCommunityReviews(tipId),
					reactQuery.mutateEntityListInfiniteQueryCache<TTipReviewWithReaction>(tipReview.id, tipReviewThumbsUpdater),
				);
				reactQuery.queryClient.setQueriesData<IInfinitePaginatedListData<TTipReviewWithReaction>>(
					reactQuery.queryKeys.tipCalendarItemReviews(tipId),
					reactQuery.mutateEntityListInfiniteQueryCache<TTipReviewWithReaction>(tipReview.id, tipReviewThumbsUpdater),
				);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

function tipReviewThumbsUpdater(tipReview: TTipReviewWithReaction | undefined): TTipReviewWithReaction | undefined {
	if (!tipReview) return tipReview;
	if (tipReview.reactions.ownReaction) {
		tipReview.reactions.likesCount--;
		tipReview.reactions.ownReaction = null;
	} else {
		tipReview.reactions.likesCount++;
		tipReview.reactions.ownReaction = 'like';
	}
	return tipReview;
}
