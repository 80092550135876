import { TabList } from 'react-tabs';
import styles from './Tabs.module.css';
import type { TabListProps } from 'react-tabs';
import clsx from 'clsx';

const TabsList = ({
	children,
	backgroundColor = 'white',
	className,
	hidden = false,
	...passThroughProps
}: ITabsListProps) => {
	if (hidden) return null;
	return (
		<TabList className={clsx(styles.tabs__tabList, className)} style={{ backgroundColor }} {...passThroughProps}>
			{children}
		</TabList>
	);
};

export interface ITabsListProps {
	/** Content of Tabs. Put at least <Tab>{title}</Tab> */
	children: TabListProps['children'];
	/** Background color for panel with tabs */
	backgroundColor?: string;
	/** Additional styles for tabs wrapper */
	className?: string;
	/** Hide tabs */
	hidden?: boolean;
}

TabsList.tabsRole = 'TabList'; // Required field to use your custom TabList  (see https://github.com/reactjs/react-tabs#custom-components)
export default TabsList;
