import TabWrapper from '../../components/TabWrapper';
import { Gap, Tabs, When } from '@ui-kit';
import ChapterMemberSectionList from '@ui-modules/communities/components/ChapterMemberSectionList';
import CommunityCalendarItemsSectionList from '@ui-modules/communities/components/CommunityCalendarItemsSectionList';
import CommunityFilesWidget from '@ui-modules/files/components/CommunityFilesWidget';
import { useTranslation, useParams, useMe, useNavigate, useEvent, useRouteSearchQueryState } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useChapterTabsHandler } from '@ui-modules/groups/hooks/useChapterTabsHandler';
import { getChapterTabsPermissions } from '@ui-modules/groups/utils/getChapterTabsPermissions';
import { ROUTES } from '@constants';
import type { TEvent, TGroupTabName, TUserProfileRecord } from '@typings';
import ChapterDiscussions from '@ui-modules/communities/components/ChapterDiscussions';
import CommunityTipsPage from '../CommunityTipsPage';

const ChapterTabsPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();

	// Input group data.
	const chapterId = useParams<IGroupTabsPageParams>().chapterId as IGroupTabsPageParams['chapterId'];
	const { data: chapter } = useCommunityQuery(chapterId);

	// Tabs logic.
	const chapterTab = useParams<IGroupTabsPageParams>().chapterTab as IGroupTabsPageParams['chapterTab'];
	const { tabs, isMeetingsShowed, isMembersShowed, isDiscussionsShowed, isDocumentsShowed } = getChapterTabsPermissions(
		chapter,
		user.roles,
	);
	const { currentTabIndex, onChangeTab } = useChapterTabsHandler(chapter?.id, tabs, String(chapterTab));

	// User actions.
	const openMemberProfile = useEvent(({ profile }: TUserProfileRecord) => navigate(ROUTES.memberProfile(profile.id)));
	const openCalendarItem = useEvent((calendarItem: TEvent) => {
		if (calendarItem['@type'] === 'Meeting') navigate(ROUTES.viewMeeting(calendarItem.id));
		else if (calendarItem['@type'] === 'Event') navigate(ROUTES.viewEvent(calendarItem.id));
	});

	const heroImageUrl =
		typeof chapter?.backgroundUrl === 'string' ? chapter?.backgroundUrl : chapter?.backgroundUrl?.contentUrl;

	return currentTabIndex === NOT_FOUND || !chapter ? null : (
		<>
			<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
				<Tabs.List>
					<When condition={isMeetingsShowed}>
						<Tabs.Tab>{t('Events')}</Tabs.Tab>
					</When>
					<When condition={isMembersShowed}>
						<Tabs.Tab>{t('Members')}</Tabs.Tab>
					</When>
					<When condition={isDiscussionsShowed}>
						<Tabs.Tab> {t('Discussions')}</Tabs.Tab>
					</When>
					<When condition={isDocumentsShowed}>
						<Tabs.Tab>{t('Files')}</Tabs.Tab>
					</When>
					<Tabs.Tab>{t('Tips')}</Tabs.Tab>
				</Tabs.List>
				<When condition={isMeetingsShowed}>
					<Tabs.Panel>
						<TabWrapper>
							<CommunityCalendarItemsSectionList
								communityId={chapterId}
								emptyMessage={t('No upcoming events or meetings')}
								heroImageUrl={heroImageUrl}
								onClick={openCalendarItem}
							/>
						</TabWrapper>
					</Tabs.Panel>
				</When>
				<When condition={isMembersShowed}>
					<Tabs.Panel>
						<TabWrapper>
							<ChapterMemberSectionList chapterId={chapterId} onClick={openMemberProfile} />
						</TabWrapper>
					</Tabs.Panel>
				</When>
				<When condition={isDiscussionsShowed}>
					<Tabs.Panel>
						<TabWrapper>
							<ChapterDiscussions
								communityId={chapterId}
								initialSearchQuery={routeSearchQuery}
								onChangeSearchQuery={setRouteSearchQuery}
							/>
						</TabWrapper>
					</Tabs.Panel>
				</When>
				<When condition={isDocumentsShowed}>
					<Tabs.Panel>
						<TabWrapper>
							<Gap gap={24} />
							<CommunityFilesWidget communityDefinition="group" communityId={chapterId} folderId={null} />
						</TabWrapper>
					</Tabs.Panel>
				</When>
				<Tabs.Panel>
					<TabWrapper>
						<CommunityTipsPage communityDefinition="chapter" communityId={chapterId} />
					</TabWrapper>
				</Tabs.Panel>
			</Tabs>
		</>
	);
};

const NOT_FOUND = -1; // Array.findIndex not found value.

export interface IGroupTabsPageParams extends Record<string, string | undefined> {
	chapterId: string;
	chapterTab: TGroupTabName;
}

export default ChapterTabsPage;
