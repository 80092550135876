import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import { makeTipMock } from '@mocks';
import type { TTip, TTipsFilter, TCommunity } from '@typings';

export const useCommunityTipsQuery = (communityId: TCommunity['id'], filters?: TTipsFilter) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TTip>(
		reactQuery.queryKeys.communityTips(communityId, filters),
		async ({ pageParam = 1 }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1500));
			// TODO it is to debug and demo the empty state
			if (filters?.categories?.length === 1 && filters?.categories[0] === 'Other')
				return {
					data: [],
					meta: { hasNext: false, hasPrevious: false },
					page: 1,
				};
			const tips = Array.from({ length: 10 }, () =>
				makeTipMock(
					filters?.categories?.length
						? {
								categories: [filters.categories[Math.floor(Math.random() * filters.categories.length)]],
							}
						: {},
				),
			);
			return {
				data: tips,
				meta: { hasNext: pageParam <= 5, hasPrevious: false },
				page: Number(pageParam),
			};
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
