import { useMemo } from 'react';
import { compact } from 'lodash';
import ImageEditTip from '../components/ImageEditTip';
import type { TCreateTipForm } from '@tiger21-llc/connect-shared/src/schemas/CreateTipForm.schema';
import type { TCarouselItem } from '../components/TipsImageCarousel/TipsImageCarousel';
import type { IPatchTipFormMainProps } from '@ui-modules/tipJar/components/PatchTipForm';

const useTipCarouselItems = ({ tipId, tipImages, isTipEditable, onTipPatched }: ITipCarouselItems): TCarouselItem[] => {
	return useMemo(() => {
		const aiImage = tipImages.find((image) => image.source === 'ai');
		const userImage = tipImages.find((image) => image.source === 'user');
		return compact([
			aiImage && {
				type: 'image',
				removable: false,
				source: aiImage.url,
			},
			userImage
				? {
						type: 'image',
						removable: isTipEditable,
						source: userImage.url,
					}
				: {
						type: 'view',
						Component: <ImageEditTip initialValue={tipImages} tipId={tipId} onTipPatched={onTipPatched} />,
					},
		]) satisfies TCarouselItem[];
	}, [tipImages, isTipEditable]);
};

interface ITipCarouselItems extends IPatchTipFormMainProps {
	tipImages: TCreateTipForm['images'];
	isTipEditable: boolean;
}

export default useTipCarouselItems;
