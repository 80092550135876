import { useMe, useTranslation } from '@hooks';
import type { TTipReview, TTip, TTipShare } from '@typings';
import styles from './TipPrimaryReviewSection.module.css';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';
import TipReviewCard from '@ui-modules/tipJar/components/TipReviewCard';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { useGetTipReviewMeatballMenuOptions } from '@ui-modules/tipJar/hooks/useGetTipReviewMeatballMenuOptions';

const TipPrimaryReviewSection = ({ tip, onClickUser, tipShare }: ITipPrimaryReviewSectionProps) => {
	const { t } = useTranslation();
	const { user } = useMe();

	const tipReviewId = tipShare?.shareUserReviewId || tip.ownerReviewId;
	const tipReviewQuery = useTipReviewQuery(tip.id, tipReviewId);
	const [meatballMenuOptions] = useGetTipReviewMeatballMenuOptions(tip.id);

	if (!tipReviewQuery.isSuccess) return null;

	return (
		<div className={styles.tipPrimaryReviewSection}>
			{tipShare ? null : <h5 className={styles.tipPrimaryReviewSection__title}>{t('Creator’s review')}</h5>}
			<TipReviewCard
				canThumbUp={TipReviewEntity.canThumbUp(tipReviewQuery.data, user)}
				meatballsMenuOptions={meatballMenuOptions(tipReviewQuery.data)}
				tipReview={tipReviewQuery.data}
				onClickUser={() => onClickUser(tipReviewQuery.data.owner)}
			/>
		</div>
	);
};

export interface ITipPrimaryReviewSectionProps {
	tip: TTip;
	onClickUser: (tipReviewOwner: TTipReview['owner']) => void;
	tipShare?: TTipShare;
}

export default TipPrimaryReviewSection;
