import { clsx } from '@utils';
import styles from './LinksSection.module.css';

const LinksSection = ({ title, links, getLinkClassName, onLinkOpened }: ILinksSectionProps) => {
	if (!links?.length) return null;
	return (
		<div className={styles.linksSection}>
			<h3 className={styles.linksSection__headerTitle}>{title}</h3>
			{links.map((link, index) => {
				return (
					<div key={link + index}>
						<a
							className={getLinkClassName?.(link, index)}
							href={link}
							rel="noreferrer"
							target="_blank"
							onClick={onLinkOpened}
						>
							<span>{link}</span>
						</a>
					</div>
				);
			})}
		</div>
	);
};

export interface ILinksSectionProps {
	title: string;
	links: string[] | undefined;
	getLinkClassName?: (link: string, index: number) => string | undefined;
	onLinkOpened?: () => void;
}

export default LinksSection;
