import { SimplePageLayout } from '../SimplePageLayout';
import { Button } from '@ui-kit';
import DiscardFormChangesModal from '@ui-modules/forms/components/DiscardFormChangesModal';
import { useTranslation, useCallback, useMemo, useHandleBackButton, useDebouncedCallback } from '@hooks';
import { isEmpty, isUndefined } from '@utils';
import type { FormikErrors, FormikProps } from 'formik';
import type { TMixpanelEvent } from '@typings';
import type { ISimplePageLayoutProps } from '../SimplePageLayout';

/** Form with header controllers and "keep editing" modal  */
function FormPageLayout<TValues extends Record<string, unknown>>({
	formProps,
	ignoreDirty,
	saveButtonTitle,
	isSaveButtonHidden = false,
	saveTrackingName,
	children,
	headerTitle,
	customLeftIcon,
	buttonBordered = true,
	customDisabled,
	onSuccessSubmit,
	onSaveFailed,
	onDiscarded,
	scrollEnabled = false,
	onSaveHandler,
	allowedNextPaths = [],
	customHeaderStyles = '',
	contentContainerStyle = '',
	submitLoading = false,
	additionalDisabled = false,
}: IFormPageLayoutProps<TValues>) {
	const { onBackButtonPress } = useHandleBackButton();
	const { t } = useTranslation();
	const finalSaveButtonTitle = saveButtonTitle ?? t('Save');
	const isDirtyOrInvalid = useMemo(
		() => (ignoreDirty ? !formProps.isValid : !formProps.dirty || !formProps.isValid),
		[ignoreDirty, formProps],
	);

	const onSave = useCallback(async () => {
		try {
			const validationErrors = await formProps.validateForm();
			if (!isEmpty(validationErrors)) {
				onSaveFailed?.(validationErrors);
				return;
			}
			await formProps.submitForm();
			onSuccessSubmit ? onSuccessSubmit() : onBackButtonPress();
		} catch (error) {
			console.info(`Save form error (Block:${headerTitle}):`, error);
		}
	}, [formProps]);
	const handleSave = useDebouncedCallback(onSaveHandler || onSave, 1000, { leading: true, trailing: false });

	return (
		<SimplePageLayout
			contentContainerStyle={contentContainerStyle}
			customHeaderStyles={customHeaderStyles}
			customLeftIcon={customLeftIcon}
			headerTitle={headerTitle}
			rightButton={
				isSaveButtonHidden ? null : (
					<Button
						disabled={(!isUndefined(customDisabled) ? customDisabled : isDirtyOrInvalid) || additionalDisabled}
						loading={submitLoading || formProps.isSubmitting}
						title={finalSaveButtonTitle}
						trackingName={saveTrackingName}
						type={buttonBordered ? 'outline' : 'transparent'}
						variant={buttonBordered ? 'small' : 'medium'}
						onClick={handleSave}
					/>
				)
			}
			scrollEnabled={scrollEnabled}
		>
			{children}
			<DiscardFormChangesModal allowedNextPaths={allowedNextPaths} ignoreDirty={ignoreDirty} onDiscard={onDiscarded} />
		</SimplePageLayout>
	);
}

interface IFormPageLayoutProps<TValues> extends ISimplePageLayoutProps {
	/** form props for the header controllers */
	formProps: FormikProps<TValues>;
	/** Header save button title can be changed from default "save" */
	saveButtonTitle?: string;
	/** Decides whether save button should be hidden. Default 'false'. */
	isSaveButtonHidden?: boolean;
	/** Name of the event send to analytics on click. */
	saveTrackingName?: TMixpanelEvent;
	/** If header save button should not be disabled if form has not changed */
	ignoreDirty?: boolean;
	/** Is save button should be bordered */
	buttonBordered?: boolean;
	/** Disabled option that not connected to form validation */
	customDisabled?: boolean;
	/** Additional disabled option that not related to form validation and customDisabled, checks with "||" */
	additionalDisabled?: boolean;
	/** If 'true' header layout shows without background color and shadow. Default 'false'. */
	isBackgroundTransparent?: boolean;
	/** Call function when save was failed because of form validation */
	onSaveFailed?: (errors: FormikErrors<TValues>) => void;
	/** Custom function that would be called on submit form success */
	onSuccessSubmit?: () => void;
	/** Custom function that would be called on onConfirm for Discard Modal
	 * for example if we use FormikPersist component we need to clear storage after discard */
	onDiscarded?: () => void;
	/** List of routes to which you can navigate without triggering showing DiscardChanges modal dialog.
	 * 	It's important to add at least "current" path, because sometimes it may be redirected to itself with new state or props that should trigger redundant dialog
	 */
	allowedNextPaths: string[];
	/** On save click handler*/
	onSaveHandler?: () => void;
	/** Boolean to check form submitting status */
	submitLoading?: boolean;
}

export default FormPageLayout;
