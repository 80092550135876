import { PROFILE_DETAILS_STATIC_LINK_LABELS, PROFILE_DETAILS_CUSTOM_LINK_LABELS, PROFILE_DETAILS } from '@constants';
import type { TEditProfileDetails, TProfileDetails } from '@schemas';
import { getEditProfileDetailsSchema } from '@schemas';
import type { THidableProfileProperties, TProfileLink } from '@typings';

const tMock = (value: string) => value;

export function mapProfileDetailsDataToForm(
	data: TProfileDetails = [],
	hideProperties: THidableProfileProperties[],
): TEditProfileDetails {
	const form = data.reduce(
		(result, item) => {
			if (PROFILE_DETAILS_STATIC_LINK_LABELS.includes(item.label as never)) {
				return {
					...result,
					[item.label]: {
						link: item.link ? cutLinkProtocol(item.link) : '',
						showOnProfile: !hideProperties.includes(`links.label=${item.label}`),
					},
				};
			} else {
				return {
					...result,
					others: [
						...(result.others ?? []),
						{
							label: item.label as (typeof PROFILE_DETAILS_CUSTOM_LINK_LABELS)[0],
							link: item.link ? cutLinkProtocol(item.link) : '',
							showOnProfile: !hideProperties.includes(`links.label=${item.label}`),
						},
					],
				};
			}
		},
		getEditProfileDetailsSchema(tMock).cast({}) as TEditProfileDetails,
	);

	const missingOthersNumber = PROFILE_DETAILS.limitOfOtherLinks - form.others.length;
	for (let i = 0; i < missingOthersNumber; i++) {
		form.others?.push({
			label: PROFILE_DETAILS_CUSTOM_LINK_LABELS[i],
			link: '',
			showOnProfile: false,
		});
	}

	return form as TEditProfileDetails;
}

export function mapProfileDetailsFormToData(form: TEditProfileDetails): TProfileDetails {
	const { others, ...staticLinks } = form;
	const mapToDataArray = (obj: object) =>
		Object.entries(obj).map(([label, item]) => ({
			label,
			...item,
		}));

	const profileLinks: TProfileLink[] = [...mapToDataArray(staticLinks), ...mapToDataArray(others)];
	const profileLinksWithProtocol: TProfileLink[] = profileLinks.map((profileLink) => ({
		...profileLink,
		link: profileLink.link ? substituteHttpsProtocolToLink(profileLink.link?.trim()) : profileLink.link,
	}));
	const filledProfileLinks = profileLinksWithProtocol.filter((item: TProfileLink) => !!item.link);

	return filledProfileLinks;
}

const cutLinkProtocol = (link: string): string => link.match(/^[a-z]+:\/\/(.*)$/)?.[1] || link;

const substituteHttpsProtocolToLink = (link: string): string => `https://${cutLinkProtocol(link)}`;
