import { useMe, useQuery, useService, useTranslation } from '@hooks';
import { dayjs } from '@utils';
import { ActivityNoLongerAvailableError } from '@errors';
import type { QueryObserverOptions } from '@tanstack/react-query';
import type { TActivity } from '@typings';

export const useFeedActivityQuery = (
	activityId?: TActivity['id'],
	feedSlug?: string | null,
	options?: QueryObserverOptions<TActivity | undefined, Error>,
) => {
	const feedService = useService('FeedService');
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { t } = useTranslation();
	const { user } = useMe();

	return useQuery<TActivity | undefined, Error>(
		reactQuery.queryKeys.getSingleActivity(activityId),
		async () => {
			const [maybeActivity] = await feedService.getActivities([String(activityId)]);
			ActivityNoLongerAvailableError.throwUnlessValid(maybeActivity);
			const activity = maybeActivity as TActivity; // existence of activity is checked in previous line.
			const communityId = activity.collectionCommunityReference?.id;

			// It is hack to determine the Learn origin of the activity, because from some places we don't have the origin. (@see T21C-7839) [@DmitriyNikolenko]
			if (!activity.origin && !communityId) {
				const streamActivity = await api.streamFeeds.getStreamActivityById(activity.foreign_id);
				activity.origin = `user:${streamActivity.globalFeedName}`;
			}

			if (!user.roles.includes('ROLE_ADMIN') && communityId) {
				const community = await reactQuery.queryClient.fetchQuery(
					reactQuery.queryKeys.getCommunity(String(communityId)),
					async () => await api.community.getCommunity(communityId),
					{
						staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
					},
				);
				if (!('membership' in community)) {
					throw new Error(
						t("The post is not available as you're not a member of {{communityName}} Network.", {
							communityName: activity.collectionCommunityReference?.data.name,
						}),
					);
				}
			}
			return activity;
		},
		{
			staleTime: dayjs.duration(7, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(7, 'seconds').asMilliseconds(),
			enabled: !!activityId,
			...(options || {}),
			onError: (error) => {
				options?.onError?.(error);
			},
		},
	);
};
