import type { GroupedVirtuosoHandle } from 'react-virtuoso';
import { GroupedVirtuoso } from 'react-virtuoso';
import ActivityIndicator from '../ActivityIndicator';
import UserListItem from '../UserListItem';
import { useCallback } from '@hooks';
import { ItemPlaceholder, getGroupCounts } from './AlphabetList.utils';
import styles from './AlphabetList.module.css';
import type { IUserListItemProps } from '../UserListItem';
import type { TProfile, TUserProfileRecord } from '@typings';
import type { ForwardedRef, ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';

/** Virtualized list rendering alphabetically grouped user-profile records (see TUserProfileRecord type).
 * 	Important! You must pass already sorted "userProfileRecords".
 */
const AlphabetList = (
	{
		userProfileRecords = [],
		selectedProfileIds,
		hideSectionHeaders = false,
		isLoading = false,
		checkWithSlug = false,
		showMyContactMark = false,
		loadingElement,
		emptyListElement,
		className,
		headerClassName,
		renderUserSubTitle,
		onClick,
		renderUserActionElement = () => null,
		initialPosition = 0,
		defaultItemHeight,
	}: IAlphabetListProps,
	ref: ForwardedRef<GroupedVirtuosoHandle>,
) => {
	const { groupCounts, groupLetters } = getGroupCounts(userProfileRecords);

	const renderGroupContent = useCallback(
		(index: number) => {
			return hideSectionHeaders ? (
				<ItemPlaceholder />
			) : (
				<div className={headerClassName}>
					<div className={styles.alphabetList__groupHeader}>
						<span className={styles.alphabetList__groupTitle}>{groupLetters[index]}</span>
					</div>
				</div>
			);
		},
		[userProfileRecords, hideSectionHeaders],
	);
	const renderItem = useCallback(
		(index: number) => {
			const userProfileRecord = userProfileRecords[index];
			const isSelected = Array.isArray(selectedProfileIds)
				? selectedProfileIds.includes(
						checkWithSlug ? userProfileRecords[index].slug : userProfileRecords[index].profile.id,
					)
				: undefined;
			const handleClick = () => onClick(userProfileRecord, isSelected);

			return (
				<div className={className}>
					<UserListItem
						actionElement={renderUserActionElement(userProfileRecords[index])}
						isMyContact={showMyContactMark && !!userProfileRecords[index].contactId}
						isSelected={isSelected}
						renderUserSubTitle={renderUserSubTitle}
						size="medium"
						userProfileRecord={userProfileRecord}
						withSeparator
						onClick={handleClick}
					/>
				</div>
			);
		},
		[userProfileRecords, selectedProfileIds],
	);

	return (
		<GroupedVirtuoso<TUserProfileRecord>
			className={styles.alphabetList__disableSticky}
			components={{
				EmptyPlaceholder: () =>
					isLoading ? loadingElement || <ActivityIndicator size="medium" type="fit" /> : emptyListElement || null,
			}}
			defaultItemHeight={defaultItemHeight}
			groupContent={renderGroupContent}
			groupCounts={groupCounts}
			initialTopMostItemIndex={initialPosition}
			itemContent={renderItem}
			ref={ref}
		/>
	);
};

export interface IAlphabetListProps {
	/** Data passed to render. */
	userProfileRecords: TUserProfileRecord[];
	/** If it is array, a radio buttons to select/deselect will be displayed for every item. Can be an array of profileIds. Radio buttons are hidden by default. */
	selectedProfileIds?: TProfile['id'][];
	/** Should headers be hidden. Default false. */
	hideSectionHeaders?: boolean;
	/** Should show an activity indicator during initial loading. Default false. */
	isLoading?: boolean;
	/** Should show checkmark icon if the user is 'my' contact. Default false. */
	showMyContactMark?: boolean;
	/** React element which will be rendered if alphabet list is loading*/
	loadingElement?: ReactElement;
	/** React element which will be rendered if alphabet list is empty */
	emptyListElement?: ReactElement;
	/** List item className */
	className?: string;
	/** List section header className */
	headerClassName?: string;
	/** Put some function (like formatUserSubTitle from @utils) to show subtitle with user role or groups. */
	renderUserSubTitle: IUserListItemProps['renderUserSubTitle'];
	/** Callback called when list item clicked. Passes chosen record and status did it be selected. */
	onClick: (userProfileRecord: TUserProfileRecord, isSelected: boolean | undefined) => void;
	/** Callback that return any React element which will be displayed on the right side of User item content */
	renderUserActionElement?: (user: TUserProfileRecord) => ReactNode;
	/** To check user condition with slug */
	checkWithSlug?: boolean | undefined;
	initialPosition?: number;
	defaultItemHeight?: number;
}

export default forwardRef(AlphabetList);
