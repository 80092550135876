import { Page } from '../../components';
import { useTranslation, useParams, useMe } from '@hooks';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { ActivityIndicator, Button, EditIcon } from '@ui-kit';
import styles from './TipPage.module.css';
import { clsx } from '@utils';
import TipDetails from '@ui-modules/tipJar/components/TipDetails';
import TipHeader from '@ui-modules/tipJar/components/TipHeader';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import TipReviewList from '@ui-modules/tipJar/components/TipReviewList';
import TipPrimaryReviewSection from '@ui-modules/tipJar/components/TipPrimaryReviewSection';
import type { TProfile } from '@typings';
import { useTipSharesQuery } from '@ui-modules/tipJar/hooks/useTipSharesQuery';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import TipScreenHeader from '@ui-modules/tipJar/components/TipScreenHeader';
import { memo } from 'react';
import { useGetQueryParamsTip } from '@ui-modules/tipJar/hooks/useGetQueryParamsTip';

const TipPage = () => {
	const { t } = useTranslation();
	const { tipId } = useParams();
	const { data: tip, isLoading, isFetching } = useTipQuery(tipId || '');
	const { contextCommunityId, calendarId } = useGetQueryParamsTip();

	return (
		<Page title={t('Tip Selected Page')}>
			{isLoading || isFetching ? (
				<ActivityIndicator size="medium" type="fit" />
			) : !tip ? (
				<div>{t('Tip not found')}</div>
			) : tip ? (
				<div className={styles.tipSelectedPage}>
					<TipHeader tip={tip} />
					<TipReviewList
						contextCalendarIdItemId={calendarId ?? undefined}
						contextCommunityId={contextCommunityId ?? undefined}
						Header={TipPageBody}
						tipId={tip.id}
					/>
				</div>
			) : null}
		</Page>
	);
};

/** Should be in the separate component to avoid unnecessary rerenders being callback for TipReviewList */
const TipPageBody = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { tipId } = useParams();
	const { user } = useMe();
	const { data: tip } = useTipQuery(tipId || '');
	const { contextCommunityId, calendarId } = useGetQueryParamsTip();

	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));
	const isTipEditable = tip && TipEntity.canEditTip(tip, user);
	const tipShareQuery = useTipSharesQuery(tipId || '', {
		enabled: !!contextCommunityId,
		// TODO Uncomment it to pick up a proper community
		// select: (shares) => shares?.find((share) => share.community.id === contextCommunityId)
		select: (shares) => shares?.[0],
	});
	const tipShare = contextCommunityId ? tipShareQuery.data : undefined;

	if (!tip) return null;
	return (
		<div className={styles.tipSelectedPage__content}>
			<div className={clsx(styles.tipSelectedPage__header)}>
				<TipScreenHeader isTipEditable={isTipEditable} tipId={tip.id} title={tip.title} />
				<TipDetails isTipEditable={isTipEditable} tip={tip} tipShare={tipShare} />
				<TipPrimaryReviewSection tip={tip} tipShare={tipShare} onClickUser={() => openProfile(tip.owner.profileID)} />

				{contextCommunityId || calendarId ? null : (
					<div className={styles.tipSelectedPage__moreMembersReview}>
						<h3>{t('More Member Reviews')}</h3>
						<Button
							icon={<EditIcon height={14} width={14} />}
							title={t('Review')}
							type="outline"
							variant="medium"
							onClick={() => navigate(ROUTES.tipReview(tip.id, 'new'))}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(TipPage);
