import { BackIcon } from '@ui-kit';
import { useHandleBackButton } from '@hooks';
import { clsx } from '@utils';
import styles from './SimplePageLayout.module.css';
import type { ReactNode } from 'react';

export function SimplePageLayout({
	children,
	headerTitle,
	customLeftIcon,
	scrollEnabled = false,
	customHeaderStyles = '',
	contentContainerStyle = '',
	rightButton,
}: ISimplePageLayoutProps) {
	const { onBackButtonPress } = useHandleBackButton();

	return (
		<div className={clsx(styles.layout, scrollEnabled && styles.layout__scrollable, contentContainerStyle)}>
			{headerTitle ? (
				<div className={clsx(styles.layout__header, styles.layout__header_transparent, customHeaderStyles)}>
					<div className={styles.layout__headerButtonBlock}>
						<button className={styles.layout__headerButton} onClick={onBackButtonPress}>
							{customLeftIcon || <BackIcon className={styles.layout__headerBackIcon} height={16} width={16} />}
						</button>
					</div>
					<span className={styles.layout__headerTitle}>{headerTitle}</span>
					<div className={clsx(styles.layout__headerButtonBlock, styles.layout__headerButtonBlock_right)}>
						{rightButton}
					</div>
				</div>
			) : null}
			<div className={clsx(styles.layout__content, scrollEnabled && styles.layout__contentScrollable)}>{children}</div>
		</div>
	);
}

export interface ISimplePageLayoutProps {
	/** Subheader title */
	headerTitle?: string;
	/** Icon that will be displayed on the left button*/
	customLeftIcon?: ReactNode;
	/** ReactNode content of the form. */
	children: ReactNode;
	/** Define if scroll appears*/
	scrollEnabled?: boolean;
	/** Will apply custom styles to the header */
	customHeaderStyles?: string;
	/** Will apply custom styles to the container */
	contentContainerStyle?: string;
	/** Boolean to check form submitting status */
	submitLoading?: boolean;
	/** */
	rightButton?: ReactNode;
}
