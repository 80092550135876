import { CameraPhotoInput, CheckboxInput, Formik, TextArea, When } from '@ui-kit';
import { useTranslation, useRef, useMutation } from '@hooks';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import styles from './NewTipForm.module.css';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import { patchTipFormSchema, type TPatchTipForm } from '@schemas';

const NewTipForm = ({ initialValues, onSubmit, renderWrapper = ({ children }) => children }: INewTipFormProps) => {
	const { t } = useTranslation();

	const formikRef = useRef<FormikProps<TPatchTipForm>>(null);

	const { openFilePicker, getInputProps } = usePickUpFile((file) => {
		const photoUrl = URL.createObjectURL(file);
		formikRef.current?.setFieldValue('images', [
			{
				url: photoUrl,
				source: 'user',
				name: file.name,
			},
		]);
		formikRef.current?.setFieldTouched('images', true);
	});
	const getCurrentGeolocationMutation = useMutation<GeolocationPosition, GeolocationPositionError>({
		mutationFn: async () =>
			await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)),
		onSuccess: (data) =>
			formikRef.current?.setFieldValue('location', {
				lat: data.coords.latitude,
				lng: data.coords.longitude,
			}),
		onError: (error) => formikRef.current?.setFieldError('location', t('Failed to get your location')),
	});

	return (
		<Formik<TPatchTipForm>
			initialValues={initialValues as TPatchTipForm}
			innerRef={formikRef}
			validateOnBlur
			validateOnChange={false}
			validateOnMount={false}
			validationSchema={patchTipFormSchema.omit(['categories'])}
			onSubmit={(values) => onSubmit(values)}
		>
			{(formProps) => {
				const { values, setFieldValue, handleBlur, handleChange, submitForm, setFieldTouched, errors, isSubmitting } =
					formProps;
				return renderWrapper({
					formProps,
					children: (
						<div className={styles.newTipForm}>
							<h3>{t('Take a photo...')}</h3>
							<CameraPhotoInput
								height={370}
								t={t}
								value={values.images?.find?.((image) => image.source === 'user')?.url}
								width="100%"
								onCallImageLibrary={openFilePicker}
								onChange={(photoUrl) => {
									if (photoUrl) {
										setFieldValue('images', [
											{
												url: photoUrl,
												source: 'user',
												name: 'Image from camera',
											},
										]);
									} else {
										setFieldValue('images', null);
									}
									setFieldTouched('images', true);
								}}
							/>
							<input {...getInputProps()} />
							<hr className={styles.newTipForm__separatorLine} />
							<h3>{t('And/or, write something')}</h3>
							<TextArea
								charactersLimit={TipEntity.descriptionLimit}
								errorMessage={errors.description}
								label={t('Write a description, add info or a web link')}
								placeholder={t('e.g. Ritz Carlton Denver hotel')}
								showCounter
								value={values.description}
								onBlur={handleBlur('description')}
								onChange={handleChange('description')}
							/>
							<When condition={!!navigator.geolocation}>
								<CheckboxInput
									disabled={getCurrentGeolocationMutation.isLoading}
									errorMessage={errors.location}
									label={
										t('Use my current Location') +
										(getCurrentGeolocationMutation.isLoading ? ` ${t('(fetching...)')}` : '')
									}
									value={!!values.location}
									onChange={(locationAttached) => {
										setFieldTouched('location', true);
										if (locationAttached) {
											getCurrentGeolocationMutation.mutate();
										} else {
											setFieldValue('location', null);
										}
									}}
								/>
							</When>
							<TipFormsSubmitButton loading={isSubmitting} title={t('Next')} onClick={submitForm} />
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface INewTipFormProps {
	initialValues: Partial<TPatchTipForm>;
	/** Form submit handler. */
	onSubmit: (values: TPatchTipForm) => Promise<unknown>;
	/** Optional renderer to wrap form content and pass formProps to a wrapper React component. */
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TPatchTipForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default NewTipForm;
