import { useService, useMutation, useNotification } from '@hooks';
import { makeFileMock } from '@mocks';
import type { TFile } from '@typings';
import type { UseMutationOptions } from '@tanstack/react-query';

export const useUploadTipFileMutation = (mutationOptions?: UseMutationOptions<TFile, Error, string>) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();

	return useMutation<TFile, Error, string>(
		['mediaObject.uploadDocument'],
		async (fileUrl) => {
			await new Promise((resolve) => setTimeout(resolve, 1200));
			return makeFileMock({}, { contentUrl: fileUrl });
			// const image = await fetch(fileUrl);
			// const imageBlob = await image.blob();
			// const imageFile = new File([imageBlob], 'photo.jpg');
			// TODO use real API call
			// const mediaObject = await api.mediaObject.uploadImage(imageFile);
			// await api.tipFile.postTipFile({
			// 	communityId,
			// 	folderId,
			// 	mediaObjectId: mediaObject.id,
			// 	name: mediaObject.originalName,
			// })
		},
		{
			...mutationOptions,
			onSuccess: (data, variables, context) => {
				mutationOptions?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				mutationOptions?.onError?.(error, variables, context);
			},
		},
	);
};
