import type { ButtonProps } from 'react-activity-feed';
import { ThumbUpFilledIcon, ThumbUpIcon } from '@ui-kit/icons';
import { Button } from '@ui-kit/components';
import styles from './ThumbUpButton.module.css';

const ThumbUpButton = ({ count, active = false, disabled, onClick }: IThumbUpButtonProps) => {
	const Icon = active ? <ThumbUpFilledIcon fill={'#8E723B'} /> : <ThumbUpIcon />;

	return (
		<Button
			className={styles.thumbUpButton}
			disabled={disabled}
			icon={Icon}
			title={count.toString()}
			titleClassName={styles.thumbUpButton__buttonTitle}
			type="transparent"
			onClick={onClick}
		/>
	);
};

export interface IThumbUpButtonProps extends Pick<ButtonProps, 'disabled' | 'onClick'> {
	count: number;
	active: boolean;
}

export default ThumbUpButton;
