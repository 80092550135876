import { Page } from '../../components';
import { ActivityIndicator, Gap, Virtuoso } from '@ui-kit';
import {
	useBlockedAndBlockingUsers,
	useCallback,
	useMe,
	useNavigate,
	useRestoreVirtuosoInitialIndex,
	useTranslation,
} from '@hooks';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import DealSortWidget from '@ui-modules/deals/components/DealSortWidget';
import MyDealCard from '@ui-modules/deals/components/MyDealCard';
import MyDealsZeroState from '@ui-modules/deals/components/MyDealsZeroState';
import ReviewSolicitationWaiverMessage from '@ui-modules/deals/components/ReviewSolicitationWaiverMessage';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import { useGetMyDeals } from '@ui-modules/deals/hooks/useGetMyDealsQuery';
import { useDealAlertsMutation } from '@ui-modules/deals/hooks/useDealAlertsMutation';
import styles from '../DealsTabsPage/DealsTabsPage.module.css';
import { DealEntity } from '@utils';
import type { ItemContent } from 'react-virtuoso';
import type { TDeal } from '@typings';

const MyDealsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	const { isMessageShown, isLoading: isWaiverLoading, onSubmit } = useSolicitationWaiver();
	const {
		data: myDeals,
		isLoading: isDealsLoading,
		fetchMore,
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		isFetched: myDealsFetched,
	} = useGetMyDeals();
	const { mutate: toggleAlerts } = useDealAlertsMutation();

	const showDeals = !isMessageShown;
	const showMessage = isMessageShown && !isWaiverLoading;
	const showActivityIndicator =
		(isWaiverLoading && !myDeals.length) || (isDealsLoading && !showMessage && !isWaiverLoading);

	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } =
		useRestoreVirtuosoInitialIndex('myDeals');

	const renderDealItem = useCallback<ItemContent<TDeal, unknown>>(
		(_, deal) => {
			if (!deal || DealEntity.isBlocked(blockedAndBlockingUsers, deal)) {
				return <Gap gap={1} />;
			}
			return (
				<MyDealCard
					assetName={deal.community.name}
					canSeePreview={DealEntity.canSeeReviewUpdates(deal, user)}
					className={styles.dealPost}
					createdAt={deal.createdAt}
					creator={deal.creator}
					creatorType={deal.creatorType}
					dealMemberRelation={deal.currentDealMemberRelation}
					dealName={deal.name}
					dealStatus={deal?.currentDealMemberRelation?.status}
					hasPreview={deal.hasPreview}
					key={deal.id}
					status={deal?.status}
					subtitleLink={ROUTES.networkTab(deal.community.id, 'deals')}
					onPress={() => {
						const clickedDealIndex = myDeals?.indexOf(deal) || 0;
						setInitialTopMostItemIndex(clickedDealIndex);
						navigate(ROUTES.dealTabs(deal.id, 'details'), { state: { restoreScrollRoute: 'myDeals' } });
					}}
					onToggleAlerts={(data) => toggleAlerts({ ...data, dealId: deal.id })}
				/>
			);
		},
		[myDeals, blockedAndBlockingUsers],
	);

	return (
		<Page tabName="invest" title={t('My Deals')}>
			{showActivityIndicator && (
				<div
					style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 99, backgroundColor: '#333333' }}
				>
					<ActivityIndicator color="white" size="medium" type="fit" />
				</div>
			)}
			{showMessage && <ReviewSolicitationWaiverMessage onSubmit={onSubmit} />}
			{showDeals && (
				<>
					{myDeals.length ? (
						<div className={styles.dealTab__sortByWrapper}>
							<DealSortWidget
								selectedOption={selectedSortOption}
								sortOptions={sortOptions}
								title={t('Sort By')}
								onSelect={setSelectedSortOption}
							/>
						</div>
					) : null}
					<Virtuoso<TDeal>
						components={{
							EmptyPlaceholder: () => (
								<>
									{myDealsFetched && (
										<MyDealsZeroState
											onGoToInvestNetworks={() => navigate(ROUTES.networksType(INVEST_NETWORKS_TAB_SLUG))}
										/>
									)}
								</>
							),
							Footer: () => <Gap gap={8} />,
						}}
						data={myDeals}
						defaultItemHeight={137}
						endReached={fetchMore}
						initialTopMostItemIndex={initialTopMostItemIndex}
						itemContent={renderDealItem}
						rangeChanged={onRangeChanged}
						totalCount={myDeals?.length}
					/>
				</>
			)}
		</Page>
	);
};

export default MyDealsPage;
