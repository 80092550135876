import { useCallback } from 'react';

import {
	useMe,
	useRef,
	useEvent,
	useEffect,
	useNavigate,
	useTranslation,
	useFloatingHeader,
	useRestoreVirtuosoInitialIndex,
	useService,
	useToggleLikeMutation,
	useBlockedAndBlockingUsers,
} from '@hooks';
import { ROUTES } from '@constants';
import { ActivityIndicator, Gap } from '@ui-kit';
import { useDealsSearch } from '@ui-modules/deals/hooks/useDealsSearch';
import { useDealFilterName } from '@ui-modules/networks/hooks/useDealFilterName';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkDealsQuery } from '@ui-modules/deals/hooks/useNetworkDealsQuery';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import { useDealAlertsMutation } from '@ui-modules/deals/hooks/useDealAlertsMutation';

import DealPost from '@ui-modules/deals/components/DealPost';
import DealsSelectWidget from '@ui-modules/deals/components/DealsSelectWidget';
import ReviewSolicitationWaiverMessage from '@ui-modules/deals/components/ReviewSolicitationWaiverMessage';

import Content from './Content';

import type { ItemContent, VirtuosoHandle } from 'react-virtuoso';
import type { TDealFilter, TDealWithReactions } from '@typings';
import { DealEntity } from '@tiger21-llc/connect-shared/src/utils/DealEntity';

const HEADER_PLACEHOLDER_ITEM_COUNT = 2; // to handle shifts in list indexes & scrolls.

const NetworkDeals = ({ networkId }: INetworkDealsProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { data: community } = useCommunityQuery(networkId);

	const navigate = useNavigate();

	const listRef = useRef<VirtuosoHandle>(null);

	const excludeNotInterested = true;
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const {
		data: networkDeals,
		filterIdWithUpdatedDate,
		isLoading: isDealsLoading,
		refetch,
		isFetching,
		isFetched,
		fetchMore,
		isRefetching: isNetworkDealsRefetching,
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		exactDealFilter,
		isExactDealFilterLoading,
		isDealFilterFetched,
		hasDataByFilter,
	} = useNetworkDealsQuery(networkId, excludeNotInterested);

	const RESTORE_KEY = `networkDeals-${networkId}-${filterIdWithUpdatedDate}-${JSON.stringify(sortOptions)}`;
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(
		RESTORE_KEY,
		HEADER_PLACEHOLDER_ITEM_COUNT,
	);

	const {
		searchResult,
		searchQuery,
		showSearchBar,
		showSearchResults,
		isSearchFetching,
		isSearchFetched,
		searchMore,
		setSearchQuery,
		setIsSearchFocused,
	} = useDealsSearch(networkId);

	const {
		floatingHeaderRef,
		floatingHeaderClassName,
		Placeholder,
		onScroll,
		itemsRendered,
		dataWithPlaceholders: networkDealsWithPlaceholders,
		floatingHeaderHeight,
	} = useFloatingHeader(
		showSearchResults ? (searchResult as TDealWithReactions[]) : networkDeals,
		initialTopMostItemIndex < HEADER_PLACEHOLDER_ITEM_COUNT,
		showSearchBar ? DealsSelectWidget.SEARCH_BAR_HEIGHT : undefined,
	);

	const { isMessageShown, isLoading: isWaiverLoading, onSubmit } = useSolicitationWaiver(community?.id);
	const dealsShown = !isMessageShown && !isWaiverLoading;

	const showMessage = isMessageShown && !isWaiverLoading;
	const onAddNewDeal = () => community && navigate(ROUTES.newDeal(community['@id']));
	const showActivityIndicator = (isWaiverLoading && !networkDeals?.length) || (isDealsLoading && !showMessage);
	const reactQuery = useService('ReactQueryService');

	const { mutate: toggleLike } = useToggleLikeMutation({
		onSuccess: (updatedActivity) => reactQuery.refetchDealsAfterReactionsMutation(updatedActivity),
	});

	const { mutate: toggleAlerts } = useDealAlertsMutation();

	const { filterTitle, readyToRender } = useDealFilterName({
		networkId,
		exactDealFilter: exactDealFilter?.[0] as TDealFilter,
	});

	const renderDealItem = useEvent<ItemContent<TDealWithReactions, unknown>>((index, deal) => {
		if (index === 0) {
			return (
				<DealsSelectWidget
					communityId={networkId}
					filters={exactDealFilter?.[0] as Partial<TDealFilter>}
					filterTitle={filterTitle}
					floatingHeaderClassName={networkDeals.length ? floatingHeaderClassName : undefined} // do not apply floating position when zero state is shown (T21C-5058)
					floatingHeaderRef={floatingHeaderRef}
					isFiltersLoading={isNetworkDealsRefetching || isExactDealFilterLoading}
					searchQuery={searchQuery}
					selectedSortOption={selectedSortOption || readyToRender || isDealFilterFetched}
					showSearchBar={showSearchBar}
					sortDisabled={!hasDataByFilter} // show sort bar only when data by filter exists and there is no reloading with new sort options.
					sortOptions={sortOptions}
					title={t('Invest')}
					onAddNewDeal={onAddNewDeal}
					onChangeFocus={setIsSearchFocused}
					onChangeSearchQuery={setSearchQuery}
					onSelectSortOption={setSelectedSortOption}
				/>
			);
		} else if (index === 1) {
			return <Placeholder />;
		}
		if (!deal || DealEntity.isBlocked(blockedAndBlockingUsers, deal)) {
			/* Used to resolve a Virtuoso list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko] */
			return <Gap gap={1} />;
		}

		return (
			<DealPost
				canSeePreview={DealEntity.canSeeReviewUpdates(deal, user)}
				currentNetworkId={networkId}
				deal={deal}
				highlightedText={searchQuery}
				likingDisabled={!('latestLikes' in deal)} // duck typing to ensure it is TDealWithReaction but not a TDeal as when searching.}
				restoreScrollRoute={selectedSortOption.field === 'updatedAt' ? RESTORE_KEY : ''}
				onClick={() => {
					const clickedDealIndex = networkDealsWithPlaceholders?.indexOf(deal) || 0;
					setInitialTopMostItemIndex(clickedDealIndex);
				}}
				onLike={(liked) => toggleLike({ activity: deal, liked })}
				onTextCollapse={() =>
					listRef.current?.scrollToIndex({
						index: networkDealsWithPlaceholders?.indexOf(deal) || 0,
						offset: -floatingHeaderHeight,
						behavior: 'smooth',
					})
				}
				onToggleAlerts={(data) => toggleAlerts({ ...data, dealId: deal.id })}
			/>
		);
	});

	const handleRefetch = useCallback(() => {
		if (!showActivityIndicator && !isFetched) {
			const timeoutId = setTimeout(() => {
				refetch();
			}, 500);

			return () => {
				clearTimeout(timeoutId); // Clear the timeout when the effect is cleaned up
			};
		}
	}, [showActivityIndicator, refetch, isFetched]);

	useEffect(handleRefetch, [handleRefetch]);

	if (isWaiverLoading) return <ActivityIndicator color="white" size="medium" type="fit" />;
	if (showMessage) return <ReviewSolicitationWaiverMessage onSubmit={onSubmit} />;
	return (
		<>
			{dealsShown && (
				<Content
					fetchMore={fetchMore}
					initialTopMostItemIndex={initialTopMostItemIndex}
					isDealsLoading={isDealsLoading}
					isFetching={isFetching}
					isNetworkDealsRefetching={isNetworkDealsRefetching}
					isSearchFetched={isSearchFetched}
					isSearchFetching={isSearchFetching}
					itemsRendered={itemsRendered}
					listRef={listRef}
					networkDeals={networkDeals}
					networkDealsWithPlaceholders={networkDealsWithPlaceholders}
					renderDealItem={renderDealItem}
					searchMore={searchMore}
					searchResult={searchResult}
					showSearchBar={showSearchBar}
					showSearchResults={showSearchResults}
					onRangeChanged={onRangeChanged}
					onScroll={onScroll}
				/>
			)}
		</>
	);
};

export interface INetworkDealsProps {
	networkId: string;
}

export default NetworkDeals;
