import { isUndefined, toString } from '@utils';
import type { TFunction } from 'i18next';
import {
	NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT,
	NETWORK_CONNECTION_OPTIONAL_FIELD_CHARACTER_LIMIT,
	YUP_URL_VALIDATION_PATTERN,
} from '@constants';
import type { TNetworkConnectionTemplateField } from '@typings';

function isValidURL(url: string) {
	// Regular expression for URL validation
	return YUP_URL_VALIDATION_PATTERN.test(url);
}

const validateField = (field: TFieldWithValue, t: TFunction) => {
	const isRequired = isUndefined(field.required) || field.required;
	if (isRequired && !toString(field.value).trim()) return t('{{value}} is required', { value: field.label });
	if (field.type === 'link' && field.value) {
		const isValid = isValidURL(field.value);
		return isValid ? undefined : t('Not a valid URL. Enter a valid URL starting with “http”');
	}
	if (field.type === 'long-text' && field.value && field?.value?.length > NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT) {
		return t(`{{value}} must be max {{maxLength}} characters`, {
			value: field.label,
			maxLength: NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT,
		});
	}
	if (field.type === 'long-text' && field.value?.length && field.value?.trim()?.length === 0) {
		return t('{{value}} is required', { value: field.label });
	}
	if (field.type === 'boolean' && isRequired) {
		return undefined; // Ignore. The boolean field can't have the "required error" field because has value 'false' by default.
	}
	if (field.type === 'money' && field.value && Number(field.value) < 1) {
		return t('Input value cannot be lower than 0.');
	}
	if (
		field.type === 'string' &&
		field?.value &&
		field?.value?.length > NETWORK_CONNECTION_OPTIONAL_FIELD_CHARACTER_LIMIT
	) {
		return t(`{{value}} must be max {{maxLength}} characters`, {
			value: field.label,
			maxLength: NETWORK_CONNECTION_OPTIONAL_FIELD_CHARACTER_LIMIT,
		});
	}
};

export const mapErrors = (values: TDynamicValues, t: TFunction) => {
	return Object.keys(values).reduce((acc, fieldSlug) => {
		const field: TFieldWithValue = values[fieldSlug];
		const error = validateField(field, t);
		if (error) {
			return {
				...acc,
				[fieldSlug]: error,
			};
		} else {
			return acc;
		}
	}, {});
};

type TFieldWithValue = TNetworkConnectionTemplateField & { value: string };

export type TDynamicValues = {
	[fieldSlug: string]: TFieldWithValue;
};

export type TTranslateFunction = (value: string, params?: object) => string;
