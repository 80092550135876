import { useTranslation } from '@hooks';
import styles from './EventNewTip.module.css';
import { Button } from '@ui-kit';

const EventNewTip = ({ eventId }: IEventNewTipProps) => {
	const { t } = useTranslation();
	/*TODO add  onClick, not to forget adding target link*/
	return <Button className={styles.eventNewTip} iconPosition="left" title={t('New Tip')} type="clear" />;
};

export interface IEventNewTipProps {
	eventId: string;
}

export default EventNewTip;
