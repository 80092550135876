import { Page } from '../../components';
import { useDefaults } from './NotificationSettingsGroupScreen.useDefaults';
import { useUserInitialSettings } from './NotificationSettingsGroupScreen.useUserInitialSettings';
import { useDefaultExpandedState } from './NotificationSettingsGroupScreen.useDefaultExpandedState';
import { ActivityIndicator } from '@ui-kit';
import { useParams, useTranslation, useNavigate } from '@hooks';
import { useNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationsSettings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import SettingsCard from '@ui-modules/settings/components/SettingsCard';
import DealSettingsCard from '@ui-modules/settings/components/DealSettingsCard';
import NetworksSettingsCard from '@ui-modules/settings/components/NetworksSettingsCard';
import { ROUTES } from '@constants';
import styles from './NotificationsSettingsGroupPage.module.css';
import { pluralizeUtils } from '@utils';
import ChapterSettingsCard from '@ui-modules/settings/components/ChapterSettingsCard';
import { useNotificationsSettingsWithChaptersQuery } from '@ui-modules/settings/hooks/useNotificationsSettingsWithChapters';

const NotificationsSettingsGroupPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const groupName = useParams<INotificationsSettingsGroupPageParams>()
		.groupName as INotificationsSettingsGroupPageParams['groupName'];
	const {
		defaultsSettings = [],
		notificationsSettings = [],
		isLoadingOfDefaultsSettings,
		isLoadingNotificationsSettings,
	} = useNotificationsSettings();
	const {
		data: { filteredChapters, isChapterOpen } = {},
		isLoading: isLoadingOfChaptersSettings,
		isFetching: isChaptersFetching,
	} = useNotificationsSettingsWithChaptersQuery({
		select: (chaptersSettings) => {
			const filteredChapters = chaptersSettings.filter((setting) => setting.communityDefinition === 'chapter');
			const isChapterOpen = filteredChapters.some((community) => community.massEmailAllowed);
			return { filteredChapters, isChapterOpen };
		},
	});
	const settings = useDefaults(groupName, defaultsSettings);
	const userInitialSettings = useUserInitialSettings(settings, notificationsSettings);
	const defaultExpanded = useDefaultExpandedState(groupName, userInitialSettings);
	const { addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();

	const onChangeCollapsed = (collapsed: boolean) => {
		return collapsed ? addCollapsedNotificationsSettings(groupName) : removeCollapsedNotificationsSettings(groupName);
	};
	const isLoading =
		isLoadingOfDefaultsSettings ||
		isLoadingNotificationsSettings ||
		isLoadingOfChaptersSettings ||
		isChapterOpen === null;

	return (
		<Page title={t('Notifications')}>
			{isLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : (
				<div className={styles.notificationsSettingsGroup}>
					<h3 className={styles.notificationsSettingsGroup__title}>
						{pluralizeUtils.singular(groupName)} {t('Notifications')}
					</h3>
					{groupName === 'Invest' ? (
						<DealSettingsCard
							onChangeCollapsed={onChangeCollapsed}
							onDealsDisabled={() => navigate(ROUTES.home(), { replace: true })}
						/>
					) : groupName === 'Networks' ? (
						<NetworksSettingsCard settings={settings} />
					) : groupName !== 'Chapter' ? (
						<SettingsCard
							initialSettings={userInitialSettings}
							isDefaultExpanded={defaultExpanded}
							settingsArray={settings}
							title={groupName}
							onChangeCollapsed={onChangeCollapsed}
						/>
					) : (
						<ChapterSettingsCard
							filteredChapters={filteredChapters}
							isChaptersFetching={isChaptersFetching}
							isDefaultExpanded={isChapterOpen}
							isLoadingOfChaptersSettings={isLoadingOfChaptersSettings}
							title={groupName}
							onChangeCollapsed={onChangeCollapsed}
						/>
					)}
				</div>
			)}
		</Page>
	);
};

export interface INotificationsSettingsGroupPageParams extends Record<string, string | undefined> {
	groupName: string;
}

export default NotificationsSettingsGroupPage;
