import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutateOptions } from '@tanstack/react-query';
import { makeTipReviewMock } from '@tiger21-llc/connect-shared/src/mockFactories/makeTipReviewMock';
import type { TTip, TTipOwner, TTipReview, TTipReviewOwner } from '@typings';

export const useDeleteTipMutation = (options?: MutateOptions<unknown, Error, Pick<TTip, 'id'>>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<unknown, Error, Pick<TTip, 'id'>>(
		['deleteTip'],
		async ({ id }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1000));
			// await api.tipJar.deleteTip(id);
		},
		{
			...options,
			onSuccess: (response, variables, context) => {
				const tipId = variables.id;
				const tip = reactQuery.queryClient.getQueryData<TTip>(reactQuery.queryKeys.tip(tipId));
				if (tip?.reviewsCount) {
					// TODO for testing only
					const newOwner: TTipOwner = {
						id: '122c151d-54c1-4253-8edc-9c963fcf8a1f',
						fullName: 'Hrant Abrahamyan',
						profileID: '122c151d-54c1-4253-8edc-9c963fcf8a1f',
						avatarUrl:
							'https://static.wikia.nocookie.net/looneytunes/images/c/c3/CECIL.png/revision/latest/thumbnail/width/360/height/360?cb=20200506184755',
						roles: ['ROLE_MEMBER'],
						communitiesForBadge: {
							names: ['Green Energy Invest Network for demo', 'Climate Investment Dummy Network'],
							shortNames: ['Green Energy Invest Network for demo', 'COM'],
						},
					};
					// TODO for testing only
					reactQuery.queryClient.setQueryData<TTip>(reactQuery.queryKeys.tip(tipId), () => ({
						...tip,
						owner: newOwner as TTipOwner,
					}));
					// TODO for testing only
					reactQuery.queryClient.setQueryData<TTipReview>(
						reactQuery.queryKeys.tipReview(tipId, String(tip.ownerReviewId)),
						(tipReview) => {
							if (!tipReview) return tipReview;
							return makeTipReviewMock({ owner: newOwner as TTipReviewOwner });
						},
					);
					showSuccess({ title: t('Tip owner is changed') });
				} else {
					showSuccess({ title: t('Tip deleted') });
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.myTips());
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.allTips());
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.tipsGroup());
					reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tip(tipId));
					reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipReviews(tipId));
					reactQuery.queryClient.removeQueries(reactQuery.queryKeys.tipContributors(tipId));
					options?.onSuccess?.(response, variables, context);
				}
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
