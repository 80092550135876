import type { TDealQuerySortField } from '@typings';
import React from 'react';
import type { ISortWidgetProps } from '@ui-kit';
import { SortWidget } from '@ui-kit';

const DealSortWidget = ({ sortOptions, selectedOption, onSelect, title, sortDisabled }: IDealSortWidgetProps) => {
	return (
		<SortWidget
			selectedOption={selectedOption}
			sortDisabled={sortDisabled}
			sortOptions={sortOptions}
			title={title}
			onSelect={onSelect}
		/>
	);
};

export interface IDealSortWidgetProps extends ISortWidgetProps<TDealQuerySortField> {}

export default DealSortWidget;
