import { CloseIcon } from '@ui-kit/icons';
import type { KeyboardEventHandler, MouseEventHandler, ReactNode } from 'react';
import type { TMixpanelEvent } from '@typings';
import Button from '../Button';
import Portal from '../Portal';
import styles from './Modal.module.css';
import { clsx } from '@utils';
import { ActivityIndicator } from '@ui-kit/components';

const Modal = ({
	visible,
	title,
	titleCentered = false,
	subTitle,
	confirmTitle,
	cancelTitle,
	onConfirm,
	onCancel,
	buttonsClassName,
	variant = 'medium',
	modalContent,
	confirmIcon,
	cancelMode = 'default',
	isConfirmLoading,
	isConfirmDisabled,
	confirmTrackingName,
	contentClassName,
	allowPropagation,
	confirmButtonClassName,
	onClose,
	subtitleClassName,
	customHeader,
}: IModalProps) => {
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		if (!allowPropagation) event.stopPropagation();
	};

	return (
		<>
			{visible ? (
				<Portal>
					<div className={styles.modal__container} onClick={handleClick} onKeyUp={handleClick}>
						<div className={clsx(styles.modal__content, contentClassName)} data-variant={variant}>
							{customHeader}
							<div className={styles.modal__titleWrapper}>
								{title ? (
									<span className={clsx(styles.modal__title, titleCentered && styles.modal__title_centered)}>
										{title}
									</span>
								) : null}
								{onClose && (
									<span
										aria-label="close"
										className={styles.modal__closeButton}
										role="button"
										tabIndex={-1}
										onClick={onClose}
										onKeyPress={onClose}
									>
										<CloseIcon height={12} width={12} />
									</span>
								)}
							</div>
							{subTitle ? <span className={clsx(styles.modal__subTitle, subtitleClassName)}>{subTitle}</span> : null}
							{modalContent}
							<div className={clsx(styles.modal__buttons, buttonsClassName)}>
								{cancelTitle && onCancel ? (
									<Button title={cancelTitle} type={cancelMode === 'default' ? 'clear' : 'solid'} onClick={onCancel} />
								) : (
									<div />
								)}
								{confirmTitle && onConfirm ? (
									<Button
										className={confirmButtonClassName}
										disabled={isConfirmDisabled || isConfirmLoading}
										icon={confirmIcon}
										iconPosition={'right'}
										loading={isConfirmLoading}
										title={confirmTitle}
										trackingName={confirmTrackingName}
										onClick={onConfirm}
									/>
								) : (
									<div />
								)}
							</div>
						</div>
					</div>
				</Portal>
			) : null}
		</>
	);
};

export interface IModalProps {
	confirmTitle?: string;
	confirmTrackingName?: TMixpanelEvent;
	cancelTitle?: string;
	visible: boolean;
	title?: string;
	titleCentered?: boolean;
	subTitle?: string;
	variant?: 'small' | 'medium' | 'large';
	onConfirm?: () => void;
	onCancel?: () => void;
	isConfirmLoading?: boolean;
	modalContent?: ReactNode;
	confirmIcon?: ReactNode;
	cancelMode?: 'default' | 'skip';
	onClose?: () => void;
	isConfirmDisabled?: boolean;
	contentClassName?: string;
	confirmButtonClassName?: string;
	allowPropagation?: boolean;
	subtitleClassName?: string;
	customHeader?: ReactNode;
	buttonsClassName?: string;
}

export default Modal;
