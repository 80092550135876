import { dayjs, findKey, isArray, isObject } from '@utils';
import { useTranslation } from '@hooks';
import { GLOBAL_SEARCH_DEFINITION } from '@constants';
import type { TSubtitleByDefinitionProps } from '@typings';
import { transformBiographyMatch } from './SubtitleByDefinition.utils';
import styles from './SearchResult.module.css';
import { RelativeTime } from '@ui-kit';

export const SubtitleByDefinition = ({
	searchQuery,
	definition,
	membersPotentialMatches,
	startDate,
	communityName,
	eventOrMeetingType,
	assetClass,
	relatedEntityName,
}: TSubtitleByDefinitionProps) => {
	const { t } = useTranslation();

	const getParsedText = (text: string, searchMatch?: string, parsedText?: string, relativeTime?: string) => {
		const regularForParse = searchMatch && new RegExp(searchMatch, 'g');
		const parsedTextWithBoldFragment =
			regularForParse && parsedText?.replace(regularForParse, '<b>' + searchMatch + '</b>');

		return (
			<div className={styles.searchResult__textWrapContainer}>
				{relativeTime ? (
					<RelativeTime className={styles.searchResult__subTitle} dateString={String(relativeTime)} />
				) : null}
				{text ? <span className={styles.searchResult__subTitle}>{text.toUpperCase()}</span> : null}
				{parsedTextWithBoldFragment ? (
					<span
						className={styles.searchResult__subTitle}
						dangerouslySetInnerHTML={{ __html: parsedTextWithBoldFragment }}
					></span>
				) : null}
			</div>
		);
	};

	const getMemberMatchByCategory = (matchCategories: Record<string, string[] | undefined>) => {
		const categoryName = findKey(matchCategories, (categories) => {
			let categoryParsed;

			if (typeof categories === 'string') {
				categoryParsed = [categories];
			} else if (isArray(categories)) {
				categoryParsed = categories;
			} else if (isObject(categories)) {
				categoryParsed = Object.values(categories as unknown as Record<number, string>);
			}

			return categoryParsed?.find?.((category) => {
				return category.toString().toLowerCase().includes(searchQuery.toLowerCase());
			});
		});

		const categoryMatch =
			categoryName &&
			matchCategories[categoryName]?.find((category) => category.toLowerCase().includes(searchQuery.toLowerCase()));

		switch (categoryName) {
			case 'communities':
				return getParsedText(t('member of'), searchQuery, categoryMatch);
			case 'expertise':
				return getParsedText(t('has expertise in'), searchQuery, categoryMatch);
			case 'investmentExpertise':
				return getParsedText(t('has investment expertise in'), searchQuery, categoryMatch);
			case 'interests':
				return getParsedText(t('interested in'), searchQuery, categoryMatch);
			case 'biography':
				return getParsedText(t('Biography:'), searchQuery, transformBiographyMatch(categoryMatch, searchQuery));
			default:
				return null;
		}
	};

	const getSubtitleByDefinition = (def: string, searchQueryMatch?: string) => {
		switch (def) {
			case GLOBAL_SEARCH_DEFINITION.member:
				return membersPotentialMatches ? getMemberMatchByCategory(membersPotentialMatches) : null;
			case GLOBAL_SEARCH_DEFINITION.network:
				return getParsedText(t('network'));
			case GLOBAL_SEARCH_DEFINITION.group:
				return getParsedText(t('tiger 21 group'));
			case GLOBAL_SEARCH_DEFINITION.chapter:
				return getParsedText(t('tiger 21 chapter'));
			case GLOBAL_SEARCH_DEFINITION.event:
			case GLOBAL_SEARCH_DEFINITION.meeting: {
				const formattedDate = startDate && dayjs(startDate, 'DD/MM/YYYY').format('DD MMM YYYY');
				// nevertheless that communityName is array of string,
				// we always have one string in them with name of community/network with current event/meeting
				return communityName && communityName.length
					? getParsedText(`${formattedDate}`, searchQueryMatch, `• ${communityName[0]} • ${eventOrMeetingType}`)
					: formattedDate
						? getParsedText(`${formattedDate}`, searchQueryMatch, ` • ${eventOrMeetingType}`)
						: null;
			}
			case GLOBAL_SEARCH_DEFINITION.discussion: {
				if (!communityName?.[0] || !startDate) return null;

				const formattedDate = startDate && dayjs(startDate).format('DD MMM YYYY');
				return getParsedText('', searchQueryMatch, `${communityName[0]} • ${formattedDate}`);
			}
			case GLOBAL_SEARCH_DEFINITION.learn: {
				const formattedDate = startDate && dayjs(startDate).format('DD MMM YYYY');
				return getParsedText(`LEARN • ${formattedDate}`);
			}
			case GLOBAL_SEARCH_DEFINITION.deal: {
				return getParsedText('•', searchQuery, assetClass, startDate);
			}
			case GLOBAL_SEARCH_DEFINITION.networkConnection: {
				return getParsedText('•', searchQuery, String([communityName?.[0]]), startDate);
			}
			case GLOBAL_SEARCH_DEFINITION.document: {
				const formattedDate = dayjs(startDate).format('DD MMM YYYY');
				return getParsedText(`${relatedEntityName} • ${formattedDate}`);
			}
			default:
				return null;
		}
	};

	return getSubtitleByDefinition(definition, searchQuery);
};
