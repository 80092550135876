import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import { makeTipReviewMock } from '@mocks';
import type { TCommunity, TEvent, TTip, TTipReviewWithReaction, TTipReview } from '@typings';

export const useTipReviewsQuery = (
	tipId: TTip['id'],
	context?: {
		contextCommunityId?: TCommunity['id'];
		contextCalendarIdItemId?: TEvent['id'];
	},
) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TTipReviewWithReaction>(
		context?.contextCommunityId
			? reactQuery.queryKeys.tipCommunityReviews(tipId, context.contextCommunityId)
			: context?.contextCalendarIdItemId
				? reactQuery.queryKeys.tipCalendarItemReviews(tipId, context.contextCalendarIdItemId)
				: reactQuery.queryKeys.tipReviews(tipId),
		async ({ pageParam = 1 }) => {
			// TODO use real API call when it's ready
			await new Promise((resolve) => setTimeout(resolve, 1500));
			const tipReviews = Array.from({ length: 10 }, () => makeTipReviewMock({ tipId }));
			return {
				data: tipReviews,
				meta: { hasNext: pageParam <= 3, hasPrevious: false },
				page: Number(pageParam),
			};
		},
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		},
	);
};
