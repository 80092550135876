import { useService, useQuery } from '@hooks';
import { makeTipSharesMock } from '@mocks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TTipShare, TTip } from '@typings';

export const useTipSharesQuery = <T = TTipShare[]>(
	tipId: TTip['id'],
	queryOptions?: UseQueryOptions<TTipShare[], Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TTipShare[], Error, T>(
		reactQuery.queryKeys.tipShares(tipId),
		async () => {
			await new Promise((resolve) => setTimeout(resolve, 700));
			return makeTipSharesMock();
		},
		queryOptions,
	);
};
