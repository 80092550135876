import { useMe } from './useMe';
import { useChatMessageQuery } from '@ui-modules/chat/hooks/useChatMessageQuery';
import { useReportChatMessageMutation } from '@ui-modules/chat/hooks/useReportChatMessageMutation';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { useReportDealMutation } from '@ui-modules/deals/hooks/useReportDealMutation';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import { useFeedCommentQuery } from '@ui-modules/feed/hooks/useFeedCommentQuery';
import { useReportFeedPostMutation } from '@ui-modules/feed/hooks/useReportFeedPostMutation';
import { useReportFeedReplyMutation } from '@ui-modules/feed/hooks/useReportFeedReplyMutation';
import { useReportDealReplyMutation } from '@ui-modules/feed/hooks/useReportDealReplyMutation';
import { ActivityEntity, toString } from '@utils';
import type { TReportContentForm } from '@schemas';
import type { IReportContentPageParams } from '../../app/pages/ReportContentPage/ReportContentPage';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useReportNetworkConnectionMutation } from '@ui-modules/deals/hooks/useReportNetworkConnectionMutation';
import type { TReportContentType } from '@typings';
import { useReportNetworkConnectionReplyMutation } from '../../ui-modules/feed/hooks/useReportNetworkConnectionReplyMutation';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';
import { useReportTipReviewMutation } from '@ui-modules/tipJar/hooks/useReportTipReviewMutation';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useReportTipMutation } from '@ui-modules/tipJar/hooks/useReportTipMutation';

export const useReportContent = (
	{ type, id, feedSlug }: IReportContentPageParams,
	{ onSuccess }: { onSuccess: () => void },
) => {
	const { user } = useMe();

	// Loading of reporting content to show. It conditionally determines what the source of content is.
	const { chatMessageId, activityId, commentId, dealId, networkConnectionId, tipReviewId, tipId } =
		convertReportContentId(type, id);
	const { data: chatMessage } = useChatMessageQuery(toString(chatMessageId));
	const { data: tipReview } = useTipReviewQuery(toString(tipReviewId), feedSlug);
	const { data: tip } = useTipQuery(toString(tipId));
	const { data: activity } = useFeedActivityQuery(toString(activityId));
	const { data: comment } = useFeedCommentQuery(toString(commentId));
	const { data: commentActivity } = useFeedActivityQuery(comment?.activity_id, feedSlug);
	const { data: deal } = useGetDealQuery(dealId);
	const { data: networkConnection } = useNetworkConnectionQuery(networkConnectionId as string, {
		enabled: !!networkConnectionId,
	});

	// Submit handling.
	const { mutateAsync: reportChatMessage } = useReportChatMessageMutation({ onSuccess }, chatMessage?.channel?.id);
	const { mutateAsync: reportFeedPost } = useReportFeedPostMutation({ onSuccess });
	const { mutateAsync: reportFeedComment } = useReportFeedReplyMutation({ onSuccess });
	const { mutateAsync: reportDealComment } = useReportDealReplyMutation({ onSuccess });
	const { mutateAsync: reportNetworkConnectionComment } = useReportNetworkConnectionReplyMutation({ onSuccess });
	const { mutateAsync: reportDeal } = useReportDealMutation({ onSuccess });
	const { mutateAsync: reportNetworkConnection } = useReportNetworkConnectionMutation({ onSuccess });
	const { mutateAsync: reportTipReview } = useReportTipReviewMutation({ onSuccess });
	const { mutateAsync: reportTip } = useReportTipMutation({ onSuccess });

	const reportContent = async (formValues: TReportContentForm) => {
		if (type === 'chat-message' && chatMessage) {
			return await reportChatMessage({
				...formValues,
				messageId: chatMessage.id,
				channelId: String(chatMessage.cid || chatMessage.channel?.id),
			});
		} else if (type === 'activity' && activity) {
			return await reportFeedPost({
				...formValues,
				activityId: String(activity.foreign_id),
				createdUserId: String(activity.actor.data.id),
				reportingUserId: String(user.slug),
				streamActivityId: String(activity.id),
			});
		} else if (type === 'comment' && comment && commentActivity) {
			const activityEntity = new ActivityEntity(commentActivity);
			const maybeDealId = activityEntity.getDealId();
			if (maybeDealId) {
				return await reportDealComment({
					...formValues,
					dealId: maybeDealId,
					commentId: String(comment.id),
				});
			}
			const maybeNetworkConnectionId = activityEntity.getNetworkConnectionId();
			if (maybeNetworkConnectionId) {
				return await reportNetworkConnectionComment({
					...formValues,
					networkConnectionId: maybeNetworkConnectionId,
					commentId: String(comment.id),
				});
			}
			return await reportFeedComment({
				...formValues,
				activityId: String(commentActivity.foreign_id),
				createdUserId: String(comment.user?.data.userId),
				reportingUserId: String(user.slug),
				streamActivityId: String(commentActivity.id),
				commentId: String(comment.id),
			});
		} else if (type === 'deal' && dealId) {
			return await reportDeal({ reason: formValues.explanation, dealId });
		} else if (type === 'connection' && networkConnection) {
			return await reportNetworkConnection({
				reason: formValues.explanation,
				networkConnectionId: String(networkConnectionId),
			});
		} else if (type === 'tip-review' && tipReview) {
			return await reportTipReview({
				reason: formValues.reason,
				explanation: formValues.explanation,
				tipId: tipReview.tipId,
				id: tipReview.id, //valid id from swager dev:  1efb3db6-23da-68fa-b8d2-41685efea1a4
			});
		} else if (type === 'tip' && tip) {
			return await reportTip({
				reason: formValues.reason,
				explanation: formValues.explanation,
				id: tip.id, //valid id from swager dev:  1efb3db6-23da-68fa-b8d2-41685efea1a4
			});
		}
	};
	return { reportContent, chatMessage, activity, comment, deal, networkConnection, tipReview, tip };
};

const convertReportContentId = (type: TReportContentType, id: string) => {
	const chatMessageId = type === 'chat-message' ? id : undefined;
	const activityId = type === 'activity' ? id : undefined;
	const commentId = type === 'comment' ? id : undefined;
	const dealId = type === 'deal' ? id : undefined;
	const networkConnectionId = type === 'connection' ? id : undefined;
	const tipReviewId = type === 'tip-review' ? id : undefined;
	const tipId = type === 'tip' ? id : undefined;
	return { chatMessageId, activityId, commentId, dealId, networkConnectionId, tipReviewId, tipId };
};
