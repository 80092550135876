import { useService, useQuery } from '@hooks';
import { makeTipMock } from '@mocks';
import { dayjs } from '@utils';
import type { TTip } from '@typings';

export const useTipQuery = (tipId: string | undefined) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return useQuery<TTip, Error>(
		reactQuery.queryKeys.tip(tipId as string),
		async () => {
			// TODO put real API call
			await new Promise((resolve) => setTimeout(resolve, 1000));
			return makeTipMock({ id: tipId, title: `Title of ${tipId} item` });
		},
		{
			enabled: !!tipId,
			staleTime: dayjs.duration(15, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minute').asMilliseconds(),
		},
	);
};
