import { CommunityEntity, memoize } from '@utils';
import type { TCommunity, TGroupTabName, TRole } from '@typings';

/** Determines abilities to see single group tabs in order to restrict access. */
export const getGroupTabsPermissions = memoize((group: TCommunity | undefined, userRoles: TRole[]) => {
	const tabs: TGroupTabName[] = [];

	if (group) {
		const isGroupUpcoming = CommunityEntity.isUpcomingGroup(group);
		const isMeetingsShowed = CommunityEntity.shouldShowMeetingsOrEvents(group, userRoles) || isGroupUpcoming;
		const isMembersShowed = CommunityEntity.shouldShowMembers(group, userRoles);
		const isDocumentsShowed = CommunityEntity.shouldShowDocumentsV2(group, userRoles);
		const isResourcesShowed = CommunityEntity.shouldShowResources(group);

		if (isMeetingsShowed) tabs.push('meetings');
		if (isMembersShowed) tabs.push('members');
		if (isDocumentsShowed) tabs.push('files');
		if (isResourcesShowed) tabs.push('resources');
		tabs.push('tips');

		return {
			tabs,
			isGroupUpcoming,
			isMeetingsShowed,
			isMembersShowed,
			isDocumentsShowed,
			isResourcesShowed,
		};
	} else {
		return {
			tabs,
		};
	}
});
