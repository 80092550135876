import { CloseIcon } from '@ui-kit';
import styles from './TipsImageCarousel.module.css';
import { noop } from '@utils';

const CarouselCloseButton = ({ hidden, onClick }: ICarouselCloseButtonProps) => {
	if (hidden) return null;
	return (
		<span className={styles.tipsImageCarousel_closeIcon} onClick={onClick} onKeyDown={noop}>
			<CloseIcon fill="black" height={8} width={8} />
		</span>
	);
};

interface ICarouselCloseButtonProps {
	hidden: boolean;
	onClick: () => void;
}

export default CarouselCloseButton;
