import ActivityIndicator from '../ActivityIndicator';
import styles from './Button.module.css';
import { useContext } from 'react';
import { UikitContext } from '../../contexts/UikitContext';
import { clsx } from '@utils';
import type { MouseEventHandler, ReactNode } from 'react';
import type { TMixpanelEvent } from '@typings';

/** Clickable rectangle button.
 *  Available types: 'outline', 'solid', 'clear', 'blurred',
 *  Can "fit" the whole width or be "inline"
 */
const Button = ({
	title,
	disabled = false,
	loading = false,
	variant = 'medium',
	block = 'inline',
	type = 'outline',
	icon: IconComponent,
	iconPosition = 'left',
	trackingName,
	testID,
	onClick,
	className,
	titleClassName,
}: IButtonProps) => {
	const { trackClickEvent } = useContext(UikitContext);

	const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.stopPropagation();
		if (onClick) onClick(event);
		if (trackingName) {
			trackClickEvent(trackingName);
		}
	};

	return (
		<button
			aria-label={title}
			className={clsx(styles.button, className)}
			data-block={block}
			data-loading={loading}
			data-testid={testID}
			data-type={type}
			data-variant={variant}
			disabled={disabled || loading}
			type="button"
			onClick={handleClick}
		>
			{IconComponent && iconPosition === 'left' ? <span className={styles.button__icon}>{IconComponent}</span> : null}
			{title ? <span className={clsx(styles.button__title, titleClassName)}>{title}</span> : null}
			{IconComponent && iconPosition === 'right' ? <span className={styles.button__icon}>{IconComponent}</span> : null}
			<div className={styles.button__activityIndicator}>
				<ActivityIndicator color={type === 'dark' ? '#fff' : '#000'} hidden={!loading} size="small" />
			</div>
		</button>
	);
};

export type TButtonType =
	| 'outline'
	| 'solid'
	| 'clear'
	| 'clear-light'
	| 'blurred'
	| 'transparent'
	| 'clear-error'
	| 'dark'
	| 'grey';
export type TButtonVariant = 'small' | 'medium';
export interface IButtonProps {
	/** Button label. */
	title?: string;
	/** Should it be able to be clicked. Default=false. */
	disabled?: boolean;
	/** Should show spinner instead of content. Default=false. */
	loading?: boolean;
	/** Size of the button. small=height:32, medium=height:42. Default=medium */
	variant?: TButtonVariant;
	/** Should button "fit" the whole width or be "inline". */
	block?: 'fit' | 'inline';
	/** Design of the button. */
	type?: TButtonType;
	/** React/HTML icon component (likely svg). If icon passed the "iconPosition" property is required */
	icon?: ReactNode;
	/** Position of icon component. Can be "left" or "right" */
	iconPosition?: 'left' | 'right';
	/** Name of the event sent to trackClickEvent function of UikitContext to pass it to the analytics service. */
	trackingName?: TMixpanelEvent;
	/** On click handler. Will not be called if button disabled or in loading. */
	onClick?: MouseEventHandler<HTMLButtonElement>;
	/** Class name of the button**/
	className?: string;
	/** Test ID to use with Jest. */
	testID?: string;
	/** Class name of the button title**/
	titleClassName?: string;
}

export default Button;
