import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useTranslation, useCallback } from '@hooks';
import { Input, TextArea, Gap, RadioGroupInput, CheckboxGroupInput, ClosingDateInput } from '@ui-kit';
import { get, isUndefined, dayjs, convertArrayToOptions, clsx } from '@utils';
import { dealFormFields, getDynamicDetailsFieldPath, getDynamicDetailsFieldValuePath } from '../DealForm.schema';
import type { TDealTemplateQueryResult } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { useGetDealTemplateQuery } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { extractIdFromIri } from '@utils';
import { DEAL_DESCRIPTION_CHARACTER_LIMIT } from '@constants';
import styles from './sections.module.css';

export const DealDetails = ({
	formProps,
	isEdit,
	dealDetails = {},
	disabled,
	minDescriptionRows,
}: IDealDetailsProps) => {
	const { t } = useTranslation();
	const { values, errors, setFieldValue, setFieldError, touched } = formProps;
	const isAssetClassFieldsTouched =
		get(touched, dealFormFields.SUB_CLASS, false) || get(touched, dealFormFields.TYPE, false);
	const { dealAssetClass = '', dealSubClass = '', dealType = '' } = values;
	const skipOverrideValues = isEdit && !isAssetClassFieldsTouched;

	const onQueryTemplateSuccess = useCallback(
		(response: TDealTemplateQueryResult) => {
			const { dealDetails } = response || {};
			!isEdit && setFieldValue('detailsTemplate', {}); // erase field values, fixes T21C-4501
			if (dealDetails?.fields) {
				dealDetails?.fields.map((field) => {
					if (!skipOverrideValues) {
						// Initialize dynamic fields values
						const fieldPath = getDynamicDetailsFieldPath(field.slug);
						setFieldValue(fieldPath, { ...field, value: '' });
					}
					return field.slug;
				});
			}
		},
		[setFieldValue, skipOverrideValues],
	);

	const { data } = useGetDealTemplateQuery(
		onQueryTemplateSuccess,
		extractIdFromIri(dealAssetClass || dealDetails.dealAssetClass || ''),
		extractIdFromIri(dealSubClass || dealDetails.dealSubClass || ''),
		extractIdFromIri(dealType || dealDetails.dealType || ''),
	);

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	const handleChangeSingleSelectField = (option: string, value: string, valuePath: string, isRequired: boolean) => {
		if (isRequired) {
			setFieldValue(valuePath, option);
		} else {
			value === option ? setFieldValue(valuePath, '') : setFieldValue(valuePath, option);
		}
	};

	const handleChangeMultiSelectField = (option: string, value: string[], valuePath: string) => {
		const newOptions = value?.includes(option)
			? value?.filter((el: string) => el !== option)
			: [...(value || []), option];
		setFieldValue(valuePath, newOptions.length ? newOptions?.sort() : undefined);
	};

	return (
		<DealFormSection className={styles.sectionDetails} title={t('Deal Details')}>
			<Input
				disabled={disabled}
				errorMessage={errors.title}
				label={t('Deal title')}
				placeholder={t('e.g. {{example}}', { example: `"Cybersecurity Startup VC Fund"` })}
				value={values.title}
				onChange={(e) => handleChangeField(dealFormFields.TITLE, e.target.value)}
			/>
			<TextArea
				disabled={disabled}
				errorMessage={
					values.description && values.description?.length > DEAL_DESCRIPTION_CHARACTER_LIMIT
						? `${values.description?.length ?? 0}/${DEAL_DESCRIPTION_CHARACTER_LIMIT}`
						: !values.description?.trim?.()?.length && errors.description
							? errors.description
							: ''
				}
				errorMessagePosition={values.description?.length > DEAL_DESCRIPTION_CHARACTER_LIMIT ? 'right' : 'left'}
				label={t('Deal description')}
				minRows={minDescriptionRows}
				rounded
				value={values.description}
				onChange={(e) => handleChangeField(dealFormFields.DESCRIPTION, e.target.value)}
			/>
			{(values.description?.length ?? 0) <= DEAL_DESCRIPTION_CHARACTER_LIMIT && (
				<p className={styles.dealDescription__counter}>
					{`${values.description?.length ?? 0}/${DEAL_DESCRIPTION_CHARACTER_LIMIT}`}
				</p>
			)}
			<ClosingDateInput
				dateFormat="DD MMM YYYY"
				disabled={disabled}
				label={t('Closing date (optional)')}
				minDate={dayjs().add(1, 'day').toDate()}
				placeholder={t('e.g. {{example}}', { example: '12 Sep 2025' })}
				value={values.closingDate ? new Date(values.closingDate) : null}
				onChange={(value) => {
					setFieldValue(dealFormFields.CLOSING_DATE, dayjs(value).utcOffset(0).format());
				}}
				onClear={() => setFieldValue(dealFormFields.CLOSING_DATE, null)}
			/>
			<Gap gap={4} />
			<div className={clsx(!!data?.dealDetails?.fields && styles.dynamicSection__container)}>
				{data?.dealDetails?.fields?.map((field) => {
					const fieldPath = getDynamicDetailsFieldPath(field.slug);
					const valuePath = getDynamicDetailsFieldValuePath(field.slug);
					const isRequired = isUndefined(field.required) || field.required;
					const value = get(values, valuePath);

					switch (field.type) {
						case 'multi-select':
							return (
								<CheckboxGroupInput
									containerClassName={styles.multiSelect__input}
									disabled={disabled}
									errorMessage={get(errors, fieldPath) as string}
									key={field.slug}
									label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
									options={convertArrayToOptions(field.choices)}
									value={value}
									onChange={(option) => {
										handleChangeMultiSelectField(option, value, valuePath);
										setFieldError(fieldPath, undefined);
									}}
								/>
							);
						case 'single-select':
							return (
								<>
									<RadioGroupInput
										backgroundColor="white"
										disabled={disabled}
										errorMessage={get(errors, fieldPath) as string}
										key={field.slug}
										label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
										options={convertArrayToOptions(field.choices)}
										value={value}
										onChange={(selectedValue) => {
											handleChangeSingleSelectField(selectedValue, value, valuePath, isRequired);
											setFieldError(fieldPath, undefined);
										}}
									/>
								</>
							);
						case 'date':
							return (
								<>
									<ClosingDateInput
										disabled={disabled}
										errorMessage={get(errors, fieldPath) as string}
										errorMessageInitialHidden
										key={field.slug}
										label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
										placeholder={field.placeholder ?? t('e.g. {{example}}', { example: '12 Sep 2025' })}
										value={value ? new Date(value) : null}
										onChange={(value) => {
											setFieldValue(valuePath, dayjs(value).format('YYYY-MM-DD'));
											setFieldError(fieldPath, undefined);
										}}
										onClear={() => {
											setFieldValue(valuePath, null);
											setFieldError(fieldPath, undefined);
										}}
									/>
								</>
							);
						default: {
							return (
								<Input
									disabled={disabled}
									errorMessage={get(errors, fieldPath) as string}
									errorMessageInitialHidden
									key={field.slug}
									label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
									placeholder={field.placeholder ?? field.label}
									value={value}
									onChange={(e) => {
										setFieldValue(valuePath, e.target.value);
										setFieldError(fieldPath, undefined);
									}}
								/>
							);
						}
					}
				})}
			</div>
		</DealFormSection>
	);
};

interface IDealDetailsProps extends IDealFormSectionSectionProps {
	dealDetails?: {
		dealAssetClass?: string;
		dealSubClass?: string;
		dealType?: string;
	};
	disabled?: boolean;
	minDescriptionRows?: number;
}
