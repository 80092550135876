import React from 'react';
import type { ISortWidgetProps } from '@ui-kit';
import { SortWidget } from '@ui-kit';
import type { TTipsQuerySortField } from '@typings';

const TipsSortWidget = ({ title, sortOptions, selectedOption, onSelect }: ITipsSortWidgetProps) => {
	return <SortWidget selectedOption={selectedOption} sortOptions={sortOptions} title={title} onSelect={onSelect} />;
};

interface ITipsSortWidgetProps extends ISortWidgetProps<TTipsQuerySortField> {}

export default TipsSortWidget;
