import type { ReactNode } from 'react';
import { clsx } from '@utils';

import styles from './CollapsibleSidebar.module.css';

const CollapsibleSidebar = ({ children, isOpened }: ICollapsibleSidebarProps) => {
	return (
		<aside className={clsx(styles.collapsibleSidebar, isOpened && styles.collapsibleSidebar_opened)}>{children}</aside>
	);
};

export interface ICollapsibleSidebarProps {
	children: ReactNode;
	isOpened: boolean;
}

export default CollapsibleSidebar;
