import { forwardRef } from 'react';
import { Formik, Gap, Input, TextArea } from '@ui-kit';
import TipCategoriesInput from '@ui-modules/tipJar/components/TipCategoriesInput';
import FilePickerField from '@ui-modules/files/components/FilePickerField';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import { useMe, useTranslation } from '@hooks';
import { createTipFormSchema } from '@schemas';
import styles from './CreateTipForm.module.css';
import { TipEntity } from '../../utils/TipEntity';
import { IMAGES_MIME_TYPES } from '@constants';
import type { TCreateTipForm, TPatchTipImage } from '@schemas';
import type { ForwardedRef, ReactNode } from 'react';
import type { TFile, TMediaObject } from '@typings';
import type { FormikProps } from 'formik';
import { compact } from 'lodash';
import { makeFileMock } from '@tiger21-llc/connect-shared/src/mockFactories/makeFileMock';

const IMAGES_ACCEPT = IMAGES_MIME_TYPES.reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});

const CreateTipForm = (
	{ initialValues, onSubmit, tipCategories, renderWrapper = ({ children }) => children }: ICreateTipFormProps,
	ref: ForwardedRef<FormikProps<TCreateTipForm>>,
) => {
	const { t } = useTranslation();
	const { user } = useMe();

	function mimicFile(userImage: TPatchTipImage | undefined): TFile | undefined {
		if (!userImage) return undefined;
		return makeFileMock(
			{
				name: userImage.name,
				owner: {
					firstName: user?.firstName,
					lastName: user?.lastName,
				} as TFile['owner'],
			},
			{
				originalName: userImage.name,
				contentUrl: userImage.url,
				owner: {
					firstName: user?.firstName,
					lastName: user?.lastName,
				} as TMediaObject['owner'],
			},
		);
	}
	return (
		<Formik<TCreateTipForm>
			initialValues={initialValues as TCreateTipForm}
			innerRef={ref}
			validateOnBlur
			validateOnChange={false}
			validationSchema={createTipFormSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			{(formProps) => {
				const {
					values,
					errors,
					handleChange,
					handleBlur,
					setFieldValue,
					handleSubmit,
					setFieldTouched,
					isSubmitting,
					isValid,
				} = formProps;

				return renderWrapper({
					formProps,
					children: (
						<div className={styles.createTipForm}>
							<div>
								<FilePickerField
									accept={IMAGES_ACCEPT}
									description={t('Add a photo of the Tip.')}
									label={t('Image Attachment')}
									title={t('Attach files')}
									value={mimicFile((values.images ?? []).find((image) => image.source === 'user'))}
									onReset={() => setFieldValue('photo', '')}
									onSelect={(file) => {
										setFieldValue(
											'images',
											compact([
												values.images.find((image) => image.source !== 'ai'),
												{
													source: 'user',
													url: URL.createObjectURL(file),
													name: file.name,
												},
											]),
										);
										setFieldTouched('images', true);
									}}
								/>
								<Gap gap={16} />
							</div>

							<Input
								errorMessage={errors.title}
								label={t('Title')}
								placeholder={t('e.g. Ritz Carlton Denver hotel')}
								value={values.title}
								onBlur={handleBlur('title')}
								onChange={handleChange('title')}
							/>

							<TipCategoriesInput
								errorMessage={errors.categories}
								label={t('Category')}
								tipCategories={tipCategories}
								value={values.categories}
								onChange={(newCategories) => {
									setFieldValue('categories', newCategories, true);
								}}
							/>

							<TextArea
								charactersLimit={TipEntity.descriptionLimit}
								errorMessage={errors.description}
								label={t('Description')}
								maxLength={TipEntity.descriptionLimit}
								maxRows={6}
								minRows={3}
								showCounter
								value={values.description}
								onBlur={handleBlur('description')}
								onChange={handleChange('description')}
							/>

							<Input
								autoCapitalize="none"
								errorMessage={errors.link}
								inputMode="url"
								label={t('Web link')}
								placeholder={t('e.g. https://www.ritzcarlton.com/en/hotels/denrz-the-ritz-carlton-denver')}
								value={values.link}
								onBlur={handleBlur('link')}
								onChange={handleChange('link')}
							/>

							<TipFormsSubmitButton
								disabled={!isValid}
								loading={isSubmitting}
								testID="SubmitButton"
								title={t('Submit')}
								onClick={() => handleSubmit()}
							/>
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface ICreateTipFormProps {
	initialValues: Partial<TCreateTipForm>;
	tipCategories: string[];
	onSubmit: (values: TCreateTipForm) => void;
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TCreateTipForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default forwardRef(CreateTipForm);
