import { memo } from 'react';
import { Formik, StarsRating, CheckboxInput, InputLabel, TextArea, FieldArray } from '@ui-kit';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import { useTranslation } from '@hooks';
import { tipReviewWithSharingFormSchema } from '@schemas';
import { TipReviewEntity } from '@ui-modules/tipJar/utils/TipReviewEntity';
import { dayjs } from '@utils';
import styles from './TipReviewForm.module.css';
import type { TEvent } from '@typings';
import type { TTipReviewWithSharingForm } from '@schemas';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';

const TipReviewForm = ({
	title,
	submitLabel,
	initialValues,
	targetMeetings = [],
	onSubmit,
	renderWrapper = ({ children }) => children,
}: ITipReviewFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TTipReviewWithSharingForm>
			initialValues={tipReviewWithSharingFormSchema.cast(initialValues) as TTipReviewWithSharingForm}
			isInitialValid={false}
			validationSchema={tipReviewWithSharingFormSchema}
			onSubmit={onSubmit}
		>
			{(formProps) => {
				const { values, isValid, isSubmitting, handleChange, setFieldValue, handleSubmit, setFieldTouched } = formProps;

				return renderWrapper({
					formProps,
					children: (
						<div className={styles.tipReviewForm}>
							<h3>{title}</h3>

							<div>
								<span>{t('Rate this Tip')}</span>
								<StarsRating
									iconSize={32}
									rating={values.rate}
									onClick={(newRating) => setFieldValue('rate', newRating)}
								/>
							</div>

							<div>
								<TextArea
									charactersLimit={TipReviewEntity.maxReviewLength}
									label={t('Add your Review')}
									maxLength={TipReviewEntity.maxReviewLength}
									maxRows={8}
									minRows={5}
									showCounter
									value={values.description}
									onChange={handleChange('description')}
								/>
							</div>

							<div className={styles.tipReviewForm__inputField}>
								<InputLabel text={t('Do you have a vested interest?')} />
								<CheckboxInput
									label={t('Yes')}
									value={values.vestedInterest}
									onChange={() => {
										setFieldValue('vestedInterest', !values.vestedInterest);
										setFieldTouched('vestedInterest');
									}}
								/>
							</div>

							{targetMeetings === null ? null : (
								<div className={styles.tipReviewForm__inputField}>
									<InputLabel text={t('Post to...')} />
									<FieldArray name="calendarItemsId">
										{() =>
											targetMeetings.map((meeting) => {
												const meetingId = meeting.id;
												const title = meeting.groups?.map?.((group) => group.community.shortName).join(', ');
												const date = dayjs(meeting.startDate).format('D MMM');
												const toggleMeeting = () => {
													const isAlreadySelected = values.calendarItemsId.includes(meetingId);
													setFieldValue(
														'calendarItemsId',
														isAlreadySelected
															? values.calendarItemsId.filter((id) => id !== meetingId)
															: [...values.calendarItemsId, meetingId],
													);
													setFieldTouched('calendarItemsId');
												};
												return (
													<CheckboxInput
														key={meeting.id}
														label={
															<>
																{t('Group meeting')} <b>{title}</b> {date}
															</>
														}
														value={values.calendarItemsId.includes(meeting.id)}
														onChange={toggleMeeting}
													/>
												);
											})
										}
									</FieldArray>
									<div className={styles.tipReviewForm__orSeparator}>
										<span className={styles.tipReviewForm__orSeparatorLabel}>{t('or')}</span>
									</div>
									<CheckboxInput
										label={t('Tip Jar (all members)')}
										value={values.calendarItemsId.length === 0}
										onChange={() => {
											setFieldValue('calendarItemsId', []);
											setFieldTouched('calendarItemsId');
										}}
									/>
								</div>
							)}

							<TipFormsSubmitButton
								disabled={!isValid}
								loading={isSubmitting}
								title={submitLabel}
								onClick={() => handleSubmit()}
							/>
						</div>
					),
				});
			}}
		</Formik>
	);
};

export interface ITipReviewFormProps {
	title: string;
	submitLabel: string;
	initialValues?: Partial<TTipReviewWithSharingForm>;
	targetMeetings: TEvent[] | null;
	children?: (formProps: FormikProps<TTipReviewWithSharingForm>) => JSX.Element;
	onSubmit: (values: TTipReviewWithSharingForm) => void | Promise<unknown>;
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TTipReviewWithSharingForm>;
		children: ReactNode;
	}) => ReactNode;
}

export default memo(TipReviewForm);
