import type { IDealStatus, TDeal, TMediaObject } from '@typings';
import { dealTemplateSectionSlug } from '@typings';
import { collectDealContentWhichMayHasUrls } from '@ui-modules/deals/utils/collectDealContentWhichMayHasUrls';
import { useCreateDealFilesMutation } from '@ui-modules/deals/hooks/useCreateDealFilesMutation';
import { useCheckTextOnMaliciousUrls, useNotification, useService, useTranslation } from '@hooks';
import { useUpdateDealMutation } from '@ui-modules/deals/hooks/useUpdateDealMutation';
import { useDeleteDealFileMutation } from '@ui-modules/deals/hooks/useDeleteDealFileMutation';
import type { FormikHelpers } from 'formik';
import type { TDealThirdPartyFormValues } from '../ThirdPartyDealForm.schema';
import { dealFormFields } from '../ThirdPartyDealForm.schema';
import { useSendDealPreviewMutation } from '@ui-modules/deals/hooks/useSendDealPreviewMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import { useManageDealPreviewFiles } from '@ui-modules/deals/hooks/useManageDealPreviewFiles';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';

export const useSubmitDeal = (
	dealId?: string,
	dealStatus?: IDealStatus,
	isPreviewRequests?: boolean,
	onSaved?: (dealReview: boolean) => void,
) => {
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess, showError } = useNotification();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });
	const { onPreviewDealSaved } = useManageDealPreviewFiles(dealId, isPreviewRequests);
	const { data: deal, refetch: refetchDeal } = useGetDealQuery(dealId);

	const { mutateAsync: updateDeal } = useUpdateDealMutation();
	const { mutateAsync: sendDealPreview } = useSendDealPreviewMutation();
	const { mutateAsync: createDealFiles } = useCreateDealFilesMutation();
	const { mutateAsync: deleteFile } = useDeleteDealFileMutation();
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		dealId: dealId as string,
		folderId: null,
	});

	const onDealSaved = async (
		deal: TDeal,
		owner: string,
		files: TMediaObject[] = [],
		dealReview: boolean,
		deletedFiles: string[],
		makeFileReadOnlyActions: TMediaObject['id'][],
		makeFileDownloadableActions: TMediaObject['id'][],
		handleClearFiles: () => void,
	) => {
		for (const fileId of deletedFiles) {
			await deleteFile(fileId);
		}
		for (const fileId of makeFileReadOnlyActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: true });
		}
		for (const fileId of makeFileDownloadableActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: false });
		}
		reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
		await createDealFiles(
			{ files, owner, relatedEntity: deal['@id'], isDealInformationFile: true },
			{
				onSuccess: async () => {
					if (dealReview) {
						// confirmation message will be showed as a result of onSaved callback in the parent component.
					} else if (deal.status === 'published') {
						showSuccess({ title: t('Updates Submitted Successfully') });
					} else {
						showSuccess({ title: t('Deal Updated') });
					}
					onSaved?.(dealReview);
					await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealDocuments(deal.id));
					handleClearFiles();
				},
				onError: async (error) => {
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(deal.id));
					showUnknownError(error);
				},
			},
		);
	};

	return async (
		{
			detailsTemplate,
			financialDetailsTemplate,
			webLink1,
			webLink2,
			webLink3,
			title,
			minInvestment,
			files = [],
			deletedFiles = [],
			makeFileReadOnlyActions = [],
			makeFileDownloadableActions = [],
			creator,
			videoUrl,
			...values
		}: TDealThirdPartyFormValues,
		formikHelpers: FormikHelpers<TDealThirdPartyFormValues>,
	) => {
		try {
			const detailsTemplateSection = detailsTemplate ? Object.values(detailsTemplate) : [];
			const financialDetailsTemplateSection = financialDetailsTemplate ? Object.values(financialDetailsTemplate) : [];
			const body = {
				sections: [
					detailsTemplateSection?.length
						? {
								section_slug: dealTemplateSectionSlug.DEAL_DETAILS,
								fields: detailsTemplateSection,
							}
						: null,
					financialDetailsTemplateSection.length
						? {
								section_slug: dealTemplateSectionSlug.FINANCIAL_DETAILS,
								fields: financialDetailsTemplateSection,
							}
						: null,
				].filter((el) => el !== null),
			};

			if (dealStatus === 'closed' || dealStatus === 'cancelled') {
				await updateDeal(
					{ id: dealId, deal: { data: { body } } },
					{
						onSuccess: async (deal) => {
							await onDealSaved(
								deal,
								creator.iriId,
								files,
								false,
								deletedFiles,
								makeFileReadOnlyActions,
								makeFileDownloadableActions,
								() => {
									formikHelpers.setFieldValue(dealFormFields.FILES, []);
									formikHelpers.setFieldValue(dealFormFields.DELETED_FILES, []);
								},
							);
							formikHelpers.setSubmitting(false);
						},
						onError: async (error) => {
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
				return;
			}

			const data = {
				...values,
				name: title,
				creator: creator.iriId,
				minInvestment: Number.parseInt(minInvestment?.replaceAll(',', ''), 10) || 0,
				webUrls: [webLink1, webLink2, webLink3].filter((link) => !!link),
				videoUrl: videoUrl?.length ? videoUrl : null,
				data: {
					body,
				},
			};
			await checkTextOnMaliciousUrls(collectDealContentWhichMayHasUrls(data));

			if (dealStatus === 'published') {
				// T21C-7246 problem deal preview synchronizing data if owner already approved/reject deal.
				const currentDealHasPreview = deal?.hasPreview;
				const { data: dealLastUpdatedData } = await refetchDeal();
				if (currentDealHasPreview && !dealLastUpdatedData?.hasPreview) {
					// T21C-7246 if 3rd party try to edit out of date data show error.
					showError({
						title: `Deal Update Failed`,
						subtitle: 'It seems the deal has been updated by its owner since you last accessed it.',
					});
					formikHelpers.setSubmitting(false);
					return;
				}

				await sendDealPreview(
					{ id: dealId, deal: { ...data } },
					{
						onSuccess: async (deal) => {
							await onPreviewDealSaved(
								deal,
								files,
								deletedFiles,
								makeFileReadOnlyActions,
								makeFileDownloadableActions,
								() => {
									formikHelpers.setFieldValue(dealFormFields.FILES, []);
									formikHelpers.setFieldValue(dealFormFields.DELETED_FILES, []);
									onSaved?.(false);
								},
							);
							formikHelpers.setSubmitting(false);
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealPreview(dealId));
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealPreviewStatus(dealId));
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
						},
						onError: async (error) => {
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
				return;
			}
			if (dealId) {
				let status = 'draft';
				if (values.submitAction === 'submit' || dealStatus === 'review') {
					status = 'review';
				}
				await updateDeal(
					{ id: dealId, deal: { ...data, status } },
					{
						onSuccess: async (deal) => {
							await onDealSaved(
								deal,
								creator.iriId,
								files,
								values.submitAction === 'submit',
								deletedFiles,
								makeFileReadOnlyActions,
								makeFileDownloadableActions,
								() => {
									formikHelpers.setFieldValue(dealFormFields.FILES, []);
									formikHelpers.setFieldValue(dealFormFields.DELETED_FILES, []);
									formikHelpers.setFieldValue(dealFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, []);
									formikHelpers.setFieldValue(dealFormFields.MAKE_FILE_DOWNLOADABLE_ACTIONS, []);
								},
							);
							formikHelpers.setSubmitting(false);
						},
						onError: async (error) => {
							reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
			}
		} catch (error) {
			showUnknownError(error as Error);
		} finally {
			formikHelpers.setSubmitting(false);
		}
	};
};
