import { Navigate, useLocation } from 'react-router-dom';
import { ActivityIndicator, Tabs } from '@ui-kit';
import BlockedUserMessage from '@ui-modules/profile/components/BlockedUserMessage';
import {
	useTranslation,
	useParams,
	useNavigate,
	useMe,
	useSearchParams,
	useEffect,
	useState,
	useMemo,
	useService,
} from '@hooks';
import { clsx, compact, DealEntity, DealFilesEntity } from '@utils';
import { ROUTES } from '../../routes';
import { Page, TabWrapper } from '../../components';
import NotFound404 from '../../components/NotFound404';
import DealDiscussions from '../DealDiscussions';
import DealHeader from '@ui-modules/deals/components/DealHeader';
import DealFilesWidget from '@ui-modules/deals/components/DealFilesWidget';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import DealMembers from '@ui-modules/deals/components/DealBlocks/DealMembers';
import type { TDeal, TDealTabName, TProfile } from '@typings';
import styles from './DealTabsPage.module.css';
import DealReviewInfoMessage from './DealReviewInfoMessage';
import { useDealAlertsMutation } from '@ui-modules/deals/hooks/useDealAlertsMutation';
import ReviewSolicitationWaiverMessage from '@ui-modules/deals/components/ReviewSolicitationWaiverMessage';
import { useNotifyOnDealClosedSoon } from '@ui-modules/deals/hooks/useNotifyOnDealClosedSoon';
import { DealDetails } from '../DealDetails';
import DealSubHeader from '@ui-modules/deals/components/DealSubHeader';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';
import { useGetDealPreviewQuery } from '@ui-modules/deals/hooks/useGetDealPreviewQuery';
import { useValidateDisplayingDeal } from '@ui-modules/deals/hooks/useValidateDisplayingDeal';
import { useGetDealPreviewFilesQuery } from '@ui-modules/deals/hooks/useGetDealPreviewFilesQuery';

const DealTabsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const [isReviewMode, setIsReviewMode] = useState(false);
	useDealsDisabledEffect();
	const dealId = useParams<IDealTabsPageParams>().dealId as IDealTabsPageParams['dealId'];
	const dealTab = useParams<IDealTabsPageParams>().dealTab as IDealTabsPageParams['dealTab'];
	const [searchParams] = useSearchParams();
	const folderId = searchParams.get('folderId') ?? null;
	const { state = {} } = useLocation();
	const reactQuery = useService('ReactQueryService');

	const {
		data: deal,
		isLoading,
		isError,
	} = useGetDealQuery(dealId, {
		onSuccess: (dealUpdated) => {
			reactQuery.updateDealsCache(dealId, (deal: TDeal) => {
				deal.hasPreview = dealUpdated.hasPreview;
				deal.status = dealUpdated.status;
			});
		},
	});

	const previewRequestEnabled = deal?.status === 'published' && DealEntity.canSeeReviewUpdates(deal, user);
	const { data: dealPreview } = useGetDealPreviewQuery(dealId, {
		enabled: previewRequestEnabled,
	});

	const { data: filesPreview, isLoading: isDealFilesPreviewLoading } = useGetDealPreviewFilesQuery(
		dealId,
		previewRequestEnabled,
	);

	const isOnlyDetailsTabPresent = deal?.status === 'draft' || deal?.status === 'review' || isReviewMode;

	const { mutate: toggleAlerts } = useDealAlertsMutation();
	const tabs: TDealTabName[] = compact(
		isOnlyDetailsTabPresent
			? ['details']
			: ['details', 'discussions', 'members', deal && DealFilesEntity.canSeeRootFiles(deal, user) ? 'files' : null],
	);
	const currentTabIndex = dealTab ? tabs.findIndex((tab) => tab === dealTab) : 0;
	const onChangeTab = (index: number) => {
		const tabName: TDealTabName = tabs[index] ?? tabs[0];
		navigate(ROUTES.dealTabs(dealId, tabName), { replace: true, state });
	};

	const onEditDeal = () =>
		navigate(ROUTES.editDeal(deal?.id), { state: { restoreScrollRoute: state?.restoreScrollRoute } });
	const goToProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	useEffect(
		function redirectFormMissingTab() {
			if (!isLoading && currentTabIndex === -1) {
				onChangeTab(0);
			}
		},
		[currentTabIndex, isLoading],
	);

	const { enabled } = useDealsEnabled();
	const { isLoading: isWaiverLoading, isMessageShown, onSubmit } = useSolicitationWaiver();

	useNotifyOnDealClosedSoon(deal);

	useEffect(() => {
		// In case if navigation to other deal and screen rerender need to reset reviewMode state. T21C-5711
		setIsReviewMode(false);
	}, [dealId]);
	useValidateDisplayingDeal(deal, function onError() {
		navigate(ROUTES.home(), { replace: true });
	});

	const isPageLoading = useMemo(() => {
		const isFilesLoading = previewRequestEnabled && isDealFilesPreviewLoading;
		return isWaiverLoading || isLoading || isFilesLoading;
	}, [previewRequestEnabled, isDealFilesPreviewLoading, isWaiverLoading, isLoading]);

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;
	else if (isPageLoading) {
		return (
			<div className={styles.dealTab__activityIndicator}>
				<ActivityIndicator size="large" />
			</div>
		);
	} else if (isMessageShown) {
		return (
			<div className={styles.dealTab__solicitationWaiverWrapper}>
				<ReviewSolicitationWaiverMessage onSubmit={onSubmit} />;
			</div>
		);
	} else if (isError || !deal) {
		return <NotFound404 />;
	}
	return (
		<Page tabName="invest" title={deal?.name || 'Deal'}>
			<div className={styles.dealTab__wrapper}>
				<div className={styles.dealTab__header}>
					<DealHeader
						deal={deal}
						subtitleLink={ROUTES.networkTab(deal.community.id, 'deals')}
						onBackPress={
							isReviewMode
								? () => {
										setIsReviewMode(false);
									}
								: undefined
						}
					/>
				</div>
				<div className={clsx(styles.dealTab__container, isReviewMode && styles.dealTab__reviewModeContainer)}>
					<DealReviewInfoMessage
						deal={deal}
						dealPreview={dealPreview}
						isFilesChanged={!!filesPreview?.length}
						isReviewMode={isReviewMode}
						onReviewChangesPress={() => setIsReviewMode(true)}
					/>
					<DealSubHeader
						deal={deal}
						dealPreview={isReviewMode ? dealPreview : undefined}
						isMessageShown={isMessageShown}
						isReviewMode={isReviewMode}
						onEditDeal={onEditDeal}
						onReviewAccept={() => setIsReviewMode(false)}
						onReviewChanges={() => setIsReviewMode(true)}
					/>
					<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
						<Tabs.List
							backgroundColor="#333333"
							className={isOnlyDetailsTabPresent ? styles.dealTabs__container : undefined}
						>
							{tabs.map((tab) => {
								switch (tab) {
									case 'details':
										return (
											<Tabs.Tab
												key="details"
												nonSelectedClassName={styles.dealTab__tab}
												selectedClassName={styles.dealTab__tabSelected}
											>
												{t('Details')}
											</Tabs.Tab>
										);
									case 'discussions':
										return (
											<Tabs.Tab
												key="discussions"
												nonSelectedClassName={styles.dealTab__tab}
												selectedClassName={styles.dealTab__tabSelected}
											>
												{t('Discussions')}
											</Tabs.Tab>
										);
									case 'members':
										return (
											<Tabs.Tab
												key="members"
												nonSelectedClassName={styles.dealTab__tab}
												selectedClassName={styles.dealTab__tabSelected}
											>
												{t('Members')}
											</Tabs.Tab>
										);
									case 'files':
										return (
											<Tabs.Tab
												key="files"
												nonSelectedClassName={styles.dealTab__tab}
												selectedClassName={styles.dealTab__tabSelected}
											>
												{t('Files')}
											</Tabs.Tab>
										);
								}
							})}
						</Tabs.List>
						{tabs.map((tab) => {
							switch (tab) {
								case 'details':
									return (
										<Tabs.Panel key="details">
											<TabWrapper className={clsx(isReviewMode && styles.dealDetailTab__container)}>
												<DealDetails
													deal={deal}
													dealPreview={isReviewMode ? dealPreview : undefined}
													onGoToProfile={goToProfile}
												/>
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'discussions':
									return (
										<Tabs.Panel key="discussions">
											<BlockedUserMessage
												blockedDescription={t(
													'To view discussions on this {{entity}} by {{userName}}, please unblock them first.',
													{
														entity: 'deal',
														userName: deal.creator.fullName,
													},
												)}
												blockingDescription={t('You are not allowed to view discussions on this {{entity}}.', {
													entity: 'deal',
												})}
												userId={deal.creator.id}
											>
												<DealDiscussions
													deal={deal}
													onToggleAlerts={(data) => toggleAlerts({ ...data, dealId: deal?.id as string })}
												/>
											</BlockedUserMessage>
										</Tabs.Panel>
									);
								case 'members':
									return (
										<Tabs.Panel key="members">
											<TabWrapper>
												<DealMembers dealId={deal?.id as string} />
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'files':
									return (
										<Tabs.Panel key="files">
											<TabWrapper>
												<DealFilesWidget dealId={deal.id} folderId={folderId} />
											</TabWrapper>
										</Tabs.Panel>
									);
							}
						})}
					</Tabs>
				</div>
			</div>
		</Page>
	);
};

export default DealTabsPage;

export interface IDealTabsPageParams extends Record<string, string | undefined> {
	dealTab: TDealTabName;
	dealId: string;
}
