import { usePaginatedInfiniteQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity, TPaginatedList, TChapterMember } from '@typings';
import type { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';

export const useChapterMembersQuery = (
	communityId: TCommunity['id'] | undefined,
	options?: Omit<UseInfiniteQueryOptions<TData, Error, TData, TData, QueryKey>, 'queryKey' | 'queryFn'>,
) => {
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TChapterMember>(
		['community.getChapterMembers', communityId],
		async ({ pageParam: page }) => await api.membership.getChapterMembers(communityId!, page),
		{
			enabled: !!communityId,
			staleTime: dayjs.duration(10, 'minutes').asMilliseconds(),
			...options,
		},
	);
};

type TData = TPaginatedList<TChapterMember>;
