import { useSearchParams } from '@hooks';
import { catchError, qs } from '@utils';
import { patchTipFormSchema, type TPatchTipForm } from '@schemas';

/** It parses the search params to use as initial values for Tip new/create forms
 *  and returns either the validated form values or a validation error in Node-style format.
 */
export const useTipFormSearchParams = () => {
	const [searchParams] = useSearchParams();
	const [tipFormParamsValidationError, tipFormValues = {} as TPatchTipForm] = catchError(
		() =>
			patchTipFormSchema.cast(qs.parse(searchParams.toString()), {
				stripUnknown: true,
				assert: false,
			}) as TPatchTipForm,
	);
	const tipFormWasEdited = searchParams.get('edited');

	return [tipFormParamsValidationError, tipFormValues, tipFormWasEdited] as const;
};
