import React, { forwardRef } from 'react';
import type { ComponentType } from 'react';
import type { SuggestionItemProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import { SuggestionListItem } from '@ui-kit';
import type { TFullUser, TRole } from '@typings';
import { useGetUserGroups } from '@hooks';
import { extractMentions } from '../../../../common/utils/extractMentions';

const AutocompleteSuggestionItem = ({
	item,
	onClickHandler,
	onSelectHandler,
	selected: focused,
	value,
}: SuggestionItemProps) => {
	const { userGroups } = useGetUserGroups(item?.userId);

	if (item.banned) return;
	const textToHighlight = extractMentions(value);

	if (isMention(item)) {
		return (
			<SuggestionListItem
				focused={focused}
				suggestion={{
					id: item.id,
					roles: item.roles,
					avatar: { contentUrl: item.avatar } as TFullUser['avatar'],
					display: item.name,
				}}
				userSubtitle={userGroups}
				onClickHandler={onClickHandler}
				textToHighlight={textToHighlight ?? ''}
				onSelectHandler={onSelectHandler}
			/>
		);
	} else {
		return null;
	}
};

export interface IAutocompleteSuggestionItemProps extends SuggestionItemProps<DefaultStreamChatGenerics> {}

export default forwardRef(AutocompleteSuggestionItem as any) as ComponentType<
	SuggestionItemProps<DefaultStreamChatGenerics>
>;

// Helper functions and types
const isMention = (output: any): output is TSuggestionUser => output.id != null && output.native == null;

export type TSuggestionUser = {
	avatar: string | null;
	banned: boolean;
	created_at: string;
	id: string;
	name: string;
	online: boolean;
	profileId: string;
	profileIri: string;
	profileType: string;
	role: 'user';
	roles: TRole[];
	updated_at: string;
	userId: string;
	userIri: string;
};
